import { useContext, useEffect, useCallback } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { DEFAULT_PROMPT_TEXT } from "../common/constants";

export const useBlocker = (blocker, when = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return undefined;
    }

    if (navigator && navigator.block && typeof navigator.block === "function") {
      const unblock = navigator.block((tx) => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            unblock();
            tx.retry();
          },
        };

        blocker(autoUnblockingTx);
      });

      return unblock;
    }

    return undefined;
  }, [navigator, blocker, when]);
};

export const usePrompt = (when = true, message = DEFAULT_PROMPT_TEXT) => {
  const blocker = useCallback(
    (tx) => {
      if (window.confirm(message)) {
        tx.retry();
      }
    },
    [message]
  );

  useBlocker(blocker, when);
};
