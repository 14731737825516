import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";

export const getEmployees = (state) => state[SLICE_NAME].employees;
export const getEmployee = (state) => state[SLICE_NAME].employee;
export const employeesIsGetted = (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const employeesIsLoading = (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const employeesIsUpdated = (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const employeesToast = (state) => state[SLICE_NAME].toast;
export const employeesToastIsView = (state) => state[SLICE_NAME].toast.view;
