import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const GET_PARAMS = {
  modal: "modal",
  modalId: "modal-id",
};

export const GET_ENUMS = {
  modals: {
    changePassword: "changePassword",
  },
};

export const useSearchModal = (name) => {
  const [searchParams] = useSearchParams();
  const modalName = searchParams.get(name);
  const modalId = searchParams.get(GET_PARAMS.modalId);

  const [mountedModal, setMountedModal] = useState(modalName);

  useEffect(() => {
    if (modalName) {
      setMountedModal(modalName);
    } else {
      setMountedModal(null);
    }
  }, [modalName]);

  return { modalId, isOpened: !!mountedModal, modalName };
};
