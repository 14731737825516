import React from "react";

export const ProductIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7697 8.85C19.7324 8.47871 19.558 8.13467 19.2807 7.88504C19.0033 7.63541 18.6429 7.49813 18.2697 7.5H16.4997C16.4997 4.185 14.4747 1.5 11.9997 1.5C9.52471 1.5 7.49971 4.185 7.49971 7.5H5.74471C5.37156 7.49813 5.01111 7.63541 4.73374 7.88504C4.45638 8.13467 4.28202 8.47871 4.24471 8.85L2.99971 20.85C2.97868 21.0592 3.00187 21.2705 3.06778 21.4702C3.13368 21.6699 3.24084 21.8534 3.38228 22.009C3.52372 22.1646 3.69629 22.2887 3.8888 22.3733C4.0813 22.4579 4.28944 22.5011 4.49971 22.5H19.4997C19.71 22.5011 19.9181 22.4579 20.1106 22.3733C20.3031 22.2887 20.4757 22.1646 20.6171 22.009C20.7586 21.8534 20.8657 21.6699 20.9316 21.4702C20.9975 21.2705 21.0207 21.0592 20.9997 20.85L19.7697 8.85ZM11.9997 3.405C13.4997 3.405 14.6997 5.52 14.6997 7.5H9.29971C9.29971 5.52 10.4997 3.405 11.9997 3.405ZM5.32471 20.25L6.40471 9.75H17.5947L18.6747 20.25H5.32471Z"
        fill={isActive ? "white" : "black"}
      />
    </svg>
  );
};
