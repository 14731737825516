import { useRef } from "react";
import { Flex, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";

import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { useIsOverflow } from "../../hooks/hooks";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";

const TableRow = ({ name, onDelete, onEdit }) => {
  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete();
  };

  const handleOnEnter = (event) => {
    event.stopPropagation();

    if (event.key === "Enter") {
      onEdit();
    }
  };

  const handleOnDelete = (event) => {
    event.stopPropagation();

    if (event.key === "Enter") {
      event.preventDefault();
      handleDelete(event);
    }
  };

  return (
    <Tr tabIndex={0} onClick={onEdit} onKeyDown={handleOnEnter}>
      <Td width="90%" style={TEXT_OVERFLOW_STYLE} cursor="pointer" ref={ref} role="gridcell">
        <TooltipWithContent text={name} isOverflow={isOverflow} />
      </Td>

      <Td width="10%" onClick={handleDelete} onKeyDownCapture={handleOnDelete} cursor="pointer" tabIndex={0}>
        <Flex justifyContent="end" gap="16px">
          <IconButton
            width="16px"
            height="16px"
            variant="unstyled"
            icon={<DeleteIcon />}
            aria-label="Delete"
            tabIndex={-1}
          />
        </Flex>
      </Td>
    </Tr>
  );
};
export default TableRow;
