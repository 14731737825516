import { LoadingState } from "../../../../store/common";

import { SLICE_NAME } from "./types";

export const getRequestedCourses = () => (state) => state[SLICE_NAME].coursesRequest;
export const requestedCoursesIsGetted = () => (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const requestedCoursesIsLoading = (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const requestedCoursesIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;

export const requestedCoursesToast = () => (state) => state[SLICE_NAME].toast;
export const requestedCoursesToastIsView = () => (state) => state[SLICE_NAME].toast.view;
