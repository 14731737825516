import { SPECIALIST } from "../../Banners/constants";

export const transformClientForSend = ({ role, warehouse, secondName, isVerifiedPerson, ...client }) => {
  const defaultValues = {
    secondName: secondName || null,
    role: role.value,
    warehouse: warehouse.id,
    ...client,
  };

  if (role.value === SPECIALIST) {
    return {
      isVerifiedPerson,
      ...defaultValues,
    };
  }

  return defaultValues;
};
