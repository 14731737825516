import { Flex, Box, Checkbox, Divider } from "@chakra-ui/react";

const optionHoverColor = "#DEEBFE";

export const CustomOption = ({ data, selectProps, innerProps, ...props }) => {
  const { payload, isChecked } = data.value;

  const { isAllSelected, isValuesEmpty, selectAllOptions } = selectProps;

  if (payload === "all") {
    return (
      <>
        <Box
          padding="8px 12px"
          {...innerProps}
          _hover={{
            background: optionHoverColor,
          }}
        >
          <Flex onClick={() => selectAllOptions(!isChecked)}>
            <Checkbox isChecked={isAllSelected} marginRight="10px" isIndeterminate={!isAllSelected && !isValuesEmpty} />
            <Box>Все</Box>
          </Flex>
        </Box>
        <Divider />
      </>
    );
  }

  return (
    <Box
      padding="8px 12px"
      {...innerProps}
      _hover={{
        background: optionHoverColor,
      }}
    >
      <Flex>
        <Checkbox isChecked={isChecked} marginRight="10px" />
        <Box>{data.label}</Box>
      </Flex>
    </Box>
  );
};
