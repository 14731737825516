import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { NAME_STYLES } from "../../common/constants";

const DEFAULT_TITLE = "Вы действительно хотите удалить";
const DEFAULT_CONFIRM_BTN = "Удалить";
const DEFAULT_CANCEL_BTN = "Отменить удаление";

const DeleteItemModal = ({
  label = "",
  item,
  title = DEFAULT_TITLE,
  descripion,
  confirmBtnText = DEFAULT_CONFIRM_BTN,
  cancelBtnText = DEFAULT_CANCEL_BTN,
  onClose,
  onDelete,
}) => {
  const handleClose = () => {
    onClose(null);
  };
  const handleDelete = () => {
    onDelete(item.id);
    handleClose();
  };

  const overflowScroll = item?.name?.length > 96 ? {} : { overflow: "hidden" };

  return (
    <Modal isCentered isOpen={item} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalBody
          style={{ fontSize: "24px", padding: "40px 40px 32px", textAlign: "center", flexDirection: "column" }}
        >
          <p style={{ marginBottom: "32px" }}>{`${title} ${label}`}</p>
          <p style={{ ...NAME_STYLES, ...overflowScroll }}>{`"${item.name}"?`}</p>
          <p>{descripion}</p>
        </ModalBody>

        <ModalFooter style={{ display: "flex", gap: "16px", flexDirection: "column", padding: "0 40px 40px" }}>
          <Button w="100%" bgColor="#C06ECC" color="#ffffff" onClick={handleDelete}>
            {confirmBtnText}
          </Button>
          <Button w="100%" bgColor="#ffffff" color="#C06ECC" onClick={handleClose}>
            {cancelBtnText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteItemModal;
