export const BannerIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.25 5.25V18.75H3.75V5.25H20.25ZM21 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V19.5C1.5 19.8978 1.65804 20.2794 1.93934 20.5607C2.22064 20.842 2.60218 21 3 21H21C21.3978 21 21.7794 20.842 22.0607 20.5607C22.342 20.2794 22.5 19.8978 22.5 19.5V4.5C22.5 4.10218 22.342 3.72064 22.0607 3.43934C21.7794 3.15804 21.3978 3 21 3ZM18 14.52V13.98C17.9584 13.6204 17.7787 13.2911 17.4988 13.0616C17.2189 12.8321 16.8607 12.7204 16.5 12.75H7.5C7.13925 12.7204 6.78111 12.8321 6.50122 13.0616C6.22132 13.2911 6.0416 13.6204 6 13.98V14.52C6.0416 14.8796 6.22132 15.2089 6.50122 15.4384C6.78111 15.6679 7.13925 15.7796 7.5 15.75H16.5C16.8607 15.7796 17.2189 15.6679 17.4988 15.4384C17.7787 15.2089 17.9584 14.8796 18 14.52ZM16.5 9.645V9.105C16.5122 8.79315 16.401 8.48905 16.1903 8.25876C15.9797 8.02846 15.6867 7.89056 15.375 7.875H8.625C8.31329 7.89056 8.02031 8.02846 7.80968 8.25876C7.59904 8.48905 7.48776 8.79315 7.5 9.105V9.645C7.48776 9.95685 7.59904 10.2609 7.80968 10.4912C8.02031 10.7215 8.31329 10.8594 8.625 10.875H15.375C15.6867 10.8594 15.9797 10.7215 16.1903 10.4912C16.401 10.2609 16.5122 9.95685 16.5 9.645Z"
        fill={isActive ? "white" : "#121212"}
      />
    </svg>
  );
};
