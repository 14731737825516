import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  InputGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Pagination } from "../../components/Pagination";
import PageLayout from "../../components/PageLayout/PageLayout";
import { usePagination } from "../../hooks/usePagination";

import {
  futureSeminarsIsUpdated,
  getFutureSeminars,
  seminarsToast as futureSeminarsToast,
  toastIsView as futureSeminarsToastIsView,
} from "./store/FutureSeminars/FutureSeminars.selectors";
import { getAllFutureSeminars } from "./store/FutureSeminars/FutureSeminars.thunk";
import { getAllSeminarsHistory } from "./store/SeminarsHistory/SeminarsHistory.thunk";
import {
  getSeminarsHistory,
  seminarsHistoryIsUpdated,
  seminarsHistoryToast,
  seminarsHistoryToastIsView,
} from "./store/SeminarsHistory/SeminarsHistory.selectors";
import { deleteToast } from "./store/FutureSeminars/FutureSeminars.slice";

import FutureSeminarsTable from "./FutureSeminars";
import SeminarsHistoryTable from "./SeminarsHistory";
import SeminarsRequestTable from "./SeminarsRequest";
import { deleteSeminarHistoryToast } from "./store/SeminarsHistory/SeminarsHistory.slice";
import {
  getSeminarsRequest,
  seminarsRequestIsUpdated,
  seminarsRequestToast,
  seminarsRequestToastIsView,
} from "./store/SeminarsRequest/SeminarsRequest.selectors";
import { deleteSeminarsRequestToast } from "./store/SeminarsRequest/SeminarsRequest.slice";
import { getAllSeminarsRequest } from "./store/SeminarsRequest/SeminarsRequest.thunk";

import { FUTURE_SEMINARS } from "./FutureSeminars/Table";
import { SEMINARS_HISTORY } from "./SeminarsHistory/Table";
import { SEMINARS_REQUEST } from "./SeminarsRequest/Table";
import InputSearch from "../../components/InputSearch";
import { useCustomToast } from "../../hooks/useCustomToast";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { onlyPhoneNumber } from "../../utils/regexp";
import { checkForNumericValue } from "../../utils/checkForNumericValue";
import { MAX_HEIGHT_SEMINAR_BODY } from "./constants";

const Seminars = () => {
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();

  // ===========    SELECTOR    ===============
  // =========== FUTURE SEMINARS ==============
  const futureSeminars = useSelector(getFutureSeminars())?.data;
  const totalFutureSeminars = useSelector(getFutureSeminars())?.count;
  const isFutureSeminarsNeedUpdate = useSelector(futureSeminarsIsUpdated());

  const futureSeminarsToastInfo = useSelector(futureSeminarsToast());
  const futureSeminarsToastIsViewed = useSelector(futureSeminarsToastIsView());

  // =========== SEMINARS HISTORY =============
  const seminarsHistory = useSelector(getSeminarsHistory())?.data;
  const totalSeminarsHistory = useSelector(getSeminarsHistory())?.count;
  const isSeminarsHistoryNeedUpdate = useSelector(seminarsHistoryIsUpdated());

  const seminarHistoryToastInfo = useSelector(seminarsHistoryToast());
  const seminarHistoryToastIsViewed = useSelector(seminarsHistoryToastIsView());

  // =========== SEMINARS REQUEST ============
  const seminarsRequest = useSelector(getSeminarsRequest())?.data;
  const totalSeminarsRequest = useSelector(getSeminarsRequest())?.count;
  const isSeminarsRequestNeedUpdate = useSelector(seminarsRequestIsUpdated());

  const seminarsRequestToastInfo = useSelector(seminarsRequestToast());
  const seminarsRequestToastIsViewed = useSelector(seminarsRequestToastIsView());

  const [currentTab, setCurrentTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchError, setSearchError] = useState(null);

  const totalPerTab = useMemo(() => {
    switch (currentTab) {
      case FUTURE_SEMINARS: {
        return totalFutureSeminars;
      }
      case SEMINARS_HISTORY: {
        return totalSeminarsHistory;
      }
      case SEMINARS_REQUEST: {
        return totalSeminarsRequest;
      }
      default: {
        return 0;
      }
    }
  }, [currentTab, totalFutureSeminars, totalSeminarsHistory, totalSeminarsRequest]);

  const { isPrevDisabled, isNextDisabled, page, limit, totalPages, nextPage, prevPage, resetPage, setPage, setLimit } =
    usePagination({
      total: totalPerTab,
    });

  const phoneNumberFormatting = () => {
    if (currentTab === SEMINARS_REQUEST) {
      if (searchInput.length < 3 || searchInput.length > 13) {
        setSearchError("Номер должен содержать от 3 до 13 символов");
        return;
      }
      setSearchQuery(searchInput.replace(onlyPhoneNumber, ""));
      return;
    }
    setSearchQuery(searchInput);
  };

  const handleChangeSearch = ({ target: { value } }) => {
    if (searchError) {
      setSearchError(null);
    }
    checkForNumericValue(value, setSearchInput);
  };

  const submitSearchQuery = (event) => {
    if (event.key === "Enter") {
      phoneNumberFormatting();
    }
  };

  const handleOnClickSubmit = () => {
    phoneNumberFormatting();
  };

  const requestFutureSeminars = useCallback(async () => {
    await dispatch(getAllFutureSeminars({ limit, page: page || 1, query: searchQuery })).unwrap();
  }, [limit, page, searchQuery, dispatch]);

  const requestSeminarsHistory = useCallback(async () => {
    await dispatch(getAllSeminarsHistory({ limit, page: page || 1, query: searchQuery })).unwrap();
  }, [limit, page, searchQuery, dispatch]);

  const requestSeminarsRequest = useCallback(async () => {
    await dispatch(getAllSeminarsRequest({ limit, page: page || 1, query: searchQuery })).unwrap();
  }, [limit, page, searchQuery, dispatch]);

  const cbResetQearchAndPages = useCallback(() => {
    resetPage();
    setSearchQuery("");
    setSearchInput("");
  }, [setSearchQuery, setSearchInput, resetPage]);

  // ============== TOASTS CALLBACKS ===========
  const cbFutureSeminarToast = useCallback(() => {
    if (futureSeminarsToastInfo.view) {
      pushToast(futureSeminarsToastInfo);
      dispatch(deleteToast());
    }
  }, [futureSeminarsToastInfo, dispatch]);
  const cbSeminarHistoryToast = useCallback(() => {
    if (seminarHistoryToastInfo.view) {
      pushToast(seminarHistoryToastInfo);
      dispatch(deleteSeminarHistoryToast());
    }
  }, [seminarHistoryToastInfo, dispatch]);
  const cbSeminarRequestToast = useCallback(() => {
    if (seminarsRequestToastInfo.view) {
      pushToast(seminarsRequestToastInfo);
      dispatch(deleteSeminarsRequestToast());
    }
  }, [seminarsRequestToastInfo, dispatch]);

  const handleDelete = () => {
    if (searchInput || searchQuery) {
      setSearchInput("");
      setSearchQuery(null);
      setSearchError(null);

      if (currentTab === FUTURE_SEMINARS) {
        cbFutureSeminarToast();
      }
      if (currentTab === SEMINARS_HISTORY) {
        requestSeminarsRequest();
      }
      if (currentTab === SEMINARS_REQUEST) {
        requestSeminarsHistory();
      }
    }
  };

  useEffect(() => {
    cbFutureSeminarToast();
  }, [futureSeminarsToastIsViewed, cbFutureSeminarToast]);

  useEffect(() => {
    cbSeminarHistoryToast();
  }, [seminarHistoryToastIsViewed, cbSeminarHistoryToast]);

  useEffect(() => {
    cbSeminarRequestToast();
  }, [seminarsRequestToastIsViewed, cbSeminarRequestToast]);

  useUpdateEffect(() => {
    if (currentTab === FUTURE_SEMINARS && isFutureSeminarsNeedUpdate) {
      requestFutureSeminars();
      cbResetQearchAndPages();
    }

    if (currentTab === SEMINARS_HISTORY && isSeminarsHistoryNeedUpdate) {
      requestSeminarsHistory();
      cbResetQearchAndPages();
    }

    if (currentTab === SEMINARS_REQUEST && isSeminarsRequestNeedUpdate) {
      requestSeminarsRequest();
      cbResetQearchAndPages();
    }
  }, [
    currentTab,
    isFutureSeminarsNeedUpdate,
    isSeminarsHistoryNeedUpdate,
    isSeminarsRequestNeedUpdate,
    requestFutureSeminars,
    requestSeminarsHistory,
    requestSeminarsRequest,
    cbResetQearchAndPages,
  ]);

  useEffect(() => {
    requestFutureSeminars();
  }, []);

  useUpdateEffect(() => {
    if (currentTab === FUTURE_SEMINARS) requestFutureSeminars();
    if (currentTab === SEMINARS_HISTORY) requestSeminarsHistory();
    if (currentTab === SEMINARS_REQUEST) requestSeminarsRequest();
  }, [currentTab, searchQuery, requestFutureSeminars, requestSeminarsHistory, requestSeminarsRequest]);

  return (
    <PageLayout>
      <Flex style={{ justifyContent: "end", marginBottom: "35px", width: "100%" }}>
        <InputGroup style={{ maxWidth: "320px" }}>
          {currentTab === SEMINARS_REQUEST ? (
            <FormControl isInvalid={searchError}>
              <InputSearch
                style={{ border: "1px solid #D0D3DA" }}
                placeholder="Поиск по номеру телефона"
                value={searchInput}
                onChange={handleChangeSearch}
                onKeyDown={submitSearchQuery}
                onClick={handleOnClickSubmit}
                onDelete={handleDelete}
              />
              <FormErrorMessage style={{ whiteSpace: "nowrap", position: "absolute" }}>{searchError}</FormErrorMessage>
            </FormControl>
          ) : (
            <InputSearch
              style={{ border: "1px solid #D0D3DA" }}
              placeholder="Поиск"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={submitSearchQuery}
              onClick={handleOnClickSubmit}
              onDelete={handleDelete}
            />
          )}
        </InputGroup>
      </Flex>

      <Tabs tabIndex={currentTab} height="calc(100% - 125px)" w="100%" onChange={setCurrentTab}>
        <TabList borderBottom="none" marginBottom="8px">
          <Flex style={{ width: "100%", justifyContent: "space-between" }}>
            <Flex color="#737680">
              <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Будущие</Tab>
              <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>История</Tab>
              <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Заявки на семинары</Tab>
            </Flex>
            <Pagination
              totalPages={totalPages}
              page={page}
              limit={limit}
              isPrevDisabled={isPrevDisabled}
              isNextDisabled={isNextDisabled}
              onNextPage={nextPage}
              onPrevPage={prevPage}
              onChangePage={setPage}
              onLimitChange={setLimit}
            />
          </Flex>
        </TabList>

        <TabPanels height={MAX_HEIGHT_SEMINAR_BODY}>
          <TabPanel height="100%" p="16px 0">
            <FutureSeminarsTable currentTab={currentTab} seminars={futureSeminars} />
          </TabPanel>
          <TabPanel height="100%" p="16px 0">
            <SeminarsHistoryTable currentTab={currentTab} seminars={seminarsHistory} />
          </TabPanel>
          <TabPanel height="100%" p="16px 0">
            <SeminarsRequestTable currentTab={currentTab} seminars={seminarsRequest} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
};
export default Seminars;
