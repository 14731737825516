import React from "react";

export const SeminarsIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8 12.8449H12.105C11.8768 12.8166 11.6669 12.7076 11.5148 12.5386C11.3627 12.3695 11.2788 12.1519 11.2788 11.9265C11.2788 11.7012 11.3627 11.4836 11.5148 11.3145C11.6669 11.1454 11.8768 11.0365 12.105 11.0082H16.8C17.0282 11.0365 17.2381 11.1454 17.3902 11.3145C17.5423 11.4836 17.6262 11.7012 17.6262 11.9265C17.6262 12.1519 17.5423 12.3695 17.3902 12.5386C17.2381 12.7076 17.0282 12.8166 16.8 12.8449ZM17.73 15.7837C17.73 15.5407 17.6325 15.3074 17.4585 15.1342C17.2845 14.961 17.048 14.8618 16.8 14.858H7.035C6.80678 14.8863 6.59694 14.9952 6.44484 15.1643C6.29274 15.3334 6.20882 15.551 6.20882 15.7763C6.20882 16.0017 6.29274 16.2193 6.44484 16.3884C6.59694 16.5574 6.80678 16.6664 7.035 16.6947H16.8C17.0467 16.6947 17.2832 16.5987 17.4576 16.4279C17.632 16.257 17.73 16.0253 17.73 15.7837ZM22.5 7.77551V19.5306C22.5 19.9203 22.342 20.2941 22.0607 20.5696C21.7794 20.8452 21.3978 21 21 21H3C2.60218 21 2.22064 20.8452 1.93934 20.5696C1.65804 20.2941 1.5 19.9203 1.5 19.5306V7.77551C1.5 7.3858 1.65804 7.01206 1.93934 6.7365C2.22064 6.46093 2.60218 6.30612 3 6.30612H4.935V4.10204C4.935 3.80976 5.05353 3.52945 5.2645 3.32278C5.47548 3.11611 5.76163 3 6.06 3C6.35837 3 6.64452 3.11611 6.8555 3.32278C7.06647 3.52945 7.185 3.80976 7.185 4.10204V6.30612H16.935V4.10204C16.935 3.80976 17.0535 3.52945 17.2645 3.32278C17.4755 3.11611 17.7616 3 18.06 3C18.3584 3 18.6445 3.11611 18.8555 3.32278C19.0665 3.52945 19.185 3.80976 19.185 4.10204V6.30612H21C21.3978 6.30612 21.7794 6.46093 22.0607 6.7365C22.342 7.01206 22.5 7.3858 22.5 7.77551ZM20.25 8.5102H3.75V18.7959H20.25V8.5102Z"
        fill={isActive ? "white" : "black"}
      />
    </svg>
  );
};
