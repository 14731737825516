import { LoadingState } from "../../../../store/common";

import { SLICE_NAME } from "./types";

export const getFutureSeminars = () => (state) => state[SLICE_NAME].seminars;
export const futureSeminarsIsGetted = () => (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const futureSeminarsIsLoading = (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const futureSeminarsIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const getSeminarFutureDetail = (state) => state[SLICE_NAME].seminar;

export const seminarsToast = () => (state) => state[SLICE_NAME].toast;
export const toastIsView = () => (state) => state[SLICE_NAME].toast.view;
