/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";

const LogoutConfirmModal = ({ onClose, onLogout }) => {
  return (
    <Modal isOpen isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody style={{ fontSize: "24px", textAlign: "center", padding: "40px 40px 0" }}>
          Вы действительно хотите выйти из системы
        </ModalBody>

        <ModalFooter style={{ display: "flex", gap: "8px", padding: "32px 40px 40px", flexDirection: "column" }}>
          <Button width="100%" color="#FFFFFF" bgColor="#C06ECC" onClick={onLogout}>
            Выйти
          </Button>
          <Button width="100%" color="#C06ECC" bgColor="#FFFFFF" onClick={onClose}>
            Остаться
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LogoutConfirmModal;
