import { useSelector } from "react-redux";
import SpecialistPromocodesTableRow from "./SpecialistPromocodesTableRow";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";
import { isLoading } from "./store/SpecialistPromocodes.selectors";
import { CONTAINER_STYLE } from "./style";

const TheadColumns = [
  { name: "ФИО специалиста", width: 30 },
  { name: "ПРОМОКОД", width: 15 },
  { name: "Покупатели", width: 15 },
  { name: "Общая сумма", width: 20 },
  { name: "Последний заказ", width: 20 },
];

const specialistPromocodesRow = (promocode) => (
  <SpecialistPromocodesTableRow key={`promocode-${promocode.name}`} values={promocode} />
);

const SpecialistPromocodesTable = ({ promocodes = [] }) => {
  const isLoadingPromocodes = useSelector(isLoading);

  return (
    <TableConstructor
      containerStyles={CONTAINER_STYLE}
      notFoundText="Здесь пока нет промокодов"
      Row={specialistPromocodesRow}
      items={promocodes}
      isLoading={isLoadingPromocodes}
      theadItems={TheadColumns}
    />
  );
};

export default SpecialistPromocodesTable;
