import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import TableRow from "./TableRow";
import { CONTAINER_STYLE } from "./style";
import { useSelector } from "react-redux";
import { seminarsRequestIsLoading } from "../store/SeminarsRequest/SeminarsRequest.selectors";
import { TableConstructor } from "../../../components/TableConstructor/TableConstructor";
import { useCallback } from "react";

export const SEMINARS_REQUEST = 2;

const TheadColumns = [
  { name: "Название семинара", width: 40 },
  { name: "Пользователь", width: 30 },
  { name: "Номер телефона", width: 20 },
  { name: "Дата", width: 10 },
];

const SeminarRow = ({ id, ...seminar }) => <TableRow key={`seminar-${seminar.id}`} seminar={{ id, ...seminar }} />;

const SeminarsTable = ({ seminars = [] }) => {
  const isLoading = useSelector(seminarsRequestIsLoading);

  return (
    <TableConstructor
      isLoading={isLoading}
      Row={SeminarRow}
      containerStyles={{ ...CONTAINER_STYLE, maxHeight: "100%" }}
      theadItems={TheadColumns}
      notFoundText="Здесь пока нет запросов на семинары"
      items={seminars}
    />
  );
};

export default SeminarsTable;
