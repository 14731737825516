import { createAsyncThunk } from "@reduxjs/toolkit";

import { getSeminarsRequest } from "../../../../services/seminarService";

import { SLICE_NAME } from "./types";

export const getAllSeminarsRequest = createAsyncThunk(
  `${SLICE_NAME}/fetchGetSeminarRequests`,
  async (params, { rejectWithValue }) => {
    try {
      const seminars = await getSeminarsRequest(params);

      return seminars?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
