import { useRef, useMemo, useState, useEffect } from "react";
import { Box, Flex, IconButton, InputGroup, InputRightElement, useDisclosure, useOutsideClick } from "@chakra-ui/react";
import { ControlDawn } from "../../assets/icon/ControlDawn";
import { InputOverflowText } from "../Autocomplete/index";

import { DEFAULT_CONTAINER, HOURS, MINUTES, OPTION_CONTAINER_STYLE, TIME_MASK } from "./constants";
import InputMask from "react-input-mask";
import { Option } from "./Option";
import { CrossIcon } from "../../assets/icon/CrossIcon";

const filterTime = (arr, max, min, stamp) =>
  arr.filter((time) => {
    const parsedMax = +max;
    const parsedMin = +min;
    const parsedTime = +time;

    return parsedMax >= parsedTime && parsedMin <= parsedTime && parsedTime % stamp === 0;
  });

export const getHours = (str) => str.split(":").at();
export const getMinutes = (str) => str.split(":").at(-1);

const TimePicker = ({
  value = "",
  onChange,
  isClearable = false,
  isChangeOnBlur = false,
  min,
  max,
  minHours = 9,
  maxHours = 23,
  stampHours = 1,
  stampMinutes = 1,
  maxMinute = 59,
  minMinute = 0,
  isDisabled = false,
}) => {
  const [inputValue, setInputValue] = useState(value ?? "");

  const ref = useRef(null);
  const { onToggle, isOpen, onClose } = useDisclosure();
  useOutsideClick({ ref, handler: onClose });

  const generatedMinHours = min ? +getHours(min) : minHours;
  const generatedMaxHours = max ? +getHours(max) : maxHours;
  const generatedMinMinutes = min ? +getMinutes(min) : minMinute;
  const generatedMaxMinutes = max ? +getMinutes(max) : maxMinute;

  const minutes = useMemo(
    () => filterTime(MINUTES, generatedMaxMinutes, generatedMinMinutes, stampMinutes),
    [generatedMaxMinutes, generatedMinMinutes, stampMinutes]
  );

  const hours = useMemo(
    () => filterTime(HOURS, generatedMaxHours, generatedMinHours, stampHours),
    [generatedMaxHours, generatedMinHours, stampHours]
  );

  const currentMinute = isChangeOnBlur ? getMinutes(inputValue) : getMinutes(value ?? "");

  const currentHour = isChangeOnBlur ? getHours(inputValue) : getHours(value ?? "");

  const handleChangeTime = (timeFromPicker, isHours) => {
    let preparedTime = value;
    const time = timeFromPicker.length > 1 ? timeFromPicker : `0${timeFromPicker}`;

    if (isHours && currentMinute) {
      preparedTime = `${time}:${currentMinute}`;
    }

    if (isHours && !currentMinute) {
      preparedTime = `${time}:00`;
    }

    if (!isHours && currentHour) {
      preparedTime = `${currentHour}:${time}`;
    }

    if (!isHours && !currentHour) {
      preparedTime = `${generatedMinHours.length > 1 ? generatedMinHours : `0${generatedMinHours}`}:${time}`;
    }

    return isChangeOnBlur ? setInputValue(preparedTime) : onChange(preparedTime);
  };

  useEffect(() => {
    setInputValue(value ?? "");
  }, [value]);

  useEffect(() => {
    if (!isOpen && isChangeOnBlur) {
      onChange(inputValue);
    }
  }, [isOpen, isChangeOnBlur]);

  return (
    <Box ref={ref} style={{ position: "relative" }}>
      <InputGroup>
        <InputOverflowText
          disabled={isDisabled}
          isReadOnly
          onClick={onToggle}
          autoComplete="off"
          value={isChangeOnBlur ? inputValue : value ?? ""}
          placeholder="--:--"
          as={InputMask}
          mask={TIME_MASK}
          maskChar={null}
        />

        <InputRightElement width={20} justifyContent="end">
          {value && isClearable && (
            <IconButton
              display="flex"
              justifyContent="center"
              alignItems="center"
              variant="unstyled"
              onClick={() => onChange("")}
              icon={<CrossIcon width="24" />}
            />
          )}
          <IconButton
            onClick={onToggle}
            icon={<ControlDawn />}
            style={{ backgroundColor: "transparent" }}
            color="black"
          />
        </InputRightElement>
      </InputGroup>

      {isOpen && !isDisabled && (
        <Box sx={{ ...OPTION_CONTAINER_STYLE, bottom: "40px" }}>
          <Box
            sx={{
              ...DEFAULT_CONTAINER,
              direction: "rtl",
            }}
          >
            {hours.map((hour) => (
              <Option
                handleSelect={() => handleChangeTime(hour, true)}
                isActive={+currentHour === +hour}
                key={hour}
                text={hour}
              />
            ))}
          </Box>

          <Box
            borderLeft="1px solid azure"
            sx={{
              right: 0,
              ...DEFAULT_CONTAINER,
            }}
          >
            {minutes.map((minute) => (
              <Option
                handleSelect={() => handleChangeTime(minute, false)}
                isActive={+currentMinute === +minute}
                key={minute}
                text={minute}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TimePicker;
