import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addFutureSeminar,
  deleteFutureSeminar,
  editFutureSeminar,
  getFutureSeminars,
  getFutureSeminar as getFutureSeminarRequest,
  addSeminarMedia,
} from "../../../../services/seminarService";

import { SLICE_NAME } from "./types";

export const getFutureSeminar = createAsyncThunk(
  `${SLICE_NAME}/fetchFutureSeminar`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await getFutureSeminarRequest(id);

      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getAllFutureSeminars = createAsyncThunk(
  `${SLICE_NAME}/fetchGetFutureSeminars`,
  async (params, { rejectWithValue }) => {
    try {
      const seminars = await getFutureSeminars(params);

      return seminars?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addingFutureSeminar = createAsyncThunk(
  `${SLICE_NAME}/fetchAddFutureSeminar`,
  async (params, { rejectWithValue }) => {
    try {
      return await addFutureSeminar(params);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const deletingFutureSeminar = createAsyncThunk(
  `${SLICE_NAME}/fetchDeleteFutureSeminar`,
  async (id, { rejectWithValue }) => {
    try {
      return await deleteFutureSeminar(id);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const editingFutureSeminar = createAsyncThunk(
  `${SLICE_NAME}/fetchEditFutureSeminar`,
  async (params, { rejectWithValue }) => {
    try {
      return await editFutureSeminar(params);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addSeminarMediaThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchAddSeminarMediaThunk`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await addSeminarMedia(params);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
