import { Box, Button, Input, Flex, Text } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect } from "react";
import { BonusesTable } from "./BonusesTable";
import { useDispatch, useSelector } from "react-redux";
import { getClientBonusesThunk, changeClientBonusesThunk } from "../../Clients/store/Clients.thunk";
import { getClientBonuses } from "../../Clients/store/Clients.selectors";
import { BONUSES_OPERATION_VALIDATION_SCHEMA } from "../constants";

const initialValues = {
  balance: 0,
  logs: [],
};

export const BonusesContent = ({ userId }) => {
  const dispatch = useDispatch();

  const bonuses = useSelector(getClientBonuses());

  const handleSaveBonusOperation = (values) => {
    dispatch(changeClientBonusesThunk({ amount: Number(values.balance), id: userId }));
  };

  const { values, errors, setFieldValue, handleSubmit, dirty, resetForm, handleChange } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: handleSaveBonusOperation,
    validationSchema: BONUSES_OPERATION_VALIDATION_SCHEMA,
  });

  useEffect(() => {
    if (userId && bonuses) {
      resetForm({
        values: {
          balance: String(bonuses.balance.balance),
          logs: bonuses.logs,
        },
      });
    }
  }, [userId, bonuses]);

  const isSaveButtonDisabled = !dirty || errors.balance;

  return (
    <Box textAlign="start">
      <Flex alignItems="center">
        <Input
          width="200px"
          name="balance"
          value={values.balance}
          onChange={handleChange}
          placeholder="Введите сумму"
        />

        <Button
          width="150px"
          onClick={handleSubmit}
          disabled={isSaveButtonDisabled}
          borderWidth={isSaveButtonDisabled ? "1px" : "0px"}
          colorScheme={isSaveButtonDisabled ? "gray" : "purple"}
          borderColor="gray"
          marginLeft="15px"
        >
          Сохранить
        </Button>

        {errors.balance && (
          <Text color="purple" marginLeft="15px" height="25px">
            {errors.balance}
          </Text>
        )}
      </Flex>
      <Box marginTop="20px">
        <BonusesTable bonusLogs={bonuses?.logs ?? []} />
      </Box>
    </Box>
  );
};
