export const checkForNumericValue = (value, cb) => {
  try {
    const onlyNum = value.replace(/\D/g, "");
    const intValue = Number(onlyNum);

    if (onlyNum === "" || intValue || intValue === 0) {
      cb(onlyNum);
    }
  } catch (e) {
    console.log(e);
  }
};
