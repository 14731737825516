import React from "react";

export const CopyIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M11.9996 6.39998L13.5996 6.39998C14.0414 6.39998 14.3996 6.75815 14.3996 7.19998L14.3996 12.4C14.3996 13.5045 13.5042 14.4 12.3996 14.4L7.19961 14.4C6.75778 14.4 6.39961 14.0418 6.39961 13.6L6.39961 12M7.99961 1.59998L3.19961 1.59998C2.31595 1.59998 1.59961 2.31632 1.59961 3.19998L1.59961 7.99997C1.59961 8.88363 2.31595 9.59997 3.19961 9.59997L7.99961 9.59998C8.88326 9.59998 9.59961 8.88363 9.59961 7.99997L9.59961 3.19998C9.59961 2.31632 8.88327 1.59998 7.99961 1.59998Z"
        stroke="#818590"
      />
    </svg>
  );
};
