import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";
import { Button, Checkbox, Flex, Th } from "@chakra-ui/react";

import SelectedSeminarsPopUp from "../SelectedSeminarsPopUp";
import DeleteSeminarModal from "../DeleteSeminarModal";
import { deletingSeminarHistory } from "../store/SeminarsHistory/SeminarsHistory.thunk";

import TableRow from "./TableRow";
import { CONTAINER_STYLE } from "./style";
import { useNavigate } from "react-router-dom";
import { seminarsHistoryIsLoading } from "../store/SeminarsHistory/SeminarsHistory.selectors";
import { TableConstructor } from "../../../components/TableConstructor/TableConstructor";

export const SEMINARS_HISTORY = 1;

const TheadColumns = [
  { name: "Название семинара", width: 80 },
  { name: "Дата", width: 10 },
  { name: "", width: 10 },
];

const SeminarsHistoryTable = ({ currentTab, seminars = [] }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(seminarsHistoryIsLoading);

  const [isCheckedAll, setCheckedAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState(Map());
  const [deleteSeminar, setDeleteSeminar] = useState(null);
  const [deleteSeminars, setDeleteSeminars] = useState(false);

  const isIndeterminate = checkedItems.some(Boolean) && !isCheckedAll;

  const handleUnselectAll = () => {
    setCheckedItems(checkedItems.clear());
  };
  const handleCheckAll = () => {
    if (isCheckedAll) handleUnselectAll();
    else seminars.forEach(({ id, name }) => setCheckedItems((set) => set.set(id, name)));
  };
  const handleSelectSeminar = useCallback(
    ({ id, name }, isSelected) =>
      isSelected ? setCheckedItems(checkedItems.delete(id)) : setCheckedItems(checkedItems.set(id, name)),
    [checkedItems]
  );

  const handleDelete = useCallback((seminar) => setDeleteSeminar(seminar), []);

  const handleDeleteConfirm = ({ id }) => {
    dispatch(deletingSeminarHistory({ id }));
  };
  const handleDeleteSeminarsConfirm = (seminarsMap) => {
    setCheckedItems(checkedItems.clear());
    seminarsMap.forEach((v, k) => dispatch(deletingSeminarHistory({ id: k })));
  };

  useEffect(() => {
    setCheckedAll(seminars?.length ? checkedItems.size === seminars?.length : false);
  }, [checkedItems, seminars, setCheckedAll]);

  useEffect(() => {
    if (currentTab !== SEMINARS_HISTORY && checkedItems.size > 0) {
      setCheckedItems(checkedItems.clear());
    }
  }, [currentTab, checkedItems, setCheckedItems, setCheckedAll]);

  const handleAddSeminar = () => navigate("history/create");

  const handleEditSeminar = ({ id }) => navigate(`history/${id}`);

  const seminarRow = (seminar) => (
    <TableRow
      key={`seminar-history-${seminar.id}`}
      seminar={seminar}
      isSelected={checkedItems.has(seminar.id)}
      onEdit={handleEditSeminar}
      onDelete={handleDelete}
      onSelect={handleSelectSeminar}
    />
  );

  return (
    <>
      <Flex gap={1} mb="16px">
        <Button w="100%" marginLeft="auto" colorScheme="purple" onClick={handleAddSeminar}>
          Добавить семинар
        </Button>
      </Flex>

      <TableConstructor
        Row={seminarRow}
        containerStyles={CONTAINER_STYLE}
        theadExtraComponent={
          <Th textAlign="center" lineHeight={0}>
            <Checkbox isChecked={isCheckedAll} isIndeterminate={isIndeterminate} onChange={handleCheckAll} />
          </Th>
        }
        theadItems={TheadColumns}
        items={seminars}
        notFoundText="Здесь пока нет семинаров"
      />

      <DeleteSeminarModal
        seminar={deleteSeminar}
        onClose={() => setDeleteSeminar(null)}
        onDelete={handleDeleteConfirm}
      />

      <DeleteSeminarModal
        seminars={deleteSeminars && checkedItems}
        onClose={() => setDeleteSeminars(false)}
        onDelete={handleDeleteSeminarsConfirm}
      />

      {!!checkedItems?.size && (
        <SelectedSeminarsPopUp
          checkedItems={checkedItems}
          onClose={handleUnselectAll}
          onDelete={() => setDeleteSeminars(true)}
        />
      )}
    </>
  );
};

export default SeminarsHistoryTable;
