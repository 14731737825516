import { Button } from "@chakra-ui/react";

export const NextFormPartButton = ({ disableCondition, onButtonClick }) => {
  return (
    <Button
      disabled={disableCondition}
      colorScheme={disableCondition ? "gray" : "purple"}
      onClick={onButtonClick}
      marginTop="35px"
      marginBottom="15px"
    >
      Дальше
    </Button>
  );
};
