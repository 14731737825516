import { useSelector } from "react-redux";
import { TableConstructor } from "../TableConstructor/TableConstructor";
import { WishListTableRow } from "./WishlistTableRow";
import { getWishlist, analyticsIsLoading } from "../../pages/Wishlists/store/Analytics.selectors";

const TheadColumns = [
  { name: "Название", width: 90 },
  { name: "Добавили, раз", width: 10 },
];

const WishListRow = (order) => {
  const { id, name, amount } = order;
  return <WishListTableRow productId={id} productName={name} productCount={amount} />;
};

export const WishlistTable = () => {
  const wishlistItems = useSelector(getWishlist());
  const isLoading = useSelector(analyticsIsLoading());
  return (
    <TableConstructor Row={WishListRow} theadItems={TheadColumns} items={wishlistItems.data} isLoading={isLoading} />
  );
};
