import { LoadingState } from "../../../../store/common";

import { SLICE_NAME } from "./types";

export const getSeminarsRequest = () => (state) => state[SLICE_NAME].seminars;
export const seminarsRequestIsGetted = () => (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const seminarsRequestIsLoading = (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const seminarsRequestIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;

export const seminarsRequestToast = () => (state) => state[SLICE_NAME].toast;
export const seminarsRequestToastIsView = () => (state) => state[SLICE_NAME].toast.view;
