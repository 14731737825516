import { Button, Flex, FormLabel, Heading, Input, Stack, useColorModeValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { updatePassword } from "../../services/authService";
import { useCustomToast } from "../../hooks/useCustomToast";
import { useSearchParams } from "../../hooks/useSearchParams";
import { useFormik } from "formik";
import { RESET_PASSWORD_FIELDS, RESET_PASSWORD_VALIDATION_SCHEMA } from "./constants";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";
import { InputPassword } from "../../components/InputPassword/InputPassword";

const initialValues = {
  email: "",
  password: "",
  repeatPassword: "",
};

const ResetPasswordForm = () => {
  const { pushToast } = useCustomToast();
  const navigate = useNavigate();

  const { handleGetSearchFieldValue } = useSearchParams();
  const token = handleGetSearchFieldValue("token");

  const onPressUpdatePassword = (values) => {
    updatePassword(token, values.password)
      .then(() => {
        pushToast({
          title: "Успешно",
          status: "success",
          description: "Данные успешно обновлены.",
          onCloseComplete: () => navigate("/login"),
        });
      })
      .catch(() => {
        pushToast({ title: "Ошибка", status: "error", description: "Неверные данные либо устаревшая ссылка." });
      })
      .finally(() => navigate("/login"));
  };

  const { values, errors, handleChange, handleBlur, isValid, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema: RESET_PASSWORD_VALIDATION_SCHEMA,
    onSubmit: onPressUpdatePassword,
  });

  const getDefaultFormProps = (name, isDisabledPaste) => ({
    autoComplete: "off",
    value: values[name],
    name,
    onChange: handleChange,
    onBlur: handleBlur,
  });

  return (
    <Flex minH="100vh" align="center" justify="center" bg={useColorModeValue("gray.50", "gray.800")}>
      <Stack w="full" maxW="md" bg={useColorModeValue("white", "gray.700")} rounded="xl" boxShadow="lg" p={6} my={12}>
        <Heading marginBottom={4} lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
          Восстановление доступа
        </Heading>

        {RESET_PASSWORD_FIELDS.map((field) => (
          <FormControlWithError
            key={field.id}
            id={field.id}
            hasError={!!errors[field.name] && touched[field.name]}
            errorText={errors[field.name]}
          >
            <FormLabel>{field.title}</FormLabel>

            <InputPassword {...getDefaultFormProps(field.name, field.isDisabledPaste)} />
          </FormControlWithError>
        ))}

        <Stack spacing={6}>
          <Button isDisabled={!isValid} onClick={handleSubmit} colorScheme="purple">
            Подтвердить
          </Button>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default ResetPasswordForm;
