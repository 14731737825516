import { createAsyncThunk } from "@reduxjs/toolkit";

import { SLICE_NAME } from "./types";
import { postLogin, postLogout } from "../../../services/authService";

export const loginUser = createAsyncThunk(`${SLICE_NAME}/fetchByIdStatus`, async (values, { rejectWithValue }) => {
  try {
    const res = await postLogin(values);

    localStorage.setItem("token", res.token.accessToken);
    localStorage.setItem("email", res.email);

    return res;
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const logoutUser = createAsyncThunk(`${SLICE_NAME}/fetchByLogout`, async (values, { rejectWithValue }) => {
  try {
    const res = await postLogout();
    return res;
  } catch (err) {
    return rejectWithValue(err.response.data);
  } finally {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
  }
});
