import { useSelector, useDispatch } from "react-redux";
import { getWarehouses } from "../../pages/Cities/store/Warehouses/Warehouse.selectors";
import { getAllWarehouses } from "../../pages/Cities/store/Warehouses/Warehouses.thunk";
import { useEffect, useMemo } from "react";
import Autocomplete from "../Autocomplete";

export const WarehousesAutocomplete = ({ value, handleChange, isDisabled, isAllOptionsNeed = false, ...props }) => {
  const dispatch = useDispatch();

  const warehouses = useSelector(getWarehouses());

  useEffect(() => {
    if (!isDisabled) {
      dispatch(getAllWarehouses());
    }
  }, [dispatch, isDisabled]);

  const warehousesItems = useMemo(
    () =>
      warehouses
        ? warehouses.map((warehouse) => ({
            ...warehouse,
            name: `${warehouse?.city ?? ""}`,
          }))
        : [],
    [warehouses]
  );

  return (
    <Autocomplete
      items={isAllOptionsNeed ? [{ name: "Все склады", id: "all" }, ...warehousesItems] : warehousesItems}
      value={value}
      isDisabled={isDisabled}
      onChange={handleChange}
      {...props}
    />
  );
};
