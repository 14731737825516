import { useEffect, useCallback } from "react";

export const useToastsCallbacks = (toastsInfo, toastsIsViewed, toastsDeletingFunctions, dispatch, pushToast) => {
  const cbCourseToast = useCallback(() => {
    if (toastsInfo.coursesToastInfo.view) {
      pushToast(toastsInfo.coursesToastInfo);
      dispatch(toastsDeletingFunctions.deleteToast());
    }
  }, [toastsInfo.coursesToastInfo, dispatch]);

  const cbRequestedCoursesToast = useCallback(() => {
    if (toastsInfo.coursesRequestToastInfo.view) {
      pushToast(toastsInfo.coursesRequestToastInfo);
      dispatch(toastsDeletingFunctions.deleteCoursesRequestToast());
    }
  }, [toastsInfo.coursesRequestToastInfo, dispatch]);

  useEffect(() => {
    cbCourseToast();
  }, [toastsIsViewed.coursesToastIsViewed, cbCourseToast]);

  useEffect(() => {
    cbRequestedCoursesToast();
  }, [toastsIsViewed.coursesRequestToastIsViewed, cbRequestedCoursesToast]);
};
