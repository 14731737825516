/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { withFormik } from "formik";
import { connect } from "react-redux";
import { addingProtocolCategory, editingProtocolCategory } from "./store/ProtocolCategories.thunk";

const ErrorMessage = styled("div")(() => ({
  color: "red",
  fontSize: 12,
}));

const CreateCategoryModal = ({
  viewCreateCategory,
  handleSubmit,
  handleChange,
  values,
  errors,
  viewModal,
  openedCategory,
}) => (
  <Modal isCentered isOpen={viewCreateCategory} onClose={() => viewModal()}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{openedCategory ? "Изменение категории" : "Добавление новой категории"}</ModalHeader>
      <ModalCloseButton />
      <form onSubmit={handleSubmit}>
        <ModalBody style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <label htmlFor="categoryName">Название категории протокола</label>
          <Input
            autoComplete="off"
            id="categoryName"
            name="categoryName"
            onChange={handleChange}
            value={values.categoryName}
            style={{
              border: `${errors.categoryName ? "2px solid red" : "1px solid gray"}`,
            }}
          />
          <ErrorMessage>{errors.categoryName}</ErrorMessage>
        </ModalBody>
        <ModalFooter style={{ display: "flex", gap: "16px" }}>
          <Button
            colorScheme="green"
            type="submit"
            isDisabled={Object.keys(errors).length !== 0 || values.categoryName === ""}
          >
            {openedCategory ? "Изменить" : "Добавить"}
          </Button>
          <Button colorScheme="blue" mr={3} onClick={() => viewModal()}>
            Отмена
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  </Modal>
);

const CreateNewCategoryForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ openedCategory }) => ({
    categoryName: openedCategory?.name || "",
  }),

  validate: (values) => {
    const errors = {};

    if (!values.categoryName.trim()) {
      errors.categoryName = "Обязательное поле";
    }

    return errors;
  },

  handleSubmit: (values, { props }) => {
    if (props.openedCategory) {
      props.updateProtocolCategoryReq(values, props.openedCategory.id);
    } else {
      props.addProtocolCategoryReq(values);
    }
    props.viewModal();
  },

  displayName: "ProtocolCategoryForm",
})(CreateCategoryModal);
const mapDispatchToProps = (dispatch) => ({
  addProtocolCategoryReq: (values) => {
    dispatch(addingProtocolCategory(values));
  },
  updateProtocolCategoryReq: (values, id) => {
    dispatch(editingProtocolCategory({ values, id }));
  },
});

export default connect(null, mapDispatchToProps)(CreateNewCategoryForm);
