import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { getAllCourses } from "./store/courses/courses.thunk";
import { deleteToast } from "./store/courses/courses.slice";

import { usePagination } from "../../hooks/usePagination";
import CoursesTable from "./Components";
import { useCoursesSelectors } from "./utils/useCoursesSelectors";
import { deleteRequestedCoursesToast } from "./store/CoursesRequest/coursesRequest.slice";
import { getAllRequestedCourses } from "./store/CoursesRequest/coursesRequest.thunk";
import { CoursesRequestsTable } from "./Components/CoursesRequestsTable";

import { COURSES_STATUSES_NUMBERS, COURSE_STATUS_TYPES, BASE_DEBOUNCE_DELAY } from "./constants";
import { PageWithTabsAndPaginationLayout } from "../../components/PageWithTabsAndPaginationLayout";
import { useCustomToast } from "../../hooks/useCustomToast";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { useToastsCallbacks } from "./utils/useToastsCallbacks";
import { useDebounceCallback } from "../../hooks/useDebounceCallback";

const CoursesPage = () => {
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();

  const {
    courses,
    totalCourses,
    isCoursesNeedUpdate,
    coursesToastInfo,
    coursesToastIsViewed,

    coursesRequest,
    totalCoursesRequest,
    isCoursesRequestNeedUpdate,
    coursesRequestToastInfo,
    coursesRequestToastIsViewed,
  } = useCoursesSelectors();

  const [currentTab, setCurrentTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const currentTabSetter = (value) => {
    setCurrentTab(value);
  };

  const totalPerTab = useMemo(() => {
    switch (currentTab) {
      case COURSES_STATUSES_NUMBERS.FUTURE_COURSES: {
        return totalCourses;
      }
      case COURSES_STATUSES_NUMBERS.COURSES_HISTORY: {
        return totalCourses;
      }
      case COURSES_STATUSES_NUMBERS.COURSES_REQUEST: {
        return totalCoursesRequest;
      }
      default: {
        return 0;
      }
    }
  }, [currentTab, totalCourses, totalCoursesRequest]);

  const { isPrevDisabled, isNextDisabled, page, limit, totalPages, nextPage, prevPage, resetPage, setPage, setLimit } =
    usePagination({
      total: totalPerTab,
    });

  const requestCourses = async (query) => {
    dispatch(
      getAllCourses({
        limit,
        page: page || 1,
        statuses: [COURSE_STATUS_TYPES.SCHEDULED, COURSE_STATUS_TYPES.ACTIVE],
        query: query ?? searchQuery,
      })
    );
  };

  const requestCoursesHistory = async (query) => {
    await dispatch(
      getAllCourses({ limit, page: page || 1, statuses: [COURSE_STATUS_TYPES.PAST], query: query ?? searchQuery })
    ).unwrap();
  };

  const requestCoursesRequest = async (query) => {
    await dispatch(getAllRequestedCourses({ query: query ?? searchQuery })).unwrap();
  };

  const cbResetQearchAndPages = useCallback(() => {
    resetPage();
    setSearchQuery("");
  }, [setSearchQuery, resetPage]);

  useToastsCallbacks(
    { coursesToastInfo, coursesRequestToastInfo },
    { coursesToastIsViewed, coursesRequestToastIsViewed },
    { deleteToast, deleteRequestedCoursesToast },
    dispatch,
    pushToast
  );

  const requests = useMemo(() => {
    return {
      [COURSES_STATUSES_NUMBERS.FUTURE_COURSES]: requestCourses,
      [COURSES_STATUSES_NUMBERS.COURSES_HISTORY]: requestCoursesHistory,
      [COURSES_STATUSES_NUMBERS.COURSES_REQUEST]: requestCoursesRequest,
    };
  }, []);

  const debounceRequest = useDebounceCallback(requests[currentTab], BASE_DEBOUNCE_DELAY, []);

  const handleChangePage = (newPage) => {
    requests[currentTab](searchQuery);

    setPage(newPage);
  };

  useUpdateEffect(() => {
    if (currentTab === COURSES_STATUSES_NUMBERS.FUTURE_COURSES && isCoursesNeedUpdate) {
      requestCourses();
      cbResetQearchAndPages();
    }

    if (currentTab === COURSES_STATUSES_NUMBERS.COURSES_HISTORY && isCoursesNeedUpdate) {
      requestCoursesHistory();
      cbResetQearchAndPages();
    }

    if (currentTab === COURSES_STATUSES_NUMBERS.COURSES_REQUEST && isCoursesRequestNeedUpdate) {
      requestCoursesRequest();
      cbResetQearchAndPages();
    }
  }, [
    currentTab,
    isCoursesNeedUpdate,
    isCoursesRequestNeedUpdate,
    requestCourses,
    requestCoursesHistory,
    requestCoursesRequest,
    cbResetQearchAndPages,
  ]);

  useEffect(() => {
    requestCourses();
  }, []);

  const searchInputValueSetter = (value) => {
    debounceRequest(value);

    setSearchQuery(value);
  };

  useUpdateEffect(() => {
    requests[currentTab](searchQuery);
  }, [currentTab]);

  const coursesElements = [
    {
      name: "Актуальные",
      renderElement: <CoursesTable courses={courses} isAddButtonNeed />,
      objectsCount: totalCourses,
    },
    {
      name: "Прошедшие",
      renderElement: <CoursesTable courses={courses} isAddButtonNeed />,
      objectsCount: totalCourses,
    },
    {
      name: "Запросы на курсы",
      renderElement: <CoursesRequestsTable coursesRequests={coursesRequest} />,
      objectsCount: totalCoursesRequest,
    },
  ];

  const handleChangeLimit = (value) => {
    requests[currentTab](searchQuery);

    setLimit(value);
  };

  const paginationParams = {
    isPrevDisabled,
    isNextDisabled,
    page: page || 1,
    limit,
    totalPages,
    nextPage,
    prevPage,
    resetPage,
    setPage: handleChangePage,
    setLimit: handleChangeLimit,
  };

  return (
    <PageWithTabsAndPaginationLayout
      isSearchInputNeed
      searchInputValue={searchQuery}
      tabsWithElements={coursesElements}
      paginationParams={paginationParams}
      currentTabSetter={currentTabSetter}
      searchInputValueSetter={searchInputValueSetter}
    />
  );
};

export default CoursesPage;
