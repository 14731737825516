import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { App, AppWithSentry } from "./App";
import "./styles/index.css";

import theme from "./theme";
import { DSN, ENVIRONMENT } from "./config";
import { Global } from "@emotion/react";
import { GlobalStyles } from "./styles/GlobalStyles";

if (ENVIRONMENT !== "development") {
  Sentry.init({
    dsn: DSN,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    environment: ENVIRONMENT,
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <Provider store={store}>
        <Global styles={GlobalStyles} />

        {ENVIRONMENT === "development" ? <App /> : <AppWithSentry />}
      </Provider>
    </BrowserRouter>
  </ChakraProvider>
);
