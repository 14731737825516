export const removeSpaces = (str) => {
  if (!str) {
    return "";
  }
  return str.replace(/\s+/g, " ").trim();
};

export const removeAllSpaces = (str) => {
  if (!str) {
    return "";
  }
  return str.replaceAll(" ", "");
};
