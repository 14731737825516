import { useState } from "react";
import { Button, Flex, FormControl, FormErrorMessage, Input } from "@chakra-ui/react";

const AddCategoryAndSubcategory = ({
  isDisabled,
  buttonLabel,
  inputPlaceholder,
  itemsList,
  errors,
  onSave,
  onClearSelected = () => {},
}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState(null);

  const errorMessage = `${itemsList[0]?.catalog_product ? "Подкатегория" : "Категория"} с таким именем уже существует`;

  const handleChangeName = (e) => {
    setError(null);
    setName(e.target.value);
  };
  const handleClearSelect = () => {
    onClearSelected();
  };
  const handleSave = () => {
    if (itemsList.some((item) => item.name === name.trim())) {
      setError(errorMessage);
      return;
    }
    onSave(name.trim());
    setName("");
  };
  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      if (itemsList.some((item) => item.name === name.trim())) {
        setError(errorMessage);
        return;
      }
      handleSave();
      setName("");
      event.target.blur();
    }
  };

  return (
    <Flex direction="column" gap="8px" marginBottom="16px">
      <FormControl isInvalid={error}>
        <Input
          id="name"
          name="name"
          value={name}
          placeholder={inputPlaceholder}
          style={{
            border: error ? "2px solid red" : "",
          }}
          onFocus={handleClearSelect}
          onChange={handleChangeName}
          onKeyDown={handleEnterKey}
          autoComplete="off"
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>

      <Flex gap="16px">
        <Button
          colorScheme="purple"
          isDisabled={(errors && Object.keys(errors).length !== 0) || name === "" || isDisabled}
          w="100%"
          onClick={handleSave}
        >
          {buttonLabel}
        </Button>
      </Flex>
    </Flex>
  );
};

export default AddCategoryAndSubcategory;
