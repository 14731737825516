import { useEffect, useRef } from "react";
import { Button, Flex, FormControl, FormErrorMessage, Input } from "@chakra-ui/react";

const CityAddRow = ({ name, address, error, isLoading, onAddCity, onChangeName, onChangeAddress }) => {
  const ref = useRef(null);

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      onAddCity();
    }
  };

  const handleClick = () => {
    onAddCity();
  };

  useEffect(() => {
    if (error) {
      ref.current.focus();
    }
  }, [error]);

  return (
    <Flex gap={1} mb="16px">
      <FormControl isInvalid={error}>
        <Input
          placeholder="Введите название города"
          value={name}
          onChange={onChangeName}
          onKeyDown={handleEnterKey}
          autoComplete="off"
          style={{ padding: "0 12px" }}
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={error}>
        <Input
          ref={ref}
          autoComplete="off"
          placeholder="Введите адрес"
          value={address}
          onChange={onChangeAddress}
          onKeyDown={handleEnterKey}
          style={{ padding: "0 12px" }}
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
      <Button
        isLoading={isLoading}
        w="100%"
        marginLeft="auto"
        colorScheme="purple"
        isDisabled={!(name && address)}
        onClick={handleClick}
        style={{ fontWeight: "500" }}
      >
        Добавить город
      </Button>
    </Flex>
  );
};

export default CityAddRow;
