import RowUserRequestTable from "./RowUserRequestTable";
import { userRequestTheadColumns } from "../../common/constants";
import { CONTAINER_STYLE } from "./style";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "../../hooks/useSearchParams";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";

const RequestsTable = ({ users = [], isLoading, markAsViewed }) => {
  const navigate = useNavigate();
  const { searchParamsString } = useSearchParams();

  const handleSelectUser = ({ id }) => {
    navigate({ pathname: id, search: searchParamsString });
    markAsViewed(id);
  };

  const rowsRenderer = (user) => (
    <RowUserRequestTable key={`approved-user-${user.id}`} user={user} onSelect={handleSelectUser} />
  );

  return (
    <TableConstructor
      containerStyles={CONTAINER_STYLE}
      Row={rowsRenderer}
      notFoundText="Здесь пока нет пользователей"
      theadItems={userRequestTheadColumns}
      items={users}
      isLoading={isLoading}
    />
  );
};

export default RequestsTable;
