import * as yup from "yup";

export const VALIDATION_SCHEMA = (names) => {
  return yup.object({
    name: yup
      .string()
      .trim()
      .required('Поле "Название" обязательное')
      .test("name", "Такой протокол уже существует", (name) => {
        if (names.includes(name)) {
          return false;
        }
        return true;
      }),
    description: yup.string().required('Поле "Описание" обязательное'),
    protocol_category: yup.object(),
    brand: yup
      .object()
      .shape({ id: yup.string().required('Поле "Бренд" обязательное') })
      .required('Поле "Бренд" обязательное')
      .nullable(),
    products: yup.array().min(1, 'Поле "Товары" обязательное'),
  });
};

export const PRODUCT_TD_STYLE = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#37393D",
};

export const TEXT_OVERFLOW_STYLE_GRAY = {
  maxWidth: "100px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  backgroundColor: "#f5f5f5",
  color: "#737680",
};
