import { useRef } from "react";
import { Flex, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";

import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import { useIsOverflow } from "../../hooks/hooks";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { getRoleObj } from "./utils/getRoleObj";
import { convertTimeToCurrentTimeZone } from "./utils/convertedDate";

const TableRow = ({ name, onDelete, onEdit, availableFor, startDate, endDate }) => {
  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete();
  };

  const handleOnEnter = (event) => {
    event.stopPropagation();

    if (event.key === "Enter") {
      onEdit();
    }
  };

  const handleOnKeyDown = (event) => {
    event.stopPropagation();

    if (event.key === "Enter") {
      event.preventDefault();
      handleDelete(event);
    }
  };

  return (
    <Tr cursor="pointer" onClick={onEdit} onKeyDown={handleOnEnter} tabIndex={0}>
      <Td style={TEXT_OVERFLOW_STYLE} cursor="pointer" ref={ref} role="gridcell">
        {isOverflow ? (
          <Tooltip label={name} color="black" bg="white" placement="bottom-start">
            <span>{name}</span>
          </Tooltip>
        ) : (
          name
        )}
      </Td>
      <Td cursor="pointer" role="gridcell">
        {getRoleObj(availableFor).name}
      </Td>
      <Td role="gridcell">{convertTimeToCurrentTimeZone(startDate)}</Td>
      <Td role="gridcell">{convertTimeToCurrentTimeZone(endDate)}</Td>
      <Td w="10%" onClick={handleDelete} onKeyDown={handleOnKeyDown} tabIndex={0}>
        <Flex justifyContent="end" gap="16px">
          <IconButton w="16px" height="16px" variant="unstyled" icon={<DeleteIcon />} aria-label="Delete" />
        </Flex>
      </Td>
    </Tr>
  );
};
export default TableRow;
