import {
  Box,
  Button,
  Flex,
  ModalBody,
  Modal as ModalChakra,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import Preloader from "../Preloader";

export const Modal = ({
  isOpen,
  children,
  headerText,
  handleClose,
  buttonCancelText = "Отменить",
  buttonSaveText = "Сохранить",
  handleSave,
  extraFooterContent,
  isNeedSaveState = false,
  isLoading = false,
}) => (
  <ModalChakra isCentered motionPreset="slideInBottom" onClose={!isLoading && handleClose} isOpen={isOpen}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{headerText}</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        {isLoading && <Preloader height={200} />}

        {isNeedSaveState && <Box display={isLoading ? "none" : "block"}>{children}</Box>}

        {!isNeedSaveState && !isLoading && children}
      </ModalBody>

      <ModalFooter>
        {extraFooterContent || (
          <Flex width="100%" gap={3}>
            <Button width="50%" isLoading={isLoading} variant="outline" onClick={handleClose}>
              {buttonCancelText}
            </Button>

            <Button width="50%" onClick={handleSave} isLoading={isLoading} colorScheme="purple">
              {buttonSaveText}
            </Button>
          </Flex>
        )}
      </ModalFooter>
    </ModalContent>
  </ModalChakra>
);
