import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import { useDispatch } from "react-redux";

import { deletingProtocol } from "./store/Protocol.thunk";
import { NAME_STYLES } from "../../common/constants";

const DeleteProtocolModal = ({ protocol, onClose }) => {
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deletingProtocol(protocol.id));
    onClose();
  };

  const overflowScroll = protocol?.name?.length > 96 ? {} : { overflow: "hidden" };

  return (
    <Modal isCentered isOpen={protocol} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody fontSize="24px" p="40px 40px 32px" textAlign="center" flexDirection="column">
          <p style={{ marginBottom: "32px" }}>Вы действительно хотите удалить протокол</p>
          <p style={{ ...NAME_STYLES, ...overflowScroll }}>{`"${protocol.name}"?`}</p>
        </ModalBody>

        <ModalFooter style={{ display: "flex", gap: "16px", flexDirection: "column", padding: "0 40px 40px" }}>
          <Button w="100%" bgColor="#C06ECC" color="#ffffff" onClick={handleDelete}>
            Удалить
          </Button>
          <Button w="100%" bgColor="#ffffff" color="#C06ECC" onClick={onClose}>
            Отменить удаление
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteProtocolModal;
