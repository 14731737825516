import { DAYS } from "../../../common/constants";
import { removeSpaces } from "../../../utils/removeSpaces";
import { formatPhoneNumber } from "./formatPhoneNumber";

export const transformOdlWarehouse = ({ city, address, phone, timetable }) => {
  return {
    city,
    address,
    phone,
    timetable,
  };
};

export const convertWarehouseToApi = ({ city, address, phone, timetable, citySlug }) => ({
  city: removeSpaces(city),
  address: removeSpaces(address),
  phone: formatPhoneNumber(phone),
  timetable: DAYS.map((day) => ({ [day]: timetable?.[day] ?? "" })),
  citySlug: removeSpaces(citySlug),
});
