import * as Yup from "yup";
import { PASSWORD_VALIDATOR } from "../EmployeeModal/constants";

export const RESET_PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
  password: PASSWORD_VALIDATOR.required('Поле "Пароль" обязательное'),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Пароли должны совпадать")
    .required('Поле "Повторите пароль" обязательное'),
});

export const RESET_PASSWORD_FIELDS = [
  { title: "Пароль*", id: "password", name: "password", type: "password" },
  {
    title: "Повторите пароль*",
    id: "repeat_password",
    name: "repeatPassword",
    type: "password",
  },
];
