import { useRef } from "react";

export const useCombinedRefs = (refs) => {
  const targetRef = useRef();

  refs.forEach((ref) => {
    if (!ref) return;
    // eslint-disable-next-line no-param-reassign
    ref.current = targetRef.current;
  });

  return targetRef;
};
