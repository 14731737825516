import { requestAxios } from "../api/axiosRequest";

const EMPLOYEE_API = "/managers";

export const createEmployee = (data) => requestAxios.post("/register", data);

export const getAllEmployees = (params) => requestAxios.get(EMPLOYEE_API, { params });

export const getOneEmployee = (id) => requestAxios.get(`${EMPLOYEE_API}/${id}`);

export const deleteEmployee = (id) => requestAxios.delete(`${EMPLOYEE_API}/${id}`);

export const updateEmployee = ({ id, values, isCurrentUser = false }) =>
  requestAxios.patch(isCurrentUser ? `${EMPLOYEE_API}/me` : `${EMPLOYEE_API}/${id}`, values);

export const validationUnique = (params) => requestAxios.get("/unique", { params });
