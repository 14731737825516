import { requestAxios } from "../api/axiosRequest";

export const getClients = ({ limit, page, role, query }) => {
  return requestAxios.get(`/users`, {
    params: {
      take: limit,
      skip: limit * (page - 1),
      query,
      role,
    },
  });
};

export const getReportClients = ({ report }) => {
  return requestAxios.get(`/reports/users`, { params: { ...report } });
};

export const getOneClient = (id) => {
  return requestAxios.get(`/users/${id}`);
};

export const getClientOrders = (params, id) => {
  const paramsCopy = params;
  delete paramsCopy.id;
  return requestAxios.get(`/users/${id}/orders`, { params: paramsCopy });
};

export const changeUserBonusBallance = ({ id, ...params }) => {
  return requestAxios.patch(`/bonuses/${id}`, params);
};

export const getClientBonusInfo = (id) => {
  return requestAxios.get(`/bonuses/${id}`);
};

export const editClient = ({ id, objToSend }) => {
  return requestAxios.patch(`/users/${id}`, objToSend);
};

export const resetPassword = (id) => {
  return requestAxios.post(`/users/${id}/reset-password`);
};
