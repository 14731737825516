import React from "react";
import { Heading } from "@chakra-ui/react";

const PageNotFound = () => {
  return (
    <Heading as="h1" size="xl" noOfLines={1}>
      Page not found :C
    </Heading>
  );
};

export default PageNotFound;
