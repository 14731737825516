import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DeleteItemModal } from "../../components/DeleteModals";

import { deletingPromocode, getAllPromocodes } from "./store/Promocodes.thunk";
import {
  getPromocodes,
  promocodesIsUpdated,
  promocodesToast,
  promocodesToastIsView,
} from "./store/Promocodes.selectors";
import { deletePromocodesToast } from "./store/Promocodes.slice";
import PromocodesTable from "./Table";
import { useCustomToast } from "../../hooks/useCustomToast";

import TemplateDefaultContainer from "../../components/common/TemplateDefaultContainer";
import { useNavigate } from "react-router-dom";

const Promocodes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pushToast } = useCustomToast();

  const promocodes = useSelector(getPromocodes());
  const isNeedPromocodesUpdated = useSelector(promocodesIsUpdated());
  const promocodesToastInfo = useSelector(promocodesToast());
  const promocodesToastIsViewed = useSelector(promocodesToastIsView());

  const [deletePromocode, setDelete] = useState(null);

  const handleDelete = (id) => {
    dispatch(deletingPromocode({ id }));
  };

  const cbToast = useCallback(() => {
    if (promocodesToastInfo.view) {
      pushToast(promocodesToastInfo);
      dispatch(deletePromocodesToast());
    }
  }, [promocodesToastInfo, dispatch]);

  useEffect(() => {
    if (!isNeedPromocodesUpdated) {
      return;
    }

    dispatch(getAllPromocodes());
  }, [isNeedPromocodesUpdated, dispatch]);

  useEffect(() => {
    cbToast();
  }, [promocodesToastIsViewed, cbToast]);

  const handleCreate = () => navigate("create");
  const handleEdit = ({ id }) => navigate(id);
  return (
    <TemplateDefaultContainer handleCreateEntity={handleCreate} textForAddButton="Добавить промокод">
      <PromocodesTable promocodes={promocodes} onEdit={handleEdit} onDelete={setDelete} />

      {deletePromocode && (
        <DeleteItemModal
          label="промокод"
          item={deletePromocode}
          onClose={() => setDelete(null)}
          onDelete={handleDelete}
        />
      )}
    </TemplateDefaultContainer>
  );
};
export default Promocodes;
