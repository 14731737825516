import styled from "@emotion/styled";

import { Progress } from "@chakra-ui/react";
import preloader from "../../assets/gif/preloader.gif";

const PreloaderContainer = styled("div")(({ height, maxHeight }) => ({
  width: "100%",
  height: height || maxHeight || "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ProgressContainer = styled("div")(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1000,
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "start",
  justifyContent: "start",
  background: "rgba(0,0,0,0.2)",
}));

export const Preloader = ({ width, ...props }) => (
  <PreloaderContainer {...props}>
    <img style={{ width: width ?? "200px" }} {...props} src={preloader} alt="Loading..." />
  </PreloaderContainer>
);

export const ProgressWrapper = (props) => (
  <ProgressContainer {...props}>
    <div style={{ width: "100%" }}>
      <Progress size="xs" isIndeterminate colorScheme="purple" />
    </div>
  </ProgressContainer>
);
