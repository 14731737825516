import React, { useCallback, useEffect, useState } from "react";
import { Button, Flex } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BackButton } from "../../components/BackButton/BackButton";
import PageContainer from "../../components/PageContainer/PageContainer";
import { ContentContainer } from "./ContentContainer";
import { ClientEditForm } from "./ClientEditForm";
import OrdersTable from "./components/OrdersTable";
import { ResetPassword } from "../../components/ResetPassword/ResetPassword";
import { CurrentPaswordInput } from "./CurrentPaswordInput";
import { getClientThunk, resetPasswordThunk } from "../Clients/store/Clients.thunk";
import { resetPassword, isLoadingTempPassword, clientToast, toastIsView } from "../Clients/store/Clients.selectors";
import { deleteToast } from "../Clients/store/Clients.slice";
import { useCustomToast } from "../../hooks/useCustomToast";
import { BonusesContent } from "./components/BonusesContent";

export const ClientInfo = () => {
  const location = useLocation();

  const { pushToast } = useCustomToast();
  const dispatch = useDispatch();
  const { id } = useParams();

  const toastInfo = useSelector(clientToast() || "");
  const toastInViewed = useSelector(toastIsView() || false);
  const tempPassword = useSelector(resetPassword());
  const isLoadingPassword = useSelector(isLoadingTempPassword());
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showTempPassword, setShowTempPassword] = useState(false);

  useEffect(() => {
    dispatch(getClientThunk(id));

    return () => {
      dispatch(deleteToast());
    };
  }, []);
  useEffect(() => {
    if (toastInfo.view) {
      pushToast(toastInfo);
    }

    dispatch(deleteToast());
  }, [toastInViewed]);

  const handleReset = useCallback(() => {
    dispatch(resetPasswordThunk(id))
      .unwrap()
      .then(() => setShowTempPassword(true));
  }, [id]);

  const handleClose = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return (
    <Flex direction="column" width="100%" overflowY="auto">
      <PageContainer>
        <BackButton state={{ currentTab: location.state?.currentTab }} />

        <ClientEditForm />

        <ContentContainer title="Смена пароля">
          {showTempPassword && !isLoadingPassword ? (
            <CurrentPaswordInput lable="Текущий пароль" value={tempPassword} />
          ) : (
            <Button
              w="20%"
              bgColor="#C06ECC"
              color="#ffffff"
              isLoading={isLoadingPassword}
              onClick={() => {
                setIsOpenModal(true);
              }}
            >
              Сменить пароль
            </Button>
          )}
        </ContentContainer>
        <ContentContainer title="Заказы">
          <OrdersTable clientId={id} />
        </ContentContainer>
        <ContentContainer title="Бонусы пользователя">
          <BonusesContent userId={id} />
        </ContentContainer>
      </PageContainer>

      {isOpenModal && (
        <ResetPassword
          title="Вы действительно хотите сбросить пароль?"
          open={isOpenModal}
          onClose={handleClose}
          onReset={handleReset}
        />
      )}
    </Flex>
  );
};
