export const generateName = (user) => {
  if (!user) {
    return "Пользователь не найден";
  }

  const { name, lastName, secondName, firmName } = user;

  if (!name && !lastName && !secondName && !firmName) {
    return "Пользователь не найден";
  }

  return `${name ?? ""} ${lastName ?? ""} ${secondName ?? ""} ${firmName ?? ""}`.trim();
};
