import { Tr, Td, Image, Tooltip, IconButton, Flex } from "@chakra-ui/react";
import { useMemo, useRef, useState } from "react";
import { useIsOverflow } from "../../hooks/hooks";
import { DATE_FORMAT_API, DEFAULT_DATE_FORMAT, TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { ACHIEVEMENTS_OPTIONS } from "./constants";
import { ReactComponent as EditIcon } from "../../assets/svg/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import { useDispatch } from "react-redux";
import { deletingPromotion } from "./store/Promotion.thunk";
import { DeleteItemModal } from "../../components/DeleteModals";
import { formatDate } from "./utils/formatDate";
import { ImageWithLoader } from "../../components/ImageWithLoader/ImageWithLoader";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";
import { PRODUCT_INPUT_STYLE } from "../Products/style";

export const PromotionViewTableRow = ({ promotion, handleOpenEditMode }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOverflow] = useIsOverflow(ref);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenDeleteModal = () => setIsOpenModal(true);

  const handleCloseDeleteModal = () => setIsOpenModal(false);

  const handleDelete = () => {
    handleCloseDeleteModal();
    dispatch(deletingPromotion(promotion.id));
  };

  const currentAchievements = useMemo(
    () => ACHIEVEMENTS_OPTIONS.find((option) => option.value === promotion.availableFor)?.label,
    [promotion?.availableFor]
  );

  return (
    <Tr height="125px">
      <Td>
        <a href={promotion.image} target="_blank" rel="noreferrer" style={PRODUCT_INPUT_STYLE}>
          <ImageWithLoader height="100px" width="111px" objectFit="cover" alt={promotion.image} src={promotion.image} />
        </a>
      </Td>

      <Td ref={ref} style={TEXT_OVERFLOW_STYLE} alignItems="center" justifyContent="space-between">
        <TooltipWithContent isOverflow={isOverflow} text={promotion.name} />
      </Td>

      <Td>{formatDate(promotion.endDate.substring(0, 10), DEFAULT_DATE_FORMAT, DATE_FORMAT_API)}</Td>

      <Td>{currentAchievements}</Td>

      <Td>
        <Flex alignItems="center" justifyContent="end">
          <IconButton
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<EditIcon />}
            onClick={handleOpenEditMode}
            aria-label="edit"
          />

          <IconButton
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<DeleteIcon />}
            onClick={handleOpenDeleteModal}
            aria-label="delete"
          />
        </Flex>

        {isOpenModal && (
          <DeleteItemModal
            title="Вы действительно хотите удалить промоакцию"
            onClose={handleCloseDeleteModal}
            onDelete={handleDelete}
            item={promotion}
          />
        )}
      </Td>
    </Tr>
  );
};
