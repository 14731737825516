import { Box, Text, Flex, Button, Divider } from "@chakra-ui/react";
import { TrashIcon } from "../../../assets/icon/TrashIcon";
import { DeleteIcon as UnActiveTrashIcon } from "../../../assets/icon/DeleteIcon";

export const UsersTable = ({ users, handleUserDropDownChange, isDisabled }) => {
  return (
    <Box marginTop="10px">
      <Text marginBottom="20px">Добавленные пользователи</Text>
      {users.map((user) => (
        <Box key={user.id}>
          <Flex width="560px" justify="space-between">
            <Box fontSize="14px">
              <Text>
                {user.lastName ?? ""} {user.name ?? ""} {user.secondName ?? ""}
              </Text>

              <Text color="gray">{user.email}</Text>
            </Box>

            <Flex align="center">
              <Text>{user.phone}</Text>

              <Button
                background="white"
                onClick={() => {
                  if (!isDisabled) {
                    handleUserDropDownChange(user, false);
                  }
                }}
                marginLeft="15px"
              >
                {isDisabled ? <UnActiveTrashIcon /> : <TrashIcon color="black" />}
              </Button>
            </Flex>
          </Flex>
          <Divider />
        </Box>
      ))}
    </Box>
  );
};
