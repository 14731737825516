import { requestAxios } from "../api/axiosRequest";

export const getBrands = () => {
  return requestAxios.get(`/brands`);
};

export const addBrand = ({ name, icon, extraCharge }) => {
  return requestAxios.post(
    `/brands`,
    { name, icon, extraCharge },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const deleteBrand = (id) => {
  return requestAxios.delete(`/brands/${id}`);
};

export const editBrand = async ({ id, name, icon, extraCharge }) => {
  return requestAxios.patch(
    `/brands/${id}`,
    { name, icon, extraCharge },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};
