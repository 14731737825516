import { Checkbox } from "@chakra-ui/react";
import { TITLE_TEXT_STYLE } from "../../common/constants";

const CheckboxWeekend = ({ title, isChecked, onChange }) => {
  const handleChange = (e) => {
    onChange(e.currentTarget.checked);
  };
  return (
    <Checkbox isChecked={!isChecked} onChange={handleChange}>
      <span style={TITLE_TEXT_STYLE}>{title}</span>
    </Checkbox>
  );
};

export default CheckboxWeekend;
