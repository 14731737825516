import { Input } from "@chakra-ui/react";
import { useEffect } from "react";

const InputNewName = ({ rowRef, ...props }) => {
  useEffect(() => {
    if (rowRef.current) {
      rowRef.current.focus();
    }
  }, [rowRef.current]);

  return <Input autoComplete="off" ref={rowRef} {...props} />;
};

export default InputNewName;
