import { formatDate } from "../../Promotion/utils/formatDate";
import { DATE_FORMAT_API, DATE_FORMAT_WITH_TIME, DEFAULT_TIME_FORMAT } from "../../../common/constants";

export const transformToSend = ({ date, time, speakerSpeciality, ...obj }) => ({
  ...obj,
  datetime: `${formatDate(date)} ${time}`,
  speaker_speciality: speakerSpeciality,
});

export const convertSeminarFromApi = ({
  name,
  description,
  speaker,
  speaker_speciality: speakerSpeciality,
  city,
  datetime,
  image,
}) => ({
  name,
  description,
  speaker,
  speakerSpeciality,
  city,
  time: (datetime && formatDate(datetime, DEFAULT_TIME_FORMAT, DATE_FORMAT_WITH_TIME)) || "",
  seminarDate: datetime ? new Date(formatDate(datetime, DATE_FORMAT_API, DATE_FORMAT_WITH_TIME)) : null,
  image,
});
