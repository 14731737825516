import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { Link, useLocation } from "react-router-dom";
import { Box, Flex, Text } from "@chakra-ui/react";
import { NavItem } from "./NavItem";

import logoSvg from "../../assets/svg/logo.svg";

import { linkItems } from "./constants";
import { getNewOrders } from "../../pages/Orders/store/Orders.selectors";
import { getNewUsers } from "../../pages/Users/store/Users.selectors";
import { getUnreviewedOrdersThunk } from "../../pages/Orders/store/Orders.thunk";
import { getMoreUnreviewedUsersThunk } from "../../pages/Users/store/Users.thunk";
import { getCountByLink } from "./utils/getCountByLink";
import { getCurrentUserState } from "../../pages/Login/store/Login.selectors";

import { notification } from "../../assets/sounds";
import { useSound } from "../../hooks/useSound";
import { GetParameterModal } from "../../hocs/GetModalParametr";
import ROUTES from "../../routes/routes";
import { checkIsRoleAnalytic } from "../../utils/roles";
import { WEBSOCKET_API } from "../../config";

const SidebarMenu = (props) => {
  const dispatch = useDispatch();
  const { play } = useSound(notification);
  const { role } = useSelector(getCurrentUserState);

  const [count, setCount] = useState(0);
  const currentLocation = useLocation().pathname;

  const unreviewedOrders = useSelector(getNewOrders());
  const unreviewedUsers = useSelector(getNewUsers());

  const [connecStatustError, setConnectStatusError] = useState(null);

  const hasUnauthorized = connecStatustError === 401;

  const userCanSeeCounter = !checkIsRoleAnalytic(role);

  const token = useMemo(() => {
    return localStorage.getItem("token");
  }, [hasUnauthorized]);

  let socket;

  useEffect(() => {
    if (userCanSeeCounter && (!unreviewedOrders || !unreviewedUsers)) {
      setTimeout(() => {
        (async () => {
          await dispatch(getUnreviewedOrdersThunk()).unwrap();
          await dispatch(getMoreUnreviewedUsersThunk()).unwrap();
        })();
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (token) {
      socket = io(WEBSOCKET_API, {
        path: "/socket.io",
        transports: ["websocket", "polling"],
        auth: {
          token,
        },
      });
      socket.on("error", (error) => {
        setConnectStatusError(error.response.status);
      });
      if (userCanSeeCounter) {
        socket.on("new-user-request", (newUser) => {
          dispatch(getMoreUnreviewedUsersThunk());
          play();
        });
        socket.on("new-user-order", (newOrder) => {
          dispatch(getUnreviewedOrdersThunk());
          play();
        });
      }
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [token]);

  useEffect(() => {
    let timeoutId;

    if (!token) {
      timeoutId = setTimeout(() => {
        setCount(count + 1);
        setConnectStatusError(null);
      }, 1500);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [count, hasUnauthorized]);

  return (
    <Box
      w={{ base: 80 }}
      height="100vh"
      display="flex"
      sx={{ flexDirection: "column", padding: "24px 0 0 90px", backgroundColor: "transparent" }}
      {...props}
    >
      <Flex height="20" marginBottom="22px" alignItems="left" mx="8">
        <Link to={ROUTES.ORDERS}>
          <img style={{ width: 114, height: 61 }} src={logoSvg} alt="Logo" />{" "}
        </Link>
      </Flex>

      <Flex direction="column" overflow="auto">
        {linkItems.map(
          (link) =>
            link.checkRoleAccess(role) && (
              <Link key={link.name} to={link.link}>
                <NavItem
                  isActive={currentLocation.includes(link.link)}
                  icon={link.icon}
                  sx={{ textAlign: "start" }}
                  hover={{
                    bg: "#C06ECC",
                    color: "white",
                  }}
                  valueBadge={getCountByLink(link.link, unreviewedOrders, unreviewedUsers)}
                >
                  {link.name}
                </NavItem>
              </Link>
            )
        )}
      </Flex>

      <GetParameterModal />
    </Box>
  );
};

export default SidebarMenu;
