import { useCallback, useRef, useState } from "react";
import { Flex, FormControl, FormErrorMessage, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";

import { ReactComponent as EditIcon } from "../../assets/svg/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import { ReactComponent as SubmitIcon } from "../../assets/svg/SubmitIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/svg/CancelIcon.svg";

import InputNewName from "../../components/InputNewName";
import { useIsOverflow } from "../../hooks/hooks";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";

const TableRow = (props) => {
  const {
    row,
    selectedId,
    editedId,
    isSelecteble,
    itemsList,
    onUpdate,
    onDelete,
    onEdit,
    onSelect = () => {},
    provider,
  } = props;

  const rowRef = useRef(null);
  const ref = useRef(null);

  const [newName, setNewName] = useState(row?.name || "");
  const [error, setError] = useState(null);

  const [isOverflow] = useIsOverflow(ref);

  const isSelected = row.id === selectedId;
  const isEditing = row.id === editedId;

  const rowActions = isSelecteble
    ? {
        color: isSelected ? "#BD68CA" : "#434652",
        cursor: "grab",
        onClick: (e) => {
          onSelect(isSelected ? null : row.id);
          e.stopPropagation();
        },
      }
    : {};

  const handleEdit = (e) => {
    setNewName(row.name);
    onEdit(row);
    rowRef.current?.focus?.();
    e.stopPropagation();
  };
  const handleCancel = useCallback(
    (event) => {
      event.stopPropagation();
      setNewName("");
      onEdit(null);
      setError(null);
    },
    [setNewName, onEdit]
  );

  const handleSave = (e) => {
    const filteredList = itemsList.filter((item) => item.id !== editedId);
    if (filteredList.some(({ name }) => name === newName)) {
      setError("Названия категорий/подкатегорий не должны совпадать");
      return;
    }
    if (row?.name !== newName) {
      setError(null);
      onUpdate(newName, row.id);
    }
    handleCancel(e);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave(e);
    } else if (e.key === "Escape") {
      handleCancel(e);
    }
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(row);
  };
  const handleChangeNewName = (e) => {
    setNewName(e.target.value);
    e.stopPropagation();
  };

  return (
    <Tr
      ref={(el) => {
        provider.innerRef(el);
      }}
      {...provider.draggableProps}
      {...provider.dragHandleProps}
      height="68px"
      backgroundColor={isSelected ? "#F8F8FA" : "transparent"}
    >
      <Td {...rowActions} style={TEXT_OVERFLOW_STYLE} width="100%" ref={ref}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {isEditing ? (
          <FormControl isInvalid={error}>
            <InputNewName
              rowRef={rowRef}
              value={newName || ""}
              onKeyDown={handleKeyDown}
              onChange={handleChangeNewName}
              onClick={(e) => e.stopPropagation()}
            />
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </FormControl>
        ) : isOverflow ? (
          <Tooltip label={row.name} color="black" bg="white" placement="bottom-start">
            <span>{row.name}</span>
          </Tooltip>
        ) : (
          row.name
        )}
      </Td>
      <Td size="sm" w="100px" onClick={(e) => e.stopPropagation()}>
        <Flex onClick={(e) => e.stopPropagation()} gap="16px">
          <IconButton
            style={{ display: !isEditing ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<SubmitIcon />}
            onClick={handleSave}
            aria-label="Save"
          />
          <IconButton
            style={{ display: !isEditing ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<CancelIcon />}
            onClick={handleCancel}
            aria-label="Close"
          />

          <IconButton
            style={{ display: isEditing ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<EditIcon />}
            onClick={handleEdit}
            aria-label="Edit"
          />
          <IconButton
            style={{ display: isEditing ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<DeleteIcon />}
            onClick={handleDelete}
            aria-label="Delete"
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default TableRow;
