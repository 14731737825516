export const Filara = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1187_712)">
        <path
          d="M0 24.8901H7.98796C10.1938 24.8901 11.982 23.102 11.982 20.8961V15.4229H5.99097C2.68221 15.4229 0 18.1051 0 21.4138V24.8901Z"
          fill="url(#paint0_linear_1187_712)"
        />
        <path
          d="M7.38548e-09 1C6.61745 1 11.982 6.36449 11.982 12.9819V20.822C11.982 14.3132 -0.000343498 20.896 7.38548e-09 6.91704V1Z"
          fill="url(#paint1_linear_1187_712)"
        />
        <path
          d="M25.0005 24.8901H17.0122C14.806 24.8901 13.0186 23.102 13.0186 20.8961V15.4229H19.0095C22.318 15.4229 25.0005 18.1051 25.0005 21.4138V24.8901Z"
          fill="url(#paint2_linear_1187_712)"
        />
        <path
          d="M25.0005 1C18.3826 1 13.0186 6.36449 13.0186 12.9819V20.822C13.0186 14.3132 25.0005 20.896 25.0005 6.91704V1Z"
          fill="url(#paint3_linear_1187_712)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1187_712"
          x1="11.982"
          y1="15.4229"
          x2="7.66846"
          y2="27.6053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C00D3" />
          <stop offset="1" stopColor="#EDBBFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1187_712"
          x1="-0.000343388"
          y1="1.73964"
          x2="11.982"
          y2="19.6386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C00D3" />
          <stop offset="0.9999" stopColor="#F0C9FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1187_712"
          x1="13.0186"
          y1="15.4229"
          x2="17.3319"
          y2="27.6053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C00D3" />
          <stop offset="1" stopColor="#EDBBFF" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1187_712"
          x1="25.0005"
          y1="1.73964"
          x2="13.0186"
          y2="19.6386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C00D3" />
          <stop offset="0.9999" stopColor="#F0C9FF" />
        </linearGradient>
        <clipPath id="clip0_1187_712">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
