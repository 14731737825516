import { requestAxios } from "../api/axiosRequest";

export const getPromocodes = () => {
  return requestAxios.get(`/promocodes`);
};

export const getPromocode = (id) => {
  return requestAxios.get(`/promocodes/${id}`);
};

export const addPromocode = (payload) => {
  return requestAxios.post(`/promocodes`, payload);
};

export const deletePromocode = ({ id }) => {
  return requestAxios.delete(`/promocodes/${id}`);
};

export const editPromocode = async ({ id, objToSend }) => {
  return requestAxios.patch(`/promocodes/${id}`, objToSend);
};
