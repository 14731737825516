import { configureStore, combineReducers } from "@reduxjs/toolkit";

import { reducer as banners } from "../pages/Banners/store/Banners.slice";
import { reducer as brands } from "../pages/Brands/store/Brands.slice";
import { reducer as categories } from "../pages/Categories/store/Categories/Categories.slice";
import { reducer as cities } from "../pages/Cities/store/Cities/Cities.slice";
import { reducer as futureSeminars } from "../pages/Seminars/store/FutureSeminars/FutureSeminars.slice";
import { reducer as loginUser } from "../pages/Login/store/Login.slice";
import { reducer as products } from "../pages/Products/store/Products.slice";
import { reducer as promocodes } from "../pages/Promocodes/store/Promocodes.slice";
import { reducer as protocolCategories } from "../pages/ProtocolCategories/store/ProtocolCategories.slice";
import { reducer as protocols } from "../pages/Protocols/store/Protocol.slice";
import { reducer as seminarsHistory } from "../pages/Seminars/store/SeminarsHistory/SeminarsHistory.slice";
import { reducer as seminarsRequest } from "../pages/Seminars/store/SeminarsRequest/SeminarsRequest.slice";
import { reducer as subcategories } from "../pages/Categories/store/Subcategories/Subcategories.slice";
import { reducer as users } from "../pages/Users/store/Users.slice";
import { reducer as warehouses } from "../pages/Cities/store/Warehouses/Warehouses.slice";
import { reducer as orders } from "../pages/Orders/store/Orders.slice";
import { reducer as clients } from "../pages/Clients/store/Clients.slice";
import { reducer as cashbacks } from "../pages/CashbackSettings/store/Cashbacks.slice";
import { reducer as promotion } from "../pages/Promotion/store/Promotion.slice";
import { reducer as employees } from "../pages/Employees/store/Employees.slice";
import { reducer as specialistPromocodes } from "../pages/SpecialistPromocodes/store/SpecialistPromocodes.slice";
import { reducer as stories } from "../pages/StoriesSettings/store/Stories.slice";
import { reducer as notifications } from "../pages/Notifications/store/Notifications.slice";
import { reducer as courses } from "../pages/Courses/store/courses/courses.slice";
import { reducer as requestedCourses } from "../pages/Courses/store/CoursesRequest/coursesRequest.slice";
import { reducer as analytics } from "../pages/Wishlists/store/Analytics.slice";

const combinedReducer = combineReducers({
  banners,
  brands,
  categories,
  cities,
  futureSeminars,
  loginUser,
  products,
  promocodes,
  protocolCategories,
  protocols,
  seminarsHistory,
  seminarsRequest,
  subcategories,
  users,
  warehouses,
  orders,
  clients,
  cashbacks,
  promotion,
  employees,
  specialistPromocodes,
  stories,
  notifications,
  courses,
  requestedCourses,
  analytics,
});

const initialState = combinedReducer({}, {});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === "loginUser/fetchByLogout/fulfilled" || action.type === "loginUser/setLogout") {
    newState = initialState;
  }
  return combinedReducer(newState, action);
};

export const store = configureStore({
  reducer: rootReducer,
});
