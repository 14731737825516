import { requestAxios } from "../api/axiosRequest";

export const getOrders = ({ limit, page, query }, orderStatus) => {
  return requestAxios.get(`/orders${orderStatus ? `/${orderStatus}` : ""}`, {
    params: {
      take: limit,
      skip: (page - 1) * limit,
      query,
    },
  });
};

export const getOneOrder = (id) => {
  return requestAxios.get(`/orders/${id}`);
};

export const editOrder = (id) => {
  return requestAxios.patch(`/orders/${id}/mark-viewed`);
};

export const getUnreviewedOrders = () => {
  return requestAxios.get("/orders/new-count");
};

export const applyOrder = ({ id, paymentType = null }) => {
  return requestAxios.patch(`/orders/${id}/apply`, { paymentType });
};

export const getReportOrders = (params) => {
  return requestAxios.get(`/reports/orders`, { params });
};

export const getProductsForWarehouse = ({ limit, page, isReady, query, id, isRetailAllowed }) => {
  return requestAxios.get(`products/for-warehouse/${id}`, {
    params: {
      take: limit,
      skip: (page - 1) * limit,
      isReady: isReady ? 1 : 0,
      query,
      isRetailAllowed,
    },
  });
};

export const updateOrder = ({ id, data }) => {
  return requestAxios.patch(`orders/${id}/products`, { products: [...data] });
};

export const getPreviewOrder = ({ id, data }) => {
  return requestAxios.patch(`orders/${id}/products/preview`, {
    products: [...data],
  });
};

export const deleteOrder = (id) => {
  return requestAxios.delete(`orders/${id}`);
};

export const cancelOrder = (id) => requestAxios.patch(`orders/${id}/cancel`);

export const updateOrderDelivery = (orderId, values) => requestAxios.patch(`/orders/${orderId}/delivery-data`, values);
