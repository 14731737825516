import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DeleteItemModal } from "../../components/DeleteModals";

import { getAllStoriesThunk, deleteStoryThunk } from "./store/Stories.thunk";
import { getStories, StoryToast, toastIsView } from "./store/Stories.selectors";

import { useCustomToast } from "../../hooks/useCustomToast";

import StoriesTable from "./Table";
import TemplateDefaultContainer from "../../components/common/TemplateDefaultContainer";
import { useNavigate } from "react-router-dom";
import { deleteToast } from "./store/Stories.slice";

const StoriesSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();

  const toastInfo = useSelector(StoryToast() || "");
  const toastInViewed = useSelector(toastIsView() || false);

  const stories = useSelector(getStories());

  const [deleteStory, setDelete] = useState(null);

  const handleDelete = (id) => {
    dispatch(deleteStoryThunk(id));
  };

  useEffect(() => {
    dispatch(getAllStoriesThunk());

    return () => {
      dispatch(deleteToast());
    };
  }, [dispatch]);

  useEffect(() => {
    if (toastInfo.view) {
      pushToast(toastInfo);
    }

    dispatch(deleteToast());
  }, [toastInViewed]);

  return (
    <TemplateDefaultContainer handleCreateEntity={() => navigate("create")} textForAddButton="Добавить историю">
      <StoriesTable stories={stories} onEdit={(story) => navigate(story.id)} onDelete={setDelete} />

      {deleteStory && (
        <DeleteItemModal label="историю" item={deleteStory} onClose={() => setDelete(null)} onDelete={handleDelete} />
      )}
    </TemplateDefaultContainer>
  );
};
export default StoriesSettings;
