import { Button, Flex } from "@chakra-ui/react";

const AddRow = ({ onAdd, textForAddButton }) => {
  return (
    <Flex mb="16px" w="100%">
      <Button w="100%" colorScheme="purple" onClick={onAdd}>
        {textForAddButton}
      </Button>
    </Flex>
  );
};

export default AddRow;
