import styled from "@emotion/styled";
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "../Autocomplete";
import { PRODUCT_INPUT_STYLE } from "../../pages/Products/style";
import { brandsIsUpdated, getBrands } from "../../pages/Brands/store/Brands.selectors";
import { getAllBrands } from "../../pages/Brands/store/Brands.thunk";

const StyledFullWidthContainer = styled.div`
  width: 100%;
`;

export const BrandSelect = ({ isDisabled, value, onChange, errors, ...props }) => {
  const dispatch = useDispatch();

  const isBrandNeedUpdated = useSelector(brandsIsUpdated());
  const brands = useSelector(getBrands()).data;

  const itemsForBrands = useMemo(() => (brands ? [...brands] : []), [brands]);

  useEffect(() => {
    if (isBrandNeedUpdated) {
      dispatch(getAllBrands());
    }
  }, [isBrandNeedUpdated, dispatch]);

  return (
    <StyledFullWidthContainer>
      <Autocomplete
        isDisabled={isDisabled}
        placeholder="Выберите бренд..."
        value={value}
        onChange={onChange}
        items={itemsForBrands}
        error={errors}
        style={PRODUCT_INPUT_STYLE}
        {...props}
      />
    </StyledFullWidthContainer>
  );
};
