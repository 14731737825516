import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";

import { defaultAcceptableImageTypes } from "../../components/UploadInputGroup/constants";

import { ReactComponent as LoadFileIcon } from "../../assets/svg/LoadFileIcon.svg";
import { TrashIcon } from "../../assets/icon/TrashIcon";

import { handleEnterKey } from "../../utils/blurOnEnter";

import TagInput from "../../components/TagInput";
import RequireCharacteristic from "../../components/inputCharacteristic/RequireCharacteristic";
import InputName from "../../components/InputName/InputName";
import DescriptionTextarea from "../../components/DescriptionTextarea/DescriptionTextarea";
import Selector from "../../components/Selector";
import CurrentProductVolume from "./CurrentProductVolume";
import { DeleteItemModal } from "../../components/DeleteModals";
import { UploadInput } from "../../components/UploadInput/UploadInput";

import {
  editingProductThunk,
  getBrandsLatestProduct,
  getProductThunk,
  addProductMediaThunk,
} from "./store/Products.thunk";
import { useCustomToast } from "../../hooks/useCustomToast";
import { convertProductFromApi, transformCharacteristics, transformProductsForSend } from "./utils/transformData";
import {
  ADD_TEXT_STYLE,
  AGES,
  MAX_CHARACTERISTIC,
  PURPOSES,
  ROW_STYLE,
  SKIN_TYPES,
  VALIDATION_PRODUCT_SCHEMA,
} from "./constants";
import { PRODUCT_HEAD_TEXT_STYLE, PRODUCT_HINT, PRODUCT_INPUT_STYLE } from "./style";
import BrandProductsSelect from "../../components/BrandProductsSelect";
import { PlusIcon } from "../../assets/icon/PlusIcon";
import { SubCategoriesSelect } from "../CashbackSettings/SubCategoriesSelect";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";
import { Drawer } from "../../components/Drawer/Drawer";
import { checkIsEnterKey } from "../../utils/checkIsEnterKey";
import { useNavigate, useParams } from "react-router-dom";
import { BrandSelect } from "../../components/BrandSelect/BrandSelect";
import { CategoriesSelect } from "../../components/CategoriesSelect/CategoriesSelect";
import { getProduct } from "./store/Products.selectors";
import { useFormik } from "formik";
import { usePrompt } from "../../hooks/usePrompt";

const initialValues = {
  tags: [],
  similars: [],
};

const remoteIsReadyVariations = (arr) =>
  arr.map((item) => {
    const newObj = { ...item };
    delete newObj?.isReady;
    delete newObj?.isRetailAllowed;
    return newObj;
  });

const DrawerProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { productDetail: selectedProduct, loadingState } = useSelector(getProduct);
  const productFileSelectorRef = useRef(null);
  const handleClose = () => navigate(-1);

  const onSubmit = (values, _formikBag, isEdit) => {
    if (isEdit) {
      const transformedProduct = transformCharacteristics(
        transformProductsForSend({ ...values, volume: remoteIsReadyVariations(values.volume) })
      );

      dispatch(editingProductThunk({ productForSend: transformedProduct, id, onClose: handleClose }));
      return;
    }

    navigate(-1);
  };

  const { values, errors, setFieldValue, resetForm, setFieldError, handleSubmit, dirty, isSubmitting } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: VALIDATION_PRODUCT_SCHEMA,
    onSubmit: (formValues, formikBag) => onSubmit(formValues, formikBag, dirty),
  });

  useEffect(() => {
    if (id) {
      dispatch(getProductThunk(id));
    }
  }, [id]);

  useEffect(() => {
    if (selectedProduct && id && selectedProduct.id === id) {
      resetForm({ values: convertProductFromApi(selectedProduct) });
    }
  }, [id, selectedProduct]);

  const [bannerNames, setBannerNames] = useState(null);

  const { pushToast } = useCustomToast();

  const onCategoryChange = (value, indexCategory) => {
    setFieldValue(
      "categoryProduct",
      values.categoryProduct.map((category, index) => {
        if (index === indexCategory) {
          return {
            ...category,
            category: value ?? null,
            sub_catalog_product: null,
          };
        }
        return category;
      })
    );
  };

  const onSubCategoryChange = (value, indexSubcategory) => {
    setFieldValue(
      "categoryProduct",
      values.categoryProduct.map((category, index) => {
        if (index === indexSubcategory) {
          return {
            ...category,
            sub_catalog_product: value,
          };
        }
        return category;
      })
    );
  };
  const handleProductSelectFile = (file) => {
    dispatch(addProductMediaThunk({ file })).then(({ payload }) => {
      setFieldValue("images", [...values.images, payload.url]);
    });
  };

  const onPurposeChange = (option) =>
    setFieldValue("purpose", option ? { value: option.value, label: option.value } : null);

  const handleChangeCharAge = (name) => setFieldValue("charAge", name);

  const handleDeleteSimilars = (similarId) =>
    setFieldValue(
      "similars",
      values.similars.filter(({ id: currentId }) => currentId !== similarId)
    );

  const handleAddSimilars = (data) => setFieldValue("similars", [...values.similars, data]);

  const handleEditVolume = (key, updateValue, itemId) =>
    setFieldValue(
      "volume",
      values.volume.map((item) =>
        item.id === itemId
          ? {
              ...item,
              [key]: updateValue,
            }
          : item
      )
    );

  const addVolumeItem = (newVolume) => setFieldValue("volume", [...values.volume, newVolume]);

  const deleteVolumeItem = (volumeId) =>
    setFieldValue(
      "volume",
      values.volume.filter((obj) => obj.id !== volumeId)
    );

  const handleEditCharacteristics = useCallback(
    (key, updateValue, itemId) => {
      setFieldValue(
        "characteristics",
        values.characteristics.map((item) =>
          item.id === itemId
            ? {
                ...item,
                [key]: updateValue,
              }
            : item
        )
      );
    },
    [values.characteristics]
  );

  const addCharacteristicItem = useCallback(
    (newCharacteristic) => setFieldValue("characteristics", [...values.characteristics, newCharacteristic]),
    [values.characteristics]
  );

  const deleteCharacteristic = useCallback(
    (charId) =>
      setFieldValue(
        "characteristics",
        values.characteristics.filter((obj) => obj.id !== charId)
      ),
    [values.characteristics]
  );

  const handleDeleteImage = (imageLink) =>
    setFieldValue(
      "images",
      values.images.filter((image) => image !== imageLink)
    );

  const handleProductAvailable = async (e) => {
    const isAvailable = e.target.checked;
    let data = [];
    if (!isAvailable) {
      data = await dispatch(getBrandsLatestProduct(id)).unwrap();
      if (data.length > 0) {
        data = data.filter(({ isProductLast }) => isProductLast);
      }
    }
    if (data.length > 0) {
      data = data.map(({ name }) => name).join(", ");
      setBannerNames(data);
      return;
    }
    setFieldValue("isRetailAllowed", !values.isRetailAllowed);
  };

  const handleProductActivated = async (e) => {
    setFieldValue("isReady", !values.isReady);
  };

  const handleConfirm = () => setFieldValue("isRetailAllowed", !values.isRetailAllowed);

  const handleCloseModal = () => {
    setBannerNames(null);
  };

  const handleAddCategory = () =>
    setFieldValue("categoryProduct", [
      ...values.categoryProduct,
      { category: null, sub_catalog_product: null, tempId: uuidv4() },
    ]);

  const handleDeleteCategory = (indexCategory) => {
    if (errors.categoryProduct?.[1]) {
      setFieldError("categoryProduct[1]", undefined);
    }

    setFieldValue(
      "categoryProduct",
      values.categoryProduct.filter((_, index) => index !== indexCategory)
    );
  };

  const handleAddVolume = (event) => {
    if (checkIsEnterKey(event.key)) {
      addVolumeItem({ volume: "", codeFrom1C: "", id: uuidv4() });
    }
  };

  usePrompt(dirty && !isSubmitting);

  return (
    <Drawer isOpen handleClose={handleClose} handleSave={handleSubmit} isLoading={loadingState}>
      <Flex boxSize="full" direction="column" sx={ROW_STYLE}>
        <Text>Название 1C</Text>
        <Input value={values.nameFrom1C} isReadOnly isDisabled />
      </Flex>

      <FormControlWithError hasError={!!errors?.name} errorText={errors.name}>
        <Text>Название* </Text>
        <InputName
          value={values.name}
          error={errors?.name}
          name="name"
          onChange={(name) => setFieldValue("name", name)}
          onEnter={handleEnterKey}
          style={PRODUCT_INPUT_STYLE}
        />
      </FormControlWithError>

      <FormControlWithError hasError={errors.brand} errorText={errors.brand}>
        <Text>Бренд*</Text>
        <BrandSelect value={values.brand} onChange={(brand) => setFieldValue("brand", brand)} error={errors.brand} />
      </FormControlWithError>

      <Flex boxSize="full" direction="column" sx={ROW_STYLE}>
        <Text>Артикул</Text>
        <Input value={values.codeFrom1C} isReadOnly isDisabled />
      </Flex>

      <FormControlWithError hasError={!!errors?.description} errorText={errors.description}>
        <Text>Описание*</Text>
        <DescriptionTextarea
          value={values.description}
          name="description"
          error={errors?.description}
          placeholder="Опишите товар"
          onChange={(description) => setFieldValue("description", description)}
          style={PRODUCT_INPUT_STYLE}
        />
      </FormControlWithError>

      <FormControlWithError hasError={errors?.images} errorText={errors.images}>
        <Text>Изображения*</Text>
        {values.images &&
          values.images?.map((imageLink) => (
            <Flex key={imageLink} alignItems="center" justifyContent="space-between" gap="10px">
              <Image boxSize="80px" p="10px" src={imageLink} />
              <IconButton icon={<TrashIcon />} onClick={() => handleDeleteImage(imageLink)} aria-label="delete" />
            </Flex>
          ))}
        {values.images?.length < 5 && (
          <InputGroup>
            <Input
              placeholder="Загрузите изображения"
              isReadOnly
              style={{ position: "relative", paddingRight: "50px" }}
              onClick={() => productFileSelectorRef.current.click()}
            />
            <InputRightElement>
              <UploadInput
                ref={productFileSelectorRef}
                setError={(errorText) => {
                  setFieldError("images", errorText);
                }}
                formats={defaultAcceptableImageTypes}
                maxFileSize={500}
                onChangeFile={handleProductSelectFile}
                reset
              />
              <IconButton
                height="14px"
                w="14px"
                icon={<LoadFileIcon />}
                variant="unstyled"
                aria-label="Load icon"
                onClick={() => productFileSelectorRef.current.click()}
              />
            </InputRightElement>
          </InputGroup>
        )}
        <Text sx={{ ...PRODUCT_HINT, margin: "8px 0" }}>Максимум 5 изображений</Text>
        <Text sx={PRODUCT_HINT}>Фото товара - 1000x1000 px PNG, JPG, JPEG</Text>
      </FormControlWithError>

      <Flex boxSize="full" direction="column" sx={ROW_STYLE}>
        <Text>Цена</Text>
        <Input value={values.price} isReadOnly isDisabled />
      </Flex>

      <Flex flexWrap="wrap">
        <Text w="50%">Категория*</Text>

        <Text paddingLeft="6px" w="50%">
          Подкатегория*
        </Text>

        {values.categoryProduct &&
          values.categoryProduct.map((category, index) => (
            <Grid width="100%" key={category?.tempId} templateColumns="repeat(2,1fr)" gap="15px">
              <FormControlWithError
                hasError={!!errors?.categoryProduct?.[index]?.category}
                errorText={errors?.categoryProduct?.[index]?.category}
              >
                <CategoriesSelect
                  value={category?.category}
                  onChange={(value) => onCategoryChange(value, index)}
                  error={errors.catalog_product?.[index]}
                />
              </FormControlWithError>

              <FormControlWithError
                errorText={errors?.categoryProduct?.[index]?.sub_catalog_product}
                hasError={errors?.categoryProduct?.[index]?.sub_catalog_product}
              >
                <Flex width="100%">
                  <SubCategoriesSelect
                    categoryId={category?.category?.id}
                    value={category?.sub_catalog_product}
                    isDisabled={!category?.category?.id}
                    onChange={(value) => onSubCategoryChange(value, index)}
                    error={errors?.sub_catalog_product}
                    style={{ ...PRODUCT_INPUT_STYLE, width: "100%" }}
                  />

                  {index !== 0 && (
                    <Flex
                      padding="12px"
                      alignItems="center"
                      justifyContent="end"
                      onClick={() => handleDeleteCategory(index)}
                    >
                      <TrashIcon />
                    </Flex>
                  )}
                </Flex>
              </FormControlWithError>
            </Grid>
          ))}

        {values.categoryProduct?.length < 3 && (
          <Button
            height="20px"
            marginBottom={5}
            variant="unstyled"
            display="flex"
            align="center"
            onClick={handleAddCategory}
            width="fit-content"
          >
            <PlusIcon />
            <Text {...ADD_TEXT_STYLE}>Добавить категорию</Text>
          </Button>
        )}
      </Flex>

      <Text>Объем*</Text>
      <CurrentProductVolume
        currentArticle={selectedProduct?.codeFrom1C}
        volume={values.currentVolume}
        onChange={(value) => setFieldValue("currentVolume", value)}
        error={errors?.currentVolume && [errors?.currentVolume]}
        onEnter={handleAddVolume}
      />

      <Flex direction="column">
        {values.volume &&
          values.volume.map(({ id: volumeId, volume, codeFrom1C }, index) => (
            <Grid key={`volume-${volumeId ?? index}`} templateColumns="10fr 10fr" gap="15px">
              <FormControlWithError
                errorText={errors?.volume?.[index]?.volume}
                hasError={!!errors?.volume?.[index]?.volume}
              >
                <Input
                  autoComplete="off"
                  value={volume}
                  onKeyDown={handleAddVolume}
                  onChange={(e) => handleEditVolume("volume", e.target.value, volumeId)}
                />
              </FormControlWithError>

              <FormControlWithError
                hasError={errors?.volume?.[index]?.codeFrom1C}
                errorText={errors?.volume?.[index]?.codeFrom1C}
              >
                <Flex>
                  <Input
                    autoComplete="off"
                    value={codeFrom1C}
                    onKeyDown={handleAddVolume}
                    onChange={(e) => handleEditVolume("codeFrom1C", e.target.value, volumeId)}
                  />

                  <Flex alignItems="center" onClick={() => deleteVolumeItem(volumeId)} paddingX="12px">
                    <TrashIcon />
                  </Flex>
                </Flex>
              </FormControlWithError>
            </Grid>
          ))}

        <Button
          variant="unstyled"
          height="20px"
          display="flex"
          align="center"
          onClick={() => addVolumeItem({ volume: "", codeFrom1C: "", id: uuidv4() })}
          width="fit-content"
          marginTop={0}
        >
          <PlusIcon />
          <Text {...ADD_TEXT_STYLE}>Добавить объем</Text>
        </Button>
      </Flex>

      <Checkbox marginTop={5} isChecked={values.isRetailAllowed} onChange={handleProductAvailable}>
        <Text>Доступен для розницы</Text>
      </Checkbox>

      <Checkbox marginBottom={5} isChecked={values.isReady} onChange={handleProductActivated}>
        <Text>Товар активен</Text>
      </Checkbox>

      <Box>
        <Text>Характеристики</Text>
        <RequireCharacteristic
          charKey="Страна-производитель"
          value={values.charCountry}
          error={errors?.charCountry}
          onChange={(char) => setFieldValue("charCountry", char)}
          onEnter={handleEnterKey}
        />
        <RequireCharacteristic
          charKey="Код товара"
          value={values.charProductCode}
          error={errors?.charProductCode}
          onChange={(char) => setFieldValue("charProductCode", char)}
          onEnter={handleEnterKey}
        />

        <RequireCharacteristic
          charKey="Тип кожи"
          multiselect
          separator=", "
          value={values.charSkinType}
          items={SKIN_TYPES}
          error={errors?.charSkinType}
          onChange={(char) => setFieldValue("charSkinType", char)}
          onEnter={handleEnterKey}
        />

        <RequireCharacteristic
          charKey="Возрасты"
          multiselect
          separator=", "
          value={values.charAge}
          items={AGES}
          error={errors?.charAge}
          onChange={handleChangeCharAge}
          onEnter={handleEnterKey}
        />
        {values.characteristics?.length > 0 &&
          values.characteristics.map(({ key, value, id: characterId }, idx) => (
            <Grid key={characterId} templateColumns="10fr 10fr" gap="16px">
              <Flex direction="column">
                <FormControlWithError
                  key={`char-${idx}-key`}
                  errorText={errors.characteristics?.[idx]?.key}
                  hasError={!!errors.characteristics?.[idx]?.key}
                >
                  <Input
                    autoComplete="off"
                    value={key}
                    placeholder="Введите название"
                    onChange={(e) => handleEditCharacteristics("key", e.target.value, characterId)}
                  />
                </FormControlWithError>
              </Flex>

              <Flex direction="column">
                <FormControlWithError
                  errorText={errors.characteristics?.[idx]?.value}
                  hasError={errors.characteristics?.[idx]?.value}
                >
                  <Flex>
                    <Input
                      autoComplete="off"
                      value={value}
                      placeholder="Введите значение"
                      onChange={(e) => handleEditCharacteristics("value", e.target.value, characterId)}
                    />

                    <Flex alignItems="center" paddingX="12px" onClick={() => deleteCharacteristic(characterId)}>
                      <TrashIcon />
                    </Flex>
                  </Flex>
                </FormControlWithError>
              </Flex>
            </Grid>
          ))}

        <Button
          variant="unstyled"
          display="flex"
          height="20px"
          align="center"
          onClick={() => addCharacteristicItem({ key: "", value: "", id: uuidv4() })}
          width="fit-content"
          isDisabled={values.characteristics?.length >= MAX_CHARACTERISTIC}
          marginTop={0}
        >
          <PlusIcon />

          <Text {...ADD_TEXT_STYLE}>Добавить характеристику</Text>
        </Button>
      </Box>
      <Text sx={PRODUCT_HINT}>Максимум 15 харакеристик</Text>

      <Flex direction="column">
        <FormControl isInvalid={errors.tags}>
          <Text>Теги товаров</Text>
          <TagInput tags={values.tags} onChange={(tags) => setFieldValue("tags", tags)} />
          {errors?.tags && <FormErrorMessage>{errors.tags[0]}</FormErrorMessage>}
        </FormControl>
        <Text sx={PRODUCT_HINT}>Максимум 120 тегов</Text>
      </Flex>

      <Selector
        value={values.purpose}
        options={PURPOSES}
        height={40}
        onPurposeChange={onPurposeChange}
        error={errors?.purpose}
        title="Назначение*"
        placeholder="Выберите назначение товара"
        style={PRODUCT_HEAD_TEXT_STYLE}
      />

      <BrandProductsSelect
        brand={null}
        selectedProductId={id}
        isSearchAll
        usePrimaryColorForRetaiItems
        products={values.similars}
        onAdd={handleAddSimilars}
        onDelete={handleDeleteSimilars}
      />

      {bannerNames?.length > 0 && (
        <DeleteItemModal
          label="товара"
          title="Вы действительно хотите изменить доступность"
          item={{ name: values.name }}
          descripion={`Данный товар является единственным в баннерах: "${bannerNames}", при изменении доступности баннер будет удален.`}
          confirmBtnText="Изменить"
          cancelBtnText="Отменить изменение"
          onClose={handleCloseModal}
          onDelete={handleConfirm}
        />
      )}
    </Drawer>
  );
};

export default DrawerProduct;
