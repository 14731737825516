import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DeleteItemModal } from "../../components/DeleteModals";

import { deletingCashback, getAllCashbacklist } from "./store/Cashbacks.thunk";
import { getCashback, cashbackIsUpdated, cashbackToast, cashbackToastIsView } from "./store/Cashbacks.selectors";
import { deleteCashbackToast } from "./store/Cashbacks.slice";

import { useCustomToast } from "../../hooks/useCustomToast";

import CashbackTable from "./Table";
import TemplateDefaultContainer from "../../components/common/TemplateDefaultContainer";
import { useNavigate } from "react-router-dom";

const CashbackSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();

  const cashbacks = useSelector(getCashback());
  const cashbackIsUpdating = useSelector(cashbackIsUpdated());
  const cashbackToastInfo = useSelector(cashbackToast());
  const cashbackToastIsViewed = useSelector(cashbackToastIsView());

  const [deleteCashback, setDelete] = useState(null);

  const handleDelete = (id) => {
    dispatch(deletingCashback({ id }));
  };

  const cbToast = useCallback(() => {
    if (cashbackToastInfo.view) {
      pushToast(cashbackToastInfo);
      dispatch(deleteCashbackToast());
    }
  }, [cashbackToastInfo, dispatch]);

  useEffect(() => {
    if (!cashbackIsUpdating) {
      return;
    }

    dispatch(getAllCashbacklist());
  }, [cashbackIsUpdating, dispatch]);

  useEffect(() => {
    cbToast();
  }, [cashbackToastIsViewed, cbToast]);

  return (
    <TemplateDefaultContainer handleCreateEntity={() => navigate("create")} textForAddButton="Добавить акцию">
      <CashbackTable cashbacks={cashbacks} onEdit={(cashback) => navigate(cashback.id)} onDelete={setDelete} />

      {deleteCashback && (
        <DeleteItemModal label="акцию" item={deleteCashback} onClose={() => setDelete(null)} onDelete={handleDelete} />
      )}
    </TemplateDefaultContainer>
  );
};
export default CashbackSettings;
