import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addPromocode,
  deletePromocode,
  editPromocode,
  getPromocode,
  getPromocodes,
} from "../../../services/promocodeService";

import { SLICE_NAME } from "./types";

export const getAllPromocodes = createAsyncThunk(
  `${SLICE_NAME}/fetchGetPromocodes`,
  async (values, { rejectWithValue }) => {
    try {
      const promocodes = await getPromocodes();

      return promocodes?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addingPromocode = createAsyncThunk(
  `${SLICE_NAME}/fetchAddPromocode`,
  async (params, { rejectWithValue }) => {
    try {
      return await addPromocode(params);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const deletingPromocode = createAsyncThunk(
  `${SLICE_NAME}/fetchDeletePromocode`,
  async (id, { rejectWithValue }) => {
    try {
      return await deletePromocode(id);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const editingPromocode = createAsyncThunk(
  `${SLICE_NAME}/fetchEditPromocode`,
  async (params, { rejectWithValue }) => {
    try {
      return await editPromocode(params);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const fetchPromocode = createAsyncThunk(`${SLICE_NAME}/fetchPromocode`, async (id, { rejectWithValue }) => {
  try {
    const response = await getPromocode(id);
    return response.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});
