/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../../store/common";

import {
  addingSeminarHistory,
  deletingSeminarHistory,
  editingSeminarHistory,
  getAllSeminarsHistory,
  getSeminarHistory,
  addSeminarMediaThunk,
} from "./SeminarsHistory.thunk";
import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  seminars: [],
  seminar: { loadingState: LoadingState.IDLE, seminarDetail: null },
};

export const {
  reducer,
  actions: { deleteSeminarHistoryToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteSeminarHistoryToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeminarHistory.pending, (state) => {
        state.seminar.loadingState = LoadingState.PENDING;
      })
      .addCase(getSeminarHistory.rejected, (state, { payload }) => {
        state.toast = {
          view: true,
          title: payload,
          description: payload,
          status: "error",
        };
        state.seminar.loadingState = LoadingState.REJECTED;
      })
      .addCase(getSeminarHistory.fulfilled, (state, { payload }) => {
        state.seminar.loadingState = LoadingState.FULFILLED;
        state.seminar.seminarDetail = payload;
      })

      .addCase(getAllSeminarsHistory.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getAllSeminarsHistory.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllSeminarsHistory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.seminars = action.payload;
      })

      .addCase(addingSeminarHistory.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(addingSeminarHistory.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Семинар добавлен",
          description: "",
          status: "success",
        };
      })

      .addCase(deletingSeminarHistory.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(deletingSeminarHistory.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Семинар удален",
          description: "",
          status: "success",
        };
      })

      .addCase(editingSeminarHistory.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(editingSeminarHistory.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Семинар изменен",
          description: "",
          status: "success",
        };
      })
      .addCase(addSeminarMediaThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка загрузки медиа",
          description: action.payload,
          status: "error",
        };
      });
  },
});
