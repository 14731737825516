import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { SearchIcon } from "../../assets/icon/SearchIcon";
import { CrossIcon } from "../../assets/icon/CrossIcon";

const InputSearch = ({ value, onChange, onClick, onDelete, ...restProps }) => (
  <InputGroup>
    <InputLeftElement cursor="pointer" onClick={onClick}>
      <SearchIcon color="gray.300" />
    </InputLeftElement>

    <Input
      autoComplete="off"
      w="100%"
      value={value || ""}
      onChange={onChange}
      {...restProps}
      sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "20px", color: "#37393D" }}
    />

    {value && (
      <InputRightElement onClick={onDelete} cursor="pointer">
        <CrossIcon color="gray.300" />
      </InputRightElement>
    )}
  </InputGroup>
);

export default InputSearch;
