/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../../store/common";
import {
  addingCategory,
  deletingCategory,
  editingCategory,
  gettingCategories,
  updatingPositionCategory,
} from "./Categories.thunk";

import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  categories: [],
};

export const {
  reducer,
  actions: { deleteCategoriesToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteCategoriesToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(gettingCategories.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(gettingCategories.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload.message,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
        state.isAuth = false;
      })
      .addCase(gettingCategories.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.categories = action.payload;
      })
      .addCase(addingCategory.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(addingCategory.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(addingCategory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Категория добавлена",
          description: "",
          status: "success",
        };
      })
      .addCase(editingCategory.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(editingCategory.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(editingCategory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Категория обновлена",
          description: "",
          status: "success",
        };
      })
      .addCase(updatingPositionCategory.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(updatingPositionCategory.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(updatingPositionCategory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Порядок категорий изменен",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingCategory.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(deletingCategory.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(deletingCategory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Категория удалена",
          description: "",
          status: "success",
        };
      });
  },
});
