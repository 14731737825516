/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";
import {
  createEmployeeThunk,
  deleteEmployeeThunk,
  getEmployeeThunk,
  getEmployeesThunk,
  updateEmployeeThunk,
} from "./Employees.thunk";
import { checkIsRoleAdmin, checkIsRoleManager } from "../../../utils/roles";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  employees: [],
  employee: { loadingState: LoadingState.IDLE, employeeDetails: null },
};

export const {
  reducer,
  actions: { deleteEmployeesToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteEmployeesToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeThunk.pending, (state) => {
        state.employee.loadingState = LoadingState.PENDING;
      })
      .addCase(getEmployeeThunk.fulfilled, (state, action) => {
        state.employee.employeeDetails = action.payload;
        state.employee.loadingState = LoadingState.FULFILLED;
      })
      .addCase(getEmployeeThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.employee.employeeDetails = null;
        state.employee.loadingState = LoadingState.REJECTED;
      })

      .addCase(updateEmployeeThunk.pending, (state) => {
        state.employee.loadingState = LoadingState.PENDING;
      })
      .addCase(updateEmployeeThunk.fulfilled, (state, action) => {
        state.employee.employeeDetails = action.payload;
        state.employee.loadingState = LoadingState.FULFILLED;
        state.employees = state.employees.map((employee) =>
          employee.id === action.payload.id ? action.payload : employee
        );
        state.toast = {
          view: true,
          title: "Сотрудник обновлен",
          description: "",
          status: "success",
        };
      })
      .addCase(updateEmployeeThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.employee.loadingState = LoadingState.REJECTED;
      })

      .addCase(getEmployeesThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getEmployeesThunk.fulfilled, (state, action) => {
        state.employees = action.payload;
        state.loadingState = LoadingState.FULFILLED;
      })
      .addCase(getEmployeesThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.employees = [];
      })

      .addCase(createEmployeeThunk.fulfilled, (state, { payload }) => {
        if (checkIsRoleManager(payload.role)) {
          state.employees.push(payload);
        } else {
          const admins = state.employees.filter((employee) => checkIsRoleAdmin(employee.role));
          const managers = state.employees.filter((employee) => checkIsRoleManager(employee.role));

          state.employees = [...admins, payload, ...managers];
        }
        state.toast = {
          view: true,
          title: "Сотрудник создан",
          description: "",
          status: "success",
        };
      })
      .addCase(createEmployeeThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })

      .addCase(deleteEmployeeThunk.fulfilled, (state, { payload }) => {
        state.employees = state.employees.filter(({ id }) => id !== payload);
        state.toast = {
          view: true,
          title: "Сотрудник удален",
          description: "",
          status: "success",
        };
      })
      .addCase(deleteEmployeeThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      });
  },
});
