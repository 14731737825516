export const getWarehousesOptions = (warehouses, valuesWarehouses, warehouseIsLoading) => {
  if (warehouses.length !== 0 && !warehouseIsLoading) {
    return Object.values(warehouses).map((warehouse) => {
      return {
        label: warehouse.city,
        value: { payload: warehouse.id, isChecked: valuesWarehouses.includes(warehouse.id) },
      };
    });
  }

  return [
    {
      label: "Загрузка",
      value: { payload: "*", isChecked: true },
    },
  ];
};
