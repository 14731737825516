import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import {
  BODY_CELL_STYLES,
  ERROR_MESSAGE_STYLE,
  HEAD_CELL_STYLES,
  HEIGHT_TABLE_ROW,
  LABLE_STYLE,
  PLACEHOLDER_STYLE,
  TABLE_HEAD_STYLE,
  TEXT_OVERFLOW_STYLE,
  TEXT_STYLE,
} from "../common/constants";

const theme = extendTheme(
  {
    colors: {
      white: {
        50: "#ffffff",
        100: "#e2e8f0",
      },
      purple: {
        50: "#F7ECF8",
        100: "#EED9F2",
        200: "#DEB4E4",
        300: "#CD8ED7",
        400: "#BD68CA",
        500: "#C06ECC",
        600: "#8A3597",
        700: "#672871",
        800: "#451B4B",
        900: "#220D26",
        950: "#110713",
      },
      dark: {
        100: "#37393D",
      },
    },
    components: {
      Input: {
        variants: {
          outline: {
            field: {
              _focus: {
                borderColor: "purple.600",
                boxShadow: "0 0 0 1px #8A3597",
              },

              _placeholder: {
                ...PLACEHOLDER_STYLE,
              },
              ...TEXT_STYLE,
              _readOnly: {
                color: "#121212",
                backgroundColor: "#F8F8FA",
                _focus: {
                  borderColor: "white.100",
                  boxShadow: "white.100",
                },
                _hover: {
                  borderColor: "white.100",
                  boxShadow: "white.100",
                },
              },
            },
          },
        },
      },
      Textarea: {
        variants: {
          outline: {
            _readOnly: {
              color: "#121212",
              backgroundColor: "#F8F8FA",
              _focus: {
                borderColor: "white.100",
                boxShadow: "white.100",
              },
              _hover: {
                borderColor: "white.100",
                boxShadow: "white.100",
              },
            },
          },
        },
        baseStyle: {
          ...TEXT_STYLE,
          _placeholder: { ...PLACEHOLDER_STYLE },
        },
        defaultProps: {
          focusBorderColor: "purple.600",
          boxShadow: "0 0 0 1px #8A3597!important",
        },
      },
      InputGroup: {
        baseStyle: {
          ...TEXT_STYLE,
          _placeholder: { ...PLACEHOLDER_STYLE },
        },
        defaultProps: {
          focusBorderColor: "purple.600",
        },
      },
      Text: {
        baseStyle: {
          ...LABLE_STYLE,
        },
      },
      FormError: {
        baseStyle: {
          text: {
            ...ERROR_MESSAGE_STYLE,
          },
        },
      },
      Progress: {
        baseStyle: {
          filledTrack: {
            bg: "#BD68CA",
          },
        },
      },
      Table: {
        baseStyle: {
          thead: {
            tr: {
              height: "0px",
            },
          },
          th: {},
        },
        variants: {
          simple: {
            tr: {
              height: HEIGHT_TABLE_ROW,
              ":first-child": {
                borderTop: "none",
              },
            },
            th: { ...TABLE_HEAD_STYLE, ...HEAD_CELL_STYLES, padding: "10px 15px" },
            td: { padding: "10px 15px", ...TEXT_OVERFLOW_STYLE, ...BODY_CELL_STYLES },
          },
        },
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: "purple",
    components: ["Checkbox"],
  })
);

export default theme;
