import { boolean, object, string, mixed } from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";

export const USER_VALIDATION_SCHEMA = object({
  name: string()
    .nullable()
    .trim("Пробелы до/после имени недопустимы")
    .strict()
    .max(30, "Длина имени должна быть не более 30 символов")
    .required('Поле "Имя" обязательное'),
  lastName: string()
    .nullable()
    .trim("Пробелы до/после фамилии недопустимы")
    .strict()
    .max(30, "Длина фамилии должна быть не более 30 символов")
    .required('Поле "Фамилия" обязательное'),
  secondName: string()
    .nullable()
    .trim("Пробелы до/после отчества недопустимы")
    .strict()
    .max(30, "Длина отчества должна быть не более 30 символов"),
  firmName: string().nullable().trim("Пробелы до/после названия недопустимы").strict(),
  email: string()
    .nullable()
    .trim("Пробелы до/после почты недопустимы")
    .strict()
    .required('Поле "Почта" обязательное')
    .email("Некорректная почта"),
  role: object().nullable().required('Поле "Роль" обязательное'),
  phone: string()
    .test("isValidPhone", "Некорректный номер телефона", (phone) =>
      phone?.length > 0 ? isValidPhoneNumber(phone) : true
    )
    .required('Поле "Номер телефона" обязательное'),
  warehouse: object().nullable().required('Поле "Склад" обязательное'),
  isVerifiedPerson: boolean(),
});

export const BONUSES_OPERATION_VALIDATION_SCHEMA = object().shape({
  balance: mixed().test("is positive integer number", "Баланс бонусов должен быть целым числом больше 0", (value) => {
    const numericValue = Number(value);

    if (Number.isNaN(numericValue)) {
      return false;
    }

    if (numericValue < 0) {
      return false;
    }

    if (numericValue % 1) {
      return false;
    }

    return true;
  }),
});

export const VERIFICATION_STATUS_KEYS = {
  NEW: "new",
  PENDING: "pending",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
};

export const VERIFICATION_STATUS_NAMES = {
  NEW: "Неподтвержденный",
  PENDING: "На рассмотрении",
  ACCEPTED: "Подтвержденный",
  REJECTED: "Отклоненный",
  UNKNOWN: "Неизвестный",
};

export const BONUS_OPERATION_TYPES = {
  add: "ADD",
  subtract: "SUBTRACT",
  reserve: "reserve",
  commit: "commit",
  rollback: "rollback",
};

export const WITHOUT_TIME_DATE_FORMAT = "dd.LL.y"; // 12.04.2023

export const orderTableHeaders = [
  "Номер заказа",
  "Дата",
  "Тип доставки",
  "Статус заказа",
  "Оплата",
  "Бонусы",
  "Сумма заказа",
];

export const orderStatusIcons = {
  OUTDATED: {
    bg: "#FFF8D7",
    text: "Просрочен",
    color: "#F2A000",
  },
  ACTIVE: {
    bg: "#E8FFED",
    text: "Завершен",
    color: "#11A930",
  },
  CANCELED: {
    bg: "#E1FFFE",
    text: "Отменён",
    color: "#2DB6C2",
  },
};

export const deliveryStatuses = {
  PICKUP: "Самовывоз",
  DELIVERY: "Доставка",
  COURIER: "Доставка",
};

export const emptyTheadItems = [{ width: 15 }, { width: 15 }, { width: 15 }, {}, {}, {}, {}];

export const PAYMENT_TYPES = {
  ONLINE: "ONLINE",
  OFFICE_TERMINAL: "OFFICE_TERMINAL",
  COURIER_TERMINAL: "COURIER_TERMINAL",
  OFFICE_CASH: "OFFICE_CASH",
  COURIER_CASH: "COURIER_CASH",
};

export const BONUS_CARD_GRADIENT_COLORS = {
  colorOne: "#c850dc",
  colorTwo: "#8A3597",
};

export const BONUSES_TABLE_HEADERS = [
  { name: "Операция", width: 30 },
  { name: "Кол-во бонусов", width: 30 },
  { name: "Дата операции", width: 20 },
  { name: "Источник операции", width: 10 },
];

export const BONUS_OPERATION_TYPES_TRANSLATION = {
  ADD: "Начисление",
  SUBTRACT: "Списание",
  RESERVE: "Удержание",
  COMMIT: "Списание",
  ROLLBACK: "Возврат",
};

export const BONUS_LOG_RESPONSIBILITY_TRANSLATION = {
  SYSTEM: "Заказ",
  ADMIN: "Администратор",
};
