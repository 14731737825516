import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import routes from "./routes/schema";

import { LoadingState } from "./store/common";

import { Flex } from "@chakra-ui/react";
import { Preloader } from "./components/Preloader/Preloader";
import { getCurrentUserState, isAuth, loadingAdminTokenState } from "./pages/Login/store/Login.selectors";
import { checkAdminToken } from "./pages/Login/store/CheckAdminToken.thunk";
import SidebarMenu from "./components/Sidebar";
import { checkMobileDevice } from "./utils/checkMobileDevice";

import "react-datepicker/dist/react-datepicker.css";
import "./styles/App.css";
import { mobileRoutes } from "./routes/mobileSchema";

const isMobile = checkMobileDevice();

export const App = () => {
  const dispatch = useDispatch();

  const isUserAuth = useSelector(isAuth());
  const { role } = useSelector(getCurrentUserState) ?? {};
  const status = useSelector(loadingAdminTokenState());

  const routing = useRoutes(routes(isUserAuth, role));
  const mobileRouting = useRoutes(mobileRoutes());

  useEffect(() => {
    if (!isUserAuth) {
      dispatch(checkAdminToken());
    }
  }, []);

  const renderPage = () => {
    if (status === LoadingState.PENDING) {
      return <Preloader />;
    }

    if (isMobile && isUserAuth) {
      return mobileRouting;
    }

    if (!role) {
      return routing;
    }

    return (
      <Flex minH="100vh" bg="#F8F8FA">
        {!isMobile && <SidebarMenu />}
        {routing}
      </Flex>
    );
  };

  return <div className="App">{renderPage()}</div>;
};

export const AppWithSentry = Sentry.withProfiler(App);
