import React, { useEffect, useCallback, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import SpecialistPromocodesTable from "./SpecialistPromocodesTable";
import { Pagination } from "../../components/Pagination";
import { usePagination } from "../../hooks/usePagination";
import InputSearch from "../../components/InputSearch";
import { Button, Flex } from "@chakra-ui/react";
import { EXPORT_FILE_BTN } from "../Products/style";
import { DatePicker } from "../../components/DatePicker/DatePicker";
import {
  getSpecialistPromocodesThunk,
  createReportSpecialistPromocodesThunk,
} from "./store/SpecialistPromocodes.thunk";
import { useDispatch, useSelector } from "react-redux";
import { SEARCH_INPUT_BORDER_STYLE } from "./style";
import { checkIsEnterKey } from "../../utils/checkIsEnterKey";
import {
  getSpecialistPromocodes,
  getTotalPromocodes,
  promocodesToast,
  toastIsView,
} from "./store/SpecialistPromocodes.selectors";
import { formatDateToApi } from "../Promotion/utils/formatDate";
import { useCustomToast } from "../../hooks/useCustomToast";
import { deleteToast } from "./store/SpecialistPromocodes.slice";

export const SpecialistPromocodes = () => {
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();

  const specialistPromocodes = useSelector(getSpecialistPromocodes);
  const total = useSelector(getTotalPromocodes);
  const toastInfo = useSelector(promocodesToast() || "");
  const toastInViewed = useSelector(toastIsView() || false);

  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    return () => {
      dispatch(deleteToast());
    };
  }, []);

  const { isPrevDisabled, isNextDisabled, page, limit, totalPages, nextPage, prevPage, resetPage, setPage, setLimit } =
    usePagination({
      total,
    });

  const getFilteredSpecialistPromocodes = useCallback(async () => {
    try {
      await dispatch(
        getSpecialistPromocodesThunk({
          limit,
          page: page || 1,
          query: {
            searchQuery,
            startDate: startDate ? formatDateToApi(startDate) : null,
            endDate: endDate ? formatDateToApi(endDate) : null,
          },
        })
      ).unwrap();
    } catch (error) {
      console.log("error", error);
    }
  }, [limit, page, searchQuery, endDate]);

  useEffect(() => {
    getFilteredSpecialistPromocodes();
  }, [getFilteredSpecialistPromocodes]);

  const handleExportToExcel = async () => {
    try {
      setIsLoading(true);
      await dispatch(
        createReportSpecialistPromocodesThunk({
          query: {
            searchQuery,
            dateFrom: startDate ? formatDateToApi(startDate) : null,
            dateTo: endDate ? formatDateToApi(endDate) : null,
          },
        })
      ).unwrap();
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = useCallback(() => {
    if (searchInput) {
      setSearchInput("");
      setSearchQuery(null);
    }
  }, [searchInput]);

  const submitSearchQuery = useCallback(
    (event) => {
      if (checkIsEnterKey(event.key)) {
        setSearchQuery(searchInput.trim());
      }
    },
    [searchInput]
  );

  const handleChangeRangeDate = useCallback(
    (update) => {
      resetPage();

      if (!update) {
        setDateRange([null, null]);
        return;
      }
      setDateRange(update);
    },
    [resetPage, endDate]
  );

  useEffect(() => {
    if (toastInfo.view) {
      pushToast(toastInfo);
    }

    dispatch(deleteToast());
  }, [toastInViewed]);

  return (
    <PageLayout>
      <Flex flexDirection="column" width="100%">
        <Flex justifyContent="flex-start" width="100%" mb="2.5%">
          <Button
            onClick={handleExportToExcel}
            sx={EXPORT_FILE_BTN}
            colorScheme="purple"
            isLoading={isLoading}
            aria-label="Export"
          >
            Сформировать отчет
          </Button>
        </Flex>

        <Flex justifyContent="space-between" width="100%" mb="2.5%" gap="2">
          <Flex width="25%">
            <InputSearch
              style={{ border: SEARCH_INPUT_BORDER_STYLE }}
              placeholder="Найти специалиста"
              value={searchInput}
              onChange={(e) => {
                resetPage();
                setSearchInput(e.target.value);
              }}
              onKeyDown={submitSearchQuery}
              onClick={() => setSearchQuery(searchInput.trim())}
              onDelete={handleDelete}
            />
          </Flex>

          <Flex width="25%">
            <DatePicker
              placeholder="C дд.мм.гг - По дд.мм.гг"
              selected={startDate}
              maxDate={new Date()}
              startDate={startDate}
              endDate={endDate}
              onChange={handleChangeRangeDate}
              selectsRange={true}
            />
          </Flex>

          <Flex width="50%" display="flex" justifyContent="flex-end">
            <Pagination
              totalPages={totalPages}
              page={page}
              limit={limit}
              isPrevDisabled={isPrevDisabled}
              isNextDisabled={isNextDisabled}
              onNextPage={nextPage}
              onPrevPage={prevPage}
              onChangePage={setPage}
              onLimitChange={setLimit}
            />
          </Flex>
        </Flex>
      </Flex>
      <SpecialistPromocodesTable promocodes={specialistPromocodes} />
    </PageLayout>
  );
};
