export const FivePost = () => {
  return (
    <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.6721 11.7349C20.5322 9.87808 19.7093 8.39938 18.2903 7.45728C17.1051 6.67098 15.5073 6.26978 13.5449 6.26978H13.3249V6.26288H11.4038V3.24377H17.5842C18.5103 3.24377 19.2668 2.54229 19.2668 1.64595C19.2668 0.74733 18.5012 0 17.5842 0H9.72122C8.79277 0 8.03857 0.75191 8.03857 1.68263V7.84238C8.03857 8.77078 8.79048 9.52498 9.72122 9.52498H13.6137C16.2179 9.52498 17.0478 10.7194 17.0478 11.9527C17.0478 13.1654 16.3921 15.1117 13.6573 15.1117C11.3969 15.1117 10.0467 13.5826 9.76702 13.3327C8.93032 12.5831 8.08671 12.6358 7.52736 13.1287C6.81441 13.7591 6.90153 14.6417 7.3325 15.2973C7.84601 16.0745 9.84502 18.4884 13.655 18.4884C15.819 18.4884 17.7309 17.7113 19.0376 16.3037C20.2021 15.0498 20.7959 13.3855 20.6721 11.7349Z"
        fill="#58595B"
      />
      <path
        d="M0.204721 19.5627C2.7814 24.6267 8.01959 27.9048 13.8194 27.9048V24.2209C9.40879 24.2209 5.42686 21.7245 3.47601 17.8686C3.06108 17.0525 2.10285 16.6743 1.2386 16.9723C0.177213 17.3391 -0.306489 18.5609 0.204721 19.5627Z"
        fill="#5EBC52"
      />
      <path
        d="M26.6146 19.5627C24.0379 24.6267 18.7997 27.9048 12.9999 27.9048V24.2209C17.4105 24.2209 21.3925 21.7245 23.3433 17.8686C23.7583 17.0525 24.7165 16.6743 25.5807 16.9723C26.6421 17.3391 27.1258 18.5609 26.6146 19.5627Z"
        fill="#5EBC52"
      />
    </svg>
  );
};
