// titles: string[] & length === 3
export const getDeclination = (n, titles) => {
  if (n % 10 === 1 && n % 100 !== 11) {
    // Форма существительного для одного предмета
    return `${n} ${titles[0]}`;
  }

  if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    // Форма существительного для двух предметов
    return `${n} ${titles[1]}`;
  }

  return `${n} ${titles[2]}`; // Форма существительного для пяти предмета
};
