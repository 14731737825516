import { LoadingState } from "../../../../store/common";

import { SLICE_NAME } from "./types";

export const getSeminarsHistory = () => (state) => state[SLICE_NAME].seminars;
export const seminarsHistoryIsGetted = () => (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const seminarsHistoryIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const seminarsHistoryIsLoading = (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const getSeminarHistoryDetail = (state) => state[SLICE_NAME].seminar;

export const seminarsHistoryToast = () => (state) => state[SLICE_NAME].toast;
export const seminarsHistoryToastIsView = () => (state) => state[SLICE_NAME].toast.view;
