import { requestAxios } from "../api/axiosRequest";

const courseEndPoint = "/courses";

export const requestCourses = async ({ limit, page, statuses, query }) => {
  return requestAxios.get(`${courseEndPoint}`, {
    params: {
      take: limit,
      skip: (page - 1) * limit,
      statuses,
      query,
    },
  });
};

export const addCourse = async (payload) => {
  return requestAxios.post(`${courseEndPoint}`, payload);
};

export const deleteCourse = async (id) => {
  return requestAxios.delete(`${courseEndPoint}/${id}`);
};

export const editCourse = async ({ id, values }) => {
  return requestAxios.patch(`${courseEndPoint}/${id}`, values);
};

export const requestOneCourse = async (id) => {
  return requestAxios.get(`${courseEndPoint}/${id}`);
};

export const addCourseMedia = (payload) => {
  return requestAxios.post(`/media/image`, payload, { headers: { "Content-Type": "multipart/form-data" } });
};

export const requestCoursesRequests = async ({ query }) => {
  return requestAxios.get(`${courseEndPoint}/requests`, {
    params: {
      query,
    },
  });
};
