import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";
import { Button, Checkbox, Flex, Th } from "@chakra-ui/react";

import SelectedSeminarsPopUp from "../SelectedSeminarsPopUp";
import DeleteSeminarModal from "../DeleteSeminarModal";
import { deletingFutureSeminar } from "../store/FutureSeminars/FutureSeminars.thunk";

import TableRow from "./TableRow";
import { CONTAINER_STYLE } from "./style";
import { useNavigate } from "react-router-dom";
import { futureSeminarsIsLoading } from "../store/FutureSeminars/FutureSeminars.selectors";
import { TableConstructor } from "../../../components/TableConstructor/TableConstructor";

export const FUTURE_SEMINARS = 0;

const TheadColumns = [
  { name: "Название семинара", width: 50 },
  { name: "Спикер", width: 30 },
  { name: "Дата", width: 10 },
  { name: "", width: 10 },
];

const SeminarsTable = ({ currentTab, seminars = [] }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(futureSeminarsIsLoading);

  const [checkedItems, setCheckedItems] = useState(Map());
  const [deleteSeminar, setDeleteSeminar] = useState(null);
  const [deleteSeminars, setDeleteSeminars] = useState(false);

  const isCheckedAll = useMemo(
    () => (seminars?.length ? checkedItems.size === seminars?.length : false),
    [seminars, checkedItems]
  );
  const isIndeterminate = checkedItems.some(Boolean) && !isCheckedAll;

  const handleAddSeminar = () => navigate("feature/create");
  const handleEditSeminar = useCallback(({ id }) => navigate(`feature/${id}`), [navigate]);

  const handleUnselectAll = () => {
    setCheckedItems(checkedItems.clear());
  };
  const handleCheckAll = () => {
    if (isCheckedAll) handleUnselectAll();
    else seminars.forEach(({ id, name }) => setCheckedItems((map) => map.set(id, name)));
  };

  const handleSelectSeminar = useCallback(
    ({ id, name }, isSelected) =>
      isSelected ? setCheckedItems(checkedItems.delete(id)) : setCheckedItems(checkedItems.set(id, name)),
    [checkedItems]
  );

  const handleDelete = (seminar) => setDeleteSeminar(seminar);

  const handleDeleteConfirm = ({ id }) => dispatch(deletingFutureSeminar({ id }));

  const handleDeleteSeminarsConfirm = (seminarsMap) => {
    setCheckedItems(checkedItems.clear());
    seminarsMap.forEach((v, k) => dispatch(deletingFutureSeminar({ id: k })));
  };

  useEffect(() => {
    if (currentTab !== FUTURE_SEMINARS && checkedItems.size > 0) {
      setCheckedItems(checkedItems.clear());
    }
  }, [currentTab, checkedItems, setCheckedItems]);

  const seminarRow = (seminar) => (
    <TableRow
      key={`future-seminar-${seminar.id}`}
      seminar={seminar}
      isSelected={checkedItems.has(seminar.id)}
      onEdit={handleEditSeminar}
      onDelete={handleDelete}
      onSelect={handleSelectSeminar}
    />
  );

  return (
    <>
      <Flex gap={1} mb="16px">
        <Button w="100%" colorScheme="purple" marginLeft="auto" onClick={handleAddSeminar}>
          Добавить семинар
        </Button>
      </Flex>

      <TableConstructor
        Row={seminarRow}
        containerStyles={CONTAINER_STYLE}
        isLoading={isLoading}
        items={seminars}
        notFoundText="Здесь пока нет семинаров"
        theadExtraComponent={
          <Th textAlign="center" lineHeight={0}>
            <Checkbox isChecked={isCheckedAll} isIndeterminate={isIndeterminate} onChange={handleCheckAll} />
          </Th>
        }
        theadItems={TheadColumns}
      />

      <DeleteSeminarModal
        seminar={deleteSeminar}
        onClose={() => setDeleteSeminar(null)}
        onDelete={handleDeleteConfirm}
      />

      <DeleteSeminarModal
        seminars={deleteSeminars && checkedItems}
        onClose={() => setDeleteSeminars(false)}
        onDelete={handleDeleteSeminarsConfirm}
      />

      {!!checkedItems?.size && (
        <SelectedSeminarsPopUp
          checkedItems={checkedItems}
          onClose={handleUnselectAll}
          onDelete={() => setDeleteSeminars(true)}
        />
      )}
    </>
  );
};

export default SeminarsTable;
