import { LoadingState } from "../../../store/common";
import { SLICE_NAME } from "./types";

export const getClients = () => (state) => state[SLICE_NAME].clients;
export const getClient = () => (state) => state[SLICE_NAME].client?.clientDetails;
export const getClientOrders = () => (state) => state[SLICE_NAME]?.client?.clientDetails?.orders;
export const getClientBonuses = () => (state) => state[SLICE_NAME]?.client?.clientDetails?.bonuses;
export const getClientOrdersCount = () => (state) => state[SLICE_NAME]?.client?.clientDetails?.orderCount;
export const isLoadingClientInfo = () => (state) => state[SLICE_NAME]?.client?.loadingState === LoadingState.PENDING;
export const isLoadingClientOrders = () => (state) =>
  state[SLICE_NAME]?.client?.ordersLoadingState === LoadingState.PENDING;
export const isLoadingEditingClientInfo = () => (state) =>
  state[SLICE_NAME].client.editingState === LoadingState.PENDING;
export const resetPassword = () => (state) => state[SLICE_NAME].tempPassword?.password;
export const isLoadingTempPassword = () => (state) =>
  state[SLICE_NAME].tempPassword?.loadingState === LoadingState.PENDING;

export const clientToast = () => (state) => state[SLICE_NAME].toast;
export const toastIsView = () => (state) => state[SLICE_NAME].toast.view;
export const isLoading = () => (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
