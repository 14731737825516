import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Table, TableContainer, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";

import { DeleteItemModal } from "../../components/DeleteModals";
import TableRow from "./TableRow";
import { isEqual } from "lodash";

const CategoriesTable = ({ categories = [], selectedId, onDelete, onChangePosition, isLoading, ...props }) => {
  const [categoriesList, setCategoriesList] = useState(categories);
  const [editCategory, setEditCategory] = useState(null);
  const [deleteCategory, setDeleteCategory] = useState(null);

  useEffect(() => {
    setCategoriesList(categories);
  }, [categories, setCategoriesList]);

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const tempListCategory = Array.from(categoriesList);
    const [selectedCategory] = tempListCategory.splice(source.index, 1);
    tempListCategory.splice(destination.index, 0, selectedCategory);

    if (!isEqual(categoriesList, tempListCategory)) {
      onChangePosition(tempListCategory.map((item) => item.id));
      setCategoriesList(tempListCategory);
    }
  };

  return (
    <>
      <TableContainer w="100%" height="calc(100% - 115px)" paddingBottom="16px" overflowY="auto">
        {categories?.length > 0 ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Table variant="simple" colorScheme="blackAlpha" size="sm" width="100%">
              <Thead>
                <Tr
                  height="44px"
                  padding="10px 8px"
                  backgroundColor="#F8F8FA"
                  color="#737680"
                  borderTop="1px solid #EBECEF"
                  borderBottom="1px solid #EBECEF"
                >
                  <Th>Название категории</Th>
                  <Th />
                </Tr>
              </Thead>
              <Droppable droppableId="categories">
                {(provider) => (
                  <Tbody ref={provider.innerRef} {...provider.droppableProps}>
                    {categoriesList.map((row, index) => (
                      <Draggable key={`category-${row.id}`} draggableId={row.id} index={index}>
                        {/* eslint-disable-next-line no-shadow */}
                        {(provider) => (
                          <TableRow
                            provider={provider}
                            {...props}
                            itemsList={categoriesList}
                            isSelecteble
                            row={row}
                            editedId={editCategory?.id}
                            selectedId={selectedId}
                            onEdit={setEditCategory}
                            onDelete={setDeleteCategory}
                          />
                        )}
                      </Draggable>
                    ))}

                    {provider.placeholder}
                  </Tbody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        ) : (
          !isLoading && <Text>Здесь пока нет категорий</Text>
        )}
      </TableContainer>

      {deleteCategory && (
        <DeleteItemModal
          label="категорию"
          item={deleteCategory}
          descripion='При удалении будут БЕЗВОЗВРАТНО удалены все её подкатегории. Товары принадлежащие ТОЛЬКО этим подкатегориям будут перемещены в "Неготовые"'
          onClose={setDeleteCategory}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

export default CategoriesTable;
