import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import theme from "../../theme";

export const ResetPassword = ({ open, title, descripion, onClose, onReset }) => {
  const handleClose = () => {
    onClose(null);
  };

  const handleReset = () => {
    onReset();
    handleClose();
  };

  return (
    <Modal isCentered isOpen={open} onClose={handleClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader />

        <ModalBody
          style={{ fontSize: "24px", padding: "40px 40px 32px", textAlign: "center", flexDirection: "column" }}
        >
          <p style={{ marginBottom: "32px" }}>{title}</p>
          <p>{descripion}</p>
        </ModalBody>

        <ModalFooter style={{ display: "flex", gap: "16px", flexDirection: "column", padding: "0 40px 40px" }}>
          <Button w="100%" bgColor={theme.colors.purple[500]} color={theme.colors.white[50]} onClick={handleReset}>
            Да
          </Button>

          <Button w="100%" bgColor={theme.colors.white[50]} color={theme.colors.purple[500]} onClick={handleClose}>
            Нет
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
