import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getNotifications,
  addNotification,
  deleteNotification,
  editNotification,
  getOneNotification,
} from "../../../services/notificationService";

import { SLICE_NAME } from "./types";

export const getNotificationsThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetNotifications`,
  async (params, { rejectWithValue }) => {
    try {
      const notifications = await getNotifications(params);

      return notifications;
    } catch (err) {
      return rejectWithValue(
        err?.response?.data?.message || "Ошибка при получении уведомлений, попробуйте вернуться сюда позже"
      );
    }
  }
);

export const getNotification = createAsyncThunk(
  `${SLICE_NAME}/fetchGetNotification`,
  async (params, { rejectWithValue }) => {
    try {
      const notification = await getOneNotification(params);

      return notification;
    } catch (err) {
      return rejectWithValue(
        err?.response?.data?.message || "Ошибка при получении уведомления, попробуйте вернуться сюда позже"
      );
    }
  }
);

export const addingNotification = createAsyncThunk(
  `${SLICE_NAME}/fetchAddNotification`,
  async (params, { rejectWithValue }) => {
    try {
      return await addNotification(params);
    } catch (err) {
      return rejectWithValue(
        err?.response?.data?.message || "Ошибка при создании уведомления, попробуйте повторить операцию позже"
      );
    }
  }
);

export const deletingNotification = createAsyncThunk(
  `${SLICE_NAME}/fetchDeleteNotification`,
  async (id, { rejectWithValue }) => {
    try {
      return await deleteNotification(id);
    } catch (err) {
      return rejectWithValue(
        err?.response?.data?.message || "Ошибка при удалении уведомления, попробуйте повторить операцию позже"
      );
    }
  }
);

export const editingNotification = createAsyncThunk(
  `${SLICE_NAME}/fetchEditNotification`,
  async (params, { rejectWithValue }) => {
    try {
      return await editNotification(params);
    } catch (err) {
      return rejectWithValue(
        err?.response?.data?.message || "Ошибка при обновлении уведомления, попробуйте повторить операцию позже"
      );
    }
  }
);
