import TableRow from "./CoursesRequestsTableRow";
import { CONTAINER_STYLE } from "./style";
import { TableConstructor } from "../../../components/TableConstructor/TableConstructor";
import { COURSES_REQUESTS_TABLE_HEADERS } from "../constants";
import { getSubscribedUsersToCourse } from "../utils/transformData";

const courseRequestRow = (courseRequest) => <TableRow {...courseRequest} />;

export const CoursesRequestsTable = ({ isLoading, coursesRequests }) => {
  return (
    <TableConstructor
      Row={courseRequestRow}
      containerStyles={CONTAINER_STYLE}
      isLoading={isLoading}
      items={getSubscribedUsersToCourse(coursesRequests)}
      notFoundText="Здесь пока нет курсов"
      theadItems={COURSES_REQUESTS_TABLE_HEADERS}
    />
  );
};
