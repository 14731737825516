import { requestAxios } from "../api/axiosRequest";

const PRODUCT_API = "/products";

export const getProducts = ({
  limit,
  page,
  isReady,
  query,
  brands,
  subcategories,
  isRetailAllowed = false,
  categories,
  isBestseller,
}) =>
  requestAxios.get(PRODUCT_API, {
    params: {
      take: limit,
      skip: (page - 1) * limit,
      isReady: isReady ? 1 : 0,
      query,
      brands,
      subcategories,
      isRetailAllowed,
      categories,
      isBestseller,
    },
  });

export const getOneProduct = (id) => requestAxios.get(`${PRODUCT_API}/${id}`);

export const editProduct = (data, id) => requestAxios.patch(`${PRODUCT_API}/${id}`, data);

export const getLatestProduct = (id) => requestAxios.get(`${PRODUCT_API}/${id}/banners`);

export const checkCode1C = (code) => requestAxios.get(`${PRODUCT_API}/by-article/${code}`);

export const addProductMedia = (payload) => {
  return requestAxios.post(`/media/image`, payload, { headers: { "Content-Type": "multipart/form-data" } });
};

export const getAllBrandProducts = ({ brandId, ...params }) => {
  return requestAxios.get(`${PRODUCT_API}/brand/${brandId}`, { params });
};
