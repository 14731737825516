import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import { NAME_STYLES } from "../../common/constants";

const DeleteCityModal = ({ id, name, onClose, onDelete }) => {
  const handleDeleteCity = () => {
    onDelete(id);
    onClose();
  };

  const overflowScroll = name?.length > 96 ? {} : { overflow: "hidden" };

  return (
    <Modal isCentered isOpen={id} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody
          style={{ fontSize: "24px", padding: "40px 40px 32px", textAlign: "center", flexDirection: "column" }}
        >
          <p style={{ marginBottom: "32px" }}>Вы действительно хотите удалить город</p>
          <p style={{ ...NAME_STYLES, ...overflowScroll }}>{`"${name}"?`}</p>
        </ModalBody>
        <ModalFooter style={{ display: "flex", gap: "16px", flexDirection: "column", padding: "0 40px 40px" }}>
          <Button w="100%" bgColor="#C06ECC" color="#ffffff" onClick={handleDeleteCity}>
            Удалить
          </Button>
          <Button w="100%" bgColor="#ffffff" color="#C06ECC" onClick={onClose}>
            Отменить удаление
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteCityModal;
