import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createEmployee,
  deleteEmployee,
  getAllEmployees,
  getOneEmployee,
  updateEmployee,
} from "../../../services/employeeService";
import { SLICE_NAME } from "./types";

export const getEmployeesThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployees`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await getAllEmployees(params);

      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || "Ошибка");
    }
  }
);

export const getEmployeeThunk = createAsyncThunk(`${SLICE_NAME}/fetchEmployee`, async (params, { rejectWithValue }) => {
  try {
    const response = await getOneEmployee(params);

    return response.data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.message || "Ошибка");
  }
});

export const createEmployeeThunk = createAsyncThunk(
  `${SLICE_NAME}/createEmployee`,
  async ({ handleCloseModal, formattedEmployee }, { rejectWithValue }) => {
    try {
      const response = await createEmployee(formattedEmployee);

      handleCloseModal();

      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || "Ошибка");
    }
  }
);

export const deleteEmployeeThunk = createAsyncThunk(`${SLICE_NAME}/deleteEmployee`, async (id, { rejectWithValue }) => {
  try {
    await deleteEmployee(id);

    return id;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.message || "Ошибка");
  }
});

export const updateEmployeeThunk = createAsyncThunk(
  `${SLICE_NAME}/updateEmployee`,
  async ({ handleCloseModal, ...data }, { rejectWithValue }) => {
    try {
      const response = await updateEmployee(data);

      handleCloseModal();

      if (data.isCurrentUser) {
        localStorage.setItem("token", response.data.token?.accessToken);
        localStorage.setItem("email", response.data.email);
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || "Ошибка");
    }
  }
);
