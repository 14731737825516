import { SLICE_NAME } from "./types";
import { LoadingState } from "../../../store/common";

export const getUsers = () => (state) => state[SLICE_NAME].users;
export const getUser = (state) => state[SLICE_NAME].user;
export const usersIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const usersIsLoading = () => (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const userToast = () => (state) => state[SLICE_NAME].toast;
export const toastIsView = () => (state) => state[SLICE_NAME].toast.view;
export const getNewUsers = () => (state) => state[SLICE_NAME].unreviewedUsers;
