/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../../store/common";
import {
  addingSubcategory,
  deletingSubcategory,
  editingSubcategory,
  gettingSubcategories,
  updatingPositionSubCategory,
} from "./Subcategories.thunk";
import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  subcategories: [],
};

export const {
  reducer,
  actions: { deleteSubcategoriesToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteSubcategoriesToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(gettingSubcategories.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(gettingSubcategories.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload.message,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
        state.isAuth = false;
      })
      .addCase(gettingSubcategories.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.subcategories = action.payload;
      })
      .addCase(addingSubcategory.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(addingSubcategory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Подкатегория добавлена",
          description: "",
          status: "success",
        };
      })
      .addCase(addingSubcategory.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(editingSubcategory.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(editingSubcategory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Подкатегория обновлена",
          description: "",
          status: "success",
        };
      })
      .addCase(editingSubcategory.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(updatingPositionSubCategory.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(updatingPositionSubCategory.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(updatingPositionSubCategory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Порядок подкатегорий изменен",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingSubcategory.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(deletingSubcategory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Подкатегория удалена",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingSubcategory.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      });
  },
});
