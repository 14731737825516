import { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Grid, IconButton, Image, Input, InputGroup, InputRightElement, Text, Textarea } from "@chakra-ui/react";

import InputImage from "../../../components/InputImage/InputImage";
import { ReactComponent as DeleteIcon } from "../../../assets/svg/DeleteIcon.svg";
import { LinkIcon } from "../../../assets/icon/LinkIcon";
import { HINT_STYLE } from "../../../common/constants";

import { FUTURE_SEMINAR_VALIDATION_SHCEMA } from "../constants";
import {
  addingFutureSeminar,
  editingFutureSeminar,
  getFutureSeminar,
  addSeminarMediaThunk,
} from "../store/FutureSeminars/FutureSeminars.thunk";
import { convertSeminarFromApi, transformToSend } from "../utils/transformData";
import { handleEnterKey } from "../../../utils/blurOnEnter";
import { getFutureSeminars, getSeminarFutureDetail } from "../store/FutureSeminars/FutureSeminars.selectors";
import { generateObjForValidate } from "../../../utils/generateObjForValidate";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { formatDateToApi } from "../../Promotion/utils/formatDate";
import { Drawer } from "../../../components/Drawer/Drawer";
import { FormControlWithError } from "../../../components/FormControlWithError/FormControlWithError";
import { checkIsEnterKey } from "../../../utils/checkIsEnterKey";
import { convertToGoogleDrive } from "../../Promotion/utils/transformData";
import { CitiesAutocomplete } from "../../../components/CitiesAutocomplete/CitiesAutocomplete";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { usePrompt } from "../../../hooks/usePrompt";
import DescriptionTextarea from "../../../components/DescriptionTextarea/DescriptionTextarea";
import TimePicker from "../../../components/TimePicker/TimePicker";
import { UploadInputGroup } from "../../../components/UploadInputGroup/UploadInputGroup";

const initialValues = {
  name: "",
  description: "",
  speaker: "",
  speakerSpeciality: "",
  city: null,
  time: "",
  seminarDate: null,
  image: null,
};

const EditSeminarModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { seminarDetail: seminar, loadingState } = useSelector(getSeminarFutureDetail);

  const handleCloseModal = () => navigate(-1);
  const futureSeminarFileSelectorRef = useRef(null);

  const handleSave = ({ name, description, speakerSpeciality, speaker, image, time, seminarDate, city }) => {
    const seminarForSend = {
      name: name.trim(),
      description: description.trim(),
      speaker: speaker.trim(),
      speakerSpeciality: speakerSpeciality.trim(),
      city: city?.id,
      date: formatDateToApi(seminarDate),
      time,
      image: image || null,
    };

    const objToSend = transformToSend(seminarForSend);

    if (id) {
      dispatch(editingFutureSeminar({ id, seminar: objToSend }));
    } else {
      dispatch(addingFutureSeminar({ seminar: objToSend }));
    }

    handleCloseModal();
  };

  const seminarNames = generateObjForValidate(seminar?.name || "", useSelector(getFutureSeminars()).data);

  const { values, errors, handleChange, resetForm, handleSubmit, setFieldValue, setFieldError, dirty, isSubmitting } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validateOnChange: false,
      onSubmit: handleSave,
      validationSchema: FUTURE_SEMINAR_VALIDATION_SHCEMA(seminarNames),
    });

  const handleChangeCity = (value) => {
    setFieldValue("city", value);
  };

  const handleFutureSeminarSelectFile = useCallback(
    (file) => {
      dispatch(addSeminarMediaThunk({ file })).then(({ payload }) => {
        setFieldValue("image", payload.url);
      });
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (id) {
      dispatch(getFutureSeminar(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && seminar && seminar.id === id) {
      resetForm({ values: convertSeminarFromApi(seminar) });
    }
  }, [id, seminar]);

  usePrompt(dirty && !isSubmitting);

  return (
    <Drawer
      isOpen
      handleClose={handleCloseModal}
      isLoading={loadingState}
      handleSave={dirty || !id ? handleSubmit : handleCloseModal}
    >
      <FormControlWithError hasError={errors?.name} errorText={errors.name}>
        <Text>Название* </Text>
        <Input autoComplete="off" name="name" value={values.name} onChange={handleChange} onKeyDown={handleEnterKey} />
      </FormControlWithError>

      <FormControlWithError hasError={errors?.description} errorText={errors.description}>
        <Text>Описание*</Text>

        <DescriptionTextarea
          placeholder="Опишите семинар"
          value={values.description}
          onChange={(text) => setFieldValue("description", text)}
        />
      </FormControlWithError>

      <FormControlWithError hasError={errors?.speaker} errorText={errors.speaker}>
        <Text>Спикер*</Text>
        <Input
          autoComplete="off"
          name="speaker"
          value={values.speaker}
          onChange={handleChange}
          onKeyDown={handleEnterKey}
        />
      </FormControlWithError>

      <FormControlWithError hasError={errors?.speakerSpeciality} errorText={errors.speakerSpeciality}>
        <Text>Специальность спикера*</Text>
        <Input
          autoComplete="off"
          name="speakerSpeciality"
          value={values.speakerSpeciality}
          onChange={handleChange}
          onKeyDown={handleEnterKey}
        />
      </FormControlWithError>

      <FormControlWithError hasError={errors?.city} errorText={errors.city}>
        <Text>Город*</Text>

        <CitiesAutocomplete handleChange={handleChangeCity} value={values.city} />
      </FormControlWithError>

      <Grid templateColumns="repeat(2,1fr)" mb="12px">
        <Flex direction="column" mr={2}>
          <FormControlWithError hasError={errors?.seminarDate} errorText={errors.seminarDate}>
            <Text>Дата*</Text>

            <DatePicker
              selected={values.seminarDate}
              onChange={(date) => setFieldValue("seminarDate", date)}
              minDate={new Date()}
            />
          </FormControlWithError>
        </Flex>

        <Flex direction="column">
          <FormControlWithError hasError={errors?.time} errorText={errors.time}>
            <Text>Время* </Text>
            <TimePicker
              value={values.time}
              minHours={6}
              maxHours={22}
              stampMinutes={5}
              onChange={(time) => setFieldValue("time", time)}
            />
          </FormControlWithError>
        </Flex>
      </Grid>

      <Flex boxSize="full" direction="column" style={{ gap: "10px" }}>
        <FormControlWithError
          extraContent={<Text sx={HINT_STYLE}>Фото спикера семинара - 750x730 px PNG, JPG, JPEG</Text>}
          hasError={errors?.image}
          errorText={errors.image}
          title="Изображение* "
        >
          <UploadInputGroup
            ref={futureSeminarFileSelectorRef}
            placeholder="Загрузите изображение"
            errorSetter={(errorText) => setFieldError("image", errorText)}
            image={values.image}
            handleSelectFile={handleFutureSeminarSelectFile}
          />
        </FormControlWithError>
      </Flex>
    </Drawer>
  );
};

export default EditSeminarModal;
