import React from "react";
import { Navigate } from "react-router-dom";
import { store } from "../../store/store";
import ROUTES from "../routes";

const userIsAuth = (state = store.getState()) => state.loginUser.isAuth;

export const routeGuard = (component) => {
  if (!userIsAuth()) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return component;
};
