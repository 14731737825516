import { Flex, Input, InputGroup, Text, IconButton, Image, InputRightElement, Box, Button } from "@chakra-ui/react";
import { useEffect, useCallback, useRef, useState } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as LoadFileIcon } from "../../assets/svg/LoadFileIcon.svg";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";
import { Drawer } from "../../components/Drawer/Drawer";
import { useNavigate, useParams } from "react-router-dom";
import { usePrompt } from "../../hooks/usePrompt";
import { DatePicker } from "../../components/DatePicker/DatePicker";
import { DeleteIcon } from "../../assets/icon/DeleteIcon";
import { HINT_STYLE, TEXT_STYLE } from "./style";
import { getOneStory, isStoryLoading } from "./store/Stories.selectors";
import TimePicker, { getHours } from "../../components/TimePicker/TimePicker";
import { UploadInput } from "../../components/UploadInput/UploadInput";
import { ROLE_NAMES, STORY_VALIDATION_SCHEME } from "./constants";
import { convertStoryFromApi } from "./utils/convertStoryFromApi";
import {
  addSlideMediaThunk,
  addStoryThunk,
  addThumbnailMediaThunk,
  editStoryThunk,
  getOneStoryThunk,
} from "./store/Stories.thunk";
import { handleEnterKey } from "../../utils/blurOnEnter";
import { convertStoryToApi } from "./utils/convertStoryToApi";
import { siteUrl } from "../../utils/siteUrl";
import Selector from "../../components/Selector";
import { WarehousesAutocomplete } from "../../components/WarehousesAutocomplete/WarehousesAutocomplete";
import SlideBox from "./SlideBox";
import { v4 } from "uuid";

const initialValues = {
  title: "",
  thumbnailId: "",
  slides: [
    {
      id: v4(),
      contentId: "",
      contentType: "image",
    },
  ],
  dateRange: [null, null],
  startTime: "",
  endTime: "",
  product: [],
  allowedRole: "ALL",
  allowedWarehouse: null,
};

export const StoryModal = () => {
  const { id } = useParams();
  const [selectedCurrentId, setSelectCurrentId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const story = useSelector(getOneStory());
  const loadingState = useSelector(isStoryLoading());

  const handleSaveStory = (values) => {
    if (values.id) {
      dispatch(editStoryThunk({ id: values.id, objToSend: convertStoryToApi(values) }))
        .unwrap()
        .then(() => navigate(-1));

      return;
    }

    dispatch(addStoryThunk(convertStoryToApi(values)))
      .unwrap()
      .then(() => navigate(-1));
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: STORY_VALIDATION_SCHEME,
    onSubmit: handleSaveStory,
  });

  useEffect(() => {
    if (id) {
      dispatch(getOneStoryThunk(id));
    }
  }, [id]);

  useEffect(() => {
    if (story && id === story.id) {
      formik.resetForm({ values: convertStoryFromApi(story) });
    }
  }, [id, story]);

  const { values, errors, handleChange, handleSubmit, setFieldValue, dirty } = formik;
  const thumbnailFileSelector = useRef();

  const handleThumbnailSelectFile = useCallback(
    (file) => {
      dispatch(addThumbnailMediaThunk({ file })).then(({ payload }) => {
        setFieldValue("thumbnailId", payload.id);
        setFieldValue("thumbnailUrl", payload.url);
      });
    },
    [setFieldValue]
  );

  const handleSlideSelectFile = useCallback(
    (file) => {
      dispatch(addSlideMediaThunk({ file })).then(({ payload }) => {
        setFieldValue(
          "slides",
          values.slides.map((elem) => {
            return elem.id === selectedCurrentId
              ? {
                  ...elem,
                  contentType: "image",
                  contentId: payload.id,
                  contentUrl: payload.url,
                }
              : elem;
          })
        );
      });
    },
    [setFieldValue, selectedCurrentId, values.slides]
  );

  const handleDeleteThumbnailImage = () => setFieldValue("thumbnailId", "");

  const handleDeleteSlideImage = (slideId) =>
    setFieldValue(
      "slides",
      values.slides.filter((slide) => slide.id !== slideId).map((slide, index) => ({ ...slide, number: index }))
    );

  const handleChangeRangeDate = useCallback(
    (update) => {
      if (!update) {
        setFieldValue("dateRange", [null, null]);
        return;
      }
      setFieldValue("dateRange", update);
    },
    [setFieldValue]
  );

  usePrompt(!formik.isSubmitting && dirty);

  const addProductToSlide = (slideId, productId) => {
    setFieldValue(
      "slides",
      values.slides.map((elem) => {
        return elem.id === slideId
          ? { ...elem, button: { productId, text: elem?.button?.text || "К покупкам" } }
          : elem;
      })
    );
  };

  const handleButtonText = (slideId, productId, text) => {
    setFieldValue(
      "slides",
      values.slides.map((elem) => {
        return elem.id === slideId ? { ...elem, button: { productId, text } } : elem;
      })
    );
  };

  const removeProductFromSlide = (slideId) => {
    setFieldValue(
      "slides",
      values.slides.map(({ button, ...slide }) => {
        if (slide.id === slideId) {
          return slide;
        }

        return { button, ...slide };
      })
    );
  };

  const addForms = () => {
    const newForm = {
      id: v4(),
      contentId: "",
      contentType: "image",
    };
    setFieldValue("slides", [...values.slides, newForm]);
  };

  return (
    <Drawer
      isLoading={loadingState}
      size="lg"
      placement="left"
      isOpen
      handleSave={handleSubmit}
      handleClose={() => navigate(-1)}
    >
      <FormControlWithError hasError={!!errors?.title} errorText={errors?.title}>
        <Text>Название</Text>
        <Input
          autoComplete="off"
          name="title"
          value={values.title}
          onChange={handleChange}
          onKeyDown={handleEnterKey}
        />
      </FormControlWithError>

      <Flex mb="12px" direction="column">
        <FormControlWithError hasError={!!errors?.thumbnailId} errorText={errors.thumbnailId}>
          <Text>Миниатюра</Text>
          {values.thumbnailId && (
            <Flex
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <Image objectFit="cover" borderRadius="50%" w="80px" height="80px" p="0" src={values.thumbnailUrl} />
              <a rel="noreferrer" target="_blank" href={values.thumbnailUrl} style={TEXT_STYLE}>
                {values.thumbnailId}
              </a>
              <IconButton
                bgColor="#FFFFFF"
                icon={<DeleteIcon isActive />}
                onClick={handleDeleteThumbnailImage}
                aria-label="delete"
              />
            </Flex>
          )}
          <InputGroup>
            <Input
              placeholder="Загрузите миниатюру"
              isReadOnly
              value={values.thumbnailId || ""}
              style={{ position: "relative", paddingRight: "50px" }}
              onClick={() => thumbnailFileSelector.current.click()}
            />
            <InputRightElement>
              <Box>
                <UploadInput
                  ref={thumbnailFileSelector}
                  setError={(errorText) => {
                    formik.setFieldError("thumbnailId", errorText);
                  }}
                  formats={[".jpg", ".jpeg", ".png"]}
                  maxFileSize={500}
                  onChangeFile={handleThumbnailSelectFile}
                  reset={true}
                />
                <IconButton
                  height="14px"
                  w="14px"
                  icon={<LoadFileIcon />}
                  variant="unstyled"
                  aria-label="Load icon"
                  onClick={() => thumbnailFileSelector.current.click()}
                />
              </Box>
            </InputRightElement>
          </InputGroup>
        </FormControlWithError>
        <Text style={HINT_STYLE}>Размер миниатюры 80x80 px, до 500 kB, PNG, JPG, JPEG</Text>
      </Flex>

      <Flex mb="12px" direction="column">
        <Text>Истории</Text>
        {values.slides.map((slide, index) => (
          <SlideBox
            slide={slide}
            index={index}
            setFieldValue={setFieldValue}
            errors={errors}
            values={values}
            handleDeleteSlideImage={handleDeleteSlideImage}
            handleSlideSelectFile={handleSlideSelectFile}
            addProductToSlide={addProductToSlide}
            removeProductFromSlide={removeProductFromSlide}
            setSelectCurrentId={setSelectCurrentId}
            handleButtonText={handleButtonText}
            setError={formik.setFieldError}
            key={slide.id}
          />
        ))}
        {values.slides.length < 5 && !values.id && (
          <Button onClick={addForms} style={{ marginTop: "30px" }}>
            Добавить слайд +
          </Button>
        )}
        <Text style={HINT_STYLE}>Максимум 5 слайдов</Text>
        <Text style={HINT_STYLE}>Размер слайда 375x810 px, до 500 kB, PNG, JPG, JPEG</Text>
      </Flex>

      <Flex flexDirection="column">
        <Selector
          value={values.allowedRole}
          isClearable={false}
          title="Кто может просматривать"
          error={errors.allowedRole}
          height={40}
          onPurposeChange={(value) => setFieldValue("allowedRole", value)}
          options={ROLE_NAMES}
          placeholder="Выберите роль"
          isSearchable={false}
        />

        <FormControlWithError
          title="Склад/Офис"
          hasError={!!errors.allowedWarehouse}
          errorText={errors.allowedWarehouse}
        >
          <WarehousesAutocomplete
            value={values.allowedWarehouse}
            placeholder="Склад не указан"
            handleChange={(value) => setFieldValue("allowedWarehouse", value)}
          />
        </FormControlWithError>

        <Text>Время жизнеспособности</Text>

        <Flex direction="column">
          <FormControlWithError hasError={!!errors?.dateRange} errorText={errors.dateRange}>
            <DatePicker
              placeholder="C дд.мм.гг - По дд.мм.гг"
              selected={values.dateRange[0]}
              startDate={values.dateRange[0]}
              endDate={values.dateRange[1]}
              onChange={handleChangeRangeDate}
              selectsRange={true}
            />
          </FormControlWithError>
        </Flex>

        <Flex gap="15px">
          <FormControlWithError hasError={!!errors.startTime} errorText={errors.startTime}>
            <Text>C </Text>

            <TimePicker
              value={values.startTime}
              min="00:00"
              stampMinutes={15}
              onChange={(time) => setFieldValue("startTime", time)}
            />
          </FormControlWithError>

          <FormControlWithError hasError={!!errors.endTime} errorText={errors.endTime}>
            <Text>По </Text>

            <TimePicker
              value={values.endTime}
              min={values.startTime ? `${getHours(values.startTime)}:00` : "00:00"}
              stampMinutes={15}
              onChange={(time) => setFieldValue("endTime", time)}
            />
          </FormControlWithError>
        </Flex>
      </Flex>

      {/* TODO: для каждого слайда должно быть опциональное поле для добалвения одного товара */}
    </Drawer>
  );
};
