import { Button, Flex } from "@chakra-ui/react";

const AddRow = ({ banners = [], onAdd }) => {
  return (
    <Flex mb="16px" w="100%">
      <Button w="100%" colorScheme="purple" isDisabled={banners?.length > 9} onClick={onAdd}>
        Добавить баннер
      </Button>
    </Flex>
  );
};

export default AddRow;
