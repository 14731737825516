import { Box, Flex, IconButton, Image, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import { ReactComponent as LoadFileIcon } from "../../assets/svg/LoadFileIcon.svg";

import React, { useEffect, useRef, useState } from "react";
import { DeleteIcon } from "../../assets/icon/DeleteIcon";
import { UploadInput } from "../../components/UploadInput/UploadInput";
import BrandProductsSelect from "../../components/BrandProductsSelect";
import { TEXT_STYLE } from "./style";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";

const SlideBox = ({
  slide,
  errors,
  values,
  handleDeleteSlideImage,
  handleSlideSelectFile,
  addProductToSlide,
  removeProductFromSlide,
  setSelectCurrentId,
  handleButtonText,
  setError,
  index,
}) => {
  const slideFileSelector = useRef();
  const [selectProduct, setSelectProduct] = useState([]);

  useEffect(() => {
    if (slide?.button?.product) {
      setSelectProduct([slide.button.product]);
    }
  }, []);

  const onAddProduct = (product) => {
    addProductToSlide(slide.id, product.id);
    setSelectProduct([product]);
  };

  const onButtonTextChange = (slideId, productId, text) => {
    handleButtonText(slideId, productId, text);
  };

  const onRemoveProduct = (slideId, productId) => {
    removeProductFromSlide(slideId, productId);
    setSelectProduct([]);
  };

  return (
    <>
      {slide.contentId && (
        <Flex
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
          }}
          key={slide.number}
        >
          <Image objectFit="cover" w="80px" height="80px" p="0" src={slide.contentUrl} />
          <a rel="noreferrer" target="_blank" href={slide.url} style={TEXT_STYLE}>
            {slide.contentId}
          </a>
        </Flex>
      )}
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box style={{ display: "flex", flexDirection: "column", gap: "24px", width: "600px" }}>
          <FormControlWithError
            hasError={!!errors?.slides?.[index]?.contentId}
            errorText={errors?.slides?.[index]?.contentId}
          >
            <InputGroup>
              <Input
                placeholder="Добавьте слайд"
                isReadOnly
                style={{ position: "relative" }}
                onClick={() => slideFileSelector.current.click()}
              />
              <InputRightElement>
                <Box onClick={() => setSelectCurrentId(slide.id)}>
                  <UploadInput
                    ref={slideFileSelector}
                    formats={[".jpg", ".jpeg", ".png"]}
                    maxFileSize={500}
                    onChangeFile={(file) => handleSlideSelectFile(file)}
                    reset={true}
                    setError={(errorText) => setError(`slides.[${index}].contentId`, errorText)}
                  />
                  <IconButton
                    height="14px"
                    w="14px"
                    icon={<LoadFileIcon />}
                    variant="unstyled"
                    aria-label="Load icon"
                    onClick={() => slideFileSelector.current.click()}
                  />
                </Box>
              </InputRightElement>
            </InputGroup>
          </FormControlWithError>
          <Box style={{ marginBottom: "60px" }}>
            <BrandProductsSelect
              isSearchAll
              products={selectProduct}
              onAdd={(product) => {
                onAddProduct(product);
              }}
              onDelete={() => {
                onRemoveProduct(slide.id, slide?.button?.productId);
              }}
              error={errors.product && [errors.product]}
            />
          </Box>
          {slide?.button?.productId && (
            <FormControlWithError
              hasError={!!errors?.slides?.[index]?.button?.text}
              errorText={errors?.slides?.[index]?.button?.text}
            >
              <Text>Текст кнопки</Text>
              <Input
                autoComplete="off"
                name="text"
                value={slide.button.text}
                onChange={(e) => onButtonTextChange(slide.id, slide.button.productId, e.target.value)}
              />
            </FormControlWithError>
          )}
        </Box>
        {values.slides.length > 1 && (
          <Box style={{ display: "flex", marginTop: "34px" }}>
            <IconButton
              bgColor="#FFFFFF"
              icon={<DeleteIcon isActive />}
              onClick={() => handleDeleteSlideImage(slide.id)}
              aria-label="delete"
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default SlideBox;
