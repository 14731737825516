import RowUsersTable from "./RowUsersTable";
import { CONTAINER_STYLE } from "./style";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";

const TheadColumns = [
  { name: "ФИ Организация", width: 25 },
  { name: "Почта", width: 25 },
  { name: "Телефон", width: 25 },
  { name: "Менеджер", width: 25 },
];

const rowsRenderer = (user) => <RowUsersTable key={`approved-user-${user.id}`} user={user} />;

const ApprovedTable = ({ users = [], isLoading }) => (
  <TableConstructor
    isLoading={isLoading}
    theadItems={TheadColumns}
    Row={rowsRenderer}
    items={users}
    containerStyles={CONTAINER_STYLE}
    notFoundText="Здесь пока нет пользователей"
  />
);

export default ApprovedTable;
