import styled from "@emotion/styled";

export const Container = styled("div")(({ focus, error }) => {
  return {
    height: "40px",
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    // eslint-disable-next-line no-nested-ternary
    border: error ? "2px solid #e53e3e" : focus ? "none" : "1px solid #e2e8f0",
    borderRadius: "7px",
    boxShadow: focus ? "0 0 0 2px #8a3597" : "none",
  };
});

export const InputContainer = styled("div")({
  input: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    border: "none",
    width: "20px",
    ":focus": {
      boxShadow: "none",
    },
  },
});

export const Error = styled("div")({
  color: "#e53e3e",
  fontSize: "14px",
});
