import { useRef } from "react";
import { Checkbox, IconButton, Td, Tr } from "@chakra-ui/react";

import { ReactComponent as DeleteIcon } from "../../../assets/svg/DeleteIcon.svg";
import { useIsOverflow } from "../../../hooks/hooks";
import { format } from "date-fns";
import { DEFAULT_DATE_FORMAT, TEXT_OVERFLOW_STYLE } from "../../../common/constants";
import { TooltipWithContent } from "../../../components/TooltipWithContent/TooltipWithContent";
import { keyHandler } from "../../../utils/keyHandler";

const TableRow = ({ course, isSelected, onDelete, onEdit, onSelect }) => {
  const { id, name, description, startDate, endDate } = course;

  const refName = useRef(null);
  const refSpeaker = useRef(null);

  const [isOverflowName] = useIsOverflow(refName);
  const [isOverflowSpeaker] = useIsOverflow(refSpeaker);

  const handleSelect = () => {
    onSelect({ id, name }, isSelected);
  };
  const handleEdit = () => {
    onEdit(course);
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(course, isSelected);
  };

  const handleSelectCourse = (event) => keyHandler(event.key, () => handleEdit());

  const dateRangeString = `С ${format(Date.parse(startDate), DEFAULT_DATE_FORMAT)} - По ${format(
    Date.parse(endDate),
    DEFAULT_DATE_FORMAT
  )}`;

  return (
    <Tr onClick={handleEdit} cursor="pointer">
      <Td color="#434652" onKeyDown={handleSelectCourse} tabIndex={0} style={TEXT_OVERFLOW_STYLE} ref={refName}>
        <TooltipWithContent isOverflow={isOverflowName} text={name} />
      </Td>
      <Td w="250px" style={TEXT_OVERFLOW_STYLE} ref={refSpeaker}>
        <TooltipWithContent isOverflow={isOverflowSpeaker} text={description} />
      </Td>
      <Td w="180px">{dateRangeString}</Td>
      <Td w="60px" maxW="60px" p="0">
        <IconButton
          w="100%"
          minHeight="52px"
          display="flex"
          justifyContent="center"
          variant="unstyled"
          onClick={handleDelete}
          icon={<DeleteIcon />}
          aria-label="Delete"
        />
      </Td>
    </Tr>
  );
};

export default TableRow;
