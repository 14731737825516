import { useRef } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";

import { TEXT_OVERFLOW_STYLE } from "../../../common/constants";

import { generateName } from "../helpers";
import { useIsOverflow } from "../../../hooks/hooks";

const TableRow = ({ seminar }) => {
  const {
    seminar: { name },
    date,
    user: { phone, name: firstName, secondName, lastName, firmName },
  } = seminar;

  const ref = useRef(null);

  const [isOverflow] = useIsOverflow(ref);

  return (
    <Tr>
      <Td color="#434652" style={TEXT_OVERFLOW_STYLE} ref={ref}>
        {isOverflow ? (
          <Tooltip label={name} w="100%" color="#434652" bg="white" placement="bottom-start">
            {name}
          </Tooltip>
        ) : (
          name
        )}
      </Td>
      <Td w="180px" color="#434652">
        {generateName(firstName, secondName, lastName, firmName)}
      </Td>
      <Td w="180px" color="#37393D">
        {phone}
      </Td>
      <Td w="180px" color="#434652">
        {date}
      </Td>
    </Tr>
  );
};

export default TableRow;
