import { requestAxios } from "../api/axiosRequest";

export const getCheckToken = () => {
  return requestAxios.get("/token/check");
};

export const postLogin = async ({ email, password }) => {
  const { data } = await requestAxios.post(`/login`, { email, password }, { withCredentials: true });
  return data;
};

export const postLogout = async () => {
  const { data } = await requestAxios.post(`/logout`, {}, { withCredentials: true });
  return data;
};

export const getRefreshToken = async () => {
  const { data } = await requestAxios.post(`/token/refresh`, {}, { withCredentials: true });
  return data;
};

export const setResetPasswordLink = async (email) => {
  return requestAxios.post(`/password-link`, { email });
};

export const updatePassword = async (token, password) => {
  return requestAxios.post(`/reset-password`, { token, password });
};

export const changePassword = (values) => requestAxios.put("/change-password", { ...values });
