import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "./types";
import {
  addProtocol,
  deleteProtocol,
  getProtocols,
  updateProtocol,
  getProtocol,
} from "../../../services/protocolService";

export const addingProtocol = createAsyncThunk(
  `${SLICE_NAME}/fetchAddProtocolCategory`,
  async (values, { rejectWithValue }) => {
    try {
      return await addProtocol(values);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getProtocolById = createAsyncThunk(`${SLICE_NAME}/getProtocolById`, async (id, { rejectWithValue }) => {
  try {
    const res = await getProtocol(id);

    return res.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const gettingProtocol = createAsyncThunk(
  `${SLICE_NAME}/fetchGetProtocolCategory`,
  async (values, { rejectWithValue }) => {
    try {
      const res = await getProtocols();
      return res.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const editingProtocol = createAsyncThunk(
  `${SLICE_NAME}/fetchEditProtocolCategory`,
  async ({ values, id }, { rejectWithValue }) => {
    try {
      return await updateProtocol(values, id);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const deletingProtocol = createAsyncThunk(
  `${SLICE_NAME}/fetchDeleteProtocolCategory`,
  async (values, { rejectWithValue }) => {
    try {
      return await deleteProtocol(values);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
