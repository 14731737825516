/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../store/common";
import { SLICE_NAME } from "./types";
import {
  getAllStoriesThunk,
  getOneStoryThunk,
  getOneSlideThunk,
  addSlideMediaThunk,
  addThumbnailMediaThunk,
  addStoryThunk,
  deleteStoryThunk,
  editStoryThunk,
} from "./Stories.thunk";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  stories: [],
  story: { loadingState: LoadingState.IDLE, storyDetails: null },
  slide: { loadingState: LoadingState.IDLE, slideDetails: null },
};

export const {
  reducer,
  actions: { deleteToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStoriesThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getAllStoriesThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.stories = action.payload;
      })
      .addCase(getAllStoriesThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getOneStoryThunk.pending, (state) => {
        state.story.loadingState = LoadingState.PENDING;
      })
      .addCase(getOneStoryThunk.fulfilled, (state, action) => {
        state.story.loadingState = LoadingState.FULFILLED;
        state.story.storyDetails = action.payload;
      })
      .addCase(getOneStoryThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.story.storyDetails = null;
        state.story.loadingState = LoadingState.REJECTED;
      })
      .addCase(getOneSlideThunk.pending, (state) => {
        state.slide.loadingState = LoadingState.PENDING;
      })
      .addCase(getOneSlideThunk.fulfilled, (state, action) => {
        state.slide.loadingState = LoadingState.FULFILLED;
        state.slide.slideDetails = action.payload;
      })
      .addCase(getOneSlideThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.slide.slideDetails = null;
        state.slide.loadingState = LoadingState.REJECTED;
      })
      .addCase(addStoryThunk.pending, (state) => {
        state.story.loadingState = LoadingState.PENDING;
      })
      .addCase(addStoryThunk.fulfilled, (state, action) => {
        state.story.loadingState = LoadingState.FULFILLED;
        state.stories = [...state.stories, action.payload];
        state.toast = {
          view: true,
          title: "История создана",
          description: "",
          status: "success",
        };
      })
      .addCase(addStoryThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка создания истории",
          description: action.payload,
          status: "error",
        };
        state.story.storyDetails = null;
        state.story.loadingState = LoadingState.REJECTED;
      })
      .addCase(editStoryThunk.pending, (state) => {
        state.story.loadingState = LoadingState.PENDING;
      })
      .addCase(editStoryThunk.fulfilled, (state, action) => {
        state.story.loadingState = LoadingState.FULFILLED;
        state.stories = [...state.stories.filter(({ id }) => id !== action.payload.id), action.payload];
        state.toast = {
          view: true,
          title: "История изменена",
          description: "",
          status: "success",
        };
      })
      .addCase(editStoryThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка изменения истории",
          description: action.payload,
          status: "error",
        };
        state.story.storyDetails = null;
        state.story.loadingState = LoadingState.REJECTED;
      })
      .addCase(deleteStoryThunk.pending, (state) => {
        state.story.loadingState = LoadingState.PENDING;
      })
      .addCase(deleteStoryThunk.fulfilled, (state, action) => {
        state.story.loadingState = LoadingState.FULFILLED;
        state.stories = state.stories.filter(({ id }) => id !== action.payload.id);
        state.toast = {
          view: true,
          title: "История удалена",
          description: "",
          status: "success",
        };
      })
      .addCase(deleteStoryThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка удаления истории",
          description: action.payload,
          status: "error",
        };
        state.story.storyDetails = null;
        state.story.loadingState = LoadingState.REJECTED;
      })
      .addCase(addSlideMediaThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка загрузки медиа",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(addThumbnailMediaThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка загрузки медиа",
          description: action.payload,
          status: "error",
        };
      });
  },
});
