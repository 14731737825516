/* eslint-disable no-underscore-dangle, import/no-cycle */

import axios from "axios";
import * as Sentry from "@sentry/react";
import { getRefreshToken } from "../services/authService";

import { store } from "../store/store";
import { setLogout } from "../pages/Login/store/Login.slice";
import { BASE_URL, ENVIRONMENT } from "../config";

export const requestAxios = axios.create({ baseURL: BASE_URL });

requestAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const newConfig = { ...config };
    newConfig.headers["Cache-Control"] = "no-cache";
    if (newConfig.headers && token) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }
    return newConfig;
  },
  (error) => Promise.reject(error)
);

requestAxios.interceptors.response.use(
  async (response) => response,

  async (error) => {
    if (ENVIRONMENT !== "development") {
      Sentry.captureException(error);
    }

    const token = localStorage.getItem("token");
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url.indexOf("/admin/token/refresh") === -1 &&
      token
    ) {
      try {
        originalRequest._retry = true;
        const { accessToken } = await getRefreshToken();
        localStorage.setItem("token", accessToken);
        requestAxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        requestAxios.defaults.headers.common["Cache-Control"] = "no-cache";
        return requestAxios(originalRequest);
      } catch (err) {
        store.dispatch(setLogout());
      }
    }
    return Promise.reject(error);
  }
);
