import React from "react";

export const OrderIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1752 3.99L19.7355 13.155L13.507 19.695L3.9617 10.5V4.32L10.1001 3.945L10.1752 3.99ZM10.5054 1.5H10.3853L3.27131 1.935C2.8186 1.96871 2.39493 2.17065 2.08379 2.50105C1.77266 2.83145 1.59662 3.26633 1.59037 3.72L1.50032 10.89C1.49589 11.1111 1.53765 11.3307 1.62293 11.5347C1.70821 11.7387 1.83513 11.9228 1.9956 12.075L12.4114 22.065C12.7106 22.3398 13.1007 22.4947 13.507 22.5C13.7496 22.4976 13.9892 22.4458 14.2111 22.3478C14.433 22.2498 14.6326 22.1076 14.7978 21.93L22.0018 14.325C22.1597 14.1676 22.285 13.9805 22.3705 13.7746C22.456 13.5687 22.5 13.3479 22.5 13.125C22.5 12.9021 22.456 12.6813 22.3705 12.4754C22.285 12.2695 22.1597 12.0824 22.0018 11.925L11.601 1.935C11.305 1.65512 10.9128 1.49942 10.5054 1.5ZM8.25411 6.75C7.95727 6.75 7.66709 6.83798 7.42028 7.0028C7.17347 7.16762 6.9811 7.40189 6.86751 7.67598C6.75391 7.95007 6.72419 8.25167 6.7821 8.54264C6.84001 8.83361 6.98295 9.10088 7.19285 9.31066C7.40275 9.52044 7.67017 9.6633 7.96131 9.72118C8.25244 9.77906 8.55421 9.74935 8.82845 9.63582C9.10269 9.52229 9.33709 9.33003 9.50201 9.08336C9.66692 8.83668 9.75495 8.54667 9.75495 8.25C9.75495 7.85218 9.59682 7.47065 9.31536 7.18934C9.0339 6.90804 8.65215 6.75 8.25411 6.75Z"
        fill={isActive ? "white" : "black"}
      />
    </svg>
  );
};
