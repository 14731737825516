import { requestAxios } from "../api/axiosRequest";

const USER_REQUEST_API = `/users/requests`;

export const getUsers = ({ limit, page, verificationStatus, query, signal = null }) =>
  requestAxios.get(USER_REQUEST_API, {
    signal,
    params: {
      take: limit,
      skip: limit * (page - 1),
      verificationStatus,
      query,
    },
  });

export const getUsersForNotifications = (query) =>
  requestAxios.get(`/users`, {
    params: {
      take: 10,
      skip: 0,
      query,
    },
  });

export const patchApproveUser = (id, body) => requestAxios.patch(`${USER_REQUEST_API}/${id}/accept`, body);

export const patchRejectUser = (id, body) => requestAxios.patch(`${USER_REQUEST_API}/${id}/reject`, body);

export const editUser = (id) => requestAxios.patch(`${USER_REQUEST_API}/${id}/mark-viewed`);

export const getUnreviewedUsers = () => requestAxios.get(`${USER_REQUEST_API}/new-count`);

export const getUserRequest = (id) => requestAxios.get(`${USER_REQUEST_API}/${id}`);
