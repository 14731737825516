import { useRef } from "react";
import { Checkbox, IconButton, Td, Tr } from "@chakra-ui/react";

import { ReactComponent as DeleteIcon } from "../../../assets/svg/DeleteIcon.svg";
import { useIsOverflow } from "../../../hooks/hooks";
import { DATE_FORMAT_WITH_TIME, DEFAULT_DATE_FORMAT, TEXT_OVERFLOW_STYLE } from "../../../common/constants";
import { formatDate } from "../../Promotion/utils/formatDate";
import { TooltipWithContent } from "../../../components/TooltipWithContent/TooltipWithContent";
import { keyHandler } from "../../../utils/keyHandler";

const TableRow = ({ seminar, isSelected, onDelete, onEdit, onSelect }) => {
  const { id, name, speaker, datetime } = seminar;

  const refName = useRef(null);
  const refSpeaker = useRef(null);

  const [isOverflowName] = useIsOverflow(refName);
  const [isOverflowSpeaker] = useIsOverflow(refSpeaker);

  const handleSelect = () => {
    onSelect({ id, name }, isSelected);
  };
  const handleEdit = () => {
    onEdit(seminar);
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(seminar, isSelected);
  };

  const handleSelectSeminar = (event) => keyHandler(event.key, () => handleEdit());

  return (
    <Tr onClick={handleEdit} cursor="pointer">
      <Td textAlign="center" lineHeight={0} onClick={(e) => e.stopPropagation()}>
        <Checkbox isChecked={isSelected} onChange={handleSelect} />
      </Td>
      <Td color="#434652" onKeyDown={handleSelectSeminar} tabIndex={0} style={TEXT_OVERFLOW_STYLE} ref={refName}>
        <TooltipWithContent isOverflow={isOverflowName} text={name} />
      </Td>
      <Td w="250px" style={TEXT_OVERFLOW_STYLE} ref={refSpeaker}>
        <TooltipWithContent isOverflow={isOverflowSpeaker} text={speaker} />
      </Td>
      <Td w="180px">{datetime && formatDate(datetime, DEFAULT_DATE_FORMAT, DATE_FORMAT_WITH_TIME)}</Td>
      <Td w="60px" maxW="60px" p="0">
        <IconButton
          w="100%"
          minHeight="52px"
          display="flex"
          justifyContent="center"
          variant="unstyled"
          onClick={handleDelete}
          icon={<DeleteIcon />}
          aria-label="Delete"
        />
      </Td>
    </Tr>
  );
};

export default TableRow;
