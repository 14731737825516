import React, { useState } from "react";
import * as ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { Box, Button, ButtonGroup, css, Flex, FormControl, Grid, IconButton, Text } from "@chakra-ui/react";

import Autocomplete from "../Autocomplete";

import { useSearch } from "../../hooks/useSearch";
import { getCategories } from "../../pages/Categories/store/Categories/Categories.selectors";
import { getSubCategoriesByCategoryId } from "../../services/categoryService";

import { ReactComponent as CloseIcon } from "../../assets/svg/CloseIcon.svg";
import { POPUP_Z_INDEX } from "../../common/constants";
import { ReactComponent as ArrowIcon } from "../../assets/svg/ArrowIcon.svg";

const fromTextStyles = css`
  height: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #bcbfcc;
`;

const TEXT_STYLE = {
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  color: "rgba(0, 0, 0, 0.4)",
};

export const PopUp = ({ checkedItems = [], isPending, onSubmitSuccess, onGetProducts, onClose }) => {
  const [isChangeCategory, setIsChangeCategory] = useState(false);

  return ReactDOM.createPortal(
    <Flex
      backgroundColor="white"
      direction="column"
      style={{
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        bottom: "20%",
        zIndex: POPUP_Z_INDEX,
        width: isChangeCategory ? "750px" : "400px",
        maxHeight: "124px",
        alignItems: "center",
        boxShadow: "0px 4px 8px rgba(76, 93, 112, 0.3), 0px 0px 1px rgba(76, 93, 112, 0.3)",
        borderRadius: "8px",
      }}
    >
      {isChangeCategory ? (
        <SelectedProductCategory
          checkedItems={checkedItems}
          onSubmitSuccess={onSubmitSuccess}
          onClickBack={() => setIsChangeCategory(false)}
          onGetProducts={onGetProducts}
          isPending={isPending}
        />
      ) : (
        <SelectedProductsInfo checkedItems={checkedItems} onClick={() => setIsChangeCategory(true)} onClose={onClose} />
      )}
    </Flex>,
    document.body
  );
};

const SelectedProductCategory = ({ checkedItems, isPending, onSubmitSuccess, onClickBack }) => {
  const categories = useSelector(getCategories());
  const {
    data: subCatalogs,
    isLoading: subCatalogsLoading,
    search: searchSubCatalogs,
    clear: clearSubCatalogs,
  } = useSearch(getSubCategoriesByCategoryId);

  const itemsForCategories =
    categories?.map((item) => ({
      name: item.name,
      id: item.id,
    })) || [];
  const isSubCategoryDisabled = subCatalogsLoading || !subCatalogs;

  const [categoryProduct, setCategoryProduct] = useState(null);
  const [subCategoryProduct, setSubCategoryProduct] = useState(null);

  const submitProductsForCategory = () => {
    const productsToSend = {
      id: subCategoryProduct.id,
      productIds: [...checkedItems].map((checkedItem) => ({ id: checkedItem })),
    };

    onSubmitSuccess(productsToSend);
  };

  const onCategoryChange = (value) => {
    clearSubCatalogs();
    setSubCategoryProduct("");
    setCategoryProduct(value);
    if (value) {
      searchSubCatalogs(value.id);
    }
  };

  const onSubCategoryChange = (value) => {
    setSubCategoryProduct(value);
  };

  return (
    <Box padding="8px 64px">
      <Flex align="center" justifyContent="space-between" width="100%">
        <ButtonGroup display="flex" alignItems="center" color="#C06ECC" onClick={onClickBack}>
          <IconButton icon={<ArrowIcon />} backgroundColor="transparent" aria-label="arrow" />
          <Text cursor="pointer">Вернуться</Text>
        </ButtonGroup>
        <Text css={fromTextStyles} style={TEXT_STYLE}>
          Количество выбранных товаров: {checkedItems.size}
        </Text>
      </Flex>

      <Grid templateColumns="2fr 2fr 1fr" style={{ gap: "16px", padding: "20px 0 22px 0", alignItems: "end" }}>
        <Flex direction="column">
          <FormControl>
            <Autocomplete
              placeholder="Выберите категорию"
              value={categoryProduct}
              items={itemsForCategories}
              onChange={onCategoryChange}
            />
          </FormControl>
        </Flex>
        <Flex direction="column">
          <FormControl isDisabled={isSubCategoryDisabled}>
            <Autocomplete
              isDisabled={isSubCategoryDisabled}
              placeholder="Выберите подкатегорию"
              value={subCategoryProduct}
              items={subCatalogs}
              onChange={onSubCategoryChange}
            />
          </FormControl>
        </Flex>
        <Button
          isLoading={isPending}
          isDisabled={!subCategoryProduct}
          backgroundColor="#C06ECC"
          color="white"
          onClick={submitProductsForCategory}
        >
          Добавить
        </Button>
      </Grid>
    </Box>
  );
};

const SelectedProductsInfo = ({ checkedItems, onClick, onClose }) => {
  return (
    <>
      <Button style={{ position: "absolute", right: 0, top: 0, backgroundColor: "white" }} onClick={onClose}>
        <CloseIcon />
      </Button>
      <Box padding="16px">
        <Flex align="center" justifyContent="center" width="100%">
          <Text css={fromTextStyles} style={TEXT_STYLE}>
            Количество выбранных товаров: {checkedItems.size}
          </Text>
        </Flex>

        <Flex align="center" justifyContent="center" width="100%" style={{ paddingTop: "16px", alignItems: "end" }}>
          <Button backgroundColor="white" color="#C06ECC" onClick={onClick}>
            Добавить в категорию
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default PopUp;
