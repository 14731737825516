import { requestAxios } from "../api/axiosRequest";

export const getCategories = () => {
  return requestAxios.get(`/catalogs`);
};

export const addCategory = (name) => {
  return requestAxios.post(`/catalogs`, { name });
};

export const editCategory = (id, name) => {
  return requestAxios.patch(`/catalogs/${id}`, { name });
};

export const updatePositionCategory = (values) => {
  return requestAxios.patch(`/catalogs/positions`, { catalogs: values });
};

export const deleteCategory = (id) => {
  return requestAxios.delete(`/catalogs/${id}`);
};

export const sendProductsForCategory = (values) => {
  return requestAxios.put(`/catalogs/products`, values);
};
export const getSubCategoriesByCategoryId = (id) => {
  return requestAxios.get(`/catalogs/${id}/sub-catalogs`);
};

export const getSubCategories = () => {
  return requestAxios.get(`/sub-catalogs`);
};

export const addSubCategory = (name, id) => {
  return requestAxios.post(`/sub-catalogs`, {
    name,
    catalog_product: {
      id,
    },
  });
};

export const editSubCategory = (id, name, categoryId) => {
  return requestAxios.patch(`/sub-catalogs/${id}`, {
    name,
    catalog_product: {
      id: categoryId,
    },
  });
};

export const updatePositionSubCategory = (value) => {
  return requestAxios.patch(`/sub-catalogs/positions`, value);
};

export const deleteSubCategory = (id) => {
  return requestAxios.delete(`/sub-catalogs/${id}`);
};
