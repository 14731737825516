/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";
import { addingProtocol, deletingProtocol, editingProtocol, getProtocolById, gettingProtocol } from "./Protocol.thunk";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  protocols: [],
  protocol: { loadingState: LoadingState.IDLE, protocolDetail: null },
};

export const {
  reducer,
  actions: { getCategories, deleteToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    getProtocols: (state) => {
      state.loadingState = LoadingState.IDLE;
    },
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProtocolById.pending, (state) => {
        state.protocol.loadingState = LoadingState.PENDING;
      })
      .addCase(getProtocolById.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload.message,
          status: "error",
        };

        state.protocol.loadingState = LoadingState.REJECTED;
      })
      .addCase(getProtocolById.fulfilled, (state, action) => {
        state.protocol.loadingState = LoadingState.FULFILLED;
        state.protocol.protocolDetail = action.payload;
      })

      .addCase(gettingProtocol.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(gettingProtocol.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload.message,
          status: "error",
        };

        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(gettingProtocol.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;

        state.protocols = action.payload;
      })
      .addCase(addingProtocol.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;

        state.toast = {
          view: true,
          title: "Протокол добавлен",
          description: "",
          status: "success",
        };
      })
      .addCase(addingProtocol.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;

        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(deletingProtocol.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;

        state.toast = {
          view: true,
          title: "Протокол удален",
          description: "",
          status: "success",
        };
      })
      .addCase(editingProtocol.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;

        state.toast = {
          view: true,
          title: "Протокол изменен",
          description: "",
          status: "success",
        };
      })
      .addCase(editingProtocol.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;

        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      });
  },
});
