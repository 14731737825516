import { formatDateToApi } from "./formatDate";

export const convertToGoogleDrive = (link) => {
  const [p, idWithSuffix] = link.match("/d/.*?(.+)") || [];
  const [idImage] = idWithSuffix?.match(".+?(?=/view)") || [];

  if (!idImage) {
    return "";
  }

  const newImage = `https://drive.google.com/uc?export=view&id=${idImage}`;

  return newImage;
};

export const transformData = (promotion, shouldUpdateImage) => ({
  ...promotion,
  name: promotion.name.trim(),
  endDate: formatDateToApi(promotion.endDate),
  availableFor: promotion.availableFor?.value ?? promotion.availableFor,
  image: promotion.image,
  description: promotion.name,
});
