import styled from "@emotion/styled";
import { Flex, Icon } from "@chakra-ui/react";
import { formatNotification } from "./utils/formatNotification";
import { MAX_NOTIFICATION_DISPLAY } from "./constants";

export const Badge = styled("div")(({ isActive, top, right }) => ({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2px 7px",
  borderRadius: "22px",
  fontSize: "10px",
  lineHeight: "16px",
  boxSizing: "border box",
  background: isActive ? "white" : "red",
  color: isActive ? "black" : "white",
  top: top || "-5px",
  right: right || "-5px",
  minWidth: "20px",
}));

export const NavItem = ({ icon, sx, isActive, onNavClick, valueBadge = 0, children }) => {
  const styles = { display: "flex", gap: "16px", color: "#121212", position: "relative" };
  const activeStyles = { ...styles, backgroundColor: "#C06ECC", color: "white", position: "relative" };

  const count = formatNotification(valueBadge, MAX_NOTIFICATION_DISPLAY);

  return (
    <Flex
      align="center"
      p="4"
      mx="4"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      style={isActive ? activeStyles : styles}
      {...sx}
      onClick={onNavClick}
    >
      {icon && (
        <Icon
          isActive={isActive}
          fontSize="24"
          _groupHover={{
            color: "white",
          }}
          as={icon}
        />
      )}
      {children}
      {count && (
        <Badge top="19px" right="13px" isActive={isActive}>
          {count}
        </Badge>
      )}
    </Flex>
  );
};
