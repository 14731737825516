import * as yup from "yup";
import { DateTime } from "luxon";

export const FUTURE_SEMINAR_VALIDATION_SHCEMA = ({ names }) =>
  yup.object({
    name: yup
      .string()
      .required('Поле "Название" обязательное')
      .test("name", "Такой семинар уже существует", (name) => {
        if (names.includes(name)) {
          return false;
        }
        return true;
      }),
    description: yup.string().required('Поле "Описание" обязательное'),
    speaker: yup.string().required('Поле "Спикер" обязательное'),
    speakerSpeciality: yup.string().required('Поле "Специальность спикера" обязательное'),
    city: yup.object().required('Поле "Город" обязательное').nullable(),
    seminarDate: yup
      .date()
      .required('Поле "Дата" обязательное')
      .test(
        "time",
        "Дата предстоящего семинара должна быть позже текущей",
        (date) => DateTime.fromJSDate(date).toMillis() > DateTime.now().toMillis()
      )
      .nullable(),
    time: yup.string().required('Поле "Время" обязательное'),
    image: yup.string().required('Поле "Фото" обязательное').nullable(),
  });

export const SEMINAR_HISTORY_VALIDATION_SHCEMA = ({ names }) =>
  yup.object({
    name: yup
      .string()
      .required('Поле "Название" обязательное')
      .test("name", "Такой семинар уже существует", (name) => {
        if (names.includes(name)) {
          return false;
        }
        return true;
      }),
    description: yup.string().required('Поле "Краткое описание" обязательное'),
    date: yup.string().required('Поле "Дата" обязательное').nullable(),
    image: yup.string().required('Поле "Фото" обязательное').nullable(),
  });

export const MAX_HEIGHT_SEMINAR_BODY = "100%";
