import React from "react";
import { Flex, FormLabel, Input } from "@chakra-ui/react";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";

export const ClientInfoInput = ({ label, hasError, errorText, ...restProps }) => {
  return (
    <Flex width="65%" justifyContent="space-between" alignItems="center">
      <FormLabel minWidth="30%" fontSize="14px" lineHeight="24px" marginBottom="22px">
        {label}
      </FormLabel>

      <FormControlWithError hasError={hasError} errorText={errorText}>
        <Input {...restProps} />
      </FormControlWithError>
    </Flex>
  );
};
