import { useState } from "react";
import { Flex, FormControl, FormErrorMessage, IconButton, Input, Td, Tr } from "@chakra-ui/react";

import { ReactComponent as EditIcon } from "../../assets/svg/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import { ReactComponent as SubmitIcon } from "../../assets/svg/SubmitIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/svg/CancelIcon.svg";

import CellCity from "./CellCity";
import { handleEnterKey } from "../../utils/blurOnEnter";
import { removeSpaces } from "../../utils/removeSpaces";

const paddingInput = {
  paddingRight: 0,
  paddingLeft: 0,
};

const CityRow = ({ id, name, address, editState, onDelete, onUpdate }) => {
  const [newName, setNewName] = useState(name);
  const [newAddress, setNewAddress] = useState(address);

  const isEdit = id === editState?.id;

  const handleChangeName = (e) => {
    setNewName(e.target.value);
  };

  const handleChangeAddress = (e) => {
    setNewAddress(e.target.value);
  };

  const handleSave = () => {
    const cityName = removeSpaces(newName);
    const cityAddress = removeSpaces(newAddress);

    if (!cityName || !cityAddress) {
      editState.setError("Город/адрес заполнены некорректно");
      return;
    }

    if (name !== cityName || address !== cityAddress) {
      onUpdate({ id, name: cityName, address: cityAddress });
    }

    if (name === cityName && address === cityAddress) {
      editState.reset();
    }
  };

  const handleClose = () => {
    editState.reset();
    setNewName(name);
    setNewAddress(address);
  };

  return (
    <Tr>
      {isEdit ? (
        <>
          <Td sx={paddingInput}>
            <FormControl isInvalid={editState?.errorMessage}>
              <Input
                maxW="314px"
                value={newName}
                onChange={handleChangeName}
                onClose={handleClose}
                onKeyDown={handleEnterKey}
              />
              <FormErrorMessage>{editState?.errorMessage}</FormErrorMessage>
            </FormControl>
          </Td>
          <Td sx={paddingInput}>
            <FormControl isInvalid={editState?.errorMessage}>
              <Input
                maxW="314px"
                value={newAddress}
                onChange={handleChangeAddress}
                onClose={handleClose}
                onKeyDown={handleEnterKey}
              />
              <FormErrorMessage>{editState?.errorMessage}</FormErrorMessage>
            </FormControl>
          </Td>
          <Td>
            <Flex justifyContent="end" gap="16px">
              <IconButton
                w="16px"
                height="16px"
                variant="unstyled"
                onClick={handleSave}
                icon={<SubmitIcon />}
                aria-label="Save"
              />
              <IconButton
                w="16px"
                height="16px"
                variant="unstyled"
                onClick={handleClose}
                icon={<CancelIcon />}
                aria-label="Close"
              />
            </Flex>
          </Td>
        </>
      ) : (
        <>
          <CellCity value={name} />
          <CellCity value={address} />
          <Td>
            <Flex justifyContent="end">
              <IconButton
                w="16px"
                height="16px"
                variant="unstyled"
                onClick={() => editState.setId(id)}
                icon={<EditIcon />}
                aria-label="Edit"
              />
              <IconButton
                w="16px"
                marginStart="15px"
                height="16px"
                variant="unstyled"
                onClick={onDelete}
                icon={<DeleteIcon />}
                aria-label="Delete"
              />
            </Flex>
          </Td>
        </>
      )}
    </Tr>
  );
};

export default CityRow;
