import OrdersTableRow from "./OrdersTableRow";
import { useSelector, useDispatch } from "react-redux";
import { Flex, useUpdateEffect } from "@chakra-ui/react";
import { usePagination } from "../../../hooks/usePagination";
import { getClientOrders, getClientOrdersCount, isLoadingClientOrders } from "../../Clients/store/Clients.selectors";
import { getClientOrdersThunk } from "../../Clients/store/Clients.thunk";
import { TableConstructor } from "../../../components/TableConstructor/TableConstructor";
import convertObjectKeysToCamelCase from "../utils/convertObjectKeysToCamelCase";
import { Pagination } from "../../../components/Pagination";
import { orderTableHeaders, emptyTheadItems } from "../constants";

const renderOrderRow = (order) => (
  <OrdersTableRow {...convertObjectKeysToCamelCase(order)} key={`user-order-${order.id}`} />
);

const OrdersTable = ({ clientId }) => {
  const dispatch = useDispatch();

  const orders = useSelector(getClientOrders());
  const isOrdersLoading = useSelector(isLoadingClientOrders());
  const total = useSelector(getClientOrdersCount());

  const {
    isPageValid,
    isPrevDisabled,
    isNextDisabled,
    page,
    limit,
    totalPages,
    nextPage,
    prevPage,
    setPage,
    setLimit,
  } = usePagination({
    total,
  });

  useUpdateEffect(() => {
    if (total === 0 || isPageValid) {
      dispatch(getClientOrdersThunk({ id: clientId, take: limit, skip: limit * (page - 1) }));
    }
  }, [limit, total, page, dispatch]);

  return (
    <>
      <Flex justify="end">
        <Pagination
          totalPages={totalPages}
          page={page}
          limit={limit}
          isPrevDisabled={isPrevDisabled}
          isNextDisabled={isNextDisabled}
          onNextPage={nextPage}
          onPrevPage={prevPage}
          onChangePage={setPage}
          onLimitChange={setLimit}
        />
      </Flex>

      <TableConstructor
        headersWithoutSpecialStyle={orderTableHeaders}
        isLoading={isOrdersLoading}
        items={orders ?? []}
        Row={renderOrderRow}
        theadItems={emptyTheadItems}
        notFoundText="Этот пользователь не имеет заказов"
        isHeaderNeed={false}
        preloadHeight="800px"
      />
    </>
  );
};

export default OrdersTable;
