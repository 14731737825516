import { useEffect } from "react";

export const useOnClickOutside = (arrRefs, handler) => {
  useEffect(() => {
    const listener = (event) => {
      const isInsideClick = arrRefs.some((ref) => {
        return !ref.current || ref.current.contains(event.target);
      });
      if (isInsideClick) {
        return;
      }

      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [arrRefs, handler]);
};
