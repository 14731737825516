import { Box, Flex, FormControl, FormErrorMessage, Text } from "@chakra-ui/react";
import { useIsOverflow } from "../../hooks/hooks";
import { useRef } from "react";
import { TooltipWithContent } from "../TooltipWithContent/TooltipWithContent";
import { TEXT_OVERFLOW_STYLE, TITLE_TEXT_STYLE } from "../../common/constants";
import { useScrollIntoViewError } from "../../hooks/useScrollIntoViewError";

export const FormControlWithError = ({
  children,
  extraContent,
  title,
  hasError = false,
  isDisabled = false,
  errorText = "",
  formProps,
  isNoBottom,
}) => {
  const errorRef = useRef(null);
  const wrapperRef = useRef(null);
  const titleRef = useRef(null);
  const [isOverflowError] = useIsOverflow(errorRef);
  const [isOverflowTitle] = useIsOverflow(titleRef);

  useScrollIntoViewError(wrapperRef, hasError);

  return (
    <FormControl ref={wrapperRef} isDisabled={isDisabled} isInvalid={hasError} {...formProps}>
      <Box
        display="block"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: `${wrapperRef.current?.clientWidth}px`,
        }}
        ref={titleRef}
      >
        {title && (
          <TooltipWithContent
            isOverflow={isOverflowTitle}
            content={
              <Text sx={{ ...TEXT_OVERFLOW_STYLE, maxWidth: "300px", marginTop: extraContent ? "10px" : "0px" }}>
                {title}
              </Text>
            }
          />
        )}
      </Box>

      {children}

      <Box paddingTop={0} height={!isNoBottom && "25px"}>
        {extraContent}

        {hasError && (
          <FormErrorMessage
            display="block"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              height: "25px",
              maxWidth: `${wrapperRef.current?.clientWidth}px`,
              textAlign: "left",
            }}
            ref={errorRef}
            marginTop={0}
          >
            <TooltipWithContent isOverflow={isOverflowError} text={errorText} />
          </FormErrorMessage>
        )}
      </Box>
    </FormControl>
  );
};
