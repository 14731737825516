import { useNavigate } from "react-router-dom";
import { Td, Tr } from "@chakra-ui/react";

export const WishListTableRow = ({ productId, productName, productCount }) => {
  const navigate = useNavigate();

  return (
    <Tr key={productId} onClick={() => navigate(`${productId}`)} cursor="pointer">
      <Td>{productName}</Td>

      <Td>{productCount}</Td>
    </Tr>
  );
};
