import { DEFAULT_TIME_FORMAT } from "../../../common/constants";
import { formatDate } from "../../Promotion/utils/formatDate";

export const getDateRanges = (start, end) => {
  const startOnlyDate = new Date(start);
  const endOnlyDate = new Date(end);
  const startOnlyTime = formatDate(startOnlyDate, DEFAULT_TIME_FORMAT);
  const endOnlyTime = formatDate(endOnlyDate, DEFAULT_TIME_FORMAT);
  startOnlyDate.setHours(0, 0, 0, 0);
  endOnlyDate.setHours(0, 0, 0, 0);

  return { dateRange: [startOnlyDate, endOnlyDate], timeRange: [startOnlyTime, endOnlyTime] };
};
