import { Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import ArrowLeft from "../../assets/icon/ArrowLeft";
import { BACK_BUTTON } from "../../pages/ClientInfo/style";

export const BackButton = ({ text = "К пользователям", path = "../clients", state = { currentTab: 0 } }) => {
  return (
    <Link style={BACK_BUTTON} to={path} state={state}>
      <Flex justifyContent="space-between" alignItems="center" cursor="pointer">
        <Icon as={ArrowLeft} />
        <p style={{ marginLeft: 8 }}>{text}</p>
      </Flex>
    </Link>
  );
};
