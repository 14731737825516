import { useFormik } from "formik";
import React from "react";
import { Modal } from "../Modal/Modal";
import { FormControlWithError } from "../FormControlWithError/FormControlWithError";
import { InputPassword } from "../InputPassword/InputPassword";
import { useNavigate, useOutlet } from "react-router-dom";
import { CHANGE_PASSWORD_VALIDATION_SCHEMA } from "./constants";
import { useSearch } from "../../hooks/useSearch";
import { useCustomToast } from "../../hooks/useCustomToast";
import { changePassword } from "../../services/authService";
import { usePrompt } from "../../hooks/usePrompt";

const FIELDS = [
  {
    name: "oldPassword",
    title: "Старый пароль",
    key: 1,
  },
  {
    name: "newPassword",
    title: "Новый пароль",
    key: 2,
  },
  {
    name: "repeatPassword",
    title: "Повторите новый пароль",
    key: 3,
  },
];

const initialValues = {
  oldPassword: "",
  newPassword: "",
  repeatPassword: "",
};

export const ChangePasswordModal = ({ id }) => {
  const navigate = useNavigate();
  const { search, isLoading, setLoading } = useSearch(changePassword);
  const { pushToast } = useCustomToast();

  const handleClose = () => navigate(-1);

  const handleSubmitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);

    try {
      await search(values);
      await navigate(-1);

      pushToast({
        view: true,
        title: "Пароль успешно изменен",
        status: "success",
      });
    } catch (error) {
      pushToast({
        view: true,
        title: error?.response?.data?.message,
        status: "error",
      });
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const { handleChange, values, handleBlur, errors, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema: CHANGE_PASSWORD_VALIDATION_SCHEMA,
    onSubmit: handleSubmitForm,
  });

  return (
    <Modal
      headerText="Смена пароля"
      handleSave={handleSubmit}
      isLoading={isLoading}
      isNeedSaveState
      handleClose={handleClose}
      isOpen={!!id}
    >
      <form onSubmit={handleSubmit}>
        {FIELDS.map((field) => (
          <FormControlWithError
            key={field.key}
            hasError={!!errors[field.name] && touched[field.name]}
            errorText={errors[field.name]}
            title={field.title}
          >
            <InputPassword
              name={field.name}
              autoComplete="new-password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[field.name]}
            />
          </FormControlWithError>
        ))}
      </form>
    </Modal>
  );
};
