/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../store/common";

import {
  addingBanner,
  deletingBanner,
  editingBanner,
  getAllBanners,
  getAnotherBanners,
  getBannerById,
  addBannerMediaThunk,
} from "./Banners.thunk";

import { SLICE_NAME } from "./types";

const initialToast = { view: false, title: "", description: "", status: "" };

const initialState = {
  toast: initialToast,
  loadingState: LoadingState.IDLE,
  banners: [],
  anotherBanners: [],
  banner: { loadingState: LoadingState.IDLE, bannerDetail: null },
};

export const {
  reducer,
  actions: { deleteBannersToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteBannersToast: (state) => {
      state.toast = initialToast;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBannerById.pending, (state) => {
        state.banner.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(getBannerById.rejected, (state, action) => {
        if (action.payload === "Forbidden") {
          return;
        }
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.banner.loadingState = LoadingState.REJECTED;
      })
      .addCase(getBannerById.fulfilled, (state, action) => {
        state.banner.loadingState = LoadingState.FULFILLED;
        state.banner.bannerDetail = action.payload;
      })
      .addCase(getAllBanners.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(getAllBanners.rejected, (state, action) => {
        if (action.payload === "Forbidden") {
          return;
        }
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllBanners.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.banners = action.payload;
      })
      .addCase(getAnotherBanners.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(getAnotherBanners.rejected, (state, action) => {
        if (action.payload === "Forbidden") {
          return;
        }
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAnotherBanners.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.anotherBanners = action.payload;
      })
      .addCase(addingBanner.rejected, (state, action) => {
        if (action.payload === "Forbidden") {
          return;
        }
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(addingBanner.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Баннер добавлен",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingBanner.rejected, (state, action) => {
        if (action.payload === "Forbidden") {
          return;
        }
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(deletingBanner.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Баннер удален",
          description: "",
          status: "success",
        };
      })
      .addCase(editingBanner.rejected, (state, action) => {
        if (action.payload === "Forbidden") {
          return;
        }
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(editingBanner.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Баннер изменен",
          description: "",
          status: "success",
        };
      })
      .addCase(addBannerMediaThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка загрузки медиа",
          description: action.payload,
          status: "error",
        };
      });
  },
});
