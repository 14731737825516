import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addSubCategory,
  deleteSubCategory,
  editSubCategory,
  getSubCategories,
  updatePositionSubCategory,
} from "../../../../services/categoryService";

import { SLICE_NAME } from "./types";

export const gettingSubcategories = createAsyncThunk(
  `${SLICE_NAME}/fetchgGetSubcategories`,
  async (values, { rejectWithValue }) => {
    try {
      const res = await getSubCategories();

      return res.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addingSubcategory = createAsyncThunk(
  `${SLICE_NAME}/fetchgAddSubcategory`,
  async ({ categoryId, name }, { rejectWithValue }) => {
    try {
      return await addSubCategory(name, categoryId);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const editingSubcategory = createAsyncThunk(
  `${SLICE_NAME}/fetchgEditSubcategory`,
  async ({ name, id, categoryId }, { rejectWithValue }) => {
    try {
      return await editSubCategory(id, name, categoryId);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const updatingPositionSubCategory = createAsyncThunk(
  `${SLICE_NAME}/fetchgUpdateSubCategory`,
  async (value, { rejectWithValue }) => {
    try {
      return await updatePositionSubCategory(value);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const deletingSubcategory = createAsyncThunk(
  `${SLICE_NAME}/fetchgDeleteSubcategory`,
  async (values, { rejectWithValue }) => {
    try {
      return await deleteSubCategory(values);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
