import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addBanner,
  deleteBanner,
  editBanner,
  getBanner,
  getBanners,
  addBannerMedia,
} from "../../../services/bannerService";

import { SLICE_NAME } from "./types";

export const getAllBanners = createAsyncThunk(`${SLICE_NAME}/fetchGetBanners`, async (params, { rejectWithValue }) => {
  try {
    const banners = await getBanners(params);

    return banners?.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const getAnotherBanners = createAsyncThunk(
  `${SLICE_NAME}/fetchGetAnotherBanners`,
  async (params, { rejectWithValue }) => {
    try {
      const banners = await getBanners(params);

      return banners?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addingBanner = createAsyncThunk(`${SLICE_NAME}/fetchAddBanner`, async (params, { rejectWithValue }) => {
  try {
    return await addBanner(params);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const deletingBanner = createAsyncThunk(`${SLICE_NAME}/fetchDeleteBanner`, async (id, { rejectWithValue }) => {
  try {
    return await deleteBanner(id);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const editingBanner = createAsyncThunk(`${SLICE_NAME}/fetchEditBanner`, async (params, { rejectWithValue }) => {
  try {
    return await editBanner(params);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const getBannerById = createAsyncThunk(`${SLICE_NAME}/fetchBanner`, async (id, { rejectWithValue }) => {
  try {
    const response = await getBanner(id);

    return response.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const addBannerMediaThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchAddBannerMediaThunk`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await addBannerMedia(params);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
