import { Button, Flex, IconButton, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { checkIsRoleManager, checkIsRoleAdmin } from "../../../utils/roles";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUserState } from "../../Login/store/Login.selectors";
import { DeleteItemModal } from "../../../components/DeleteModals";
import { deleteEmployeeThunk } from "../store/Employees.thunk";
import { TrashIcon } from "../../../assets/icon/TrashIcon";
import { HUMAN_READABLE_USER_ROLES } from "../../../common/constants";
import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useIsOverflow } from "../../../hooks/hooks";
import { TooltipWithContent } from "../../../components/TooltipWithContent/TooltipWithContent";

const generateFullName = (firstName, lastName) => `${lastName} ${firstName}`;

export const TableRow = ({ employee }) => {
  const nameRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { role } = useSelector(getCurrentUserState);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [isNameOverflow] = useIsOverflow(nameRef);

  const handleDeleteEmployee = () => dispatch(deleteEmployeeThunk(employee.id));

  const fullName = generateFullName(employee.name, employee.lastName);
  const isVisibleDelete = !checkIsRoleAdmin(employee.role) && checkIsRoleAdmin(role);

  const handleOpenDetail = () => navigate(employee.id);

  return (
    <Tr>
      <Td ref={nameRef}>
        <Link to={employee.id}>
          <TooltipWithContent isOverflow={isNameOverflow} text={fullName} />
        </Link>
      </Td>

      <Td onClick={handleOpenDetail} cursor="pointer">
        {employee.email ?? employee.phone}
      </Td>

      <Td onClick={handleOpenDetail} cursor="pointer">
        {HUMAN_READABLE_USER_ROLES[employee.role]}
      </Td>

      <Td>
        <Flex alignItems="center" justifyContent="end">
          {isVisibleDelete && (
            <IconButton aria-label="delete" icon={<TrashIcon />} variant="unstyled" onClick={onOpen} />
          )}
        </Flex>

        {isOpen && (
          <DeleteItemModal
            label="сотрудника"
            item={{ name: fullName }}
            onClose={onClose}
            onDelete={handleDeleteEmployee}
          />
        )}
      </Td>
    </Tr>
  );
};
