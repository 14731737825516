import styled from "@emotion/styled";
import { css } from "@emotion/react";
import MultiSelect from "react-select";
import { COLORS } from "./constants";

const ERROR_COLOR = "#E53E3E";

export const StyledSelect = styled(MultiSelect)`
  ${({ width }) =>
    css`
      width: ${width};
    `}
  margin-top: 5px;
  margin-right: 15px;
  .select-selection {
    &__control {
      background-color: ${({ isValuesEmpty }) => (!isValuesEmpty ? "white" : "#F8F8FA")};
      ${({ isError }) =>
        isError &&
        css`
          border-color: ${ERROR_COLOR};
          box-shadow: 0 0 0 1px ${ERROR_COLOR};
        `}

      &--is-disabled {
        opacity: 0.7;
        background-color: #f8f8fa;
      }

      min-height: ${({ height }) => height}px;
      border-radius: ${({ borderRadius }) => borderRadius}px;
    }

    &__indicator {
      cursor: pointer;
      padding-right: 15px;
    }

    &__value-container--has-value {
      resize: none;
      height: 32px;
    }
  }
`;

export const StyledFormPartButton = styled("button")`
  border-radius: 100px;
  margin-right: 10px;
  height: 32px;
  width: 32px;
  border-color: ${({ isReadyOrSelect }) => (!isReadyOrSelect ? COLORS.DISABLED_GRAY : COLORS.ACTIVE_PURPLE)};
  cursor: ${({ isReadyOrSelect }) => (!isReadyOrSelect ? "default" : "#pointer")};
  border-width: 1px;
  background-color: ${({ isActive }) => (isActive ? COLORS.ACTIVE_PURPLE : "white")};
  color: ${({ isActive }) => (isActive ? "white" : COLORS.DISABLED_GRAY)};
  img {
    margin-left: 7px;
  }
`;
