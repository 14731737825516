import { ROLE_NAMES } from "../constants";
import { getDateRanges } from "./getDateRanges";

export const convertStoryFromApi = (story) => {
  const { dateRange, timeRange } = getDateRanges(story.startDate, story.endDate);

  return {
    ...story,
    slides: story.slides.map((slide) => ({
      ...slide,
      button: slide.button
        ? {
            product: slide.button.product,
            productId: slide.button.product?.id,
            text: slide.button.text,
          }
        : null,
    })),
    dateRange,
    startTime: timeRange[0],
    endTime: timeRange[1],
    product: [],
    allowedWarehouse: {
      ...story.allowedWarehouse,
      name: `${story.allowedWarehouse?.city ?? ""}`,
    },
    allowedRole: ROLE_NAMES.find((userRole) => userRole.value === (story.allowedRole ? story.allowedRole : "ALL")),
  };
};
