export const createPromoCodeMessage = (brandsArr, subcategoryObj, productList) => {
  if (productList.length) {
    return "Промокод применится ко всем продуктам из списка товаров";
  }
  if (brandsArr?.length && subcategoryObj) {
    return `Промокод применится ко всем продуктам брендов "${brandsArr
      .map(({ name }) => name)
      .join(", ")}" подкатегории "${subcategoryObj.name}"`;
  }
  if (brandsArr?.length) {
    return `Промокод применится ко всем продуктам брендов "${brandsArr.map(({ name }) => name).join(", ")}"`;
  }
  if (subcategoryObj) {
    return `Промокод применится ко всем продуктам подкатегории "${subcategoryObj.name}"`;
  }
  return null;
};
