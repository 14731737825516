import { createAsyncThunk } from "@reduxjs/toolkit";
import { SLICE_NAME } from "./types";
import {
  createPromotion,
  getPromotion,
  deletePromotion,
  editPromotion,
  addPromotionMedia,
} from "../../../services/promotionServices";

export const getAllPromotion = createAsyncThunk(
  `${SLICE_NAME}/fetchGetPromotion`,
  async (values, { rejectWithValue }) => {
    try {
      const promotion = await getPromotion();

      return promotion?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || "Ошибка");
    }
  }
);

export const addingPromotion = createAsyncThunk(
  `${SLICE_NAME}/createPromotion`,
  async ({ values, resetForm, setFieldError }, { rejectWithValue }) => {
    try {
      const promotion = await createPromotion(values);

      resetForm();

      return { ...promotion?.data, endDate: promotion?.data.endDate.split(" ").at() };
    } catch (error) {
      setFieldError(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data?.message || "Ошибка");
    }
  }
);

export const deletingPromotion = createAsyncThunk(
  `${SLICE_NAME}/deletingPromotion`,
  async (values, { rejectWithValue }) => {
    try {
      await deletePromotion(values);

      return values;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || "Ошибка");
    }
  }
);

export const editingPromotion = createAsyncThunk(
  `${SLICE_NAME}/editingPromotion`,
  async (values, { rejectWithValue }) => {
    try {
      const promotion = await editPromotion(values);

      return { ...promotion?.data, endDate: promotion?.data.endDate.split(" ").at() };
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || "Ошибка");
    }
  }
);

export const addPromotionMediaThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchAddThumbnailMediaThunk`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await addPromotionMedia(params);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
