import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addCashback,
  deleteCashback,
  editCashback,
  getCashbacks,
  fetchCashback,
} from "../../../services/cashbackService";

import { SLICE_NAME } from "./types";

export const getAllCashbacklist = createAsyncThunk(
  `${SLICE_NAME}/fetchGetCashbackList`,
  async (values, { rejectWithValue }) => {
    try {
      const cashbacks = await getCashbacks();

      return cashbacks?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addingCashback = createAsyncThunk(
  `${SLICE_NAME}/fetchAddCashback`,
  async (params, { rejectWithValue }) => {
    try {
      return await addCashback(params);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const deletingCashback = createAsyncThunk(
  `${SLICE_NAME}/fetchDeleteCashback`,
  async (id, { rejectWithValue }) => {
    try {
      return await deleteCashback(id);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const editingCashback = createAsyncThunk(
  `${SLICE_NAME}/fetchEditCashback`,
  async (params, { rejectWithValue }) => {
    try {
      return await editCashback(params);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getCashback = createAsyncThunk(`${SLICE_NAME}/fetchCashback`, async (id, { rejectWithValue }) => {
  try {
    return await fetchCashback(id);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});
