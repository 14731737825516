import { requestAxios } from "../api/axiosRequest";

export const getProtocols = () => {
  return requestAxios.get(`/protocols`);
};

export const getProtocol = (id) => {
  return requestAxios.get(`/protocols/${id}`);
};

export const addProtocol = (data) => {
  return requestAxios.post(`/protocols`, data);
};

export const updateProtocol = (values, id) => {
  return requestAxios.patch(`/protocols/${id}`, values);
};

export const deleteProtocol = (id) => {
  return requestAxios.delete(`/protocols/${id}`);
};
