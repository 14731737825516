import { useEffect, useState, useMemo, useCallback } from "react";
import { debounce } from "lodash";
import { getUsersForNotifications } from "../../../services/userService";
import { Flex, Button, Box, Text } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { UsersTable } from "../Components/UsersTable";
import { CustomOption as Option } from "../Components/CustomOption";
import { UserOption } from "../Components/UserOption";
import { getAllWarehouses } from "../../Cities/store/Warehouses/Warehouses.thunk";
import { CustomMultiSelect } from "../Components/CustomMultiselect";
import { getWarehouses, warehousesIsLoading } from "../../Cities/store/Warehouses/Warehouse.selectors";
import { commasLogicForSingleItem } from "../helpers/commasLogicForSingleItem";
import { getWarehousesOptions } from "../helpers/getWarehousesOptions";
import { getUsersOptions } from "../helpers/getUsersOptions";
import { getWarehousesValues } from "../helpers/getWarehouseValues";
import { userRolesForNotifications } from "../constants";
import { StyledSelect } from "../styles";
import { useFormikContext } from "formik";
import { CustomMultiValue } from "../Components/CustomMultiValue";
import { components } from "react-select";

const NullComponent = () => {
  return null;
};

const customMultiselectComponents = {
  Option,
  MultiValueRemove: NullComponent,
  ClearIndicator: NullComponent,
  IndicatorSeparator: NullComponent,
  MultiValueContainer: CustomMultiValue,
};

const UserNoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Пользователь не найден</span>
    </components.NoOptionsMessage>
  );
};

export const AudienceFormPart = ({ handleDropdownChange, handleUserDropDownChange, isFormBlockedForEditing }) => {
  const dispatch = useDispatch();

  const warehouseIsLoading = useSelector(warehousesIsLoading());
  const warehouses = useSelector(getWarehouses());
  const [users, setUsers] = useState([]);

  const [isUserAddingOpen, setIsUserAddingOpen] = useState(false);

  const { values, setFieldValue } = useFormikContext();

  const fetchUsers = async (query) => {
    try {
      setUsers([]);
      const response = await getUsersForNotifications(query);

      setUsers(response.data.data);
    } catch (error) {
      setUsers([]);
    }
  };

  useEffect(() => {
    fetchUsers("");
  }, []);

  useEffect(() => {
    dispatch(getAllWarehouses());
  }, []);

  const debounceFn = useCallback(debounce(fetchUsers, 1000), []);

  const selectAllWarehouses = () => {
    if (values.warehouses.length < warehouses.length) {
      setFieldValue(
        "warehouses",
        warehouses.map((warehouse) => warehouse.id)
      );

      return;
    }
    setFieldValue("warehouses", []);
  };

  const userOptions = useMemo(() => getUsersOptions(users, values.users), [values.users, users]);

  const warehousesOptions = useMemo(
    () => getWarehousesOptions(warehouses, values.warehouses, warehouseIsLoading),
    [values.warehouses, warehouses]
  );

  const warehousesValues = useMemo(
    () => getWarehousesValues(warehouses, values.warehouses, warehouseIsLoading),
    [values.warehouses, warehouses]
  );

  const rolesOptions = Object.values(userRolesForNotifications).map((role) => {
    return { label: role.label, value: { payload: role.value, isChecked: values.roles.includes(role.value) } };
  });

  const rolesValues = values.roles.map((role, index) => {
    return {
      value: { payload: role, isChecked: values.roles.includes(role) },
      label: commasLogicForSingleItem(userRolesForNotifications[role].label, index === values.roles.length - 1),
    };
  });

  return (
    <Box>
      <Flex>
        <Box>
          <Text>Роль пользователя</Text>
          <CustomMultiSelect
            placeholder="Выберите роль"
            components={customMultiselectComponents}
            value={rolesValues}
            handleDropdownChange={handleDropdownChange}
            options={rolesOptions}
            isDisabled={isFormBlockedForEditing}
            fieldName="roles"
            isNeedSelectAllButton={false}
          />
        </Box>

        <Box>
          <Text>Склад</Text>
          <CustomMultiSelect
            placeholder={warehouseIsLoading ? "Загрузка" : "Выберите склад"}
            value={warehousesValues}
            handleDropdownChange={handleDropdownChange}
            options={warehousesOptions}
            isDisabled={isFormBlockedForEditing || warehouseIsLoading}
            isNeedSelectAllButton={true}
            fieldName="warehouses"
            selectAllOptions={selectAllWarehouses}
            isAllSelected={values.warehouses.length === warehouses.length}
          />
        </Box>
      </Flex>

      <Flex flexDirection="column">
        <Button
          marginTop="20px"
          _hover={{ backgroundColor: "white", cursor: isFormBlockedForEditing ? "default" : "pointer" }}
          _active={{ backgroundColor: "white" }}
          color={isFormBlockedForEditing ? "purple.200" : "purple.500"}
          variant="ghost"
          onClick={() => {
            if (!isFormBlockedForEditing) {
              setIsUserAddingOpen(true);
            }
          }}
          width="240px"
        >
          + Добавить пользователя
        </Button>

        {isUserAddingOpen && (
          <StyledSelect
            placeholder="Поиск пользователя"
            width="540px"
            components={{
              Option: UserOption,
              MultiValueRemove: NullComponent,
              ClearIndicator: NullComponent,
              NoOptionsMessage: UserNoOptionsMessage,
            }}
            isMulti
            classNamePrefix="select-selection"
            onInputChange={(value) => {
              debounceFn(value);
            }}
            onChange={(user, event) => {
              setIsUserAddingOpen(false);
              handleUserDropDownChange(event.option.value, true);
            }}
            options={userOptions}
          />
        )}

        {values.users.length > 0 && (
          <UsersTable
            users={values.users}
            handleUserDropDownChange={handleUserDropDownChange}
            isDisabled={isFormBlockedForEditing}
          />
        )}
      </Flex>
    </Box>
  );
};
