import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";

export const getNotifications = () => (state) => state[SLICE_NAME].notifications;
export const getNotificationsCount = () => (state) => state[SLICE_NAME].count;
export const notificationsIsGetted = () => (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const notificationsIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const notificationsIsLoading = () => (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const notificationIsLoading = () => (state) => state[SLICE_NAME].notification.loadingState;
export const notificationsToast = () => (state) => state[SLICE_NAME].toast;
export const notificationsToastIsView = () => (state) => state[SLICE_NAME].toast.view;
export const getEditingNotification = () => (state) => state[SLICE_NAME].notification.notification;
