import PageLayout from "../../components/PageLayout/PageLayout";
import { CreatePromotion } from "./CreatePromotion";
import { PromotionTable } from "./PromotionTable";
import { useEffect } from "react";
import { useCustomToast } from "../../hooks/useCustomToast";
import { promotionToast, promotionToastIsView } from "./store/Promotion.selectors";
import { useSelector, useDispatch } from "react-redux";
import { deletePromotionToast } from "./store/Promotion.slice";

export const Promotion = () => {
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();

  const toastInfo = useSelector(promotionToast);
  const toastIsViewed = useSelector(promotionToastIsView);

  useEffect(() => {
    if (toastIsViewed) {
      pushToast(toastInfo);
    }
    dispatch(deletePromotionToast());
  }, [toastIsViewed, pushToast, dispatch]);

  return (
    <PageLayout>
      <CreatePromotion />

      <PromotionTable />
    </PageLayout>
  );
};
