import { createAsyncThunk } from "@reduxjs/toolkit";

import { getWishlist } from "../../../services/analyticsService";

import { SLICE_NAME } from "./types";

export const getWishlistThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetWishlist`,
  async (params, { rejectWithValue, fulfillWithValue }) => {
    try {
      const wishlist = await getWishlist(params);

      return wishlist.data;
    } catch (err) {
      if (!err?.status) {
        return fulfillWithValue([]);
      }
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
