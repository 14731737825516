import { DateTime } from "luxon";
import { DATE_FORMAT_API, DEFAULT_DATE_FORMAT } from "../../../common/constants";

// TODO Fix / Refactor Function
export const formatDate = (date, format = DEFAULT_DATE_FORMAT, fromFormat = null, zone = "UTC+3") => {
  if (typeof date === "string") {
    if (fromFormat) {
      return DateTime.fromFormat(date, fromFormat).setZone(zone).toFormat(format);
    }
    let result = DateTime.fromISO(date).setZone(zone).toFormat(format);

    if (result === "Invalid DateTime") {
      result = new DateTime(date).toFormat(format);
    }
    return result;
  }
  if (typeof date === "object") {
    return DateTime.fromJSDate(date).toFormat(format);
  }
  return "";
};
export const formatDateToApi = (date) => formatDate(date, DATE_FORMAT_API);
