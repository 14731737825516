import { useRef } from "react";
import { Td, Tr } from "@chakra-ui/react";
import { useIsOverflow } from "../../hooks/hooks";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";
import { generateName } from "../Clients/utils/generateName";

const SpecialistPromocodesTableRow = ({ values }) => {
  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);

  const { name, specialist, promocode, count, total, date } = values;

  const finalName = specialist ? generateName(specialist) : null;

  return (
    <Tr tabIndex={0}>
      <Td sx={TEXT_OVERFLOW_STYLE} ref={ref} role="gridcell">
        <TooltipWithContent text={finalName || name} isOverflow={isOverflow} />
      </Td>

      <Td sx={TEXT_OVERFLOW_STYLE} role="gridcell">
        {promocode}
      </Td>

      <Td sx={TEXT_OVERFLOW_STYLE} role="gridcell">
        {count}
      </Td>

      <Td sx={TEXT_OVERFLOW_STYLE} role="gridcell">
        {new Intl.NumberFormat("ru", { maximumFractionDigits: 0 }).format(total)}₽
      </Td>

      <Td sx={TEXT_OVERFLOW_STYLE} role="gridcell">
        {date}
      </Td>
    </Tr>
  );
};
export default SpecialistPromocodesTableRow;
