import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getStories,
  getOneStory,
  getOneSlide,
  editStory,
  addStory,
  addSlideMedia,
  addThumbnailMedia,
  deleteStory,
} from "../../../services/storiesService";

import { SLICE_NAME } from "./types";

export const getAllStoriesThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetAllStories`,
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await getStories();

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getOneStoryThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetOneStoryThunk`,
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getOneStory(id);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getOneSlideThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetOneSlideThunk`,
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getOneSlide(id);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const editStoryThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchEditStoryThunk`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await editStory(params);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addStoryThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchAddStoryThunk`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await addStory(params);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const deleteStoryThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchDeleteStoryThunk`,
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteStory(id);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addSlideMediaThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchAddSlideMediaThunk`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await addSlideMedia(params);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addThumbnailMediaThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchAddThumbnailMediaThunk`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await addThumbnailMedia(params);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
