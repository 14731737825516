import { Tr, Td, IconButton, FormControl, Input, Flex } from "@chakra-ui/react";
import { ReactComponent as SubmitIcon } from "../../assets/svg/SubmitIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/svg/CancelIcon.svg";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { useEffect, useMemo, useRef, useCallback } from "react";
import { useFormik } from "formik";
import { ACHIEVEMENTS_OPTIONS, PROMOTION_VALIDATION_SCHEMA } from "./constants";
import { useDispatch } from "react-redux";
import { editingPromotion, addPromotionMediaThunk } from "./store/Promotion.thunk";
import { DatePicker } from "../../components/DatePicker/DatePicker";
import { transformData } from "./utils/transformData";
import { UploadInputGroup } from "../../components/UploadInputGroup/UploadInputGroup";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";

export const PromotionEditTableRow = ({ promotion, handleCancel }) => {
  const dispatch = useDispatch();

  const handleSubmitForm = (values) => {
    dispatch(editingPromotion({ id: values.id, values: transformData(values, values.image !== promotion.image) }));
    handleCancel();
  };

  const promotionFileLoaderRef = useRef(null);

  const formik = useFormik({
    initialValues: { ...promotion, endDate: new Date(promotion.endDate.substring(0, 10)) },
    validateOnChange: false,
    validationSchema: PROMOTION_VALIDATION_SCHEMA,
    onSubmit: handleSubmitForm,
  });

  const { dirty, errors, handleChange, handleSubmit, values, setFieldError, setFieldValue } = formik;

  const currentAchievements = useMemo(
    () => ACHIEVEMENTS_OPTIONS.find((option) => option.value === promotion?.availableFor)?.label,
    [promotion?.availableFor]
  );

  const handleCourseSelectFile = useCallback(
    (file) => {
      dispatch(addPromotionMediaThunk({ file })).then(({ payload }) => {
        setFieldValue("image", payload.url);
      });
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (formik.isSubmitting && errors.image) {
      setFieldValue("image", "");
    }
  }, [formik.isSubmitting, errors.image]);

  return (
    <Tr height="125px">
      <Td style={TEXT_OVERFLOW_STYLE} alignItems="center" justifyContent="space-between">
        <FormControlWithError isInvalid={!!errors.image}>
          <UploadInputGroup
            image={values.image}
            placeholder="Загрузите изображение"
            errorSetter={(errorText) => setFieldError("image", errorText)}
            ref={promotionFileLoaderRef}
            handleSelectFile={handleCourseSelectFile}
          />
        </FormControlWithError>
      </Td>

      <Td>
        <FormControl isInvalid={!!errors.name}>
          <Input
            name="name"
            onChange={handleChange}
            placeholder="Введите название промоакции"
            value={values.name}
            autoComplete="off"
          />
        </FormControl>
      </Td>

      <Td>
        <FormControl isInvalid={!!errors.endDate}>
          <DatePicker
            selected={values.endDate}
            minDate={new Date()}
            onChange={(date) => formik.setFieldValue("endDate", date)}
          />
        </FormControl>
      </Td>

      <Td>{currentAchievements}</Td>

      <Td>
        <Flex alignItems="center" justifyContent="end">
          <IconButton
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<SubmitIcon />}
            onClick={dirty ? handleSubmit : handleCancel}
            aria-label="submit"
          />

          <IconButton
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<CancelIcon />}
            onClick={handleCancel}
            aria-label="cancel"
          />
        </Flex>
      </Td>
    </Tr>
  );
};
