import { useRef } from "react";
import { Box, ButtonGroup, Flex, Grid, HStack, IconButton, Input, Text } from "@chakra-ui/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import Decimal from "decimal.js";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { CurrencyIcon } from "../../assets/icon/CurrencyIcon";
import { DeleteIcon } from "../../assets/icon/DeleteIcon";
import { PRICE_STYLE } from "./style";

export const RowProduct = ({ product, isEdit, productsAmount, onDecrease, onIncrease, onDelete }) => {
  const {
    id,
    name,
    volume,
    price,
    codeFrom1C,
    product_in_warehouse_info: productInWarehouse,
    count,
    discount: productDiscount,
    brand,
  } = product;
  const { amount } = productInWarehouse;

  const priceDecimal = new Decimal(price);
  const discountDecimal = new Decimal(productDiscount);

  const { current: limitProducts } = useRef(count + amount);

  return (
    <Box key={id} borderBottom="1px" borderBottomColor="#EBECEF" marginBottom="20px" paddingLeft="8px">
      <Grid templateColumns="repeat(2,1fr)" style={{ display: "flex", justifyContent: "space-between" }} mb="8px">
        <Text fontSize="12px" fontWeight="500" maxW="80%">
          {brand?.name || "-"}
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#434652">
          {codeFrom1C}
        </Text>
      </Grid>
      <Grid templateColumns="repeat(2,1fr)" style={{ display: "flex", justifyContent: "space-between" }} mb="8px">
        <Text fontSize="12px" fontWeight="500">
          Количество
        </Text>
        <Text fontSize="12px" fontWeight="400" color="#434652">
          {isEdit ? (
            <ButtonGroup isAttached variant="outline" style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                isDisabled={count <= 1}
                icon={<AiOutlineMinus />}
                onClick={() => onDecrease(id)}
                height="20px"
                minWidth="20px"
                borderRadius="4px 0px 0px 4px"
                aria-label="decrease"
              />
              <Input
                style={{
                  width: "24px",
                  padding: "0",
                  textAlign: "center",
                  height: "20px",
                  borderRadius: 0,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
                value={count}
                onChange={() => {}}
                isReadOnly
              />

              <IconButton
                isDisabled={count >= limitProducts}
                icon={<AiOutlinePlus />}
                onClick={() => onIncrease(id)}
                height="20px"
                minWidth="20px"
                borderLeft="none"
                borderRadius="0px 4px 4px 0px"
                aria-label="increase"
              />
            </ButtonGroup>
          ) : (
            count
          )}
        </Text>
      </Grid>
      <Grid templateColumns="repeat(2,1fr)" style={{ display: "flex", justifyContent: "space-between" }} mb="8px">
        <Text fontSize="12px" fontWeight="500" maxW="80%">
          {name || "-"}
        </Text>
      </Grid>

      <Box style={{ display: "flex", gap: "15px", justifyContent: "space-between" }} mb="8px">
        <div style={{ display: "flex", flexDirection: "column-reverse" }}>
          <Text fontSize="14px" fontWeight="400">
            {count > 1 ? `(${volume}) x${count}` : volume}
          </Text>
        </div>

        <HStack>
          {productDiscount && (
            <Text fontSize="14px" fontWeight="500" color="#434652" style={PRICE_STYLE}>
              {price}
            </Text>
          )}
          {productDiscount && <CurrencyIcon color="#99939F" />}
          <Text fontSize="14px" fontWeight="500" color="#434652" style={{ display: "flex", alignItems: "center" }}>
            {priceDecimal.minus(discountDecimal).toNumber()}
          </Text>
          <CurrencyIcon />
        </HStack>
      </Box>
      {isEdit && productsAmount > 1 && (
        <Flex justifyContent="end">
          <Flex align="center" onClick={() => onDelete(id)} marginBottom="6px" gap={1} _hover={{ cursor: "pointer" }}>
            <DeleteIcon />
            <Text
              style={{
                color: `#99939F`,
                fontWeight: "500",
                fontSize: "14px",
              }}
            >
              Удалить
            </Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
