/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../store/common";
import { SLICE_NAME } from "./types";

import {
  applyOrderThunk,
  createReportOrdersThunk,
  getOneOrderThunk,
  getOrdersThunk,
  getPreviewOrderThunk,
  getUnreviewedOrdersThunk,
  updateDetailsOfOrderThunk,
  updateOrderThunk,
  deleteOrderThunk,
  cancelOrderThunk,
} from "./Orders.thunk";

const initialToast = { view: false, title: "", description: "", status: "" };

const initialState = {
  toast: initialToast,
  loadingState: LoadingState.IDLE,
  orders: [],
  count: null,
  selectedOrder: { detail: null, loadingState: LoadingState.IDLE },
  unreviewedOrders: null,
  loadingStateReport: LoadingState.IDLE,
};

export const {
  reducer,
  actions: { deleteToast, applyOrderAction, clearSelectedOrder },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteToast: (state) => {
      state.toast = initialToast;
    },
    applyOrderAction: (state, action) => {
      state.toast = {
        view: true,
        title: action.payload.title,
        description: action.payload.description,
        status: action.payload.status,
      };
    },
    clearSelectedOrder: (state) => {
      state.selectedOrder = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrdersThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.orders = action.payload.data;
        state.count = action.payload.count;
      })
      .addCase(getOrdersThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(getOrdersThunk.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        if (action.payload === "Forbidden") {
          return;
        }
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(getOneOrderThunk.pending, (state) => {
        state.selectedOrder.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(getOneOrderThunk.fulfilled, (state, action) => {
        state.selectedOrder.detail = action.payload;
        state.selectedOrder.loadingState = LoadingState.FULFILLED;
      })
      .addCase(getOneOrderThunk.rejected, (state, action) => {
        state.selectedOrder.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
        state.selectedOrder.detail = null;
      })

      .addCase(updateOrderThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
      })
      .addCase(updateOrderThunk.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(updateOrderThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(getUnreviewedOrdersThunk.fulfilled, (state, action) => {
        state.unreviewedOrders = action.payload;
      })
      .addCase(getUnreviewedOrdersThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(getUnreviewedOrdersThunk.pending, (state) => {
        state.toast = initialToast;
      })
      .addCase(createReportOrdersThunk.fulfilled, (state) => {
        state.loadingStateReport = LoadingState.FULFILLED;
      })
      .addCase(createReportOrdersThunk.rejected, (state) => {
        state.loadingStateReport = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: "Что-то пошло не так, повторите попытку позже",
          status: "error",
        };
      })
      .addCase(createReportOrdersThunk.pending, (state) => {
        state.loadingStateReport = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(applyOrderThunk.fulfilled, (state) => {
        state.loadingState = LoadingState.FULFILLED;
      })
      .addCase(applyOrderThunk.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(applyOrderThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(updateDetailsOfOrderThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.selectedOrder.detail = action.payload;

        const id = state.orders.findIndex((order) => order.id === action.payload.id);

        state.orders[id] = action.payload;
        state.toast = {
          view: true,
          title: "Заказ успешно обновлен!",
          description: "",
          status: "success",
        };
      })
      .addCase(updateDetailsOfOrderThunk.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(updateDetailsOfOrderThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(getPreviewOrderThunk.fulfilled, (state) => {
        state.loadingState = LoadingState.FULFILLED;
      })
      .addCase(getPreviewOrderThunk.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(getPreviewOrderThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(deleteOrderThunk.fulfilled, (state, action) => {
        const chosenOrderIndex = state.orders.findIndex((order) => order.id === action.payload);
        const newOrders = [...state.orders];
        newOrders.splice(chosenOrderIndex, 1);
        state.loadingState = LoadingState.FULFILLED;
        state.orders = newOrders;
      })
      .addCase(deleteOrderThunk.rejected, (state) => {
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(deleteOrderThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(cancelOrderThunk.rejected, (state) => {
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(cancelOrderThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(cancelOrderThunk.fulfilled, (state, action) => {
        const chosenOrderIndex = state.orders.findIndex((order) => order.id === action.payload);
        const newOrders = [...state.orders];
        newOrders.splice(chosenOrderIndex, 1);
        state.loadingState = LoadingState.FULFILLED;
        state.orders = newOrders;
      });
  },
});
