import TemplateDefaultContainer from "../../components/common/TemplateDefaultContainer";
import { useNavigate } from "react-router-dom";
import { EmployeesTable } from "./components/EmployeesTable";
import { EmployeeToastView } from "./EmployeeToastView";

export const Employees = () => {
  const navigate = useNavigate();

  return (
    <EmployeeToastView>
      <TemplateDefaultContainer handleCreateEntity={() => navigate("create")} textForAddButton="Добавить сотрудника">
        <EmployeesTable />
      </TemplateDefaultContainer>
    </EmployeeToastView>
  );
};
