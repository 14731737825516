import { requestAxios } from "../api/axiosRequest";

const PROMOTION_API = "/advancement";

export const getPromotion = () => requestAxios.get(PROMOTION_API);

export const createPromotion = (promotion) => requestAxios.post(PROMOTION_API, promotion);

export const deletePromotion = (id) => requestAxios.delete(`${PROMOTION_API}/${id}`);

export const editPromotion = ({ id, values }) => requestAxios.patch(`${PROMOTION_API}/${id}`, values);

export const addPromotionMedia = (payload) => {
  return requestAxios.post(`/media/image`, payload, { headers: { "Content-Type": "multipart/form-data" } });
};
