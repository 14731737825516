import { Flex, InputGroup, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import InputSearch from "../InputSearch";
import PageLayout from "../PageLayout/PageLayout";
import { Pagination } from "../Pagination";
import { MAX_HEIGHT_SEMINAR_BODY } from "../../pages/Seminars/constants";

export const PageWithTabsAndPaginationLayout = ({
  tabsWithElements,
  paginationParams,
  currentTabSetter,
  isSearchInputNeed = false,
  searchInputValue = "",
  searchInputValueSetter = () => {},
  submitSearchQuery = () => {},
  handleOnClickSubmit = () => {},
}) => {
  const { isPrevDisabled, isNextDisabled, page, limit, totalPages, nextPage, prevPage, setPage, setLimit } =
    paginationParams;

  return (
    <PageLayout>
      {isSearchInputNeed && (
        <Flex style={{ justifyContent: "end", marginBottom: "35px", width: "100%" }}>
          <InputGroup style={{ maxWidth: "320px" }}>
            <InputSearch
              style={{ border: "1px solid #D0D3DA" }}
              placeholder="Поиск"
              value={searchInputValue}
              onChange={(e) => searchInputValueSetter(e.target.value)}
              onKeyDown={submitSearchQuery}
              onClick={handleOnClickSubmit}
            />
          </InputGroup>
        </Flex>
      )}

      <Tabs height="calc(100% - 125px)" w="100%" onChange={currentTabSetter}>
        <TabList borderBottom="none" marginBottom="8px">
          <Flex style={{ width: "100%", justifyContent: "space-between" }}>
            <Flex color="#737680">
              {tabsWithElements.map((tab, index) => (
                <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }} key={index}>
                  {tab.name}
                </Tab>
              ))}
            </Flex>
            <Pagination
              totalPages={totalPages}
              page={page}
              limit={limit}
              isPrevDisabled={isPrevDisabled}
              isNextDisabled={isNextDisabled}
              onNextPage={nextPage}
              onPrevPage={prevPage}
              onChangePage={setPage}
              onLimitChange={setLimit}
            />
          </Flex>
        </TabList>

        <TabPanels height={MAX_HEIGHT_SEMINAR_BODY}>
          {tabsWithElements.map((tab, index) => (
            <TabPanel height="100%" p="16px 0" key={index}>
              {tab.renderElement}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
};
