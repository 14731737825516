/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  createReportClientsThunk,
  getClientThunk,
  getClientsThunk,
  editingClientThunk,
  resetPasswordThunk,
  getClientOrdersThunk,
  getClientBonusesThunk,
  changeClientBonusesThunk,
} from "./Clients.thunk";
import { LoadingState } from "../../../store/common";
import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  clients: [],
  client: {
    clientDetails: null,
    loadingState: LoadingState.IDLE,
    editingState: LoadingState.IDLE,
    ordersLoadingState: LoadingState.IDLE,
  },
  tempPassword: { password: null, loadingState: LoadingState.IDLE },
  orderCount: 0,
};

export const {
  reducer,
  actions: { deleteToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientsThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getClientsThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getClientsThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.clients = action.payload;
      })

      .addCase(createReportClientsThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(createReportClientsThunk.rejected, (state) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: "Что-то пошло не так, повторите попытку позже",
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(createReportClientsThunk.fulfilled, (state) => {
        state.loadingState = LoadingState.FULFILLED;
      })

      .addCase(getClientThunk.pending, (state) => {
        state.client.loadingState = LoadingState.PENDING;
        state.client.ordersLoadingState = LoadingState.PENDING;
      })
      .addCase(getClientThunk.rejected, (state, { payload }) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: "При загрузке данных произошла ошибка, повторите попытку позже",
          status: "error",
        };
        state.client.loadingState = LoadingState.REJECTED;
        state.client.ordersLoadingState = LoadingState.REJECTED;
      })
      .addCase(getClientThunk.fulfilled, (state, { payload }) => {
        state.client.loadingState = LoadingState.FULFILLED;
        state.client.ordersLoadingState = LoadingState.FULFILLED;
        state.client.clientDetails = payload;
      })
      .addCase(getClientOrdersThunk.fulfilled, (state, { payload }) => {
        state.client.ordersLoadingState = LoadingState.FULFILLED;
        state.client.clientDetails.orders = payload.orders;
      })
      .addCase(getClientOrdersThunk.rejected, (state, { payload }) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: payload,
          status: "error",
        };
        state.client.ordersLoadingState = LoadingState.REJECTED;
      })
      .addCase(getClientOrdersThunk.pending, (state) => {
        state.client.ordersLoadingState = LoadingState.PENDING;
      })
      .addCase(editingClientThunk.pending, (state) => {
        state.client.editingState = LoadingState.PENDING;
      })
      .addCase(editingClientThunk.rejected, (state, { payload }) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: payload,
          status: "error",
        };
        state.client.editingState = LoadingState.REJECTED;
      })
      .addCase(editingClientThunk.fulfilled, (state, { payload }) => {
        state.client.editingState = LoadingState.FULFILLED;
        state.toast = {
          view: true,
          title: "Пользователь изменен",
          description: "",
          status: "success",
        };
        state.client.clientDetails = payload;
      })
      .addCase(resetPasswordThunk.pending, (state) => {
        state.tempPassword.loadingState = LoadingState.PENDING;
      })
      .addCase(resetPasswordThunk.rejected, (state, { payload }) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: "При сбросе пароля произошла ошибка, повторите попытку позже",
          status: "error",
        };
        state.tempPassword.loadingState = LoadingState.REJECTED;
      })
      .addCase(resetPasswordThunk.fulfilled, (state, { payload }) => {
        state.tempPassword.loadingState = LoadingState.FULFILLED;
        state.tempPassword.password = payload;
      })
      .addCase(getClientBonusesThunk.fulfilled, (state, { payload }) => {
        state.client.clientDetails.bonuses = payload;
      })
      .addCase(changeClientBonusesThunk.fulfilled, (state, { payload }) => {
        state.client.clientDetails.bonuses = payload;
      });
  },
});
