import { Flex, Box, Text } from "@chakra-ui/react";
import { components } from "react-select";

export const UserOption = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <Flex justify="space-between">
        <Box>
          <Text>
            {data.value.name} {data.value.lastName} {data.value.secondName ?? ""}
          </Text>

          <Text color="gray">{data.value.email}</Text>
        </Box>
        <Text color="gray">{data.value.phone}</Text>
      </Flex>
    </components.Option>
  );
};
