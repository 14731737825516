import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
import { WEBSOCKET_API } from "../config";

export const useWebsocet = (request, action) => {
  const dispatch = useDispatch();

  let socket;

  const [count, setCount] = useState(0);
  const [connectStatusError, setConnectStatusError] = useState(null);
  const [newItem, setNewItem] = useState(null);

  const hasUnauthorized = connectStatusError === 401;

  const token = useMemo(() => localStorage.getItem("token"), [hasUnauthorized]);

  useEffect(() => {
    if (token) {
      socket = io(WEBSOCKET_API, {
        path: "/socket.io",
        transports: ["websocket", "polling"],
        auth: {
          token,
        },
      });
      socket.on("error", (error) => {
        setConnectStatusError(error.response.status);
      });
      socket.on(request, (newItemFromWss) => {
        setNewItem(newItemFromWss);

        if (action) {
          dispatch(action());
        }
      });
    }

    return () => {
      setNewItem(null);

      if (socket) {
        socket.disconnect();
      }
    };
  }, [token]);

  useEffect(() => {
    let timeoutId;

    if (!token) {
      timeoutId = setTimeout(() => {
        setCount((prevCount) => prevCount + 1);
        setConnectStatusError(null);
      }, 1500);
    }
  }, [token]);

  return newItem;
};
