import { VERIFICATION_STATUS_KEYS, VERIFICATION_STATUS_NAMES } from "../constants";

export const getUserStatusNameByStatusKey = (status) => {
  switch (status) {
    case VERIFICATION_STATUS_KEYS.NEW:
      return VERIFICATION_STATUS_NAMES.NEW;
    case VERIFICATION_STATUS_KEYS.PENDING:
      return VERIFICATION_STATUS_NAMES.PENDING;
    case VERIFICATION_STATUS_KEYS.ACCEPTED:
      return VERIFICATION_STATUS_NAMES.ACCEPTED;
    case VERIFICATION_STATUS_KEYS.REJECTED:
      return VERIFICATION_STATUS_NAMES.REJECTED;
    default:
      return VERIFICATION_STATUS_NAMES.UNKNOWN;
  }
};
