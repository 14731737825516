import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  editProduct,
  getLatestProduct,
  getOneProduct,
  getProducts,
  addProductMedia,
} from "../../../services/productService";

import { SLICE_NAME } from "./types";
import { sendProductsForCategory } from "../../../services/categoryService";

export const getAllProductsThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetBrands`,
  async (values, { rejectWithValue }) => {
    try {
      const products = await getProducts(values);
      return products.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getProductThunk = createAsyncThunk(`${SLICE_NAME}/fetchProduct`, async (id, { rejectWithValue }) => {
  try {
    const response = await getOneProduct(id);

    return response.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const editingProductThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchEditProduct`,
  async ({ id, productForSend, onClose }, { rejectWithValue }) => {
    try {
      const response = await editProduct(productForSend, id);

      await onClose();

      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const updateProductThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchUpdateProducts`,
  async (value, { rejectWithValue }) => {
    try {
      return (await sendProductsForCategory(value)).data.message;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getBrandsLatestProduct = createAsyncThunk(
  `${SLICE_NAME}/fetchBrandsLatestProduct`,
  async (values, { rejectWithValue }) => {
    try {
      const product = await getLatestProduct(values);

      return product.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addProductMediaThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchAddProductMediaThunk`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await addProductMedia(params);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
