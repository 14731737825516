import React from "react";

const ArrowDown = () => {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.07993 1.00002H6.91993C7.02263 0.992979 7.12516 1.01599 7.21499 1.06626C7.30482 1.11652 7.37807 1.19185 7.4258 1.28306C7.47352 1.37427 7.49365 1.4774 7.48373 1.57985C7.47381 1.68231 7.43427 1.77966 7.36993 1.86002L4.44993 4.80002C4.39363 4.86387 4.32438 4.915 4.2468 4.95002C4.16921 4.98504 4.08506 5.00316 3.99993 5.00316C3.91481 5.00316 3.83066 4.98504 3.75307 4.95002C3.67549 4.915 3.60624 4.86387 3.54993 4.80002L0.629935 1.86002C0.5656 1.77966 0.526059 1.68231 0.516139 1.57985C0.506219 1.4774 0.526351 1.37427 0.574075 1.28306C0.621799 1.19185 0.69505 1.11652 0.784881 1.06626C0.874713 1.01599 0.977238 0.992979 1.07993 1.00002Z"
        fill="#414348"
      />
    </svg>
  );
};

export default ArrowDown;
