import { LoadingState } from "../../../store/common";
import { SLICE_NAME } from "./types";

export const getOrders = () => (state) => state[SLICE_NAME].orders;
export const getOneOrderSelector = () => (state) => state[SLICE_NAME].selectedOrder.detail;
export const orderLoadingState = () => (state) => state[SLICE_NAME].selectedOrder.loadingState;
export const getTotalOrders = () => (state) => state[SLICE_NAME].count;
export const isOrdersButtonLoading = () => (state) => state[SLICE_NAME].loadingStateReport === LoadingState.PENDING;
export const isOrdersLoading = () => (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const orderToast = () => (state) => state[SLICE_NAME].toast;
export const toastIsView = () => (state) => state[SLICE_NAME].toast.view;
export const getNewOrders = () => (state) => state[SLICE_NAME].unreviewedOrders;
