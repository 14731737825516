import { Flex, Grid, Input, InputGroup, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { useFormik } from "formik";
import InputWithSymbol from "../../components/InputWithSymbol";
import Autocomplete from "../../components/Autocomplete";
import { PRODUCT_INPUT_STYLE } from "../Products/style";
import { useSelector, useDispatch } from "react-redux";
import { getCategories } from "../Categories/store/Categories/Categories.selectors";
import { gettingCategories } from "../Categories/store/Categories/Categories.thunk";
import { SubCategoriesSelect } from "./SubCategoriesSelect";
import BrandProductsSelect from "../../components/BrandProductsSelect";
import { createCashbackMessage } from "./utils/createCashbackMessage";
import { BrandsSelector } from "./BrandsSelector";
import { CASHBACK_MODAL_SCHEME } from "./constants";
import { checkNaN } from "../../utils/checkNaN";
import { addingCashback, editingCashback, getCashback } from "./store/Cashbacks.thunk";
import { convertCashbackToApi } from "./utils/converCashbackToApi";
import { convertCashbackFromApi } from "./utils/convertCashbackFromApi";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";
import { Drawer } from "../../components/Drawer/Drawer";
import { useNavigate, useParams } from "react-router-dom";
import { getCashbackById } from "./store/Cashbacks.selectors";
import { usePrompt } from "../../hooks/usePrompt";
import { isEqual } from "lodash";

const initialValues = { name: "", percent: null, category: "", subcategory: "", brand: "", products: [] };

export const CashbackModal = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector(getCategories());
  const { loadingState, cashbackDetails } = useSelector(getCashbackById);

  useEffect(() => {
    if (!categories || categories.length === 0) {
      dispatch(gettingCategories());
    }
  }, [dispatch]);

  const handleSaveCashback = (values) => {
    const isEqualBanner = isEqual(convertCashbackFromApi(cashbackDetails), values);

    if (!isEqualBanner) {
      if (values.id) {
        dispatch(editingCashback({ id: values.id, objToSend: convertCashbackToApi(values) }));
      } else {
        dispatch(addingCashback(convertCashbackToApi(values)));
      }
    }

    navigate(-1);
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: CASHBACK_MODAL_SCHEME,
    onSubmit: handleSaveCashback,
  });

  const categoriesItems = useMemo(
    () =>
      categories?.map((item) => ({
        name: item.name,
        id: item.id,
      })) || [],
    [categories]
  );

  useEffect(() => {
    if (formik.isSubmitting && formik.values.products.length > 0) {
      formik.setFieldValue("category", null);
      formik.setFieldValue("subcategory", null);
      formik.setFieldValue("brand", null);
    }
  }, [formik.isSubmitting]);

  useEffect(() => {
    if (id) {
      dispatch(getCashback(id));
    }
  }, [id]);

  useEffect(() => {
    if (cashbackDetails && id === cashbackDetails.id) {
      formik.resetForm({ values: convertCashbackFromApi(cashbackDetails) });
    }
  }, [id, cashbackDetails]);

  const { values, handleChange, errors, handleSubmit, setFieldValue, dirty } = formik;

  usePrompt(!formik.isSubmitting && dirty);

  return (
    <Drawer
      isLoading={loadingState}
      size="lg"
      placement="left"
      isOpen
      extraText={createCashbackMessage(values.category, values.subcategory, values.products, values.brand)}
      handleSave={handleSubmit}
      handleClose={() => navigate(-1)}
    >
      <FormControlWithError errorText={errors.name} hasError={!!errors?.name}>
        <Text>Заголовок*</Text>

        <Input value={values.name} name="name" onChange={handleChange} autoComplete="off" />
      </FormControlWithError>

      <FormControlWithError errorText={errors.percent} hasError={!!errors?.percent}>
        <Text>Кешбэк*</Text>
        <InputGroup>
          <InputWithSymbol
            value={values.percent}
            error={errors?.percent}
            symbol="%"
            onChange={(percent) => {
              const percentNum = Number(percent);
              if (checkNaN(percentNum)) {
                return;
              }

              setFieldValue("percent", percentNum ?? null);
            }}
            autoComplete="off"
            maxLength={2}
          />
        </InputGroup>
      </FormControlWithError>

      <Grid templateColumns="repeat(2,1fr)" style={{ gap: 16 }}>
        <Flex direction="column">
          <FormControlWithError hasError={errors?.category}>
            <Text>Категория</Text>

            <Autocomplete
              value={values.category}
              placeholder="Выберите категорию"
              onChange={(category) => {
                setFieldValue("category", category);
                setFieldValue("subcategory", null);
              }}
              items={categoriesItems}
              isDisabled={values.brand}
              error={errors?.category}
              style={PRODUCT_INPUT_STYLE}
            />
          </FormControlWithError>
        </Flex>

        <Flex direction="column">
          <FormControlWithError mb="12px" hasError={errors?.subcategory}>
            <Text>Подкатегория</Text>

            <SubCategoriesSelect
              categoryId={values.category?.id}
              errors={errors?.subcategory}
              isDisabled={!values.category || values.brand}
              value={values.subcategory}
              onChange={(subCategory) => setFieldValue("subcategory", subCategory)}
            />
          </FormControlWithError>
        </Flex>
      </Grid>

      <FormControlWithError hasError={errors?.brand}>
        <Text>Бренд</Text>

        <BrandsSelector
          isDisabled={values.category || values.subcategory}
          onChange={(brand) => setFieldValue("brand", brand)}
          error={errors?.brand}
          value={values.brand}
        />
      </FormControlWithError>

      <Text>Товары</Text>
      <BrandProductsSelect
        brand={values.brand}
        extraText={
          errors?.brand && (
            <Text color="red.600">В акции должна быть выбрана хотя бы одна характеристика или товар</Text>
          )
        }
        isSearchAll
        subcategory={values.subcategory}
        category={values.category}
        usePrimaryColorForRetaiItems
        products={values.products}
        onAdd={(product) => setFieldValue("products", [...values.products, product])}
        onDelete={(product) => {
          setFieldValue(
            "products",
            values.products.filter((productState) => productState.id !== product)
          );
        }}
        error={!!errors.products}
      />
    </Drawer>
  );
};
