import { useRef } from "react";
import { Flex, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";

import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import { useIsOverflow } from "../../hooks/hooks";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";
import { checkIsEnterKey } from "../../utils/checkIsEnterKey";

const ProtocolRow = ({ protocol, onEdit, onDelete }) => {
  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);

  const handleEdit = () => {
    onEdit(protocol.id);
  };
  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete(protocol);
  };

  const handleOnEnter = (event) => {
    event.stopPropagation();

    if (checkIsEnterKey(event.key)) {
      handleEdit();
    }
  };

  const handleOnDelete = (event) => {
    event.stopPropagation();

    if (checkIsEnterKey(event.key)) {
      event.preventDefault();
      handleDelete(event);
    }
  };

  return (
    <Tr cursor="pointer" tabIndex={0} borderBottom="solid 1px #EBECEF" onClick={handleEdit} onKeyDown={handleOnEnter}>
      <Td color="#434652" style={TEXT_OVERFLOW_STYLE} ref={ref}>
        <TooltipWithContent isOverflow={isOverflow} text={protocol.name} />
      </Td>
      <Td size="sm" w="100px" textAlign="center" onClick={handleDelete} onKeyDown={handleOnDelete} tabIndex={0}>
        <Flex justifyContent="end">
          <IconButton paddingLeft="15px" variant="unstyled" icon={<DeleteIcon />} aria-label="delete" tabIndex={-1} />
        </Flex>
      </Td>
    </Tr>
  );
};

export default ProtocolRow;
