import { requestAxios } from "../api/axiosRequest";

export const getSpecialistPromocodes = ({ limit, page, query }) => {
  return requestAxios.get(`/promocodes/specialist`, {
    params: {
      take: limit,
      skip: limit * (page - 1),
      query: query.searchQuery || undefined,
      endDate: query.endDate || undefined,
      startDate: query.startDate || undefined,
    },
  });
};

export const getReportSpecialistPromocodes = ({ query }) =>
  requestAxios.get(`/reports/promocode`, {
    params: {
      query: query.searchQuery || undefined,
      dateTo: query.dateTo || undefined,
      dateFrom: query.dateFrom || undefined,
    },
  });
