import { useEffect, useMemo, useRef, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tooltip,
  Tr,
  Flex,
  Grid,
  GridItem,
  Box,
  Text,
} from "@chakra-ui/react";

import { getProducts } from "../../services/productService";
import { useApiRequest } from "../../hooks/useApiRequest";
import useDebounce from "../../hooks/useDebounce";
import { PlusIcon } from "../../assets/icon/PlusIcon";
import { TrashIcon } from "../../assets/icon/TrashIcon";
import { SearchIcon } from "../../assets/icon/SearchIcon";

import { useIsOverflow } from "../../hooks/hooks";

import { TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { PRODUCT_TD_STYLE, TEXT_OVERFLOW_STYLE_GRAY } from "../../pages/Protocols/constants";
import styled from "@emotion/styled";
import { FormControlWithError } from "../FormControlWithError/FormControlWithError";

const RETAIL = "RETAIL";

const ScrollableTableContainer = styled(TableContainer)`
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
`;

const defaultBoxShadow = "0px 4px 8px rgba(76, 93, 112, 0.3), 0px 0px 1px rgba(76, 93, 112, 0.3)";

const GRAY_TEXT = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#737680",
};

const BrandProductsSelect = ({
  isSearchAll,
  products,
  availableFor,
  brand,
  brands,
  subcategory,
  error,
  onAdd,
  onDelete,
  isRetail,
  usePrimaryColorForRetaiItems = false,
  selectedProductId,
  category,
  extraText,
  extraInputContent,
}) => {
  const [search, setSearch] = useState("");
  const debounceSearch = useDebounce(search, 500);

  const {
    data: { data },
    isLoading,
    clear,
    request,
  } = useApiRequest(getProducts, { initialData: { data: [] } });

  const productsIds = useMemo(
    () => [selectedProductId, ...products.map(({ id }) => id)],
    [products, selectedProductId]
  );

  const clearData = useMemo(
    () => (data && data.length ? data.filter(({ id }) => !productsIds.includes(id)) : []),
    [data, productsIds]
  );

  const filteredData = useMemo(() => {
    if (availableFor === RETAIL) {
      return clearData.filter(({ isRetailAllowed }) => isRetailAllowed);
    }
    return clearData;
  }, [clearData, availableFor]);

  const filterProducts = useMemo(() => {
    if (availableFor === RETAIL) {
      return products.filter(({ isRetailAllowed }) => isRetailAllowed);
    }
    return products;
  }, [products, availableFor]);

  useEffect(() => {
    const brandRequestString = brand ? brand.id : brands?.map(({ id }) => id).join(",");

    if (!brandRequestString && !isSearchAll) {
      clear({ data: [] });
      return;
    }

    request({
      limit: 10,
      page: 1,
      isReady: true,
      query: debounceSearch,
      brands: brandRequestString,
      subcategories: subcategory?.id,
      categories: category?.id,
      isRetailAllowed: isRetail,
    });
  }, [debounceSearch, brand, brands, isSearchAll, subcategory, request, clear, category, isRetail]);

  return (
    <FormControl>
      <Accordion border="transparent" allowToggle>
        <AccordionItem position="relative" paddingTop="10px" borderRadius={8}>
          {({ isExpanded }) => (
            <>
              <FormControlWithError hasError={!!error} errorText={error?.[0] ?? error}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>

                  <Input
                    padding="8px 12px 8px 40px"
                    autoComplete="off"
                    placeholder="Поиск по товарам"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />

                  <InputRightElement paddingRight="12px">
                    <AccordionButton padding={0} display="flex" _hover="none">
                      <AccordionIcon />
                    </AccordionButton>
                  </InputRightElement>
                </InputGroup>

                {extraInputContent}
              </FormControlWithError>

              <Flex position="relative" width="100%" zIndex={2}>
                <Box
                  width="100%"
                  display="block"
                  position="absolute"
                  borderRadius={8}
                  boxShadow={isExpanded && defaultBoxShadow}
                  backgroundColor="white"
                  bottom={(error && error[0]) || extraInputContent ? "75px" : "65px"}
                >
                  <AccordionPanel display="flex" width="100%" padding={0} pb={4}>
                    <ScrollableTableContainer maxH="300px" width="100%">
                      <Table variant="simple" colorScheme="blackAlpha">
                        <Tbody>
                          {isLoading && (
                            <Tr>
                              <Td>
                                <Spinner />
                              </Td>
                            </Tr>
                          )}
                          {!isLoading && filteredData.length === 0 ? (
                            <Tr>
                              <Td style={PRODUCT_TD_STYLE}>Нет товаров</Td>
                              <Td />
                              <Td />
                            </Tr>
                          ) : (
                            filteredData.map(({ id, name, brand: productBrand, isRetailAllowed }) => (
                              <RenderData
                                key={id}
                                id={id}
                                onClick={onAdd}
                                name={name}
                                productBrand={productBrand}
                                isRetailAllowed={isRetailAllowed}
                                isRetail={isRetail && !isRetailAllowed}
                                isClearData
                                usePrimaryColorForRetaiItems={usePrimaryColorForRetaiItems}
                              />
                            ))
                          )}
                        </Tbody>
                      </Table>
                    </ScrollableTableContainer>
                  </AccordionPanel>
                </Box>
              </Flex>

              <Grid padding="10px 11px" templateColumns="repeat(10, 1fr)">
                <GridItem paddingX="12px" colSpan={6} style={GRAY_TEXT}>
                  Название
                </GridItem>

                <GridItem colSpan={3} paddingX="16px" style={GRAY_TEXT}>
                  Бренд
                </GridItem>
              </Grid>

              <Flex
                top={(error && error[0]) || extraInputContent ? "120px" : "100px"}
                position="absolute"
                width="100%"
                borderBottomRadius={8}
                zIndex={0}
              >
                {filterProducts.length ? (
                  <ScrollableTableContainer maxH="40vh" width="100%">
                    <Table variant="simple" colorScheme="blackAlpha">
                      <Tbody>
                        {filterProducts.map(({ id, name, brand: productBrand, isRetailAllowed }) => (
                          <RenderData
                            key={id}
                            id={id}
                            name={name}
                            onClick={onDelete}
                            productBrand={productBrand}
                            isRetail={isRetail && !isRetailAllowed}
                            usePrimaryColorForRetaiItems={usePrimaryColorForRetaiItems}
                          />
                        ))}
                      </Tbody>
                    </Table>
                  </ScrollableTableContainer>
                ) : (
                  <TableContainer paddingLeft="8px" zIndex={0} width="100%">
                    <Table borderBottomRadius={8} variant="simple" colorScheme="blackAlpha">
                      <Tbody>
                        <Tr>
                          <Td style={PRODUCT_TD_STYLE}>{extraText ?? "Нет товаров"}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
              </Flex>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </FormControl>
  );
};

export default BrandProductsSelect;

const RenderData = ({
  id,
  name,
  productBrand,
  isRetailAllowed,
  onClick,
  isClearData,
  isRetail,
  usePrimaryColorForRetaiItems,
}) => {
  const refName = useRef(null);
  const refBrand = useRef(null);
  const [isOverflowName] = useIsOverflow(refName, false, true);
  const [isOverflowBrand] = useIsOverflow(refBrand, false, true);

  const handleClickIcon = () => {
    if (isClearData) {
      return onClick({ id, name, brand: productBrand, isRetailAllowed });
    }

    return onClick(id);
  };

  return (
    <Tr key={id} height="52px">
      <Td
        ref={refName}
        style={{
          width: "60%",
          ...(isRetail && !usePrimaryColorForRetaiItems ? TEXT_OVERFLOW_STYLE_GRAY : TEXT_OVERFLOW_STYLE),
        }}
      >
        {isOverflowName ? (
          <Tooltip label={name || ""} display="block" color="black" bg="white" placement="bottom-start">
            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{name}</span>
          </Tooltip>
        ) : (
          <span>{name}</span>
        )}
      </Td>
      <Td
        ref={refBrand}
        style={{
          width: "30%",
          maxWidth: "250px",
          ...(isRetail && !usePrimaryColorForRetaiItems ? TEXT_OVERFLOW_STYLE_GRAY : TEXT_OVERFLOW_STYLE),
        }}
      >
        {isOverflowBrand ? (
          <Tooltip
            label={productBrand?.name || " - "}
            color="black"
            display="block"
            bg="white"
            placement="bottom-start"
          >
            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{productBrand?.name || " - "}</span>
          </Tooltip>
        ) : (
          <span>{productBrand?.name || " - "}</span>
        )}
      </Td>

      <Td
        style={{
          width: "40px",
          ...(isRetail && !usePrimaryColorForRetaiItems ? TEXT_OVERFLOW_STYLE_GRAY : {}),
        }}
        p="0"
      >
        <Flex justifyContent="end">
          <IconButton
            w="40px"
            h="40px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            variant="unstyled"
            onClick={handleClickIcon}
            icon={isClearData ? <PlusIcon /> : <TrashIcon />}
            aria-label="add"
          />
        </Flex>
      </Td>
    </Tr>
  );
};
