import { SLICE_NAME } from "./types";

import { LoadingState } from "../../../store/common";

export const getProducts = () => (state) => state[SLICE_NAME].products;
export const getProduct = (state) => state[SLICE_NAME].product;
export const productsIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const productToast = () => (state) => state[SLICE_NAME].toast;
export const toastIsView = () => (state) => state[SLICE_NAME].toast.view;
export const isPending = () => (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
