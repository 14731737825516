import { createSelector } from "@reduxjs/toolkit";
import { LoadingState } from "../../../../store/common";
import { SLICE_NAME } from "./types";

export const getWarehouses = () => (state) => state[SLICE_NAME].warehouses;
export const getWarehouse = (state) => state[SLICE_NAME].warehouse;
export const warehousesIsGetted = () => (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const warehousesIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const warehousesIsLoading = () => (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const warehousesToast = () => (state) => state[SLICE_NAME].toast;
export const toastIsView = () => (state) => state[SLICE_NAME].toast.view;

export const warehousesSelector = (id) =>
  createSelector(getWarehouses(), (warehouses) => warehouses.filter((item) => id !== item.id));
