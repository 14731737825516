/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";
import {
  editingProductThunk,
  getAllProductsThunk,
  getBrandsLatestProduct,
  getProductThunk,
  updateProductThunk,
  addProductMediaThunk,
} from "./Products.thunk";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  products: [],
  product: { productDetail: null, loadingState: LoadingState.IDLE },
};

export const {
  reducer,
  actions: { deleteToast, addToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
    addToast: (state, action) => {
      state.toast = {
        view: true,
        title: "Ошибка",
        description: action.payload.message,
        status: "error",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductThunk.pending, (state) => {
        state.product.loadingState = LoadingState.PENDING;
      })
      .addCase(getProductThunk.rejected, (state, { payload }) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: payload,
          status: "error",
        };

        state.product.loadingState = LoadingState.REJECTED;
      })
      .addCase(getProductThunk.fulfilled, (state, { payload }) => {
        state.product.loadingState = LoadingState.FULFILLED;

        state.product.productDetail = payload;
      })

      .addCase(getAllProductsThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getAllProductsThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };

        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllProductsThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;

        state.products = action.payload;
      })

      .addCase(getBrandsLatestProduct.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getBrandsLatestProduct.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };

        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getBrandsLatestProduct.fulfilled, (state) => {
        state.loadingState = LoadingState.FULFILLED;
      })

      .addCase(editingProductThunk.pending, (state) => {
        state.product.loadingState = LoadingState.PENDING;
      })
      .addCase(editingProductThunk.fulfilled, (state, { payload }) => {
        state.product.loadingState = LoadingState.IDLE;
        state.products.data = state.products.data.map((product) => (product.id === payload.id ? payload : product));

        state.toast = {
          view: true,
          title: "Продукт изменен",
          description: "",
          status: "success",
        };
      })
      .addCase(editingProductThunk.rejected, (state, action) => {
        state.product.loadingState = LoadingState.IDLE;

        state.toast = {
          view: true,
          title: "Oшибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(updateProductThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;

        state.toast = {
          view: true,
          title: action.payload,
          description: "",
          status: "success",
        };
      })
      .addCase(updateProductThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(updateProductThunk.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;

        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(addProductMediaThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка загрузки медиа",
          description: action.payload,
          status: "error",
        };
      });
  },
});
