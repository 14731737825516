import { LoadingState } from "../../../store/common";
import { SLICE_NAME } from "./types";

export const getStories = () => (state) => state[SLICE_NAME].stories;
export const getOneStory = () => (state) => state[SLICE_NAME].story.storyDetails;
export const getOneSlide = () => (state) => state[SLICE_NAME].slide.slideDetails;
export const storyLoadingState = () => (state) => state[SLICE_NAME].story.loadingState;
export const isStoryLoading = () => (state) => state[SLICE_NAME].story.loadingState === LoadingState.PENDING;
export const isStoriesLoading = () => (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const StoryToast = () => (state) => state[SLICE_NAME].toast;
export const toastIsView = () => (state) => state[SLICE_NAME].toast.view;
