import { requestAxios } from "../api/axiosRequest";

const WAREHOUSE_API = `/warehouses`;

export const getWarehouses = () => requestAxios.get(WAREHOUSE_API);

export const addWarehouse = ({ values }) => requestAxios.post(WAREHOUSE_API, values);

export const deleteWarehouse = ({ id }) => requestAxios.delete(`${WAREHOUSE_API}/${id}`);

export const editWarehouse = ({ id, values }) => requestAxios.patch(`${WAREHOUSE_API}/${id}`, values);

export const getWarehouse = (id) => requestAxios.get(`${WAREHOUSE_API}/${id}`);
