import InputMask from "react-input-mask";
import { Input } from "@chakra-ui/react";
import { telMask } from "../../common/constants";

const InputPhoneNumber = ({ value, placeholder, onChange, onKeyDown, ...rest }) => {
  return (
    <Input
      autoComplete="off"
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      as={InputMask}
      mask={telMask}
      maskChar={null}
      {...rest}
    />
  );
};

export default InputPhoneNumber;
