import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import TableRow from "./TableRow";
import { CONTAINER_STYLE } from "./style";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";
import { useSelector } from "react-redux";
import { cashbackIsLoading } from "./store/Cashbacks.selectors";

const TheadColumns = [
  { name: "Заголовок", width: 70 },
  { name: "Кешбэк, %", width: 20 },
  { name: "", width: 10 },
];

const CashbackTable = ({ cashbacks = [], onEdit = () => {}, onDelete }) => {
  const isLoading = useSelector(cashbackIsLoading);

  const cashbackRow = (cashback) => (
    <TableRow
      key={`cashbacks-${cashback.id}`}
      onEdit={() => onEdit(cashback)}
      onDelete={() => onDelete(cashback)}
      {...cashback}
    />
  );

  return (
    <TableConstructor
      isLoading={isLoading}
      notFoundText="Здесь пока нет акций"
      Row={cashbackRow}
      theadItems={TheadColumns}
      items={cashbacks}
      containerStyles={CONTAINER_STYLE}
    />
  );
};

export default CashbackTable;
