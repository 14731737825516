/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { SLICE_NAME } from "./types";

import { LoadingState } from "../../../store/common";
import { checkAdminToken } from "./CheckAdminToken.thunk";
import { loginUser, logoutUser } from "./Login.thunk";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.PENDING,
  isAuth: false,
  user: null,
};

export const {
  reducer,
  actions: { setLogin, setLogout, disableToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setLogin: (state) => {
      state.loadingState = LoadingState.FULFILLED;
      state.isAuth = true;
    },
    setLogout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      state.isAuth = false;
      state.user = null;
      state.loadingState = LoadingState.FULFILLED;
    },
    disableToast: (state) => {
      state.toast = { view: false, title: "", description: "", status: "" };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload.message,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
        state.isAuth = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.isAuth = true;
        state.user = action.payload;
      })
      .addCase(logoutUser.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.user = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload.message,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
        state.isAuth = false;
        state.user = null;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.isAuth = false;
        state.user = null;
        state.user = action.payload;
      })
      .addCase(checkAdminToken.rejected, (state) => {
        state.isAuth = false;

        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(checkAdminToken.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.isAuth = true;
        state.user = action.payload;
      });
  },
});
