import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";
import { Button, Checkbox, Flex, Th } from "@chakra-ui/react";

import SelectedCoursesPopUp from "../SelectedCoursesPopUp";
import DeleteCourseModal from "../DeleteCourseModal";
import { deletingCourse } from "../store/courses/courses.thunk";

import TableRow from "./TableRow";
import { CONTAINER_STYLE } from "./style";
import { useNavigate } from "react-router-dom";
import { courseIsLoading } from "../store/courses/courses.selectors";
import { TableConstructor } from "../../../components/TableConstructor/TableConstructor";
import { COURSES_STATUSES_NUMBERS, COURSES_TABLE_HEADERS } from "../constants";

const CoursesTable = ({ currentTab, courses = [], isAddButtonNeed = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(courseIsLoading);

  const [checkedItems, setCheckedItems] = useState(Map());
  const [deleteCourse, setDeleteCourse] = useState(null);
  const [deleteCourses, setDeleteCourses] = useState(false);

  const isCheckedAll = useMemo(
    () => (courses?.length ? checkedItems.size === courses?.length : false),
    [courses, checkedItems]
  );
  const isIndeterminate = checkedItems.some(Boolean) && !isCheckedAll;

  const handleAddCourse = () => navigate("create");
  const handleEditCourse = useCallback(({ id }) => navigate(id), [navigate]);

  const handleUnselectAll = () => {
    setCheckedItems(checkedItems.clear());
  };
  const handleCheckAll = () => {
    if (isCheckedAll) handleUnselectAll();
    else courses.forEach(({ id, name }) => setCheckedItems((map) => map.set(id, name)));
  };

  const handleSelectCourse = useCallback(
    ({ id, name }, isSelected) =>
      isSelected ? setCheckedItems(checkedItems.delete(id)) : setCheckedItems(checkedItems.set(id, name)),
    [checkedItems]
  );

  const handleDelete = (course) => setDeleteCourse(course);

  const handleDeleteConfirm = ({ id }) => dispatch(deletingCourse({ id }));

  useEffect(() => {
    if (currentTab !== COURSES_STATUSES_NUMBERS.FUTURE_COURSES && checkedItems.size > 0) {
      setCheckedItems(checkedItems.clear());
    }
  }, [currentTab, checkedItems, setCheckedItems]);

  const courseRow = (course) => (
    <TableRow
      key={`course-${course.id}`}
      course={course}
      isSelected={checkedItems.has(course.id)}
      onEdit={handleEditCourse}
      onDelete={handleDelete}
      onSelect={handleSelectCourse}
    />
  );

  return (
    <>
      {isAddButtonNeed && (
        <Flex gap={1} mb="16px">
          <Button w="100%" colorScheme="purple" marginLeft="auto" onClick={handleAddCourse}>
            Добавить курс
          </Button>
        </Flex>
      )}

      <TableConstructor
        Row={courseRow}
        containerStyles={CONTAINER_STYLE}
        isLoading={isLoading}
        items={courses}
        notFoundText="Здесь пока нет курсов"
        theadItems={COURSES_TABLE_HEADERS}
      />

      <DeleteCourseModal course={deleteCourse} onClose={() => setDeleteCourse(null)} onDelete={handleDeleteConfirm} />

      <SelectedCoursesPopUp
        checkedItems={checkedItems}
        onClose={handleUnselectAll}
        onDelete={() => setDeleteCourses(true)}
        isOpen={!!checkedItems?.size}
      />
    </>
  );
};

export default CoursesTable;
