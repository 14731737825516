import { Flex, Grid, Input } from "@chakra-ui/react";
import { PRODUCT_INPUT_STYLE } from "./style";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";

const CurrentProductVolume = ({ volume, currentArticle, onChange, onEnter, error }) => (
  <Grid templateColumns="repeat(2,1fr)" style={{ gap: "15px" }}>
    <FormControlWithError hasError={error} errorText={error?.at()}>
      <Input
        autoComplete="off"
        placeholder="Значение"
        value={volume}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onEnter}
        style={PRODUCT_INPUT_STYLE}
      />
    </FormControlWithError>

    <Flex direction="column">
      <Input isDisabled placeholder="Артикул" value={currentArticle} />
    </Flex>
  </Grid>
);

export default CurrentProductVolume;
