import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";

export const getCashback = () => (state) => state[SLICE_NAME].cashbacks;
export const cashbackIsGetted = () => (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const cashbackIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const cashbackIsLoading = (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;

export const cashbackToast = () => (state) => state[SLICE_NAME].toast;
export const cashbackToastIsView = () => (state) => state[SLICE_NAME].toast.view;
export const getCashbackById = (state) => state[SLICE_NAME].cashback;
