import { useEffect, useState } from "react";
import { Input } from "@chakra-ui/react";
import { timeParse, timeToString } from "../../pages/Cities/utils/timeParse";
import { Container, Error, InputContainer } from "./style";

const InputTime = ({ day, time, onChange, error }) => {
  const [workTime, setWorkTime] = useState(() => timeParse(time));
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    setWorkTime(() => timeParse(time));
  }, [time]);

  const handleChangeTime = (event) => {
    const [key1, key2] = event.currentTarget.name.split(".");
    const { value } = event.currentTarget;
    if (value.length > 2) {
      return;
    }
    if ((key2 === "hours" && Number(value[0]) > 2) || (key2 === "hours" && Number(value) > 23)) {
      return;
    }
    if ((key2 === "minutes" && Number(value[0]) > 5) || (key2 === "minutes" && Number(value) > 59)) {
      return;
    }
    setWorkTime({ ...workTime, [key1]: { ...workTime[key1], [key2]: value } });
  };

  const handleFocus = (event) => {
    event.target.select();
    setFocus(true);
  };

  useEffect(() => {
    onChange(timeToString(workTime), day);
  }, [workTime, day]);

  return (
    <>
      <Container focus={focus} error={error}>
        <InputContainer>
          <Input
            name="from.hours"
            value={workTime.from.hours || ""}
            onChange={handleChangeTime}
            onFocus={handleFocus}
            onBlur={() => setFocus(false)}
            autoComplete="off"
            placeholder="__"
          />
        </InputContainer>
        :
        <InputContainer>
          <Input
            name="from.minutes"
            value={workTime.from.minutes || ""}
            onChange={handleChangeTime}
            onFocus={handleFocus}
            onBlur={() => setFocus(false)}
            autoComplete="off"
            placeholder="__"
          />
        </InputContainer>
        -
        <InputContainer>
          <Input
            name="to.hours"
            value={workTime.to.hours || ""}
            onChange={handleChangeTime}
            onFocus={handleFocus}
            onBlur={() => setFocus(false)}
            autoComplete="off"
            placeholder="__"
          />
        </InputContainer>
        :
        <InputContainer>
          <Input
            name="to.minutes"
            value={workTime.to.minutes || ""}
            onChange={handleChangeTime}
            onFocus={handleFocus}
            onBlur={() => setFocus(false)}
            autoComplete="off"
            placeholder="__"
          />
        </InputContainer>
      </Container>
      <Error>{error}</Error>
    </>
  );
};

export default InputTime;
