import { useEffect, useRef } from "react";

const useUpdateEffect = (effect, deps) => {
  const isFirstMountRef = useRef(true);
  useEffect(() => {
    if (isFirstMountRef.current) {
      isFirstMountRef.current = false;
      return undefined;
    }
    return effect();
  }, deps);
};

export default useUpdateEffect;
