import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import { useDispatch } from "react-redux";

import { deletingProtocolCategory } from "../ProtocolCategories/store/ProtocolCategories.thunk";
import { NAME_STYLES } from "../../common/constants";
import { useSearchParams } from "../../hooks/useSearchParams";

const DeleteCategoryProtocolModal = ({ categoryProtocol, onClose }) => {
  const dispatch = useDispatch();
  const { handleGetSearchFieldValue, handleDeleteSearchParams } = useSearchParams();

  const handleDeleteCity = () => {
    if (categoryProtocol.id === handleGetSearchFieldValue("id")) {
      handleDeleteSearchParams();
    }

    dispatch(deletingProtocolCategory(categoryProtocol.id));
    onClose();
  };

  const overflowScroll = categoryProtocol?.name?.length > 96 ? {} : { overflow: "hidden" };

  return (
    <Modal isCentered isOpen={categoryProtocol} onClose={() => onClose(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody
          style={{ fontSize: "24px", padding: "40px 40px 32px", textAlign: "center", flexDirection: "column" }}
        >
          <p style={{ marginBottom: "32px" }}>Вы действительно хотите удалить категорию протокола</p>
          <p style={{ ...NAME_STYLES, ...overflowScroll }}>{`"${categoryProtocol.name}"?`}</p>
          <p>
            При удалении категории протокола будут БЕЗВОЗВРАТНО удалены все протоколы этой категории, ранее добавленные,
            в данную категорию, продукты перестанут ей принадлежать.
          </p>
        </ModalBody>
        <ModalFooter style={{ display: "flex", gap: "16px", flexDirection: "column", padding: "0 40px 40px" }}>
          <Button w="100%" bgColor="#C06ECC" color="#ffffff" onClick={handleDeleteCity}>
            Удалить
          </Button>
          <Button w="100%" bgColor="#ffffff" color="#C06ECC" onClick={onClose}>
            Отменить удаление
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteCategoryProtocolModal;
