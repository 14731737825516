import { useRef, useCallback } from "react";
import { useToast } from "@chakra-ui/react";
import { CustomViewToast } from "./components/customViewToast";

export const useCustomToast = () => {
  const toast = useToast();
  const toastIdRef = useRef();

  const close = (id) => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current[id]);
    }
  };
  const pushToast = useCallback((props) => {
    const id = `${new Date().getTime()}`;
    toastIdRef.current = {
      ...toastIdRef.current,
      [id]: toast({
        onCloseComplete: props.onCloseComplete,
        position: "top",
        duration: 3000,
        isClosable: true,
        render: () => <CustomViewToast {...props} onClose={() => close(id)} />,
      }),
    };
  }, []);
  return { pushToast };
};
