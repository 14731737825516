import { useRef } from "react";
import { Td, Tooltip } from "@chakra-ui/react";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { useIsOverflow } from "../../hooks/hooks";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";

const CellCity = ({ value }) => {
  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);

  return (
    <Td sx={TEXT_OVERFLOW_STYLE} ref={ref}>
      <TooltipWithContent isOverflow={isOverflow} text={value} />
    </Td>
  );
};

export default CellCity;
