import { useRef } from "react";
import Select, { components } from "react-select";
import { Flex, Text } from "@chakra-ui/react";
import { useScrollIntoViewError } from "../../hooks/useScrollIntoViewError";
import { CUSTOM_STYLES_SELECTOR, getNoOptionText } from "../../common/constants";
import DropdownIndicator from "../DropdownIndicator/DropdownIndicator";
import styled from "@emotion/styled";
import { CrossIcon } from "../../assets/icon/CrossIcon";
import { FormControlWithError } from "../FormControlWithError/FormControlWithError";
import { css } from "@emotion/react";

const ERROR_COLOR = "#E53E3E";

const StyledSelect = styled(Select)`
  .select-selection {
    &__control {
      ${({ isError }) =>
        isError &&
        css`
          border-color: ${ERROR_COLOR};
          box-shadow: 0 0 0 1px ${ERROR_COLOR};
        `}

      &--is-disabled {
        opacity: 0.4;

        ${({ isError }) =>
          isError &&
          css`
            opacity: 1;
            border-color: ${ERROR_COLOR};
            box-shadow: 0 0 0 1px ${ERROR_COLOR};
          `}
      }

      min-height: ${({ height }) => height}px;
      border-radius: ${({ borderRadius }) => borderRadius}px;
    }

    &__indicator {
      cursor: pointer;
      padding-right: 15px;
    }
  }
`;

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <CrossIcon />
  </components.ClearIndicator>
);

const StyledSelectContainer = styled.div`
  border-radius: "6px";
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `};
`;

const Selector = ({
  error,
  onPurposeChange,
  title,
  style,
  isDisabled,
  isSearchable,
  containerStyles,
  borderRadius = 6,
  height = 32,
  ...props
}) => {
  const selectorRef = useRef(null);

  useScrollIntoViewError(selectorRef, error);

  return (
    <Flex direction="column" sx={containerStyles} ref={selectorRef}>
      <FormControlWithError isDisabled={isDisabled} hasError={!!error} errorText={error}>
        <Text>{title}</Text>
        <StyledSelectContainer isDisabled={isDisabled}>
          <StyledSelect
            noOptionsMessage={getNoOptionText}
            classNamePrefix="select-selection"
            onChange={onPurposeChange}
            borderRadius={borderRadius}
            height={height}
            isError={error}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            styles={CUSTOM_STYLES_SELECTOR}
            components={{
              ClearIndicator,
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }}
            isClearable={props.value || props.defaultValue}
            {...props}
          />
        </StyledSelectContainer>
      </FormControlWithError>
    </Flex>
  );
};

export default Selector;
