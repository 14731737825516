import { Boxberry } from "../../../assets/icon/Boxberry";
import { CDEK } from "../../../assets/icon/CDEK";
import { Filara } from "../../../assets/icon/Filara";
import { FivePost } from "../../../assets/icon/FivePost";

export const serviceDelivery = {
  FILARA: "Filara",
  FIVE_POST: "5 post",
  BOXBERRY: "Boxberry",
  RUSSIAN_POST: "Почта России",
  CDEK: "CDEK",
};

export const getDeliveryIcon = (type) => {
  switch (type) {
    case "BOXBERRY":
      return <Boxberry />;
    case "FILARA":
      return <Filara />;
    case "CDEK":
      return <CDEK />;
    case "FIVE_POST":
      return <FivePost />;
    default:
      return null;
  }
};
