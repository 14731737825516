import { Text, Flex, Image, Divider, Box } from "@chakra-ui/react";
import { NextFormPartButton } from "./Components/NextFormPartButton";
import { COLORS } from "./constants";
import { StyledFormPartButton } from "./styles";
import pencilIcon from "../../assets/svg/pencil-icon.svg";

export const FormPartsControl = ({
  renderComponentForm,
  isActive,
  formPartNumber,
  nextStepButtonClick,
  previousStepButtonClick,
  stepHeader,
  isReady,
  isLastPart = false,
}) => {
  const isReadyOrSelected = isActive || isReady;

  return (
    <>
      <Flex align="center">
        <StyledFormPartButton
          onClick={() => {
            if (isReady) {
              previousStepButtonClick(formPartNumber);
            }
          }}
          isActive={isActive}
          isReadyOrSelect={isReadyOrSelected}
        >
          {isReady && !isActive ? <Image src={pencilIcon} /> : formPartNumber}
        </StyledFormPartButton>
        <Text
          fontSize="16px"
          fontWeight="600"
          marginLeft="7px"
          color={isReadyOrSelected ? COLORS.ACTIVE_PURPLE : COLORS.DISABLED_GRAY}
        >
          {stepHeader}
        </Text>
      </Flex>

      <Flex>
        <Divider
          orientation="vertical"
          marginRight="30px"
          marginLeft="17px"
          marginTop="10px"
          marginBottom="10px"
          borderColor={isActive || isReady ? "purple.500" : COLORS.DISABLED_GRAY}
          borderWidth="1px"
          height={isActive ? "auto" : "30px"}
        />

        {isActive && (
          <Box marginTop="25px">
            {renderComponentForm}

            {!isLastPart && (
              <NextFormPartButton
                disableCondition={!isReady}
                onButtonClick={() => nextStepButtonClick(formPartNumber)}
              />
            )}
          </Box>
        )}
      </Flex>
    </>
  );
};
