import { Box, Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { CONTAINER_STYLE } from "../Banners/style";
import { useSelector, useDispatch } from "react-redux";
import { promotionIsLoading, getPromotion } from "./store/Promotion.selectors";
import Preloader from "../../components/Preloader";
import { useEffect } from "react";
import { getAllPromotion } from "./store/Promotion.thunk";
import { PromotionTableRow } from "./PromotionTableRow";

const TABLE_HEAD_COLUMNS = [
  { name: "Изображение", width: 20 },
  { name: "Название", width: 20 },
  { name: "Дата окончания", width: 20 },
  { name: "Доступность", width: 20 },
  { name: "", width: 20 },
];

export const PromotionTable = () => {
  const dispatch = useDispatch();
  const promotions = useSelector(getPromotion);
  const isLoading = useSelector(promotionIsLoading);

  useEffect(() => {
    dispatch(getAllPromotion());
  }, []);

  return (
    <Box maxH="calc(100% - 16px)">
      {isLoading && (
        <Flex w="100%" maxH="500px" alignItems="center" justifyContent="center">
          <Preloader />
        </Flex>
      )}

      {!isLoading && (
        <TableContainer sx={CONTAINER_STYLE}>
          <Table variant="simple" colorScheme="gray" size="sm">
            <Thead>
              <Tr>
                {TABLE_HEAD_COLUMNS.map(({ name, width, index }) => (
                  <Th key={index} width={`${width}%`}>
                    {name}
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {promotions?.length > 0 ? (
                promotions.map((promotion) => <PromotionTableRow promotion={promotion} key={promotion.id} />)
              ) : (
                <Tr>
                  <Td colSpan={TABLE_HEAD_COLUMNS.length} textAlign="center" borderBottom="none">
                    Здесь пока нет промоакций
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
