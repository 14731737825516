export const getCountByLink = (link, ordersCount, usersCount) => {
  if (ordersCount && link === "/orders") {
    return ordersCount;
  }
  if (usersCount && link === "/users") {
    return usersCount;
  }

  return 0;
};
