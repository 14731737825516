import { useCallback, useRef } from "react";
import { useIsOverflow } from "../../hooks/hooks";
import { TooltipText } from "../Autocomplete";
import { TooltipWithContent } from "../TooltipWithContent/TooltipWithContent";
import { PlusIcon } from "../../assets/icon/PlusIcon";
import { TrashIcon } from "../../assets/icon/TrashIcon";
import { IconButton, Td, Tr, Flex } from "@chakra-ui/react";

export const RenderBrands = ({ id, name, onClick, isClearData }) => {
  const refName = useRef(null);
  const [isOverflowName] = useIsOverflow(refName, false, true);

  const handleClickIcon = useCallback(() => {
    if (isClearData) {
      return onClick({ id, name });
    }

    return onClick(id);
  }, [id, isClearData, name, onClick]);

  return (
    <Tr key={id} height="52px">
      <Td
        ref={refName}
        style={{
          width: "90%",
        }}
      >
        {isOverflowName ? (
          <TooltipWithContent
            label={name || ""}
            display="block"
            color="black"
            bg="white"
            placement="bottom-start"
            content={
              <TooltipText cursor="default" overflow="hidden" textOverflow="ellipsis">
                {name}
              </TooltipText>
            }
          />
        ) : (
          <TooltipText cursor="default">{name}</TooltipText>
        )}
      </Td>

      <Td
        style={{
          width: "40px",
        }}
        p="0"
      >
        <Flex justifyContent="end">
          <IconButton
            w="40px"
            height="40px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            variant="unstyled"
            onClick={handleClickIcon}
            icon={isClearData ? <PlusIcon /> : <TrashIcon />}
            aria-label="add"
          />
        </Flex>
      </Td>
    </Tr>
  );
};
