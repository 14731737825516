import { Flex, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";

import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import { DEFAULT_DATE_FORMAT } from "../../common/constants";
import { formatDate } from "../Promotion/utils/formatDate";
import { ImageWithLoader } from "../../components/ImageWithLoader/ImageWithLoader";
import { getLifetime } from "./utils/getLifetime";
import { getDeclination } from "../../utils/getDeclination";
import { Status } from "./style";
import { DECLENSION_OF_HOURS, STATUS_NAMES } from "./constants";

const TableRow = ({ title, onDelete, onEdit, thumbnailUrl, startDate, endDate, status, clicksCount, viewsCount }) => {
  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete();
  };

  const handleOnEnter = (event) => {
    event.stopPropagation();

    if (event.key === "Enter") {
      onEdit();
    }
  };

  const handleOnKeyDown = (event) => {
    event.stopPropagation();

    if (event.key === "Enter") {
      event.preventDefault();
      handleDelete(event);
    }
  };

  return (
    <Tr cursor="pointer" onClick={onEdit} onKeyDown={handleOnEnter} tabIndex={0}>
      <Td>
        <span>{formatDate(startDate, DEFAULT_DATE_FORMAT)}</span>
      </Td>

      <Td>
        <a href={thumbnailUrl} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
          <ImageWithLoader
            height="52px"
            width="52px"
            objectFit="cover"
            borderRadius="50%"
            alt={thumbnailUrl}
            src={thumbnailUrl}
          />
        </a>
      </Td>

      <Td>
        <span>{title}</span>
      </Td>

      <Td>
        <Tooltip label="Просмотры истории" color="black" bg="white" placement="bottom-start">
          <span>{viewsCount}</span>
        </Tooltip>
      </Td>

      <Td>
        <Tooltip label="Клики на истории" color="black" bg="white" placement="bottom-start">
          <span>{clicksCount}</span>
        </Tooltip>
      </Td>

      <Td>
        <span>{getDeclination(getLifetime(startDate, endDate), DECLENSION_OF_HOURS)}</span>
      </Td>

      <Td>
        <Status color={STATUS_NAMES[status]?.color || "red"}>{STATUS_NAMES[status]?.name || "Неизвестен"}</Status>
      </Td>

      <Td onClick={handleDelete} onKeyDown={handleOnKeyDown} tabIndex={0}>
        <Flex gap="16px" alignItems="center" textAlign="center" justifyContent="end">
          <IconButton w="16px" height="16px" variant="unstyled" icon={<DeleteIcon />} aria-label="Delete" />
        </Flex>
      </Td>
    </Tr>
  );
};
export default TableRow;
