import React, { useEffect, useMemo } from "react";
import Autocomplete from "../../components/Autocomplete";
import { PRODUCT_INPUT_STYLE } from "../Products/style";
import { useDispatch, useSelector } from "react-redux";
import { gettingSubcategories } from "../Categories/store/Subcategories/Subcategories.thunk";
import { getSubcategories } from "../Categories/store/Subcategories/Subcategories.selectors";
import styled from "@emotion/styled";

const StyledFullWidthContainer = styled.div`
  width: 100%;
`;

export const SubCategoriesSelect = ({ isDisabled, onChange, errors, value, categoryId, ...props }) => {
  const dispatch = useDispatch();
  const subcategories = useSelector(getSubcategories());

  useEffect(() => {
    if (!subcategories || subcategories?.length === 0) {
      dispatch(gettingSubcategories());
    }
  }, [dispatch]);

  const currentSubCategories = useMemo(
    () => subcategories.filter((subcategory) => subcategory?.catalog_product?.id === categoryId),
    [categoryId, subcategories]
  );

  return (
    <StyledFullWidthContainer>
      <Autocomplete
        isDisabled={isDisabled || currentSubCategories.length === 0}
        placeholder="Выберите подкатегорию"
        value={value}
        onChange={onChange}
        items={currentSubCategories}
        error={errors}
        style={PRODUCT_INPUT_STYLE}
        {...props}
      />
    </StyledFullWidthContainer>
  );
};
