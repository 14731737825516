import styled from "@emotion/styled";

export const CONTAINER_STYLE = { maxHeight: "calc(100% - 80px)", overflowY: "auto" };

export const TEXT_STYLE = {
  "flex-grow": "1",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#37393D",
  "text-overflow": "ellipsis",
  overflow: "hidden",
  "white-space": "nowrap",
};

export const HINT_STYLE = {
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "20px",
  color: "#737680",
};

export const Status = styled.span`
  padding: 4px 12px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;
