import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer } from "../../components/Drawer/Drawer";
import { useSelector, useDispatch } from "react-redux";
import { getEmployee, getEmployees } from "../Employees/store/Employees.selectors";
import { createEmployeeThunk, getEmployeeThunk, updateEmployeeThunk } from "../Employees/store/Employees.thunk";
import { useFormik } from "formik";
import { USER_ROLES, USER_ROLES_OPTIONS } from "../../common/constants";
import { Button, Input, Text } from "@chakra-ui/react";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";
import InputPhoneNumber from "../../components/InputPhoneNumber/InputPhoneNumber";
import Selector from "../../components/Selector";
import { getCurrentUserState } from "../Login/store/Login.selectors";
import { checkIsRoleAdmin, checkIsRoleManager } from "../../utils/roles";
import { usePrompt } from "../../hooks/usePrompt";
import { EMPLOYEE_VALIDATION_SCHEMA } from "./constants";
import { WarehousesAutocomplete } from "../../components/WarehousesAutocomplete/WarehousesAutocomplete";
import { removeSpaces } from "../../utils/removeSpaces";
import { InputPassword } from "../../components/InputPassword/InputPassword";
import { convertEmployeeFromApi, convertEmployeeToApi } from "./transformData";
import { ADD_TEXT_STYLE } from "../Products/constants";
import { useSearchParams } from "../../hooks/useSearchParams";

const initialValues = {
  email: "",
  name: "",
  lastName: "",
  phone: "",
  role: null,
  warehouse: null,
};

export const EmployeeModal = () => {
  const navigate = useNavigate();
  const employees = useSelector(getEmployees);
  const { role, id: userId } = useSelector(getCurrentUserState);
  const dispatch = useDispatch();
  const { handleChangeSearchParams } = useSearchParams();

  const { id } = useParams();
  const { employeeDetails, loadingState } = useSelector(getEmployee);

  const isCurrentUser = id === userId;

  const handleCloseModal = () => navigate(-1);

  const handleCreateEmployee = (values, _formik, formIsEdit) => {
    if (formIsEdit) {
      const formattedEmployee = convertEmployeeToApi(values);

      if (isCurrentUser) {
        dispatch(updateEmployeeThunk({ values: formattedEmployee, id, isCurrentUser, handleCloseModal }));
      }

      if (!id) {
        dispatch(createEmployeeThunk({ formattedEmployee, handleCloseModal }));
      }

      return;
    }

    handleCloseModal();
  };

  const { values, resetForm, handleChange, setFieldValue, errors, dirty, handleSubmit, isSubmitting } = useFormik({
    initialValues,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: EMPLOYEE_VALIDATION_SCHEMA(
      employees.map((employee) => employee.email),
      isCurrentUser,
      employeeDetails?.phone,
      employeeDetails?.email
    ),
    onSubmit: (formValues, formikBag) => handleCreateEmployee(formValues, formikBag, dirty),
  });

  const shouldAddCity = checkIsRoleManager(values.role?.value);

  useEffect(() => {
    if (id) {
      dispatch(getEmployeeThunk(id));
    }
  }, [id]);

  useEffect(() => {
    if (employeeDetails && id && employeeDetails.id === id) {
      resetForm({
        values: convertEmployeeFromApi(employeeDetails),
      });
    }
  }, [employeeDetails, id]);

  usePrompt(!isSubmitting && dirty);

  const isEditable = useMemo(() => {
    if (checkIsRoleManager(role) && userId === id) {
      return true;
    }

    if (id) {
      return false;
    }

    if (checkIsRoleAdmin(role)) {
      return true;
    }

    return false;
  }, [id, userId, role]);

  const handleChangePassword = () => handleChangeSearchParams({ modal: "changePassword", "modal-id": id });

  return (
    <Drawer
      isLoading={loadingState}
      extraButtonHeader={
        !isEditable && (
          <Button variant="outline" w="100%" onClick={handleCloseModal}>
            Отменить
          </Button>
        )
      }
      extraText={
        !id &&
        removeSpaces(values.email) &&
        `При создании пользователя на почту "${values.email}" отправится письмо с паролем.`
      }
      isOpen
      handleClose={handleCloseModal}
      handleSave={handleSubmit}
    >
      <FormControlWithError isDisabled={!isEditable} title="Имя*" hasError={errors.name} errorText={errors.name}>
        <Input name="name" value={values.name} onChange={handleChange} />
      </FormControlWithError>

      <FormControlWithError
        isDisabled={!isEditable}
        title="Фамилия*"
        hasError={errors.lastName}
        errorText={errors.lastName}
      >
        <Input name="lastName" value={values.lastName} onChange={handleChange} />
      </FormControlWithError>

      <FormControlWithError isDisabled={!isEditable} title="Почта*" hasError={errors.email} errorText={errors.email}>
        <Input name="email" value={values.email} onChange={handleChange} />
      </FormControlWithError>

      <FormControlWithError isDisabled={!isEditable} title="Телефон*" hasError={errors.phone} errorText={errors.phone}>
        <InputPhoneNumber name="phone" value={values.phone} onChange={handleChange} />
      </FormControlWithError>

      <Selector
        title="Должность*"
        error={errors.role}
        isDisabled={id}
        isSearchable={false}
        placeholder="Выберeте должность"
        options={USER_ROLES_OPTIONS}
        height={40}
        value={values.role}
        onPurposeChange={(value) => setFieldValue("role", value)}
      />

      {shouldAddCity && (
        <FormControlWithError
          title="Город*"
          isDisabled={!isEditable}
          hasError={errors?.warehouse}
          errorText={errors.warehouse}
        >
          <WarehousesAutocomplete
            isDisabled={id}
            handleChange={(value) => setFieldValue("warehouse", value)}
            value={values.warehouse}
          />
        </FormControlWithError>
      )}

      {isCurrentUser && checkIsRoleAdmin(role) && (
        <Button
          height="20px"
          marginBottom={5}
          variant="unstyled"
          display="flex"
          align="center"
          onClick={handleChangePassword}
          width="fit-content"
        >
          <Text {...ADD_TEXT_STYLE}>Сменить пароль</Text>
        </Button>
      )}
    </Drawer>
  );
};
