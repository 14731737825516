import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex, FormControl, Heading, Input, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react";

import { setResetPasswordLink } from "../../services/authService";
import { useCustomToast } from "../../hooks/useCustomToast";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [formEmail, setFormEmail] = useState("");
  const { pushToast } = useCustomToast();

  const sendLink = () => {
    setResetPasswordLink(formEmail)
      .then(() => {
        pushToast({
          title: "Успешно",
          status: "success",
          description: "Мы отправили вам письмо.",
          onCloseComplete: () => navigate("/login"),
        });
      })
      .catch(() => {
        pushToast({ title: "Ошибка", description: "Проверьте правильность введенной почты", status: "error" });
      });
  };

  const hangleEmailInput = (text) => {
    setFormEmail(text);
  };

  return (
    <Flex minH="100vh" align="center" justify="center" bg={useColorModeValue("gray.50", "gray.800")}>
      <Stack
        spacing={4}
        w="full"
        maxW="md"
        bg={useColorModeValue("white", "gray.700")}
        rounded="xl"
        boxShadow="lg"
        p={6}
        my={12}
      >
        <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
          Забыли пароль?
        </Heading>
        <Text fontSize={{ base: "sm", sm: "md" }} color={useColorModeValue("gray.800", "gray.400")}>
          Вам будет отправлено письмо с ссылкой для восстановления доступа
        </Text>
        <FormControl id="email">
          <Input
            autoComplete="off"
            onChange={(e) => hangleEmailInput(e.target.value)}
            placeholder="your-email@example.com"
            type="email"
          />
        </FormControl>
        <Stack spacing={6}>
          <Button onClick={sendLink} colorScheme="purple">
            Отправить письмо
          </Button>
          <Link href="/login" color="#C06ECC">
            Вернуться
          </Link>
        </Stack>
      </Stack>
    </Flex>
  );
};

export default ForgotPasswordForm;
