import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import { CONTAINER_STYLE } from "./style";
import RowClientsTable from "./RowClientsTable";
import { TheadColumns } from "../../common/constants";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";
import { useSelector } from "react-redux";
import { isLoading } from "./store/Clients.selectors";

const ClientsTable = ({ users = [], currentTab = 0 }) => {
  const isLoadingUsers = useSelector(isLoading());

  const userRow = (user) => <RowClientsTable key={`approved-user-${user.id}`} user={user} currentTab={currentTab} />;

  return (
    <TableConstructor
      isLoading={isLoadingUsers}
      items={users}
      containerStyles={CONTAINER_STYLE}
      Row={userRow}
      theadItems={TheadColumns}
      notFoundText="Здесь пока нет пользователей"
    />
  );
};

export default ClientsTable;
