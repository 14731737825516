import { useSelector } from "react-redux";
import {
  courseIsUpdated,
  getCourse,
  courseToast as coursesToast,
  toastIsView as coursesToastIsView,
} from "../store/courses/courses.selectors";
import {
  getRequestedCourses,
  requestedCoursesIsUpdated,
  requestedCoursesToast,
  requestedCoursesToastIsView,
} from "../store/CoursesRequest/coursesRequest.selectors";

export const useCoursesSelectors = () => {
  // =========== FUTURE COURSES =============
  const courses = useSelector(getCourse())?.data;
  const totalCourses = useSelector(getCourse())?.count;
  const isCoursesNeedUpdate = useSelector(courseIsUpdated());

  const coursesToastInfo = useSelector(coursesToast());
  const coursesToastIsViewed = useSelector(coursesToastIsView());

  // =========== COURSES REQUEST ============
  const coursesRequest = useSelector(getRequestedCourses());
  const totalCoursesRequest = useSelector(getRequestedCourses());
  const isCoursesRequestNeedUpdate = useSelector(requestedCoursesIsUpdated());

  const coursesRequestToastInfo = useSelector(requestedCoursesToast());
  const coursesRequestToastIsViewed = useSelector(requestedCoursesToastIsView());

  return {
    courses,
    totalCourses,
    isCoursesNeedUpdate,
    coursesToastInfo,
    coursesToastIsViewed,

    coursesRequest,
    totalCoursesRequest,
    isCoursesRequestNeedUpdate,
    coursesRequestToastInfo,
    coursesRequestToastIsViewed,
  };
};
