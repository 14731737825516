import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addCourse,
  deleteCourse,
  editCourse,
  requestOneCourse,
  requestCourses,
  addCourseMedia,
} from "../../../../services/courseService";

import { SLICE_NAME } from "./types";

export const getCourse = createAsyncThunk(`${SLICE_NAME}/fetchCourse`, async (id, { rejectWithValue }) => {
  try {
    const course = await requestOneCourse(id);

    return course.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const getAllCourses = createAsyncThunk(`${SLICE_NAME}/fetchGetCourse`, async (params, { rejectWithValue }) => {
  try {
    const courses = await requestCourses(params);

    return courses?.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const addingCourse = createAsyncThunk(`${SLICE_NAME}/fetchAddCourse`, async (params, { rejectWithValue }) => {
  try {
    return await addCourse(params);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const deletingCourse = createAsyncThunk(
  `${SLICE_NAME}/fetchDeleteCourse`,
  async ({ id }, { rejectWithValue }) => {
    try {
      return await deleteCourse(id);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const editingCourse = createAsyncThunk(`${SLICE_NAME}/fetchEditCourse`, async (params, { rejectWithValue }) => {
  try {
    return await editCourse(params);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const addCourseMediaThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchAddThumbnailMediaThunk`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await addCourseMedia(params);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
