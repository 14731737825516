import { Flex } from "@chakra-ui/react";

const OrderStatusDiv = ({ bg, text, color }) => {
  return (
    <Flex align="center" justify="center" borderRadius="10px" bg={bg} color={color} width="150px" height="40px">
      {text}
    </Flex>
  );
};

export default OrderStatusDiv;
