/* eslint-disable no-param-reassign */
import { createSlice, createAction } from "@reduxjs/toolkit";

import { LoadingState } from "../../../store/common";

import {
  addingNotification,
  deletingNotification,
  editingNotification,
  getNotificationsThunk,
  getNotification,
} from "./Notifications.thunk";

import { SLICE_NAME } from "./types";

const initialToast = { view: false, title: "", description: "", status: "" };

const initialState = {
  toast: initialToast,
  loadingState: LoadingState.IDLE,
  notifications: [],
  notification: { loadingState: LoadingState.IDLE, notification: null },
  count: 0,
};

export const {
  reducer,
  actions: { deleteNotificationsToast, clearEditingPushValues },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteNotificationsToast: (state) => {
      state.toast = initialToast;
    },
    clearEditingPushValues: (state) => {
      state.notification = { loadingState: LoadingState.IDLE, notification: null };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(getNotificationsThunk.rejected, (state, action) => {
        if (action.payload === "Forbidden") {
          return;
        }
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getNotificationsThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.notifications = action.payload.data.data;
        state.count = action.payload.data.count;
      })
      .addCase(getNotification.pending, (state) => {
        state.notification.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(getNotification.rejected, (state, action) => {
        if (action.payload === "Forbidden") {
          return;
        }
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.notification.loadingState = LoadingState.REJECTED;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.notification.loadingState = LoadingState.FULFILLED;
        state.notification.notification = action.payload.data;
      })
      .addCase(addingNotification.rejected, (state, action) => {
        if (action.payload === "Forbidden") {
          return;
        }
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.notification.loadingState = LoadingState.REJECTED;
      })
      .addCase(addingNotification.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
        state.toast = initialToast;
      })
      .addCase(addingNotification.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Уведомление успешно создано",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingNotification.rejected, (state, action) => {
        if (action.payload === "Forbidden") {
          return;
        }
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(deletingNotification.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Уведомление удалено",
          description: "",
          status: "success",
        };
      })
      .addCase(editingNotification.rejected, (state, action) => {
        if (action.payload === "Forbidden") {
          return;
        }

        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };

        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(editingNotification.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Уведомление изменено",
          description: "",
          status: "success",
        };
        state.notification = { loadingState: LoadingState.IDLE, notification: null };
      });
  },
});
