import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  InputGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import Select from "react-select";

import PageLayout from "../../components/PageLayout/PageLayout";
import { Pagination } from "../../components/Pagination";
import InputSearch from "../../components/InputSearch";

import { usePagination } from "../../hooks/usePagination";
import UsersApprovedTable from "./UsersApproved";
import UsersRequestsTable from "./UsersRequests";
import { getUsers, toastIsView, usersIsLoading, usersIsUpdated, userToast } from "./store/Users.selectors";
import {
  getMoreUnreviewedUsersThunk,
  getUnreviewedUsersThunk,
  getUsersThunk,
  updateUserThunk,
} from "./store/Users.thunk";
import { deleteToast } from "./store/Users.slice";
import { useCustomToast } from "../../hooks/useCustomToast";
import { logoutUser } from "../Login/store/Login.thunk";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { checkMobileDevice } from "../../utils/checkMobileDevice";
import { CUSTOM_STYLES_SELECTOR, MAX_HEIGHT_BODY } from "../../common/constants";
import { onlyPhoneNumber } from "../../utils/regexp";
import { InputSearchMobile, InputSearchNotMobile, TabListMobile, TabListNotMobile } from "./style";
import { checkForNumericValue } from "../../utils/checkForNumericValue";
import { useWebsocet } from "../../hooks/useWebsocet";
import { useSearchParams } from "../../hooks/useSearchParams";

const TAB_NAMES = {
  0: "pending",
  1: "accepted",
  2: "rejected",
};

const VALUES_FIR_SELECT_TABLE = [
  { value: 0, label: "Запросы" },
  { value: 1, label: "Подтвержденные" },
  { value: 2, label: "Отклоненные" },
];

const isMobile = checkMobileDevice();

const Users = () => {
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();
  const newUsers = useWebsocet("new-user-request");

  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchError, setSearchError] = useState(null);
  const { handleGetSearchFieldValue, handleChangeSearchParams } = useSearchParams();

  const currentTab = +handleGetSearchFieldValue("currentTab") ?? 0;

  const users = useSelector(getUsers())?.data;
  const total = useSelector(getUsers())?.count;
  const isNeedUsersUpdate = useSelector(usersIsUpdated());
  const isLoading = useSelector(usersIsLoading());
  const toastInfo = useSelector(userToast());
  const toastInViewed = useSelector(toastIsView());

  const { isPrevDisabled, isNextDisabled, page, limit, totalPages, nextPage, prevPage, setPage, setLimit } =
    usePagination({
      total,
    });

  const handleChangeSearch = ({ target: { value } }) => {
    if (searchError) {
      setSearchError(null);
    }

    checkForNumericValue(value, setSearchInput);
  };

  const handleKeyDownSearchQuery = (event) => {
    if (event.key === "Enter") {
      if (searchInput.length < 3 || searchInput.length > 13) {
        setSearchError("Номер должен содержать от 3 до 13 символов");
        return;
      }
      setSearchQuery(searchInput.replace(onlyPhoneNumber, ""));
    }
  };

  const handleClickSearchQuery = () => {
    if (searchInput.length < 3 || searchInput.length > 13) {
      setSearchError("Номер должен содержать от 3 до 13 символов");
      return;
    }
    setSearchQuery(searchInput.replace(onlyPhoneNumber, ""));
  };

  const requestUsersList = useCallback(
    (verificationStatus = TAB_NAMES[0], signal = null) => {
      dispatch(getUsersThunk({ limit, page: page || 1, verificationStatus, query: searchQuery, signal }));
    },
    [limit, page, searchQuery, dispatch]
  );

  const cbResetSearchAndPages = useCallback(() => {
    setPage(1);
    setSearchQuery("");
    setSearchInput("");
  }, [setSearchQuery, setSearchInput, setPage]);

  const handleDelete = () => {
    if (searchInput || searchQuery) {
      setSearchInput("");
      setSearchQuery("");
      setSearchError(null);
    }
  };

  const updateInfoNewUsers = async (id) => {
    // it won't update and mark user as "viewed" without await
    await dispatch(updateUserThunk(id));
    await dispatch(getUnreviewedUsersThunk());

    requestUsersList();
  };

  useUpdateEffect(() => {
    if (page === 1 && currentTab === 0) {
      requestUsersList();
    }
  }, [newUsers]);

  useUpdateEffect(() => {
    if (isNeedUsersUpdate) {
      requestUsersList();
    }
  }, [isNeedUsersUpdate]);

  useEffect(() => {
    dispatch(getUsersThunk({ limit, page: page || 1, verificationStatus: TAB_NAMES[currentTab], query: searchQuery }));
  }, [page, limit, searchQuery, currentTab, dispatch]);

  useEffect(() => {
    if (toastInfo.view) {
      pushToast(toastInfo);
    }
    dispatch(deleteToast());
  }, [toastInViewed]);

  return (
    <PageLayout>
      <Flex sx={!isMobile ? InputSearchNotMobile : InputSearchMobile}>
        <InputGroup sx={!isMobile ? { maxWidth: "320px" } : { width: "100%" }}>
          <FormControl isInvalid={searchError} justifyContent="end">
            <InputSearch
              style={{ border: "1px solid #D0D3DA" }}
              placeholder="Поиск по номеру телефона"
              value={searchInput}
              onChange={handleChangeSearch}
              onKeyDown={handleKeyDownSearchQuery}
              onClick={handleClickSearchQuery}
              onDelete={handleDelete}
            />
            <FormErrorMessage sx={{ whiteSpace: "nowrap", position: "absolute" }}>{searchError}</FormErrorMessage>
          </FormControl>
        </InputGroup>
      </Flex>

      <Tabs
        defaultIndex={currentTab}
        tabIndex={currentTab}
        height="calc(100% - 75px)"
        w="100%"
        onChange={(tab) => {
          cbResetSearchAndPages();
          handleChangeSearchParams({ currentTab: tab });
        }}
      >
        <TabList borderBottom="none" marginBottom="8px">
          <Flex sx={!isMobile ? TabListNotMobile : TabListMobile}>
            {!isMobile && (
              <Flex color="#737680">
                <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Запросы</Tab>
                <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Подтвержденные</Tab>
                <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Отклоненные</Tab>
              </Flex>
            )}

            {isMobile && (
              <div style={{ borderRadius: "8px", width: "100%", marginBottom: "15px" }}>
                <Select
                  placeholder="Выберите назначение"
                  defaultValue={{ value: 0, label: "Запросы" }}
                  onChange={(option) => {
                    cbResetSearchAndPages();
                    handleChangeSearchParams({ currentTab: option.value });
                  }}
                  options={VALUES_FIR_SELECT_TABLE}
                  styles={CUSTOM_STYLES_SELECTOR}
                />
              </div>
            )}

            <Pagination
              totalPages={totalPages}
              page={page}
              limit={limit}
              isPrevDisabled={isPrevDisabled}
              isNextDisabled={isNextDisabled}
              onNextPage={nextPage}
              onPrevPage={prevPage}
              onChangePage={setPage}
              onLimitChange={setLimit}
            />
          </Flex>
        </TabList>

        <TabPanels height={MAX_HEIGHT_BODY}>
          <TabPanel height="100%" p="16px 0 0 0">
            <UsersRequestsTable users={users} isLoading={isLoading} markAsViewed={updateInfoNewUsers} />
          </TabPanel>
          <TabPanel height="100%" p="16px 0 0 0">
            <UsersApprovedTable users={users} isLoading={isLoading} />
          </TabPanel>
          <TabPanel height="100%" p="16px 0 0 0">
            <UsersApprovedTable users={users} isLoading={isLoading} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
};
export default Users;
