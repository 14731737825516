import { PromotionViewTableRow } from "./PromotionViewTableRow";
import { PromotionEditTableRow } from "./PromotionEditTableRow";
import { useDisclosure } from "@chakra-ui/react";

export const PromotionTableRow = ({ promotion }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return isOpen ? (
    <PromotionEditTableRow promotion={promotion} handleCancel={onClose} />
  ) : (
    <PromotionViewTableRow promotion={promotion} handleOpenEditMode={onOpen} />
  );
};
