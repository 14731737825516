/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../store/common";

import { addingCashback, deletingCashback, editingCashback, getAllCashbacklist, getCashback } from "./Cashbacks.thunk";

import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  cashbacks: [],
  cashback: { loadingState: LoadingState.IDLE, cashbackDetails: null },
};

export const {
  reducer,
  actions: { deleteCashbackToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteCashbackToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCashback.pending, (state) => {
        state.cashback.loadingState = LoadingState.PENDING;
      })
      .addCase(getCashback.fulfilled, (state, action) => {
        state.cashback.cashbackDetails = action.payload.data;
        state.cashback.loadingState = LoadingState.FULFILLED;
      })
      .addCase(getCashback.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.cashback.cashbackDetails = null;
        state.cashback.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllCashbacklist.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getAllCashbacklist.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllCashbacklist.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.cashbacks = action.payload;
      })
      .addCase(addingCashback.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(addingCashback.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Акция добавлена",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingCashback.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(deletingCashback.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Акция удалена",
          description: "",
          status: "success",
        };
      })
      .addCase(editingCashback.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(editingCashback.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Акция изменена",
          description: "",
          status: "success",
        };
      });
  },
});
