import React from "react";

export const ControlDawn = () => {
  return (
    <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.07993 1.00001H6.91993C7.02263 0.992963 7.12516 1.01598 7.21499 1.06624C7.30482 1.1165 7.37807 1.19184 7.4258 1.28304C7.47352 1.37425 7.49365 1.47738 7.48373 1.57984C7.47381 1.6823 7.43427 1.77965 7.36993 1.86001L4.44993 4.80001C4.39363 4.86385 4.32438 4.91498 4.2468 4.95001C4.16921 4.98503 4.08506 5.00314 3.99993 5.00314C3.91481 5.00314 3.83066 4.98503 3.75307 4.95001C3.67549 4.91498 3.60624 4.86385 3.54993 4.80001L0.629935 1.86001C0.5656 1.77965 0.526059 1.6823 0.516139 1.57984C0.506219 1.47738 0.526351 1.37425 0.574075 1.28304C0.621799 1.19184 0.69505 1.1165 0.784881 1.06624C0.874713 1.01598 0.977238 0.992963 1.07993 1.00001Z"
        fill="#818590"
      />
    </svg>
  );
};
