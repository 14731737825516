import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay } from "@chakra-ui/react";
import { NAME_STYLES } from "../../common/constants";

const DeleteCourseModal = ({ course, courses, onClose, onDelete }) => {
  const deleteCoursesInfo = () => (
    <>
      {course ? `"${course.name}"` : courses && `"${courses?.values().next().value}"`}
      {courses?.size > 1 && (
        <>
          <br /> и еще {courses.size - 1}
        </>
      )}
    </>
  );

  const handleDelete = () => {
    onDelete(course || courses);
    onClose();
  };

  const overflowScroll = course?.name?.length > 96 ? {} : { overflow: "hidden" };

  return (
    <Modal isCentered isOpen={course || courses} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody
          style={{ fontSize: "24px", padding: "40px 40px 32px", textAlign: "center", flexDirection: "column" }}
        >
          <p style={{ color: "#000000", marginBottom: "32px" }}>Вы действительно хотите удалить семинар</p>
          <p style={{ ...NAME_STYLES, ...overflowScroll }}>{deleteCoursesInfo()}?</p>
        </ModalBody>
        <ModalFooter style={{ display: "flex", gap: "16px", flexDirection: "column", padding: "0 40px 40px" }}>
          <Button w="100%" bgColor="#C06ECC" color="#ffffff" onClick={handleDelete}>
            Удалить
          </Button>
          <Button w="100%" bgColor="#ffffff" color="#C06ECC" onClick={onClose}>
            Отменить удаление
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteCourseModal;
