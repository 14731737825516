import { ChangePasswordModal } from "../components/ChangePasswordModal/ChangePasswordModal";
import { GET_ENUMS, GET_PARAMS, useSearchModal } from "../hooks/useSearchModal";

const MODALS = {
  [GET_ENUMS.modals.changePassword]: ChangePasswordModal,
};

export const GetParameterModal = () => {
  const { isOpened, modalId, modalName } = useSearchModal(GET_PARAMS.modal);

  const Component = MODALS[modalName];

  if (!Component) {
    return null;
  }

  return <Component isOpened={isOpened} id={modalId} />;
};
