import { useDispatch, useSelector } from "react-redux";
import { Flex, FormControl, FormErrorMessage, Grid, Input, InputGroup, Stack, Text } from "@chakra-ui/react";
import { isEqual } from "lodash";

import InputPhoneNumber from "../../components/InputPhoneNumber/InputPhoneNumber";
import InputTime from "../../components/InputTime/InputTime";
import CheckboxWeekend from "../../components/CheckboxWeekend/CheckboxWeekend";
import { addingWarehouse, editingWarehouse, getWarehouseThunk } from "./store/Warehouses/Warehouses.thunk";
import { handleEnterKey } from "../../utils/blurOnEnter";
import { VALIDATION_WAREHOUSE_SCHEMA } from "./constants";
import { DAYS, WEEK_DAYS } from "../../common/constants";
import { convertWarehouseToApi, transformOdlWarehouse } from "./utils/transformData";
import { getWarehouse, warehousesSelector } from "./store/Warehouses/Warehouse.selectors";
import { useNavigate, useParams } from "react-router-dom";
import { getCities } from "./store/Cities/Cities.selectors";
import { Drawer } from "../../components/Drawer/Drawer";
import { useFormik } from "formik";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";
import { useEffect } from "react";
import { usePrompt } from "../../hooks/usePrompt";

const initialValues = {
  city: "",
  address: "",
  phone: "",
  citySlug: "",
  timetable: null,
};

const CITY_CONSIST = "Город с таким адресом уже существует!";

const WarehouseModal = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const warehouseList = useSelector(warehousesSelector(id));

  const { loadingState, warehouseDetail: warehouse } = useSelector(getWarehouse);
  const cities = useSelector(getCities()).data;

  const handleSaveWarehouse = (values, formikBag) => {
    const warehouseForSend = convertWarehouseToApi(values);

    if (cities) {
      const isAlreadyExists = cities.some(
        (storedCity) => storedCity.name === values.city && storedCity.address === values.address
      );

      if (isAlreadyExists) {
        formikBag.setFieldError("address", CITY_CONSIST);
        return;
      }
    }

    const objToSend = {
      ...warehouseForSend,
      timetable: warehouseForSend.timetable.reduce((el, acc) => ({ ...acc, ...el }), {}),
    };

    const oldWarehouse = id && transformOdlWarehouse(warehouse);
    if (id && !isEqual(objToSend, oldWarehouse)) {
      dispatch(editingWarehouse({ id, values: objToSend }));
    }

    if (!id) {
      dispatch(addingWarehouse({ values: objToSend }));
    }

    navigate(-1);
  };

  const { handleSubmit, handleChange, setFieldValue, values, errors, resetForm, dirty, isSubmitting } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_WAREHOUSE_SCHEMA(warehouseList),
    onSubmit: handleSaveWarehouse,
    validateOnChange: false,
  });

  const handleChangeTime = (time, day) => {
    setFieldValue("timetable", { ...values.timetable, [day]: time });
  };

  const handleWeekendChange = (isChecked, day) =>
    setFieldValue("timetable", { ...values.timetable, [day]: isChecked ? "" : "09:00 - 20:00" });

  const handleClose = () => navigate(-1);

  useEffect(() => {
    if (id) {
      dispatch(getWarehouseThunk(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && warehouse && id === warehouse.id) {
      resetForm({ values: warehouse });
    }
  }, [id, warehouse]);

  usePrompt(dirty && !isSubmitting);

  return (
    <Drawer isLoading={loadingState} handleSave={handleSubmit} isOpen handleClose={handleClose}>
      <FormControlWithError errorText={errors?.city} hasError={!!errors?.city} title="Город*">
        <Input
          placeholder="Введите город"
          value={values.city}
          onChange={handleChange}
          name="city"
          onKeyDown={handleEnterKey}
          autoComplete="off"
        />
      </FormControlWithError>

      <FormControlWithError errorText={errors?.address} hasError={!!errors?.address} title="Адрес*">
        <Input
          placeholder="Введите адрес"
          name="address"
          value={values.address}
          onChange={handleChange}
          onKeyDown={handleEnterKey}
          autoComplete="off"
        />
      </FormControlWithError>

      <FormControlWithError errorText={errors?.phone} hasError={!!errors?.phone} title="Номер телефона*">
        <InputPhoneNumber
          value={values.phone || ""}
          placeholder="Введите номер телефона"
          name="phone"
          onChange={handleChange}
          onKeyDown={handleEnterKey}
        />
      </FormControlWithError>

      <FormControlWithError errorText={errors?.citySlug} hasError={!!errors?.citySlug} title="Отображаемый город">
        <Input
          placeholder="Отображаемый город"
          name="citySlug"
          value={values.citySlug}
          onChange={handleChange}
          onKeyDown={handleEnterKey}
          autoComplete="off"
        />
      </FormControlWithError>

      <FormControlWithError title="Код из 1С">
        <Input readOnly placeholder="Склад не имеет кода из 1С" value={warehouse?.codeFrom1C} />
      </FormControlWithError>

      <FormControl>
        <Text>Время работы</Text>

        {DAYS.map((day, i) => (
          <Stack key={day} spacing={5}>
            <Grid templateColumns="repeat(2,1fr)" style={{ gap: "15px", marginBottom: "12px" }}>
              <Flex direction="column">
                <Input value={WEEK_DAYS[day] || ""} isReadOnly />
              </Flex>
              <Flex direction="column">
                <InputTime
                  time={values.timetable && values.timetable[day]}
                  onChange={handleChangeTime}
                  day={day}
                  error={errors?.timetable?.[day]}
                />
                <CheckboxWeekend
                  title="Выходной"
                  isChecked={values.timetable && values.timetable[day]}
                  onChange={(isChecked) => handleWeekendChange(isChecked, day)}
                />
              </Flex>
            </Grid>
          </Stack>
        ))}
      </FormControl>
    </Drawer>
  );
};

export default WarehouseModal;
