import { useRef } from "react";
import { Flex, Td, Tr } from "@chakra-ui/react";
import { TEXT_OVERFLOW_STYLE, DATE_FORMAT_WITH_TIME } from "../../common/constants";
import { useIsOverflow } from "../../hooks/hooks";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";
import { keyHandler } from "../../utils/keyHandler";
import { formatDate } from "../Promotion/utils/formatDate";
import { getDeliveryIcon } from "./utils/generatePicPupItems";
import getDeliveryType from "../../utils/getDeliveryType";

const CUSTOMER_WIDTH = 300;
const SUM_WIDTH = 200;

const RowOrder = ({
  orderId,
  fullName,
  orderNumber,
  deliveryType,
  isViewedByAdmin,
  date,
  total,
  onSelectOrder,
  isPayed,
  isActual,
  delivery,
}) => {
  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);
  const renderDeliveryType = getDeliveryType(deliveryType);
  const handleSelectOrder = (event) => keyHandler(event.key, () => onSelectOrder(orderId));

  return (
    <Tr
      key={orderId}
      cursor="pointer"
      tabIndex={0}
      onKeyUp={handleSelectOrder}
      onClick={() => onSelectOrder(orderId)}
      sx={{ backgroundColor: !isViewedByAdmin ? "#e7c0df4f" : "" }}
    >
      <Td w={CUSTOMER_WIDTH} style={{ ...TEXT_OVERFLOW_STYLE }} ref={ref}>
        <TooltipWithContent isOverflow={isOverflow} text={fullName} />
      </Td>

      <Td w={CUSTOMER_WIDTH}>{orderNumber}</Td>

      <Td w={CUSTOMER_WIDTH}>
        <Flex gap="2" alignItems="center">
          {getDeliveryIcon(delivery.deliveryService)}
          {renderDeliveryType}
        </Flex>
      </Td>

      <Td w={SUM_WIDTH}>{formatDate(date, DATE_FORMAT_WITH_TIME)}</Td>

      <Td w={SUM_WIDTH}>{total} ₽</Td>

      {isActual && <Td w={SUM_WIDTH}>{isPayed ? "Да" : "Нет"}</Td>}
    </Tr>
  );
};

export default RowOrder;
