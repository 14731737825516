import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { v4 as uuidv4 } from "uuid";
import { Flex, HStack, Input, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";

import { addToast } from "../../pages/Products/store/Products.slice";
import { getTags } from "../../services/tagService";
import useDebounce from "../../hooks/useDebounce";
import { useApiRequest } from "../../hooks/useApiRequest";
import { useKeyPress } from "../../hooks/useKeyPress";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { removeDuplicate } from "../../utils/removeDuplicate";
import { removeSpaces } from "../../utils/removeSpaces";
import { MAX_LENGTH_TAGS, TEXT_STYLE } from "../../common/constants";

const LIMIT_TAGS = 120;

const ItemContainer = styled("ul")({
  position: "absolute",
  width: "100%",
  backgroundColor: "white",
  zIndex: 10,
  borderRadius: "10px",
  border: "1px solid #e2e8f0",
});

const Item = styled("li")(({ isHover }) => ({
  listStyleType: "none",
  padding: "5px 15px",
  borderRadius: "5px",
  backgroundColor: isHover ? "#F7ECF8" : "",
  ...TEXT_STYLE,
}));

const TagInput = ({ tags, onChange }) => {
  const ref = useRef();
  const suggestionRef = useRef();
  const itemRef = useRef();
  const dispatch = useDispatch();

  const [searchTag, setSearchTag] = useState("");
  const debounceSearch = useDebounce(searchTag, 300);

  const [hasTags, setHasTags] = useState(null);

  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);

  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");

  const {
    data: { data },
    request,
  } = useApiRequest(getTags, {
    initialData: {
      data: [],
    },
  });

  useEffect(() => {
    if (data.length && downPress) {
      setCursor((prevState) => (prevState < data.length - 1 ? prevState + 1 : prevState));
    }
  }, [downPress]);

  useEffect(() => {
    if (data.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress]);

  useEffect(() => {
    if (cursor) {
      ref.current.value = data[cursor].name;
    }
  }, [cursor]);

  useEffect(() => {
    if (data.length && hovered) {
      setCursor(data.indexOf(hovered));
    }
  }, [hovered]);

  useEffect(() => {
    setHasTags(data);
  }, [data]);

  useEffect(() => {
    if (!removeSpaces(debounceSearch)) {
      return () => {};
    }
    request(debounceSearch);
    return () => {};
  }, [debounceSearch, request]);

  const handleKeyDown = (e) => {
    const arrTags = e.target.value.split(",").filter((item) => item.trim().length !== 0);
    if (e.key === "Enter") {
      const id = uuidv4();
      setHasTags(null);
      setSearchTag("");

      if (tags.find((el) => el.name === e.target.value)) {
        e.target.value = "";
        dispatch(addToast({ message: "Такой тег уже существует" }));
        return;
      }

      if (arrTags.length > 1 && tags.length + arrTags.length < MAX_LENGTH_TAGS) {
        const newArrTags = arrTags.map((tag, i) => ({ name: tag, id: `${Date.now()}${i}` }));
        onChange([...tags, ...removeDuplicate(tags, newArrTags)]);
        e.target.value = "";
        return;
      }

      if (tags.length < MAX_LENGTH_TAGS) {
        if (e.target.value.trim()) {
          onChange([...tags, { name: e.target.value, id }]);
        }
        e.target.value = "";
      }
    } else if (e.key === "Backspace" && !e.target.value) {
      if (tags.length > 0) {
        onChange(tags.slice(0, -1));
      }
    }
  };

  const handleDeleteTag = (id) => {
    onChange(tags.filter((obj) => obj.id !== id));
  };

  const handleClickSuggestion = (tag) => {
    setHasTags(null);
    ref.current.value = "";
    ref.current.blur();
    if (tags.find((item) => item.name === tag)) {
      dispatch(addToast({ message: "Такой тег уже существует" }));
      return;
    }
    const id = uuidv4();
    onChange([...tags, { name: tag, id }]);
  };

  const handleOnChange = (e) => {
    setSearchTag(e.target.value);
    if (!e.target.value) {
      setHasTags(null);
    }
  };

  const handleReset = useCallback(() => {
    ref.current.value = "";
    setHasTags(null);
    setSearchTag("");
  }, []);

  useOnClickOutside([ref, suggestionRef, itemRef], handleReset);

  return (
    <div>
      <Flex
        style={{ position: "relative" }}
        direction="column"
        border="1px solid #D0D3DA"
        borderRadius="8px"
        minHeight="40px"
        onClick={() => ref.current.focus()}
      >
        <HStack spacing={4} style={{ display: "flex", flexWrap: "wrap", margin: "5px" }}>
          {tags &&
            tags.map((tag) => (
              <Tag
                key={tag.id}
                variant="solid"
                style={{
                  backgroundColor: "#F7ECF8",
                  color: "black",
                  marginBottom: "5px",
                  marginInlineStart: "0",
                  marginInlineEnd: "16px",
                  ...TEXT_STYLE,
                }}
              >
                <TagLabel>{tag.name}</TagLabel>
                <TagCloseButton onClick={() => handleDeleteTag(tag.id)} />
              </Tag>
            ))}
          <Input
            autocomplete="off"
            ref={ref}
            style={{ margin: "0" }}
            onKeyDown={handleKeyDown}
            onChange={handleOnChange}
            disabled={tags.length === LIMIT_TAGS}
          />
        </HStack>
      </Flex>
      {hasTags?.length > 0 && (
        <ItemContainer ref={suggestionRef}>
          {hasTags.map(({ name, id }, index) => (
            <Item
              ref={itemRef}
              isHover={index === cursor}
              key={id}
              onClick={() => handleClickSuggestion(name)}
              onMouseEnter={() => {
                ref.current.value = name;
                setCursor(index);
              }}
              onMouseLeave={() => setHovered(undefined)}
            >
              {name}
            </Item>
          ))}
        </ItemContainer>
      )}
    </div>
  );
};

export default TagInput;
