import { useEffect, useMemo, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, FormControl, IconButton, Image, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import { isEqual } from "lodash";

import InputWithSymbol from "../../components/InputWithSymbol";
import { UploadInputGroup } from "../../components/UploadInputGroup/UploadInputGroup";

import BrandProductsSelect from "../../components/BrandProductsSelect";

import { addingBanner, editingBanner, getBannerById, addBannerMediaThunk } from "./store/Banners.thunk";
import { getBannerState, getBanners } from "./store/Banners.selectors";
import { handleEnterKey } from "../../utils/blurOnEnter";
import {
  MAX_LIMIT_SUM,
  PERCENT,
  PRICE,
  RETAIL,
  SPECIALIST,
  STYLES_TEXT_SELECTOR,
  TYPES_DISCOUNT,
  VALIDATION_BANNER_SCHEMA,
} from "./constants";
import Selector from "../../components/Selector";
import { defaultValue } from "./utils/defaultValueForSelector";
import { generateObjForValidate } from "./utils/generateObjValidate";
import { checkNaN } from "../../utils/checkNaN";
import { HINT_STYLE, TEXT_STYLE } from "../../common/constants";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";
import { Drawer } from "../../components/Drawer/Drawer";
import { useFormik } from "formik";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { transformBannerForSend } from "./utils/transformData";
import { usePrompt } from "../../hooks/usePrompt";
import { BrandSelect } from "../../components/BrandSelect/BrandSelect";
import { getAllBrandProducts } from "../../services/productService";

const initialValues = {
  name: "",
  description: "",
  discount: null,
  image: null,
  type: TYPES_DISCOUNT[0],
  products: [],
  brand: null,
};

const EditBannerModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const currentTab = searchParams.get("currentTab") ?? SPECIALIST;

  const { loadingState, bannerDetail: banner } = useSelector(getBannerState);

  const handleSaveBanner = (values) => {
    const transformedBanner = transformBannerForSend({ ...values, type: values.type.value });
    const transformedPrevBanner = banner && transformBannerForSend(banner);
    const isEqualBanner = isEqual(transformedBanner, transformedPrevBanner);

    if (!isEqualBanner) {
      if (id) {
        dispatch(editingBanner({ id, values: transformedBanner }));
      } else {
        dispatch(addingBanner({ ...transformedBanner, availableFor: currentTab }));
      }
    }

    navigate(-1);
  };

  const bannerValidateObj = generateObjForValidate({
    oldName: banner?.name || "",
    oldDescription: banner?.description || "",
    oldImage: banner?.image || "",
    arr: useSelector(getBanners()),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: handleSaveBanner,
    validationSchema: VALIDATION_BANNER_SCHEMA(bannerValidateObj),
  });

  const bannerFileSelectorRef = useRef(null);

  useEffect(() => {
    if (id) {
      dispatch(getBannerById(id));
    }
  }, [id]);

  useEffect(() => {
    if (banner && id === banner.id) {
      const discount = banner.percent ?? banner.price;
      const preparedBanner = { ...banner };

      delete preparedBanner.price;
      delete preparedBanner.percent;

      formik.resetForm({
        values: {
          ...preparedBanner,
          brand: banner.brand ?? null,
          discount,
          type: banner.type === PRICE ? TYPES_DISCOUNT[1] : TYPES_DISCOUNT[0],
        },
      });
    }
  }, [banner, id]);

  const { errors, values, handleChange, setFieldValue, setFieldError } = formik;

  const handleDiscountChange = (value) => {
    setFieldValue("type", value);
    setFieldValue("discount", null);
  };

  const handleChangeDiscount = (maxCount) => (value) => {
    try {
      const discountNum = Number(value);

      if (!checkNaN(discountNum) && discountNum >= 0 && discountNum <= maxCount) {
        setFieldValue("discount", discountNum);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddProducts = (product) => setFieldValue("products", [...values.products, product]);

  const handleAddBrandProducts = async (brand) => {
    try {
      if (!brand) {
        return;
      }

      const brandProductsResponse = await getAllBrandProducts({ brandId: brand.id, availableFor: currentTab });
      const brandProducts = brandProductsResponse.data;

      const newProductArray = [];

      for (let i = 0; i < brandProducts.length; i += 1) {
        if (!formik.values.products.some((product) => product.id === brandProducts[i].id)) {
          newProductArray.push(brandProducts[i]);
        }
      }
      
      setFieldValue("products", [...values.products, ...newProductArray]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteProducts = (i) =>
    setFieldValue(
      "products",
      values.products.filter(({ id: stId }) => stId !== i)
    );

  const handleBannerSelectFile = useCallback(
    (file) => {
      dispatch(addBannerMediaThunk({ file })).then(({ payload }) => {
        setFieldValue("imageId", payload.id);
        setFieldValue("image", payload.url);
      });
    },
    [setFieldValue]
  );

  const isPercent = values.type.value === PERCENT;

  const discountTitle = useMemo(
    () => (values.type.label === "Цена" ? "Цена с учетом скидки*" : "Процент скидки*"),
    [values.type.label]
  );

  const type = defaultValue(banner, currentTab);

  usePrompt(!formik.isSubmitting && formik.dirty);

  return (
    <Drawer isLoading={loadingState} isOpen handleSave={formik.handleSubmit} handleClose={() => navigate(-1)}>
      <FormControlWithError errorText={errors.name} hasError={!!errors?.name}>
        <Text>Заголовок*</Text>
        <Input value={values.name} name="name" onChange={handleChange} onKeyDown={handleEnterKey} autoComplete="off" />
      </FormControlWithError>

      <FormControlWithError errorText={errors.description} hasError={!!errors?.description}>
        <Text>Краткое описание*</Text>
        <Input
          id="description"
          name="description"
          value={values.description}
          onChange={handleChange}
          onKeyDown={handleEnterKey}
          autoComplete="off"
        />
      </FormControlWithError>

      <Selector
        value={values.type}
        isClearable={false}
        title="Тип скидки*"
        error={errors.count}
        height={40}
        onPurposeChange={handleDiscountChange}
        options={TYPES_DISCOUNT}
        placeholder="Выберите тип скидки... "
        style={STYLES_TEXT_SELECTOR}
        isSearchable={false}
      />

      <FormControlWithError hasError={!!errors?.discount} errorText={errors.discount}>
        <Text>{discountTitle}</Text>
        <InputGroup>
          <InputWithSymbol
            value={values.discount}
            error={errors?.discount}
            symbol={isPercent ? "%" : "₽"}
            onChange={isPercent ? handleChangeDiscount(100) : handleChangeDiscount(MAX_LIMIT_SUM)}
            onKeyDown={handleEnterKey}
            autoComplete="off"
          />
        </InputGroup>
      </FormControlWithError>

      <Flex mb="12px" direction="column">
        <FormControl>
          <Text>Доступен для</Text>
          <Input id="description" name="description" value={type.label} isReadOnly isDisabled />
        </FormControl>
      </Flex>

      <Flex mb="12px" direction="column" style={{ gap: "10px" }}>
        <FormControlWithError hasError={!!errors?.image} errorText={errors.image} title="Баннер*">
          <UploadInputGroup
            ref={bannerFileSelectorRef}
            placeholder="Загрузите изображение"
            errorSetter={(errorText) => setFieldError("image", errorText)}
            image={values.image}
            handleSelectFile={handleBannerSelectFile}
          />
        </FormControlWithError>
        <Text style={HINT_STYLE}>Размер баннера 576x320 px PNG, JPG, JPEG</Text>
      </Flex>

      <FormControlWithError hasError={!!errors?.brand} errorText={errors.brand} title="Добавить все товары из бренда:">
        <BrandSelect value="" onChange={handleAddBrandProducts} onDelete={() => null} isNeedToKeepInputEmpty />
      </FormControlWithError>

      <Text>Товары*</Text>
      <BrandProductsSelect
        isSearchAll
        usePrimaryColorForRetaiItems
        products={values.products}
        availableFor={values.type?.value}
        onAdd={handleAddProducts}
        onDelete={handleDeleteProducts}
        error={errors?.products && [errors?.products]}
        isRetail={type.value === RETAIL}
      />
    </Drawer>
  );
};

export default EditBannerModal;
