import { useRef } from "react";
import { Textarea } from "@chakra-ui/react";
import { useScrollIntoViewError } from "../../hooks/useScrollIntoViewError";
import { PRODUCT_INPUT_STYLE } from "../../pages/Products/style";

const DescriptionTextarea = ({ value, error, onChange, placeholder, style, ...props }) => {
  const ref = useRef(null);
  useScrollIntoViewError(ref, error);

  return (
    <Textarea
      ref={ref}
      onChange={(event) => onChange(event.target.value)}
      value={value}
      sx={{ ...PRODUCT_INPUT_STYLE, minHeight: "60px", ...style }}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default DescriptionTextarea;
