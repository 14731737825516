import * as Yup from "yup";
import { getCashbackNames } from "./utils/getCashbacsNames";

const REQUIRED_SOME_ONE_FIELD_MESSAGE = 'Одно из полей: "Товары, Категория, Подкатегория, Бренд" обязательно';

export const CASHBACK_MODAL_SCHEME = Yup.object().shape({
  id: Yup.mixed(),
  name: Yup.string()
    .required('Поле "Название" обязательное')
    .test({
      name: "isUnique",
      message: "Акция с таким заголовком уже существует",
      test: (name, { parent }) => !getCashbackNames(parent?.id).includes(name),
    }),
  percent: Yup.number().nullable().required('Поле "Кэшбек" обязательноe').positive("Кэшбек должeн быть больше нуля"),
  products: Yup.array().test({
    name: "isSomeOneFieldNotEmpty",
    message: REQUIRED_SOME_ONE_FIELD_MESSAGE,
    test: (field, configValidation) => {
      const { category, subcategory, brand } = configValidation.parent;

      return category || subcategory || brand || field.length > 0;
    },
  }),
  category: Yup.mixed().test({
    name: "isSomeOneFieldNotEmpty",
    message: REQUIRED_SOME_ONE_FIELD_MESSAGE,
    test: (field, configValidation) => {
      const { products, subcategory, brand } = configValidation.parent;

      return products.length > 0 || subcategory || brand || field;
    },
  }),
  brand: Yup.mixed().test({
    name: "isSomeOneFieldNotEmpty",
    message: REQUIRED_SOME_ONE_FIELD_MESSAGE,
    test: (field, configValidation) => {
      const { category, products, subcategory } = configValidation.parent;

      return category || products.length > 0 || subcategory || field;
    },
  }),
});
