export const PromocodeIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 10.95H10C9.46957 10.95 8.96086 10.7288 8.58579 10.3349C8.21071 9.9411 8 9.40695 8 8.85C8 8.29305 8.21071 7.7589 8.58579 7.36508C8.96086 6.97125 9.46957 6.75 10 6.75H15C15.2652 6.75 15.5196 6.86062 15.7071 7.05754C15.8946 7.25445 16 7.52152 16 7.8C16 8.07848 16.1054 8.34555 16.2929 8.54246C16.4804 8.73937 16.7348 8.85 17 8.85C17.2652 8.85 17.5196 8.73937 17.7071 8.54246C17.8946 8.34555 18 8.07848 18 7.8C18 6.96457 17.6839 6.16335 17.1213 5.57261C16.5587 4.98187 15.7956 4.65 15 4.65H13V2.55C13 2.27152 12.8946 2.00445 12.7071 1.80754C12.5196 1.61062 12.2652 1.5 12 1.5C11.7348 1.5 11.4804 1.61062 11.2929 1.80754C11.1054 2.00445 11 2.27152 11 2.55V4.65H10C8.93913 4.65 7.92172 5.0925 7.17157 5.88015C6.42143 6.6678 6 7.73609 6 8.85C6 9.96391 6.42143 11.0322 7.17157 11.8198C7.92172 12.6075 8.93913 13.05 10 13.05H14C14.5304 13.05 15.0391 13.2712 15.4142 13.6651C15.7893 14.0589 16 14.593 16 15.15C16 15.707 15.7893 16.2411 15.4142 16.6349C15.0391 17.0287 14.5304 17.25 14 17.25H9C8.73478 17.25 8.48043 17.1394 8.29289 16.9425C8.10536 16.7455 8 16.4785 8 16.2C8 15.9215 7.89464 15.6545 7.70711 15.4575C7.51957 15.2606 7.26522 15.15 7 15.15C6.73478 15.15 6.48043 15.2606 6.29289 15.4575C6.10536 15.6545 6 15.9215 6 16.2C6 17.0354 6.31607 17.8366 6.87868 18.4274C7.44129 19.0181 8.20435 19.35 9 19.35H11V21.45C11 21.7285 11.1054 21.9955 11.2929 22.1925C11.4804 22.3894 11.7348 22.5 12 22.5C12.2652 22.5 12.5196 22.3894 12.7071 22.1925C12.8946 21.9955 13 21.7285 13 21.45V19.35H14C15.0609 19.35 16.0783 18.9075 16.8284 18.1198C17.5786 17.3322 18 16.2639 18 15.15C18 14.0361 17.5786 12.9678 16.8284 12.1802C16.0783 11.3925 15.0609 10.95 14 10.95Z"
        fill={isActive ? "white" : "black"}
      />
    </svg>
  );
};
