import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as DoubleRightIcon } from "../../assets/svg/DoubleRightIcon.svg";

import {
  getProtocolCategories,
  protocolCategoriesIsLoading,
} from "../ProtocolCategories/store/ProtocolCategories.selectors";
import { addingProtocolCategory, editingProtocolCategory } from "../ProtocolCategories/store/ProtocolCategories.thunk";

import { getProtocols } from "./store/Protocol.selectors";
import DeleteCategoryProtocolModal from "./DeleteCategoryProtocolModal";
import DeleteProtocolModal from "./DeleteProtocolModal";
import ProtocolRow from "./ProtocolRow";
import CategoryRow from "./CategoryRow";
import { removeSpaces } from "../../utils/removeSpaces";
import { generateObjForValidate } from "../../utils/generateObjForValidate";
import { useNavigate, useSearchParams } from "react-router-dom";
import qs from "qs";
import Preloader from "../../components/Preloader";

const ProtocolWorkArea = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const protocolCategories = useSelector(getProtocolCategories());
  const isLoading = useSelector(protocolCategoriesIsLoading());
  const protocols = useSelector(getProtocols());

  const [newCategoryProtocolName, setNewCategoryProtocolName] = useState("");
  const [deleteCategoryProtocol, setDeleteCategoryProtocol] = useState(null);

  const selectedCategory = { id: searchParams.get("id"), name: searchParams.get("name") };

  const [categoryEditId, setCategoryEditId] = useState();

  const [deleteProtocol, setDeleteProtocol] = useState(null);

  const [validationErrorCreate, setValidationErrorCreate] = useState(null);
  const [validationErrorUpdate, setValidationErrorUpdate] = useState(null);

  const { names: protocolCategoriesNames } = generateObjForValidate("", useSelector(getProtocolCategories()));

  const protocolsForCategory = useMemo(
    () => protocols.filter(({ protocol_category: { id } }) => id === selectedCategory.id),
    [selectedCategory, protocols]
  );

  // =========== CATEGORY PROTOCOL ==============
  const handleCreateNewCategoryProtocol = () => {
    const name = removeSpaces(newCategoryProtocolName);

    if (protocolCategoriesNames.includes(name)) {
      return setValidationErrorCreate({ name: "Такая категория протокола уже существует" });
    }

    if (name) {
      dispatch(addingProtocolCategory(name)).then(() => {
        setNewCategoryProtocolName("");
      });
    }

    return setValidationErrorCreate(null);
  };

  const handleKeyDownNewCategoryProtocol = (e) => {
    if (e.key === "Enter") {
      handleCreateNewCategoryProtocol();
      e.target.blur();
    }
  };

  const handleSelectCategoryProtocol = (categoryProtocol) => {
    const newSelectedCategody =
      categoryProtocol?.id !== selectedCategory?.id ? { id: categoryProtocol.id, name: categoryProtocol.name } : {};

    setSearchParams(qs.stringify(newSelectedCategody));
  };

  const handleEditCategoryProtocol = (id) => {
    setCategoryEditId(id);
  };

  const handleCloseEditMode = () => {
    setCategoryEditId(null);
    setValidationErrorUpdate(null);
  };

  const handleResetError = () => {
    setValidationErrorUpdate(null);
  };

  const handleUpdateCategoryProtocol = async (id, newName) => {
    const name = removeSpaces(newName);

    if (protocolCategoriesNames.includes(name)) {
      return setValidationErrorUpdate({ name: "Такая категория протокола уже существует" });
    }

    if (name) {
      try {
        await dispatch(editingProtocolCategory({ name, id })).unwrap();
        handleCloseEditMode();
      } catch (e) {
        console.log(e);
      }
    }

    return setValidationErrorUpdate(null);
  };

  const handleDeleteCategoryProtocol = (categoryProtocol) => {
    setDeleteCategoryProtocol(categoryProtocol);
  };

  const handleChangeProtocol = (e) => {
    setValidationErrorCreate(null);
    setNewCategoryProtocolName(e.target.value);
  };

  // ============= PROTOCOL ======================
  const handleEditProtocol = (id) => navigate({ pathname: id, search: searchParams.toString() });

  const handleDeleteProtocol = (protocol) => {
    setDeleteProtocol(protocol);
  };

  return (
    <Box minH="100%" height="100%">
      {deleteCategoryProtocol && (
        <DeleteCategoryProtocolModal
          categoryProtocol={deleteCategoryProtocol}
          onClose={() => setDeleteCategoryProtocol(null)}
        />
      )}

      {deleteProtocol && <DeleteProtocolModal protocol={deleteProtocol} onClose={() => setDeleteProtocol(null)} />}

      <Flex justifyContent="space-between" height="calc(100% - 15px)">
        <Flex w="100%" direction="column" gap="8px">
          <Flex gap="16px" direction="column">
            <FormControl isInvalid={validationErrorCreate?.name}>
              <Input
                autoComplete="off"
                placeholder="Введите название категории"
                value={newCategoryProtocolName}
                onChange={handleChangeProtocol}
                onKeyDown={handleKeyDownNewCategoryProtocol}
              />
              <FormErrorMessage>{validationErrorCreate?.name}</FormErrorMessage>
            </FormControl>

            <Button
              colorScheme="purple"
              w="100%"
              minH="40px"
              height="40px"
              isDisabled={!newCategoryProtocolName.trim()}
              onClick={handleCreateNewCategoryProtocol}
            >
              Добавить категорию протокола
            </Button>
          </Flex>

          {isLoading && <Preloader width={300} />}

          {!isLoading && protocolCategories && (
            <TableContainer w="100%" marginTop="20px" height="100%" overflowY="auto" paddingBottom="24px">
              <Table variant="simple" colorScheme="blackAlpha" size="sm" width="100%">
                <Thead>
                  <Tr
                    height="44px"
                    padding="10px 8px"
                    backgroundColor="#F8F8FA"
                    color="#737680"
                    borderTop="1px solid #EBECEF"
                    borderBottom="1px solid #EBECEF"
                  >
                    <Th>Название категории</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {protocolCategories?.map((row) => (
                    <CategoryRow
                      key={`category-protocol-${row.id}`}
                      isSelecteble
                      row={row}
                      selectedId={selectedCategory.id}
                      editedId={categoryEditId}
                      onSelect={handleSelectCategoryProtocol}
                      onEdit={handleEditCategoryProtocol}
                      onDelete={handleDeleteCategoryProtocol}
                      onUpdate={handleUpdateCategoryProtocol}
                      validationName={validationErrorUpdate}
                      onClose={handleCloseEditMode}
                      resetError={handleResetError}
                    />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}

          {!isLoading && protocolCategories && protocolCategories?.length === 0 && (
            <Text>Здесь пока нет категорий протоколов</Text>
          )}
        </Flex>

        <Flex w="208px" height="inherit" justifyContent="center" alignItems="center">
          <DoubleRightIcon />
        </Flex>
        {selectedCategory.id ? (
          <Flex w="100%" direction="column" gap="28px" marginTop="42px">
            <Button
              colorScheme="purple"
              w="100%"
              minH="40px"
              height="40px"
              mr={3}
              onClick={() => navigate({ pathname: "create", search: searchParams.toString() })}
            >
              Добавить протокол
            </Button>

            {protocolsForCategory?.length > 0 ? (
              <TableContainer w="100%" marginTop="0" height="100%" overflowY="auto" paddingBottom="24px">
                <Table variant="simple" colorScheme="blackAlpha" size="sm">
                  <Thead>
                    <Tr
                      height="44px"
                      padding="10px 8px"
                      backgroundColor="#F8F8FA"
                      color="#737680"
                      borderTop="1px solid #EBECEF"
                      borderBottom="1px solid #EBECEF"
                    >
                      <Th>Название протокола</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {protocolsForCategory.map((protocol) => (
                      <ProtocolRow
                        key={`protocol-${protocol.id}`}
                        protocol={protocol}
                        onEdit={handleEditProtocol}
                        onDelete={handleDeleteProtocol}
                      />
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Text>Здесь пока нет протоколов</Text>
            )}
          </Flex>
        ) : (
          <Flex w="100%" justify="center" alignItems="center">
            <Text fontSize="lg">Выберите категорию протокола</Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default ProtocolWorkArea;
