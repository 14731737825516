/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { createReportSpecialistPromocodesThunk, getSpecialistPromocodesThunk } from "./SpecialistPromocodes.thunk";
import { LoadingState } from "../../../store/common";
import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  specialistPromocodes: [],
  totalPromocodes: 0,
};

export const {
  reducer,
  actions: { deleteToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpecialistPromocodesThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getSpecialistPromocodesThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: "При загрузке данных произошла ошибка, повторите попытку позже",
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getSpecialistPromocodesThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.specialistPromocodes = action.payload.data.data;
        state.totalPromocodes = action.payload.data.count;
      })
      .addCase(createReportSpecialistPromocodesThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(createReportSpecialistPromocodesThunk.rejected, (state) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: "Что-то пошло не так, повторите попытку позже",
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(createReportSpecialistPromocodesThunk.fulfilled, (state) => {
        state.loadingState = LoadingState.FULFILLED;
      });
  },
});
