import { StyledSelect } from "../styles";
import { CustomOption as Option } from "./CustomOption";
import { CustomMultiValue } from "./CustomMultiValue";

const NullComponent = () => {
  return null;
};

const customMultiselectComponents = {
  Option,
  MultiValueRemove: NullComponent,
  ClearIndicator: NullComponent,
  IndicatorSeparator: NullComponent,
  MultiValueContainer: CustomMultiValue,
};

export const CustomMultiSelect = ({
  handleDropdownChange,
  isNeedSelectAllButton,
  value,
  options,
  isDisabled,
  placeholder,
  fieldName,
  selectAllOptions,
  isAllSelected,
}) => {
  const allOption = { value: { payload: "all" }, label: "Все" };

  return (
    <StyledSelect
      isAllSelected={isAllSelected}
      selectAllOptions={selectAllOptions}
      placeholder={placeholder}
      width="270px"
      components={customMultiselectComponents}
      closeMenuOnSelect={false}
      isMulti
      classNamePrefix="select-selection"
      value={value}
      onChange={(eventValue, event) => handleDropdownChange(fieldName, eventValue, event)}
      options={isNeedSelectAllButton ? [allOption, ...options] : options}
      isDisabled={isDisabled}
      isValuesEmpty={value.length === 0}
    />
  );
};
