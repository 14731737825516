export const checkIsUniq = (field, arrayThisFields) => {
  const temp = {};

  [field, ...arrayThisFields].forEach((id) => {
    if (Object.prototype.hasOwnProperty.call(temp, id)) {
      temp[id].push(id);
    } else {
      temp[id] = [];
    }
  });

  return temp[field].length === 1;
};
