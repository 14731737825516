import React, { useEffect, useState, useCallback } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Select from "react-select";
import { ButtonGroup, css, Flex, IconButton, Input, Text } from "@chakra-ui/react";
import DropdownIndicator from "../DropdownIndicator/DropdownIndicator";
import { CUSTOM_STYLES_SELECTOR } from "../../common/constants";
import { checkMobileDevice } from "../../utils/checkMobileDevice";

const defaultLimitOptions = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "50", label: "50" },
];

const fromTextStyles = css`
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #bcbfcc;
  min-width: 60px;
  text-align: start;
`;

const TEXT_STYLE = {
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  color: "#434652",
};

const INPUT_PAGE = { width: "42px", padding: "0", textAlign: "center", fontSize: "14px", height: "32px" };

const isMobile = checkMobileDevice();

export const Pagination = (props) => {
  const {
    page: currentPage,
    totalPages,
    isPrevDisabled,
    isNextDisabled,
    limitOptions = defaultLimitOptions,
    onNextPage,
    onPrevPage,
    onChangePage,
    onLimitChange,
  } = props;

  const [page, setPage] = useState(currentPage);

  const handleLimitChange = useCallback(({ value }) => {
    onLimitChange(value);
  }, []);

  const handleChangePage = (e) => {
    const pageNum = Number(e.target.value);

    if (pageNum > 0 && pageNum <= totalPages) {
      setPage(pageNum);
    } else if (pageNum === 0) {
      setPage(0);
    } else if (pageNum < 0) {
      setPage(null);
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      if (page > 0 && page <= totalPages) {
        onChangePage(page);
        e.target.blur();
      }
    }
  };

  useEffect(() => {
    if (currentPage !== page) {
      setPage(currentPage);
    }
  }, [setPage, currentPage]);

  const pageForDisplay = totalPages === 0 ? 0 : page;

  return (
    <Flex align="center" gap={2}>
      <Flex gap={2} align="center">
        {!isMobile && <Text style={TEXT_STYLE}>Показывать</Text>}
        <Select
          styles={CUSTOM_STYLES_SELECTOR}
          placeholder=""
          isSearchable={false}
          defaultValue={defaultLimitOptions[0]}
          onChange={handleLimitChange}
          options={limitOptions}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
          }}
        />
      </Flex>

      <Flex gap={2} align="center">
        <Text sx={TEXT_STYLE}>{isMobile ? "Стр." : "Страница"}</Text>
        <Input
          sx={INPUT_PAGE}
          value={pageForDisplay}
          max={totalPages}
          isReadOnly={totalPages < 2}
          isDisabled={totalPages < 2}
          onChange={handleChangePage}
          onKeyDown={handleEnterKey}
        />
        <Text css={fromTextStyles} style={{ ...TEXT_STYLE, color: "#BCBFCC" }}>
          из {totalPages}
        </Text>
      </Flex>

      <ButtonGroup size="md" isAttached variant="outline" display="flex" alignItems="center">
        <IconButton
          isDisabled={isPrevDisabled}
          icon={<AiOutlineLeft />}
          onClick={onPrevPage}
          height="32px"
          aria-label="previous page"
        />

        <IconButton
          isDisabled={isNextDisabled}
          icon={<AiOutlineRight />}
          onClick={onNextPage}
          height="32px"
          aria-label="next page"
        />
      </ButtonGroup>
    </Flex>
  );
};
