import { DEFAULT_PURPOSES_RETAIL, DEFAULT_PURPOSES_SPECIALIST, RETAIL, SPECIALIST } from "../constants";

export const defaultValue = (banner, tab) => {
  if (tab === SPECIALIST) {
    return DEFAULT_PURPOSES_SPECIALIST;
  }
  if (tab === RETAIL) {
    return DEFAULT_PURPOSES_RETAIL;
  }

  return {
    value: banner?.availableFor,
    label: `${banner?.availableFor === RETAIL ? "Розничных пользователей" : "Специалистов"}`,
  };
};
