import { formatedDate } from "./convertedDate";

export const transformPromocodeForSend = ({
  products,
  percent,
  category,
  availableFor,
  startDate,
  endDate,
  ...sendPromocode
}) => {
  const defaultFields = {
    name: sendPromocode.name,
    promocode: sendPromocode.promocode,
    percent,
    availableFor: availableFor.value,
    startDate: formatedDate(startDate),
    endDate: formatedDate(endDate),
  };

  if (products.length > 0) {
    return { ...defaultFields, productIds: products?.map(({ id }) => id) };
  }

  if (sendPromocode.brands.length && sendPromocode.subcategory) {
    return {
      ...defaultFields,
      brandIds: sendPromocode.brands?.map(({ id }) => id),
      subcategory: sendPromocode.subcategory.id,
    };
  }

  if (sendPromocode.brands.length) {
    return { ...defaultFields, brandIds: sendPromocode.brands?.map(({ id }) => id) };
  }

  if (sendPromocode.subcategory) {
    return { ...defaultFields, subcategory: sendPromocode.subcategory.id };
  }

  return defaultFields;
};

export const transformOldPromocode = ({
  name,
  promocode,
  subcategory,
  brand,
  products,
  percent,
  startDate,
  endDate,
}) => {
  return {
    name,
    promocode,
    discount: percent,
    subcategory: subcategory?.id,
    brand: brand?.id,
    products,
    startDate,
    endDate,
  };
};
