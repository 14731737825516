import { Flex, FormLabel } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";

const StyledWidthContainer = styled.div`
  width: 100%;
  text-align: start;
`;

export const ClientInfoInputContainer = ({ label, children, hasError, errorText }) => {
  return (
    <Flex width="65%" justifyContent="space-between" alignItems="center">
      <FormLabel minWidth="30%" fontSize="14px" lineHeight="24px" marginBottom="22px">
        {label}
      </FormLabel>

      <StyledWidthContainer>
        <FormControlWithError hasError={hasError} errorText={errorText}>
          {children}
        </FormControlWithError>
      </StyledWidthContainer>
    </Flex>
  );
};
