import { useCallback, useEffect, useMemo, useState } from "react";

export const usePagination = (options) => {
  const { total, initialLimit = 10, initialPage = 1 } = options;
  const [page, setPage] = useState(initialPage);
  const [limit, setLimit] = useState(initialLimit);

  const totalPages = useMemo(() => {
    const calculatedTotalPages = Math.ceil(total / limit);

    if (page > calculatedTotalPages) {
      setPage(calculatedTotalPages || 1);
    }

    return calculatedTotalPages || 0;
  }, [page, total, limit]);

  const isPageValid = page > 0 && page <= totalPages;

  const isNextDisabled = total === 0 || page === totalPages;
  const isPrevDisabled = total === 0 || page === 1;

  const nextPage = useCallback(() => {
    if (!isNextDisabled) {
      setPage((currentPage) => currentPage + 1);
    }
  }, [isNextDisabled]);

  const prevPage = useCallback(() => {
    if (!isPrevDisabled) {
      setPage((currentPage) => currentPage - 1);
    }
  }, [isPrevDisabled]);

  const resetPage = useCallback(() => {
    setPage(1);
  }, []);

  return {
    isPageValid,
    isPrevDisabled,
    isNextDisabled,
    page,
    limit,
    totalPages,
    nextPage,
    prevPage,
    resetPage,
    setLimit,
    setPage,
  };
};
