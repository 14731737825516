export const datePickerConfig = {
  showTimeSelect: true,
  showYearDropdown: true,
  isReadOnly: true,
  scrollableYearDropdown: true,
  timeCaption: "Время:",
  dateFormatCalendar: "MMMM",
  yearDropdownItemNumber: 20,
  timeFormat: "p",
  timeIntervals: 15,
  dateFormat: "dd.MM.yy p",
  isClearable: false,
};
