import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  Flex,
  Grid,
  GridItem,
  Box,
} from "@chakra-ui/react";

import useDebounce from "../../hooks/useDebounce";
import { SearchIcon } from "../../assets/icon/SearchIcon";

import { brandsIsUpdated, getBrands } from "../../pages/Brands/store/Brands.selectors";
import { getAllBrands } from "../../pages/Brands/store/Brands.thunk";

import { PRODUCT_TD_STYLE } from "../../pages/Protocols/constants";
import styled from "@emotion/styled";
import { FormControlWithError } from "../FormControlWithError/FormControlWithError";
import { RenderBrands } from "./RenderBrands";

const ScrollableTableContainer = styled(TableContainer)`
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
`;

const defaultBoxShadow = "0px 4px 8px rgba(76, 93, 112, 0.3), 0px 0px 1px rgba(76, 93, 112, 0.3)";

const GRAY_TEXT = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#737680",
};

const BrandsSelect = ({ brands, onAdd, onDelete }) => {
  const dispatch = useDispatch();

  const isBrandNeedUpdated = useSelector(brandsIsUpdated());
  const allBrands = useSelector(getBrands()).data;

  useEffect(() => {
    if (isBrandNeedUpdated) {
      dispatch(getAllBrands());
    }
  }, [isBrandNeedUpdated, dispatch]);

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const filteredUnselectedBrands = useMemo(() => {

    const selectedBrandsIds = brands.map(({ id }) => id);

    const unselectedBrands = (allBrands || []).filter(({ id }) => !selectedBrandsIds.includes(id));

    return unselectedBrands.filter(({ name }) => name.toLowerCase().includes(debouncedSearch.toLowerCase()));
  }, [allBrands, brands, debouncedSearch]);

  return (
    <FormControl>
      <Accordion border="transparent" allowToggle>
        <AccordionItem position="relative" borderRadius={8} marginBottom={25}>
          {({ isExpanded }) => (
            <>
              <FormControlWithError>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>

                  <Input
                    padding="8px 12px 8px 40px"
                    autoComplete="off"
                    placeholder="Поиск по брендам"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />

                  <InputRightElement paddingRight="12px">
                    <AccordionButton padding={0} display="flex" _hover="none">
                      <AccordionIcon />
                    </AccordionButton>
                  </InputRightElement>
                </InputGroup>
              </FormControlWithError>

              <Flex position="relative" width="100%" zIndex={2}>
                <Box
                  width="100%"
                  display="block"
                  position="absolute"
                  borderRadius={8}
                  boxShadow={isExpanded && defaultBoxShadow}
                  backgroundColor="white"
                  bottom="65px"
                >
                  <AccordionPanel display="flex" width="100%" padding={0} pb={4}>
                    <ScrollableTableContainer maxH="300px" width="100%">
                      <Table variant="simple" colorScheme="blackAlpha">
                        <Tbody>
                          {filteredUnselectedBrands.length === 0 ? (
                            <Tr>
                              <Td style={PRODUCT_TD_STYLE}>Нет брендов</Td>
                              <Td />
                              <Td />
                            </Tr>
                          ) : (
                            filteredUnselectedBrands.map(({ id, name }) => (
                              <RenderBrands key={id} id={id} onClick={onAdd} name={name} isClearData />
                            ))
                          )}
                        </Tbody>
                      </Table>
                    </ScrollableTableContainer>
                  </AccordionPanel>
                </Box>
              </Flex>

              <Grid padding="10px 11px" templateColumns="repeat(10, 1fr)">
                <GridItem paddingX="12px" colSpan={6} style={GRAY_TEXT}>
                  Название
                </GridItem>
              </Grid>

              <Flex top="100px" width="100%" borderBottomRadius={8} zIndex={0}>
                {brands?.length ? (
                  <ScrollableTableContainer maxH="40vh" width="100%">
                    <Table variant="simple" colorScheme="blackAlpha">
                      <Tbody>
                        {brands.map(({ id, name }) => (
                          <RenderBrands key={id} id={id} name={name} onClick={onDelete} />
                        ))}
                      </Tbody>
                    </Table>
                  </ScrollableTableContainer>
                ) : (
                  <TableContainer paddingLeft="8px" zIndex={0} width="100%">
                    <Table borderBottomRadius={8} variant="simple" colorScheme="blackAlpha">
                      <Tbody>
                        <Tr>
                          <Td style={PRODUCT_TD_STYLE}>Бренды не выбраны</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
              </Flex>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </FormControl>
  );
};

export default BrandsSelect;
