import styled from "@emotion/styled";
import PageLayout from "../../PageLayout/PageLayout";
import AddRow from "./AddRow";

const InformationContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "calc(100% - 11px)",
  gap: "24px",
});

const TemplateDefaultContainer = ({ handleCreateEntity, textForAddButton, children }) => {
  return (
    <PageLayout>
      <InformationContainer>
        {handleCreateEntity && <AddRow onAdd={handleCreateEntity} textForAddButton={textForAddButton} />}
        {children}
      </InformationContainer>
    </PageLayout>
  );
};

export default TemplateDefaultContainer;
