import React from "react";

export const ExitIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.375 16.875C15.6722 16.8789 15.9561 16.9987 16.1662 17.2088C16.3763 17.4189 16.4961 17.7028 16.5 18V21C16.5 21.3978 16.342 21.7794 16.0607 22.0607C15.7794 22.342 15.3978 22.5 15 22.5H4.5C4.10218 22.5 3.72064 22.342 3.43934 22.0607C3.15804 21.7794 3 21.3978 3 21V3C3 2.60218 3.15804 2.22064 3.43934 1.93934C3.72064 1.65804 4.10218 1.5 4.5 1.5H15C15.3978 1.5 15.7794 1.65804 16.0607 1.93934C16.342 2.22064 16.5 2.60218 16.5 3V4.875C16.4961 5.17216 16.3763 5.45606 16.1662 5.6662C15.9561 5.87634 15.6722 5.99612 15.375 6C15.0778 5.99612 14.7939 5.87634 14.5838 5.6662C14.3737 5.45606 14.2539 5.17216 14.25 4.875V3.75H5.25V20.25H14.25V18C14.2539 17.7028 14.3737 17.4189 14.5838 17.2088C14.7939 16.9987 15.0778 16.8789 15.375 16.875ZM21 11.625C20.9995 11.452 20.9602 11.2813 20.8849 11.1255C20.8096 10.9697 20.7003 10.8329 20.565 10.725L17.67 7.83C17.4567 7.63128 17.1747 7.5231 16.8832 7.52824C16.5918 7.53338 16.3137 7.65145 16.1076 7.85757C15.9014 8.06369 15.7834 8.34176 15.7782 8.63322C15.7731 8.92467 15.8813 9.20674 16.08 9.42L17.16 10.5H11.88C11.5816 10.5 11.2955 10.6185 11.0845 10.8295C10.8735 11.0405 10.755 11.3266 10.755 11.625C10.755 11.9234 10.8735 12.2095 11.0845 12.4205C11.2955 12.6315 11.5816 12.75 11.88 12.75H17.16L16.08 13.83C15.8693 14.0409 15.751 14.3269 15.751 14.625C15.751 14.9231 15.8693 15.2091 16.08 15.42C16.2909 15.6307 16.5769 15.749 16.875 15.749C17.1731 15.749 17.4591 15.6307 17.67 15.42L20.565 12.525C20.7003 12.4171 20.8096 12.2803 20.8849 12.1245C20.9602 11.9687 20.9995 11.798 21 11.625Z"
        fill="#414348"
      />
    </svg>
  );
};
