export const createCashbackMessage = (categoryObj, subcategoryObj, productList, brandObj) => {
  if (productList?.length) {
    return "Акция применится ко всем продуктам из списка товаров";
  }
  if (brandObj && subcategoryObj && categoryObj) {
    return `Акция применится ко всем продуктам бренда "${brandObj.name}" подкатегории "${subcategoryObj.name}"`;
  }
  if (brandObj) {
    return `Акция применится ко всем продуктам бренда "${brandObj.name}"`;
  }
  if (subcategoryObj && categoryObj) {
    return `Акция применится ко всем продуктам категории "${categoryObj.name}" подкатегории "${subcategoryObj.name}"`;
  }
  if (categoryObj) {
    return `Акция применится ко всем продуктам категории "${categoryObj.name}"`;
  }
  return null;
};
