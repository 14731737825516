import { useLayoutEffect, useState } from "react";

export const useIsOverflow = (ref, isHorizontal = false, isInitialOverflow = false) => {
  const [isOverflow, setIsOverflow] = useState(isInitialOverflow);

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const trigger = () => {
        const hasOverflow = isHorizontal
          ? current.scrollHeight > current.clientHeight
          : current.scrollWidth > current.clientWidth;

        setIsOverflow(hasOverflow);
      };

      if ("ResizeObserver" in window) {
        new ResizeObserver(trigger).observe(current);
      }
    }
  });

  return [isOverflow];
};
