import { Td, Tr, Flex, Text } from "@chakra-ui/react";
import { ReactComponent as BonusIcon } from "../../../assets/svg/BonusIcon.svg";
import {
  BONUS_OPERATION_TYPES,
  BONUS_OPERATION_TYPES_TRANSLATION,
  BONUS_LOG_RESPONSIBILITY_TRANSLATION,
} from "../constants";

export const BonusesTableRow = ({ amount, log_type: type, date, logResponsibility: operationResponsible }) => {
  const isBonusesAdded = type === BONUS_OPERATION_TYPES.add || type === BONUS_OPERATION_TYPES.rollback;

  return (
    <Tr>
      <Td>{BONUS_OPERATION_TYPES_TRANSLATION[type]}</Td>
      <Td color={isBonusesAdded ? "green" : "red"}>
        <Flex>
          {isBonusesAdded ? "+" : "-"}
          {amount}
          <BonusIcon />
        </Flex>
      </Td>
      <Td>{date}</Td>
      <Td>{BONUS_LOG_RESPONSIBILITY_TRANSLATION[operationResponsible]}</Td>
    </Tr>
  );
};
