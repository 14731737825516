import { useRef } from "react";
import { Tooltip } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useIsOverflow } from "../../../hooks/hooks";

const OptionContainer = styled("div")({
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const Option = ({ name }) => {
  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);

  return (
    <OptionContainer ref={ref}>
      {isOverflow ? (
        <Tooltip label={name} color="black" bg="white" placement="bottom-start">
          <span>{name}</span>
        </Tooltip>
      ) : (
        name
      )}
    </OptionContainer>
  );
};

export default Option;
