import { useState } from "react";

import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";

import RowSuggestProducts from "./RowSuggestProducts";
import { SearchIcon } from "../../assets/icon/SearchIcon";
import { PRODUCT_TD_STYLE } from "../Protocols/constants";
import { BiChevronDown } from "react-icons/bi";

export const TableProducts = ({ products, search, onChange, onAdd }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleClickIcon = (itemId) => {
    onAdd(itemId);
  };

  const handleChangeCollapsed = () => {
    setIsCollapsed((prev) => !prev);
  };

  return (
    <Flex direction="column" width="100%">
      <InputGroup position="relative">
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          autoComplete="off"
          placeholder="Поиск по товарам"
          value={search}
          onChange={onChange}
          paddingRight="40px"
        />
        <IconButton
          w="20px"
          icon={<BiChevronDown />}
          bg="transparent"
          _hover={{ bg: "transparent" }}
          position="absolute"
          right="0px"
          zIndex={1}
          onClick={handleChangeCollapsed}
          aria-label="check"
        />
      </InputGroup>

      {!isCollapsed && (
        <TableContainer
          style={{
            maxHeight: products.length > 0 ? "300px" : "120px",
            overflowY: products.length === 0 ? "visible" : "auto",
          }}
        >
          <Table variant="simple" colorScheme="blackAlpha">
            <Thead>
              <Tr>
                <Td style={PRODUCT_TD_STYLE}>Название</Td>
                <Td style={PRODUCT_TD_STYLE}>Бренд</Td>
                <Td />
              </Tr>
            </Thead>

            <Tbody>
              {/* eslint-disable-next-line no-nested-ternary */}
              {products.length === 0 ? (
                <Tr>
                  <Td style={PRODUCT_TD_STYLE}>Нет товаров</Td>
                  <Td />
                  <Td />
                </Tr>
              ) : (
                products.map(({ id, name, brand: productBrand }) => (
                  <RowSuggestProducts
                    key={id}
                    id={id}
                    name={name}
                    productBrand={productBrand}
                    onAdd={handleClickIcon}
                  />
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Flex>
  );
};
