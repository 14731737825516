import React from "react";

export const BrandIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7.06504L12.735 9.45004L13.215 11.04H17.595L15.285 12.81L14.055 13.755L14.505 15.255L15.315 17.895L13.365 16.395L12 15.345L10.635 16.395L8.68504 17.895L9.49504 15.255L9.94504 13.755L8.71504 12.81L6.40504 11.04H10.785L11.265 9.45004L12 7.06504ZM12 1.50004C11.8124 1.49827 11.6293 1.5572 11.4779 1.66803C11.3265 1.77886 11.215 1.93566 11.16 2.11504L9.12004 8.79004H2.38504C2.21945 8.8073 2.06208 8.87092 1.93101 8.9736C1.79995 9.07628 1.70051 9.21385 1.64411 9.3705C1.58772 9.52715 1.57666 9.69654 1.6122 9.8592C1.64774 10.0219 1.72845 10.1712 1.84504 10.29L7.33504 14.595L5.26504 21.375C5.22495 21.5058 5.21625 21.6442 5.23965 21.7789C5.26305 21.9137 5.31788 22.041 5.39971 22.1506C5.48153 22.2602 5.58804 22.3489 5.7106 22.4096C5.83315 22.4704 5.96828 22.5013 6.10504 22.5C6.29429 22.4949 6.47744 22.4321 6.63004 22.32L12 18.18L17.37 22.305C17.5163 22.4304 17.7024 22.4995 17.895 22.5C18.0324 22.4989 18.1677 22.4657 18.29 22.4033C18.4124 22.3408 18.5186 22.2507 18.6001 22.1401C18.6816 22.0294 18.7362 21.9014 18.7596 21.766C18.7831 21.6306 18.7746 21.4916 18.735 21.36L16.665 14.595L22.155 10.365C22.2716 10.2462 22.3523 10.0969 22.3879 9.9342C22.4234 9.77154 22.4124 9.60216 22.356 9.4455C22.2996 9.28885 22.2001 9.15128 22.0691 9.0486C21.938 8.94592 21.7806 8.8823 21.615 8.86504H14.88L12.84 2.11504C12.7851 1.93566 12.6736 1.77886 12.5222 1.66803C12.3708 1.5572 12.1877 1.49827 12 1.50004Z"
        fill={isActive ? "white" : "black"}
      />
    </svg>
  );
};
