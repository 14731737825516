export const StarIcon = ({ isActive = false }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.56494 0.404424L8.93692 4.85625H13.4665C13.5779 4.86776 13.6837 4.91019 13.7719 4.97868C13.86 5.04716 13.9269 5.1389 13.9648 5.24338C14.0027 5.34786 14.0102 5.46083 13.9863 5.56931C13.9624 5.6778 13.9081 5.77739 13.8297 5.85666L10.1374 8.72783L11.5296 13.2397C11.5666 13.3576 11.5646 13.4842 11.524 13.601C11.4835 13.7178 11.4063 13.8187 11.3039 13.8891C11.2015 13.9594 11.0791 13.9954 10.9546 13.992C10.83 13.9886 10.7099 13.9458 10.6116 13.8699L7 11.1188L3.38844 13.8799C3.29012 13.9558 3.16995 13.9986 3.04542 14.002C2.92089 14.0054 2.79851 13.9694 2.69609 13.8991C2.59366 13.8287 2.51654 13.7278 2.47595 13.611C2.43536 13.4942 2.43342 13.3677 2.47042 13.2497L3.86258 8.72783L0.17032 5.90668C0.0919033 5.82741 0.0376251 5.72782 0.0137208 5.61933C-0.0101835 5.51085 -0.00274424 5.39788 0.035184 5.2934C0.0731122 5.18893 0.139991 5.09718 0.228137 5.0287C0.316283 4.96022 0.422122 4.91778 0.533494 4.90627H5.06307L6.43506 0.404424C6.47444 0.286699 6.55021 0.184267 6.65162 0.11167C6.75303 0.0390723 6.87493 0 7 0C7.12507 0 7.24697 0.0390723 7.34838 0.11167C7.44979 0.184267 7.52556 0.286699 7.56494 0.404424Z"
        fill={isActive ? "#FFCC00" : "gray"}
      />
    </svg>
  );
};
