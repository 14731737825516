import { Button, Flex, Icon, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../../components/PageLayout/PageLayout";
import CreateNewCategoryForm from "./CreateCategoryModal";
import DeleteCategory from "./DeleteCategoryModal";
import {
  getProtocolCategories,
  protocolCategoriesIsUpdated,
  protocolCategoryToast,
  toastIsView,
} from "./store/ProtocolCategories.selectors";
import { deleteToast } from "./store/ProtocolCategories.slice";
import { gettingProtocolCategories } from "./store/ProtocolCategories.thunk";
import { useCustomToast } from "../../hooks/useCustomToast";

const ProtCategoryContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "600px",
  gap: "24px",

  ".protocol-category-table-edit-icon": {
    display: "none",
  },

  tr: {
    height: "44px",
    "&:hover .protocol-category-table-edit-icon": {
      display: "block",
    },
  },

  td: {
    border: "none",
  },
});

const ProtocolCategries = () => {
  const [viewCreateCategory, setViewCreateCategory] = useState(false);
  const [openedCategory, setOpenedCategory] = useState(null);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();

  const protocolCategories = useSelector(getProtocolCategories());
  const isNeedUpdated = useSelector(protocolCategoriesIsUpdated());
  const toastIsViewed = useSelector(toastIsView());
  const toastInfo = useSelector(protocolCategoryToast());

  const viewModal = useCallback(
    (category = null) => {
      setOpenedCategory(category);

      setViewCreateCategory(!viewCreateCategory);
    },
    [viewCreateCategory]
  );

  useEffect(() => {
    if (isNeedUpdated === true) {
      dispatch(gettingProtocolCategories());
    }
  }, [isNeedUpdated]);

  useEffect(() => {
    if (toastInfo.view) {
      pushToast(toastInfo);
    }
    dispatch(deleteToast());
  }, [toastIsViewed]);

  return (
    <PageLayout>
      <ProtCategoryContainer>
        {viewCreateCategory && (
          <CreateNewCategoryForm
            viewCreateCategory={viewCreateCategory}
            openedCategory={openedCategory}
            viewModal={viewModal}
          />
        )}

        {deleteCategory && <DeleteCategory deleteCategory={deleteCategory} setDeleteCategory={setDeleteCategory} />}
        <Button colorScheme="blue" w={240} onClick={() => viewModal()} marginLeft="auto">
          Добавить категорию
        </Button>
        <TableContainer w="100%">
          <Table variant="striped" colorScheme="blackAlpha" size="sm">
            <Thead>
              <Tr>
                <Th>Название категории</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {protocolCategories?.map((category) => (
                <Tr key={`protocol-category-${category.id}`} cursor="pointer" onClick={() => viewModal(category)}>
                  <Td>{category.name}</Td>

                  <Td size="sm" w="100px">
                    <Flex gap="16px">
                      <Icon w="24px" height="24px" as={AiOutlineEdit} className="protocol-category-table-edit-icon" />
                      <Icon
                        w="24px"
                        height="24px"
                        as={AiOutlineDelete}
                        className="protocol-category-table-edit-icon"
                        onClick={(event) => {
                          event.stopPropagation();
                          setDeleteCategory(category);
                        }}
                      />
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </ProtCategoryContainer>
    </PageLayout>
  );
};

export default ProtocolCategries;
