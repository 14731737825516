import { requestAxios } from "../api/axiosRequest";

export const getBanners = (availableFor) => {
  return requestAxios.get(`/banners`, {
    params: { availableFor },
  });
};

export const getBanner = (id) => {
  return requestAxios.get(`/banners/${id}`);
};

export const addBanner = (payload) => {
  return requestAxios.post(`/banners`, payload);
};

export const deleteBanner = ({ id }) => {
  return requestAxios.delete(`/banners/${id}`);
};

export const editBanner = async ({ id, values }) => {
  return requestAxios.patch(`/banners/${id}`, values);
};

export const addBannerMedia = (payload) => {
  return requestAxios.post(`/media/image`, payload, { headers: { "Content-Type": "multipart/form-data" } });
};
