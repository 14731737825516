import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";

import logoSvg from "../../assets/svg/logo.svg";

import { isAuthLoading, requestToast } from "./store/Login.selectors";
import { disableToast } from "./store/Login.slice";
import { useCustomToast } from "../../hooks/useCustomToast";
import { loginUser } from "./store/Login.thunk";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";
import { InputPassword } from "../../components/InputPassword/InputPassword";

const validateFunction = (values) => {
  const errors = {};
  if (!values.email.trim()) {
    errors.email = "Поле обязательно";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email.trim())) {
    errors.email = "Некорректный адрес электронной почты";
  }
  if (!values.password) {
    errors.password = "Поле обязательно";
  }
  return errors;
};

const HEAD_TEXT = {
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "24px",
  color: "#37393D",
};

const INPUT_STYLE = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#37393D",
};

const STYLE_BTN = {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
};

const LoginForm = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isAuthLoading());
  const reqToast = useSelector(requestToast());

  const { pushToast } = useCustomToast();

  useEffect(() => {
    if (reqToast.view) {
      pushToast(reqToast);
      dispatch(disableToast());
    }
  }, [reqToast, dispatch]);

  const handleSubmitLoginForm = (values) => {
    const convertedLoginData = { ...values, email: values.email.trim() };

    dispatch(loginUser(convertedLoginData));
  };

  return (
    <Flex minH="100vh" align="center" justify="center" bg={useColorModeValue("gray.50", "gray.800")}>
      <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
        <Stack align="center">
          <img style={{ maxWidth: 439, width: "274px", height: "206px" }} src={logoSvg} alt="Logo" />
        </Stack>

        <Box rounded="lg" bg={useColorModeValue("white", "gray.700")} boxShadow="lg" p={6} maxW="514px" width="100%">
          <Heading fontSize="24px" fontWeight={500} textAlign="start" marginBottom={3} color="#000000">
            Вход в учётную запись
          </Heading>
          <Stack spacing={4}>
            <Formik
              initialValues={{ email: "", password: "" }}
              validate={validateFunction}
              onSubmit={handleSubmitLoginForm}
            >
              {(formProps) => (
                <Form>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControlWithError
                        id="email"
                        hasError={form.errors.email && form.touched.email}
                        errorText={form.errors.email}
                      >
                        <FormLabel style={HEAD_TEXT}>E-mail</FormLabel>
                        <Input
                          {...field}
                          id="email"
                          placeholder="Введите свой e-mail"
                          style={INPUT_STYLE}
                          autoComplete="off"
                        />
                      </FormControlWithError>
                    )}
                  </Field>

                  <Field name="password">
                    {({ field, form }) => (
                      <FormControlWithError
                        id="password"
                        hasError={form.errors.password && form.touched.password}
                        errorText={form.errors.password}
                      >
                        <FormLabel sx={HEAD_TEXT}>Пароль</FormLabel>
                        <InputPassword
                          autoComplete="new-password"
                          {...field}
                          id="password"
                          placeholder="Введите пароль"
                        />
                      </FormControlWithError>
                    )}
                  </Field>

                  <Stack>
                    <Button
                      colorScheme="purple"
                      type="submit"
                      isDisabled={Object.keys(formProps.errors).length || isLoading}
                      isLoading={isLoading}
                      style={STYLE_BTN}
                    >
                      Войти
                    </Button>
                    <Link href="/forgot-password" style={{ ...STYLE_BTN, color: "#C06ECC" }}>
                      Забыли пароль?
                    </Link>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default LoginForm;
