import { FormControl, Input, FormErrorMessage, Text, Button, Grid, Flex, Image } from "@chakra-ui/react";
import { HINT_STYLE } from "../../common/constants";
import { ACHIEVEMENTS_OPTIONS, PROMOTION_VALIDATION_SCHEMA } from "./constants";
import Selector from "../../components/Selector";
import { STYLES_TEXT_SELECTOR } from "../Banners/constants";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { addingPromotion, addPromotionMediaThunk } from "./store/Promotion.thunk";
import { transformData } from "./utils/transformData";
import { DatePicker } from "../../components/DatePicker/DatePicker";
import { useEffect, useRef, useCallback } from "react";
import { UploadInputGroup } from "../../components/UploadInputGroup/UploadInputGroup";

const initialValues = {
  name: "",
  endDate: null,
  image: "",
  availableFor: null,
};

export const CreatePromotion = () => {
  const dispatch = useDispatch();
  const promotionFileLoaderRef = useRef(null);

  const handleSubmitForm = (values, { resetForm, setFieldError }) => {
    dispatch(
      addingPromotion({
        values: transformData(values, true),
        resetForm: () => resetForm({ values: initialValues }),
        setFieldError: (text) => setFieldError("availableFor", text),
      })
    );
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: handleSubmitForm,
    validationSchema: PROMOTION_VALIDATION_SCHEMA,
  });

  const { dirty, errors, handleChange, setFieldValue, handleSubmit, setFieldError, values } = formik;

  const handleCourseSelectFile = useCallback(
    (file) => {
      dispatch(addPromotionMediaThunk({ file })).then(({ payload }) => {
        setFieldValue("image", payload.url);
      });
    },
    [setFieldValue]
  );

  const isDisabledCreate =
    !dirty || !values.availableFor || !values.image.trim() || !values.name.trim() || !values.endDate;

  useEffect(() => {
    if (formik.isSubmitting && errors.image) {
      setFieldValue("image", "");
    }
  }, [formik.isSubmitting, errors.image]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid templateColumns="repeat(5, 1fr)">
        <FormControl isInvalid={!!errors.image}>
          <UploadInputGroup
            image={values.image}
            placeholder="Загрузите изображение"
            errorSetter={(errorText) => setFieldError("image", errorText)}
            ref={promotionFileLoaderRef}
            handleSelectFile={handleCourseSelectFile}
          />
        </FormControl>

        <FormControl paddingLeft="15px" isInvalid={!!errors.name}>
          <Input
            name="name"
            onChange={handleChange}
            placeholder="Введите название промоакции"
            value={values.name}
            autoComplete="off"
          />
        </FormControl>

        <FormControl paddingLeft="15px" isInvalid={!!errors.endDate}>
          <DatePicker
            onChange={(date) => setFieldValue("endDate", date)}
            selected={values.endDate}
            minDate={new Date()}
          />

          <Text sx={{ position: "absolute", top: "40px", whiteSpace: "nowrap", ...HINT_STYLE }}>
            Дата окончания промоакции
          </Text>
        </FormControl>

        <Selector
          height={40}
          containerStyles={{ paddingX: "15px" }}
          placeholder="Выберите доступность"
          error={errors.availableFor}
          value={values.availableFor}
          onPurposeChange={(availableFor) => setFieldValue("availableFor", availableFor)}
          options={ACHIEVEMENTS_OPTIONS}
          style={STYLES_TEXT_SELECTOR}
        />

        <Button isDisabled={isDisabledCreate} type="submit" w="100%" marginLeft="auto" colorScheme="purple">
          Добавить промоакцию
        </Button>
      </Grid>
    </form>
  );
};
