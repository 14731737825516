import { requestAxios } from "../api/axiosRequest";

export const getStories = () => {
  return requestAxios.get(`/stories`);
};

export const getOneStory = (id) => {
  return requestAxios.get(`/stories/${id}`);
};

export const getOneSlide = (id) => {
  return requestAxios.get(`/stories/slides/${id}`);
};

export const addOneSlide = (id) => {
  return requestAxios.get(`/stories/${id}/slides`);
};

export const editStory = ({ id, objToSend }) => {
  return requestAxios.patch(`/stories/${id}`, objToSend);
};

export const addStory = (payload) => {
  return requestAxios.post(`/stories`, payload);
};

export const deleteStory = (id) => {
  return requestAxios.delete(`/stories/${id}`);
};

export const addSlideMedia = (payload) => {
  return requestAxios.post(`/media/image`, payload, { headers: { "Content-Type": "multipart/form-data" } });
};

export const addThumbnailMedia = (payload) => {
  return requestAxios.post(`/media/image`, payload, { headers: { "Content-Type": "multipart/form-data" } });
};
