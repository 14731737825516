import { forIn } from "lodash";
import { EXCLUDED_CHARACTERISTICS } from "../constants";
import { v4 as uuidv4 } from "uuid";

export const convertProductFromApi = ({
  name,
  nameFrom1C,
  description,
  tags,
  similars,
  variations,
  volume,
  characteristics,
  brand,
  purpose,
  catalog_product: categoryProduct,
  isRetailAllowed,
  ...product
}) => ({
  ...product,
  name: name ?? nameFrom1C,
  description: description ?? "",
  tags: tags ?? [],
  similars,
  volume: variations ?? [],
  currentVolume: volume ?? "",
  characteristics: characteristics?.filter(({ key }) => !EXCLUDED_CHARACTERISTICS.find((b) => key === b)) || [],
  brand,
  categoryProduct:
    categoryProduct?.length === 0
      ? [{ category: null, sub_catalog_product: null, tempId: uuidv4() }]
      : categoryProduct.map((category) => ({
          category,
          sub_catalog_product: category.sub_catalog_product,
          tempId: uuidv4(),
        })),
  purpose: purpose ? { value: purpose, label: purpose } : null,
  charCountry: characteristics.find(({ key }) => key === "Страна-производитель")?.value || "",
  charProductCode: characteristics.find(({ key }) => key === "Код товара")?.value || "",
  charSkinType: characteristics.find(({ key }) => key === "Тип кожи")?.value || "",
  charAge: characteristics.find(({ key }) => key === "Возрасты")?.value || "",
  isRetailAllowed,
  nameFrom1C,
});

export const transformOldProduct = ({
  brand,
  characteristics: arr,
  description,
  images,
  name,
  purpose,
  similars,
  sub_catalog_product: subCatalogProduct,
  tags,
  variations,
  catalog_product: catalogProduct,
  volume,
  codeFrom1C,
  isRetailAllowed,
}) => {
  const charCountry = arr.find(({ key }) => key === "Страна-производитель")?.value || "";
  const charProductCode = arr.find(({ key }) => key === "Код товара")?.value || "";
  const charSkinType = arr.find(({ key }) => key === "Тип кожи")?.value || "";
  const charAge = arr.find(({ key }) => key === "Возрасты")?.value || "";
  const characteristics = arr?.filter(({ key }) => !EXCLUDED_CHARACTERISTICS.find((b) => key === b)) || [];

  return {
    codeFrom1C,
    name,
    description,
    images,
    catalog_product: catalogProduct,
    sub_catalog_product: subCatalogProduct,
    purpose,
    brand,
    characteristics: characteristics?.length ? characteristics : undefined,
    charCountry,
    charProductCode,
    charSkinType,
    charAge,
    volume,
    variations,
    tags: tags?.length ? tags : [],
    similars: similars.map(({ id: sId }) => sId),
    isRetailAllowed,
  };
};

export const transformCharacteristics = ({
  charCountry,
  charProductCode,
  charSkinType,
  charAge,
  characteristics,
  ...sendProducts
}) => {
  const country = { key: "Страна-производитель", value: charCountry };
  const productCode = { key: "Код товара", value: charProductCode };
  const skinType = { key: "Тип кожи", value: charSkinType };
  const age = { key: "Возрасты", value: charAge };

  const newCharacteristics = characteristics.map((characteristic) => ({
    key: characteristic.key,
    value: characteristic.value,
  }));

  return {
    ...sendProducts,
    characteristics: [country, productCode, skinType, age, ...newCharacteristics],
  };
};

export const transformProductsForSend = ({ volume, purpose, currentVolume, codeFrom1C, similars, ...sendProducts }) => {
  let product = {};

  forIn(sendProducts, (value, key) => {
    if (typeof element === "string") {
      product = { ...product, [key]: value.trim() };
    } else {
      product = { ...product, [key]: value };
    }
  });

  return {
    ...product,
    brand: sendProducts?.brand?.id,
    similars: similars.map((similar) => similar.id),
    purpose: purpose.value,
    catalog_product: sendProducts?.categoryProduct?.map((catalog) => catalog?.category?.id).filter((el) => !!el),
    sub_catalog_product: sendProducts?.categoryProduct
      ?.map((catalog) => catalog?.sub_catalog_product?.id)
      .filter((el) => !!el),
    variations: [
      { volume: currentVolume.trim(), productCodeFrom1C: codeFrom1C },
      ...(volume?.map(({ id, codeFrom1C: productCodeFrom1C, images, warehousesJSON, ...restVolume }) => ({
        ...restVolume,
        productCodeFrom1C: productCodeFrom1C.trim(),
      })) || []),
    ],
    tags: sendProducts.tags?.map(({ name }) => name),
  };
};
