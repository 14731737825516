import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import PageLayout from "../../components/PageLayout/PageLayout";

import CityAddRow from "./CityAddRow";
import CitiesTable from "./CitiesTable";
import DeleteCityModal from "./DeleteCityModal";
import WarehouseTable from "./WarehouseTable";
import WarehouseModal from "./WarehouseModal";
import { addingCity, deletingCity, editingCity, getAllCities } from "./store/Cities/Cities.thunk";
import { citiesIsUpdated, citiesToast, citiesToastIsView, getCities, isLoading } from "./store/Cities/Cities.selectors";
import { deleteToast as deleteCitiesToast } from "./store/Cities/Cities.slice";
import {
  getWarehouses,
  toastIsView as warehouesToastIsView,
  warehousesIsUpdated,
  warehousesToast,
} from "./store/Warehouses/Warehouse.selectors";
import { deletingWarehouse, editingWarehouse, getAllWarehouses } from "./store/Warehouses/Warehouses.thunk";
import { deleteToast as deleteWarehousesToast } from "./store/Warehouses/Warehouses.slice";
import { useCustomToast } from "../../hooks/useCustomToast";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import DeleteWarehouseModal from "./DeleteWarehouseModal";
import { removeSpaces } from "../../utils/removeSpaces";
import { MAX_HEIGHT_CITY_BODY } from "./style";
import { useNavigate } from "react-router-dom";

const CITIES_TAB = 0;
const WAREHOUSES_TAB = 1;
const ADD_BUTTON = {
  width: "100%",
  marginLeft: "auto",
  marginBottom: "16px",
};

const TAB_STYLES = {
  padding: "12px 4px 8px",
};

const Cities = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pushToast } = useCustomToast();

  const cities = useSelector(getCities())?.data;
  const isNeedCitiesUpdated = useSelector(citiesIsUpdated());
  const warehouses = useSelector(getWarehouses());
  const isNeedWarehousesUpdated = useSelector(warehousesIsUpdated());
  const citiesToastInfo = useSelector(citiesToast());
  const citiesToastIsViewed = useSelector(citiesToastIsView());
  const warehousesToastInfo = useSelector(warehousesToast());
  const warehousesToastIsViewed = useSelector(warehouesToastIsView());

  const inProgress = useSelector(isLoading());

  const [currentTab, setCurrentTab] = useState(0);
  const [deleteCity, setDeleteCity] = useState(null);

  const [cityName, setCityName] = useState("");
  const [address, setAddress] = useState("");
  const [errorNewCity, setErrorNewCity] = useState(null);

  const [editState, setEditState] = useState(null);
  const [deleteWarehouse, setDeleteWarehouse] = useState(null);

  // ============ CITIES ============
  const handleAddCity = () => {
    if (!removeSpaces(cityName) || !removeSpaces(address)) {
      setErrorNewCity("Город/адрес заполнены некорректно");
      return;
    }

    const isInvalid = cities.some((city) => city.name === cityName && city.address === address);
    if (isInvalid) {
      setErrorNewCity("Город с таким адресом уже существует");
      return;
    }
    if (cityName && address) {
      dispatch(addingCity({ name: cityName, address })).then((res) => {
        if (res.error) {
          setErrorNewCity(res.error.message);
          return;
        }
        setCityName("");
        setAddress("");
        setErrorNewCity(null);
      });
    }
  };

  const editFieldState = useMemo(() => {
    return {
      ...editState,
      setId: (id) => setEditState({ ...editState, id }),
      reset: () => setEditState(null),
      setError: (textError) => setEditState({ ...editState, errorMessage: textError }),
    };
  }, [editState]);

  const request = useCallback(async () => {
    await dispatch(getAllCities()).unwrap();
  }, []);

  const handleEditCity = async (payload) => {
    try {
      await dispatch(editingCity(payload)).unwrap();
      editFieldState.reset();
    } catch (e) {
      editFieldState.setError("Город с таким адресом уже существует");
    }
  };

  const handleDeleteCity = (id) => {
    dispatch(deletingCity({ id }));
  };

  // ============ WAREHOUSES - OFFICES ============
  const handleCreate = () => {
    navigate("create");
  };

  const handleDeleteWarehouse = (id) => {
    dispatch(deletingWarehouse({ id }));
  };

  const handleChangeName = (event) => {
    setCityName(event.target.value);
  };

  const handleChangeAddress = (event) => {
    setAddress(event.target.value);
  };

  useEffect(() => {
    if (citiesToastInfo.view) {
      pushToast(citiesToastInfo);
    }
    dispatch(deleteCitiesToast());
  }, [citiesToastIsViewed, dispatch]);

  useEffect(() => {
    if (warehousesToastInfo.view) {
      pushToast(warehousesToastInfo);
    }
    dispatch(deleteWarehousesToast());
  }, [warehousesToastIsViewed, dispatch]);

  useUpdateEffect(() => {
    if (currentTab === CITIES_TAB) dispatch(getAllCities());
    else if (currentTab === WAREHOUSES_TAB) dispatch(getAllWarehouses());
  }, [currentTab, dispatch]);

  useUpdateEffect(() => {
    if (isNeedCitiesUpdated) dispatch(getAllCities());
  }, [isNeedCitiesUpdated, dispatch]);

  useUpdateEffect(() => {
    if (isNeedWarehousesUpdated) dispatch(getAllWarehouses());
  }, [isNeedWarehousesUpdated, dispatch]);

  useEffect(() => {
    request();
  }, [dispatch]);

  return (
    <PageLayout>
      <Tabs tabIndex={currentTab} height="calc(100% - 75px)" w="100%" onChange={setCurrentTab}>
        <TabList borderBottom="none" marginBottom="8px">
          <Flex w="100%" color="#737680">
            <Tab
              style={{ ...TAB_STYLES, marginRight: "16px" }}
              _selected={{ color: "dark.100", borderColor: "purple.500" }}
            >
              Города
            </Tab>
            <Tab style={TAB_STYLES} _selected={{ color: "dark.100", borderColor: "purple.500" }}>
              Офисы
            </Tab>
          </Flex>
        </TabList>

        <TabPanels height={MAX_HEIGHT_CITY_BODY}>
          <TabPanel height="100%" p="16px 0">
            <CityAddRow
              name={cityName}
              address={address}
              onChangeName={handleChangeName}
              onChangeAddress={handleChangeAddress}
              onAddCity={handleAddCity}
              error={errorNewCity}
              isLoading={inProgress}
            />
            <CitiesTable
              currentTab={currentTab}
              cities={cities}
              editState={editFieldState}
              onUpdate={handleEditCity}
              onDelete={setDeleteCity}
            />
          </TabPanel>
          <TabPanel height="100%" p="16px 0">
            <Button style={ADD_BUTTON} colorScheme="purple" onClick={handleCreate}>
              Добавить город
            </Button>

            <WarehouseTable currentTab={currentTab} warehouses={warehouses} onDelete={setDeleteWarehouse} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <DeleteCityModal
        name={deleteCity && deleteCity.name}
        id={deleteCity && deleteCity.id}
        onClose={() => setDeleteCity(null)}
        onDelete={handleDeleteCity}
      />
      <DeleteWarehouseModal
        name={deleteWarehouse && deleteWarehouse.city}
        id={deleteWarehouse && deleteWarehouse.id}
        onClose={() => setDeleteWarehouse(null)}
        onDelete={handleDeleteWarehouse}
      />
    </PageLayout>
  );
};
export default Cities;
