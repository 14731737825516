import { formatDateToApi } from "../../Promotion/utils/formatDate";

export const prepareData = (id, filteredProducts, productsArr) => {
  const product = filteredProducts.find(({ id: productId }) => id === productId);
  const data = productsArr.map(({ id: productId, count }) => ({ product_id: productId, count }));

  return [...data, { product_id: product.id, count: 1 }];
};

export const formatDeliveryToApi = ({ deliveryDate, timeStart, timeEnd, deliveryStatus }) => {
  const isDeliveryDate = deliveryDate;
  const isDeliveryDateAndTimeStart = deliveryDate && timeStart;
  const isDeliveryDateAndTimeEnd = deliveryDate && timeEnd;

  let deliveryStartDate = null;
  let deliveryEndDate = null;

  if (isDeliveryDate) {
    deliveryStartDate = formatDateToApi(deliveryDate);
    deliveryEndDate = formatDateToApi(deliveryDate);
    if (isDeliveryDateAndTimeStart) {
      deliveryStartDate = `${formatDateToApi(deliveryDate)}T${timeStart}Z`;
    }

    if (isDeliveryDateAndTimeEnd) {
      deliveryEndDate = `${formatDateToApi(deliveryDate)}T${timeEnd}Z`;
    }
  }

  return {
    deliveryStatus: deliveryStatus?.value,
    deliveryStartDate,
    deliveryEndDate,
  };
};
