import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCustomToast } from "../../hooks/useCustomToast";
import { employeesToast, employeesToastIsView } from "./store/Employees.selectors";
import { deleteEmployeesToast } from "./store/Employees.slice";

export const EmployeeToastView = ({ children }) => {
  const dispatch = useDispatch();

  const { pushToast } = useCustomToast();

  const toastInfo = useSelector(employeesToast);
  const toastIsViewed = useSelector(employeesToastIsView);

  useEffect(() => {
    if (toastIsViewed) {
      pushToast(toastInfo);
    }
    dispatch(deleteEmployeesToast());
  }, [toastIsViewed, pushToast, dispatch]);

  return children;
};
