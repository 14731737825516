import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addSeminarHistory,
  deleteSeminarHistory,
  editSeminarHistory,
  getSeminarsHistory,
  getSeminarHistory as getSeminarHistoryRequest,
  addSeminarMedia,
} from "../../../../services/seminarService";

import { SLICE_NAME } from "./types";

export const getAllSeminarsHistory = createAsyncThunk(
  `${SLICE_NAME}/fetchGetSeminarsHistory`,
  async (params, { rejectWithValue }) => {
    try {
      const seminars = await getSeminarsHistory(params);

      return seminars?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addingSeminarHistory = createAsyncThunk(
  `${SLICE_NAME}/fetchAddSeminarHistory`,
  async (params, { rejectWithValue }) => {
    try {
      return await addSeminarHistory(params);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const deletingSeminarHistory = createAsyncThunk(
  `${SLICE_NAME}/fetchDeleteSeminarHistory`,
  async (id, { rejectWithValue }) => {
    try {
      return await deleteSeminarHistory(id);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const editingSeminarHistory = createAsyncThunk(
  `${SLICE_NAME}/fetchEditSeminarHistory`,
  async (params, { rejectWithValue }) => {
    try {
      return await editSeminarHistory(params);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getSeminarHistory = createAsyncThunk(
  `${SLICE_NAME}/fetchSeminarHistory`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await getSeminarHistoryRequest(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addSeminarMediaThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchAddSeminarMediaThunk`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await addSeminarMedia(params);

      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
