import { useState } from "react";
import { useDispatch } from "react-redux";
import { Flex, Text } from "@chakra-ui/react";
import { NavItem } from "../Sidebar/NavItem";
import { ExitIcon } from "../../assets/icon/ExitIcon";
import LogoutConfirmModal from "../Sidebar/LogoutConfirmModal";
import { logoutUser } from "../../pages/Login/store/Login.thunk";
import { checkMobileDevice } from "../../utils/checkMobileDevice";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../routes/routes";

const NAME_STYLE = {
  fontWeight: "400",
  fontSize: "14px",
  color: "#434652",
  lineHeight: "20px",
};

const isMobile = checkMobileDevice();

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const email = localStorage.getItem("email");

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      navigate(ROUTES.LOGIN);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Flex justify="flex-end" padding={!isMobile ? 6 : 1} pr={0} align="center">
      {!isMobile ? (
        <>
          <div className="admin-email" style={NAME_STYLE}>
            {email}
          </div>
          <NavItem
            key="Exit"
            icon={ExitIcon}
            onNavClick={() => setOpen(true)}
            sx={{ padding: "12px", background: "#EBECEF", marginRight: "0" }}
          />
        </>
      ) : (
        <NavItem key="Exit" icon={ExitIcon} onNavClick={() => setOpen(true)} sx={{ padding: "16px 0 16px 16px" }}>
          <Text color="#FA2E2E">Выход</Text>
        </NavItem>
      )}
      {isOpen && <LogoutConfirmModal onClose={() => setOpen(false)} onLogout={handleLogout} />}
    </Flex>
  );
};

export default Header;
