import { useCallback, useEffect } from "react";
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import CityRow from "./CityRow";
import { CONTAINER_STYLE } from "./style";
import { TheadColumns } from "./constants";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";
import { useSelector } from "react-redux";
import { isLoading } from "./store/Cities/Cities.selectors";

const CitiesTable = ({ currentTab, cities = [], editState, onUpdate = () => {}, onDelete }) => {
  const isLoadingCities = useSelector(isLoading());

  useEffect(() => {
    editState.reset();
  }, [currentTab]);

  const cityRow = (cityItem) => {
    const { id, name, city, address } = cityItem;

    return (
      <CityRow
        key={`city-${id}`}
        editState={editState}
        id={id}
        address={address}
        name={name || city}
        onUpdate={onUpdate}
        onDelete={() => onDelete(cityItem)}
      />
    );
  };

  return (
    <TableConstructor
      containerStyles={CONTAINER_STYLE}
      notFoundText="Здесь пока нет городов"
      Row={cityRow}
      theadItems={TheadColumns}
      items={cities}
      isLoading={isLoadingCities}
    />
  );
};

export default CitiesTable;
