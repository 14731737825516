import { requestAxios } from "../api/axiosRequest";

// ================== FUTURE SEMINARS ===================
const SEMINARS_FUTURE_API = `/seminars/future`;

export const getFutureSeminars = ({ limit, page, query }) =>
  requestAxios.get(SEMINARS_FUTURE_API, {
    params: {
      take: limit,
      skip: (page - 1) * limit,
      query,
    },
  });

export const addFutureSeminar = ({ seminar }) => requestAxios.post(SEMINARS_FUTURE_API, seminar);

export const deleteFutureSeminar = ({ id }) => requestAxios.delete(`${SEMINARS_FUTURE_API}/${id}`);

export const editFutureSeminar = async ({ id, seminar }) => requestAxios.patch(`${SEMINARS_FUTURE_API}/${id}`, seminar);

export const getFutureSeminar = (id) => requestAxios.get(`${SEMINARS_FUTURE_API}/${id}`);

// ================== SEMINARS HISTORY ===================
const SEMINARS_HISTORY_API = `/seminars/history`;

export const getSeminarsHistory = ({ limit, page, query }) =>
  requestAxios.get(SEMINARS_HISTORY_API, {
    params: {
      take: limit,
      skip: (page - 1) * limit,
      query,
    },
  });

export const addSeminarHistory = ({ seminar }) => requestAxios.post(SEMINARS_HISTORY_API, seminar);

export const deleteSeminarHistory = ({ id }) => requestAxios.delete(`${SEMINARS_HISTORY_API}/${id}`);

export const editSeminarHistory = ({ id, seminar }) => requestAxios.patch(`${SEMINARS_HISTORY_API}/${id}`, seminar);

export const getSeminarHistory = (id) => requestAxios.get(`${SEMINARS_HISTORY_API}/${id}`);

// ================== SEMINARS REQUEST ===================
export const getSeminarsRequest = ({ limit, page, query }) =>
  requestAxios.get(`/seminars/requests`, {
    params: {
      take: limit,
      skip: (page - 1) * limit,
      query,
    },
  });

export const addSeminarMedia = (payload) => {
  return requestAxios.post(`/media/image`, payload, { headers: { "Content-Type": "multipart/form-data" } });
};
