/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../../store/common";

import { getAllSeminarsRequest } from "./SeminarsRequest.thunk";
import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  seminars: [],
};

export const {
  reducer,
  actions: { deleteSeminarsRequestToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deleteSeminarsRequestToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSeminarsRequest.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getAllSeminarsRequest.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllSeminarsRequest.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.seminars = action.payload;
      });
  },
});
