export const timeParse = (value) => {
  if (!value) {
    return {
      from: { hours: "", minutes: "" },
      to: { hours: "", minutes: "" },
    };
  }
  const [from, to] = value.split("-");
  const [fromHH, fromMM] = from.split(":");
  const [toHH, toMM] = to.split(":");
  return {
    from: { hours: fromHH.trim(), minutes: fromMM.trim() },
    to: { hours: toHH.trim(), minutes: toMM.trim() },
  };
};

export const timeToString = (timeObj) => {
  if (!timeObj.from.hours) {
    return "";
  }
  return `${timeObj.from.hours}:${timeObj.from.minutes} - ${timeObj.to.hours}:${timeObj.to.minutes}`;
};
