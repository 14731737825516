import { forwardRef } from "react";
import { sizeFormat } from "../../utils/sizeFormat";

export const UploadInput = forwardRef(
  ({ name, formats, maxFileSize, onChangeFile, setError, reset = false }, fileSelector) => {
    const handleChangeFile = (event) => {
      if (setError) {
        setError("");
      }

      const file = event.currentTarget.files[0];
      const fileFormat = `.${file.name.split(".").at(-1)?.toLowerCase()}`;

      if (!formats.includes(fileFormat)) {
        return;
      }

      const isFileSizeMoreThatMax = file && sizeFormat(file.size) >= maxFileSize;

      if (!isFileSizeMoreThatMax) {
        onChangeFile(file);
      }

      if (isFileSizeMoreThatMax && setError) {
        setError(`Максимальный размер файла должен составлять ${maxFileSize}КБ`);
      }
    };

    return (
      <input
        autoComplete="off"
        name={name}
        ref={fileSelector}
        style={{ display: "none" }}
        type="file"
        onChange={handleChangeFile}
        accept={formats}
        {...(reset ? { value: "" } : {})}
      />
    );
  }
);
