export const BACK_BUTTON = {
  color: "#C06ECC",
  display: "flex",
  justifyContent: "flex-start",
  margin: "34px 0 24px",
  width: "100%",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "24px",
};

export const CONTAINER_DESKTOP = {
  display: "flex",
  flexDirection: "column",
  padding: "24px",
  backgroundColor: "#fff",
  boxShadow: "-5px 1px 15px 0px rgba(0, 0, 0, 0.05)",
  borderRadius: "8px",
};

export const CONTAINER_MOBILE = {
  display: "flex",
  flexDirection: "column",
  padding: "24px 16px",
  backgroundColor: "#fff",
  boxShadow: "-5px 1px 15px 0px rgba(0, 0, 0, 0.05)",
  borderRadius: "8px",
};
