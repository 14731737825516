import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, IconButton, Image, Input, InputGroup, InputRightElement, Text, Textarea } from "@chakra-ui/react";
import { ValidationError } from "yup";
import { isEmpty, isEqual } from "lodash";

import InputImage from "../../../components/InputImage/InputImage";
import { UploadInputGroup } from "../../../components/UploadInputGroup/UploadInputGroup";
import { LinkIcon } from "../../../assets/icon/LinkIcon";
import { DATE_FORMAT_API, DEFAULT_DATE_FORMAT, HINT_STYLE } from "../../../common/constants";

import { buildError } from "../../Products/utils/buildError";

import { SEMINAR_HISTORY_VALIDATION_SHCEMA } from "../constants";
import {
  addingSeminarHistory,
  editingSeminarHistory,
  getSeminarHistory,
  addSeminarMediaThunk,
} from "../store/SeminarsHistory/SeminarsHistory.thunk";
import { getSeminarHistoryDetail, getSeminarsHistory } from "../store/SeminarsHistory/SeminarsHistory.selectors";
import { handleEnterKey } from "../../../utils/blurOnEnter";
import { generateObjForValidate } from "../../../utils/generateObjForValidate";
import { formatDate } from "../../Promotion/utils/formatDate";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { Drawer } from "../../../components/Drawer/Drawer";
import { FormControlWithError } from "../../../components/FormControlWithError/FormControlWithError";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { convertToGoogleDrive } from "../../Promotion/utils/transformData";
import { usePrompt } from "../../../hooks/usePrompt";
import DescriptionTextarea from "../../../components/DescriptionTextarea/DescriptionTextarea";
import { DateTime } from "luxon";

const initialValues = {
  name: "",
  description: "",
  date: null,
  image: null,
};

const EditSeminarModal = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { seminarDetail: seminar, loadingState } = useSelector(getSeminarHistoryDetail);
  const historySeminarFileSelectorRef = useRef(null);

  const seminarNames = generateObjForValidate(seminar?.name || "", useSelector(getSeminarsHistory())?.data);

  const handleSave = (values) => {
    const seminarForSend = {
      name: values.name.trim(),
      description: values.description.trim(),
      date: values.date && formatDate(values.date),
      image: values.image || null,
    };

    const oldSeminar = seminar?.id && {
      name: seminar.name,
      description: seminar.description,
      date: seminar.date && formatDate(seminar.date),
      image: seminar.image || null,
    };

    if (!id) {
      dispatch(addingSeminarHistory({ seminar: seminarForSend }));
    }

    if (id && !isEqual(oldSeminar, seminarForSend)) {
      dispatch(editingSeminarHistory({ id: seminar.id, seminar: seminarForSend }));
    }

    navigate(-1);
  };

  const { resetForm, values, errors, handleChange, handleSubmit, setFieldValue, setFieldError, dirty, isSubmitting } =
    useFormik({
      initialValues,
      validateOnChange: false,
      validationSchema: SEMINAR_HISTORY_VALIDATION_SHCEMA(seminarNames),
      enableReinitialize: true,
      onSubmit: handleSave,
    });

  useEffect(() => {
    if (id) {
      dispatch(getSeminarHistory(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && seminar && seminar.id === id) {
      resetForm({
        values: { ...seminar, date: new Date(formatDate(seminar.date, DATE_FORMAT_API, DEFAULT_DATE_FORMAT)) },
      });
    }
  }, [id, seminar]);

  const handleHistorySeminarSelectFile = useCallback(
    (file) => {
      dispatch(addSeminarMediaThunk({ file })).then(({ payload }) => {
        setFieldValue("image", payload.url);
      });
    },
    [setFieldValue]
  );

  usePrompt(dirty && !isSubmitting);

  return (
    <Drawer isOpen isLoading={loadingState} handleClose={() => navigate(-1)} handleSave={handleSubmit}>
      <FormControlWithError hasError={errors?.name} errorText={errors.name}>
        <Text>Название* </Text>

        <Input autoComplete="off" name="name" value={values.name} onChange={handleChange} onKeyDown={handleEnterKey} />
      </FormControlWithError>

      <FormControlWithError hasError={errors?.description} errorText={errors?.description}>
        <Text>Краткое описание*</Text>

        <DescriptionTextarea
          value={values.description}
          placeholder="Опишите семинар"
          onChange={(text) => setFieldValue("description", text)}
        />
      </FormControlWithError>

      <FormControlWithError hasError={errors?.date} errorText={errors.date}>
        <Text>Дата* </Text>
        <DatePicker
          selected={values.date}
          maxDate={DateTime.now().minus({ day: 1 }).toJSDate()}
          onChange={(date) => setFieldValue("date", date)}
        />
      </FormControlWithError>

      <Flex direction="column" style={{ gap: "10px" }}>
        <FormControlWithError
          extraContent={<Text style={HINT_STYLE}>История семинаров - 402x387 px PNG, JPG, JPEG</Text>}
          hasError={!!errors?.image}
          errorText={errors.image}
          title="Изображения*"
        >
          <UploadInputGroup
            ref={historySeminarFileSelectorRef}
            placeholder="Загрузите изображение"
            errorSetter={(errorText) => setFieldError("image", errorText)}
            image={values.image}
            handleSelectFile={handleHistorySeminarSelectFile}
          />
        </FormControlWithError>
      </Flex>
    </Drawer>
  );
};

export default EditSeminarModal;
