import React from "react";
import { Button, Flex, FormLabel, Input, InputGroup, InputRightElement, useClipboard } from "@chakra-ui/react";
import { CopyIcon } from "../../assets/icon/CopyIcon";

export const CurrentPaswordInput = ({ lable, value }) => {
  const { onCopy, hasCopied } = useClipboard(value);

  return (
    <Flex width="100%" direction="raw" alignItems="flex-end" gap="2">
      <Flex width="40%" direction="column" alignItems="flex-start" gap="4">
        <FormLabel fontSize="14px" lineHeight="24px" m="0">
          {lable}
        </FormLabel>

        <InputGroup size="md">
          <Input id="temp-password" value={value} isReadOnly />
          <InputRightElement>
            <Button sx={{ background: "inherit", padding: 0 }} onClick={onCopy}>
              <CopyIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </Flex>

      <Flex direction="raw">
        {hasCopied && (
          <div
            style={{
              fontSize: "14px",
              lineHeight: "24px",
              color: "#737680",
            }}
          >
            Скопировано!
          </div>
        )}
      </Flex>
    </Flex>
  );
};
