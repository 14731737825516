/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../../store/common";

import {
  addingFutureSeminar,
  deletingFutureSeminar,
  editingFutureSeminar,
  getAllFutureSeminars,
  getFutureSeminar,
  addSeminarMediaThunk,
} from "./FutureSeminars.thunk";
import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  seminar: { loadingState: LoadingState.IDLE, seminarDetail: null },
  seminars: [],
};

export const {
  reducer,
  actions: { getAgainSeminars, deleteToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    getAgainSeminars: (state) => {
      state.loadingState = LoadingState.IDLE;
    },
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFutureSeminar.pending, (state) => {
        state.seminar.loadingState = LoadingState.PENDING;
      })
      .addCase(getFutureSeminar.rejected, (state, { payload }) => {
        state.toast = {
          view: true,
          title: payload,
          description: payload,
          status: "error",
        };
        state.seminar.loadingState = LoadingState.REJECTED;
      })
      .addCase(getFutureSeminar.fulfilled, (state, { payload }) => {
        state.seminar.loadingState = LoadingState.FULFILLED;
        state.seminar.seminarDetail = payload;
      })

      .addCase(getAllFutureSeminars.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getAllFutureSeminars.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllFutureSeminars.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.seminars = action.payload;
      })
      .addCase(addingFutureSeminar.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(addingFutureSeminar.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Семинар добавлен",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingFutureSeminar.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(deletingFutureSeminar.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Семинар удален",
          description: "",
          status: "success",
        };
      })
      .addCase(editingFutureSeminar.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(editingFutureSeminar.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Семинар изменен",
          description: "",
          status: "success",
        };
      })
      .addCase(addSeminarMediaThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка загрузки медиа",
          description: action.payload,
          status: "error",
        };
      });
  },
});
