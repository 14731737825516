import { TableConstructor } from "../../../components/TableConstructor/TableConstructor";
import { BonusesTableRow } from "./BonusesTableRow";
import { BONUSES_TABLE_HEADERS } from "../constants";

const renderBonusesRow = (bonus) => <BonusesTableRow {...bonus} key={`user-bonus-${bonus.id}`} />;

export const BonusesTable = ({ bonusLogs }) => {
  return (
    <TableConstructor
      isLoading={false}
      theadItems={BONUSES_TABLE_HEADERS}
      items={bonusLogs}
      Row={renderBonusesRow}
      notFoundText="Операций с бонусами не найдено"
      preloadHeight="300px"
    />
  );
};
