import { useRef } from "react";
import { Checkbox, Flex, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";

import { DeleteIcon } from "../../../assets/icon/DeleteIcon";
import { useIsOverflow } from "../../../hooks/hooks";
import { TEXT_OVERFLOW_STYLE } from "../../../common/constants";
import { TooltipWithContent } from "../../../components/TooltipWithContent/TooltipWithContent";
import { keyHandler } from "../../../utils/keyHandler";

const TableRow = ({ seminar, isSelected, onDelete, onEdit, onSelect }) => {
  const { id, name, date } = seminar;

  const ref = useRef(null);

  const [isOverflow] = useIsOverflow(ref);

  const handleSelect = (e) => {
    e.stopPropagation();
    onSelect({ id, name }, isSelected);
  };
  const handleEdit = () => {
    onEdit(seminar);
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(seminar, isSelected);
  };

  const handleSelectSeminar = (event) => keyHandler(event.key, () => handleEdit());

  return (
    <Tr onClick={handleEdit} cursor="pointer">
      <Td textAlign="center" lineHeight={0} onClick={(e) => e.stopPropagation()}>
        <Checkbox isChecked={isSelected} onChange={handleSelect} />
      </Td>
      <Td onKeyDown={handleSelectSeminar} tabIndex={0} style={TEXT_OVERFLOW_STYLE} ref={ref}>
        <TooltipWithContent isOverflow={isOverflow} text={name} />
      </Td>
      <Td w="180px">{date}</Td>
      <Td w="60px" maxW="60px" p="0">
        <Flex justifyContent="end">
          <IconButton
            w="100%"
            minHeight="52px"
            display="flex"
            justifyContent="center"
            variant="unstyled"
            icon={<DeleteIcon isActive />}
            onClick={handleDelete}
            aria-label="Delete"
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default TableRow;
