import { Box, CloseButton } from "@chakra-ui/react";
import styled from "@emotion/styled";

import { ReactComponent as SuccessIcon } from "../../../assets/svg/SubmitIcon.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/svg/ErrorIcon.svg";

const COLORS = {
  error: "#ff6060",
  success: "#77c777",
};

const Container = styled("div")(({ type }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  backgroundColor: "white",

  width: "410px",
  minHeight: "60px",
  text: "wrap",
  border: `2px solid ${type === "error" ? COLORS.error : COLORS.success}`,
  borderRadius: "10px",
  padding: "15px",

  span: {
    marginRight: "20px",
    svg: {
      width: "25px",
      height: "25px",
      path: {
        fill: type === "error" ? COLORS.error : COLORS.success,
      },
    },
  },

  button: {
    width: "25px",
    height: "25px",
    backgroundColor: type === "error" ? COLORS.error : COLORS.success,
    color: "white",
    position: "absolute",
    right: "15px",
    top: "15px",
  },
  ".title": {
    display: "flex",
    flexDirection: "column",
    maxWidth: "300px",
  },
}));

export const CustomViewToast = ({ title, status, description, onClose }) => {
  let descriptionText;

  if (Array.isArray(description)) {
    descriptionText = description.join(", ");
  } else {
    descriptionText = description;
  }

  return (
    <Container type={status}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <span>{status === "error" ? <ErrorIcon stroke={COLORS.error} /> : <SuccessIcon />}</span>
        <span className="title">
          <span style={{ fontWeight: description ? 600 : 400 }}>{title}</span>
          {descriptionText && <span style={{ marginTop: 5 }}>{descriptionText}</span>}
        </span>
      </Box>
      <CloseButton onClick={() => onClose()} />
    </Container>
  );
};
