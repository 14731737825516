/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../store/common";
import {
  addingProtocolCategory,
  deletingProtocolCategory,
  editingProtocolCategory,
  gettingProtocolCategories,
} from "./ProtocolCategories.thunk";

import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  protocolCategories: [],
};

export const {
  reducer,
  actions: { getCategories, deleteToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    getCategories: (state) => {
      state.loadingState = LoadingState.IDLE;
    },
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(gettingProtocolCategories.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(gettingProtocolCategories.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(gettingProtocolCategories.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.protocolCategories = action.payload;
      })
      .addCase(addingProtocolCategory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Категория протокола добавлена",
          description: "",
          status: "success",
        };
      })
      .addCase(addingProtocolCategory.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        // eslint-disable-next-line no-param-reassign
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(deletingProtocolCategory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Категория протокола удалена",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingProtocolCategory.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(editingProtocolCategory.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Категория протокола изменена",
          description: "",
          status: "success",
        };
      })
      .addCase(editingProtocolCategory.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      });
  },
});
