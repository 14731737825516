import { IconButton, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useState } from "react";
import { ReactComponent as EyeIcon } from "../../assets/svg/EyeIcon.svg";
import { ReactComponent as EyeNoIcon } from "../../assets/svg/EyeNoIcon.svg";

export const InputPassword = ({ placeholder = "Введите пароль", ...props }) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleToggleEye = () => setIsShowPassword((prev) => !prev);

  return (
    <InputGroup>
      <Input type={isShowPassword ? "text" : "password"} placeholder={placeholder} {...props} />

      <InputRightElement>
        <IconButton bg="transparent" _hover={{ background: "transparent" }} onClick={handleToggleEye} aria-label="Eye">
          {isShowPassword ? <EyeNoIcon /> : <EyeIcon />}
        </IconButton>
      </InputRightElement>
    </InputGroup>
  );
};
