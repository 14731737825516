import { createAsyncThunk } from "@reduxjs/toolkit";

import { SLICE_NAME } from "./types";
import { addBrand, deleteBrand, editBrand, getBrands } from "../../../services/brandService";

export const getAllBrands = createAsyncThunk(`${SLICE_NAME}/fetchGetBrands`, async (values, { rejectWithValue }) => {
  try {
    const brands = await getBrands();
    return brands.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const addingBrand = createAsyncThunk(`${SLICE_NAME}/fetchAddBrand`, async (payload, { rejectWithValue }) => {
  try {
    return await addBrand(payload);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const deletingBrand = createAsyncThunk(`${SLICE_NAME}/fetchDeleteBrand`, async (id, { rejectWithValue }) => {
  try {
    return await deleteBrand(id);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const editingBrand = createAsyncThunk(`${SLICE_NAME}/fetchEditBrand`, async (payload, { rejectWithValue }) => {
  try {
    return await editBrand(payload);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});
