import { useRef } from "react";
import { Flex, Grid, Input, Select } from "@chakra-ui/react";
import MultiSelect from "react-select";

import { CUSTOM_STYLES_SELECTOR, getNoOptionText } from "../../common/constants";
import { useScrollIntoViewError } from "../../hooks/useScrollIntoViewError";
import { FormControlWithError } from "../FormControlWithError/FormControlWithError";

const RequireCharacteristic = ({ charKey, value, items, multiselect, separator, error, onChange, onEnter }) => {
  const ref = useRef(null);

  useScrollIntoViewError(ref, error);

  const input = () => {
    switch (true) {
      case multiselect: {
        const cur = value
          ?.split(",")
          .filter((v) => v)
          .map((v) => ({ label: v.trim(), value: v.trim() }));
        const handleChange = (values) => onChange(values.map(({ value: v }) => v).join(separator || ","));

        return (
          <div ref={ref} style={{ borderRadius: "5px", marginBottom: "0" }}>
            <MultiSelect
              noOptionsMessage={getNoOptionText}
              isMulti
              placeholder="Выбрать..."
              closeMenuOnSelect={false}
              options={items}
              value={cur}
              onChange={handleChange}
              styles={CUSTOM_STYLES_SELECTOR}
            />
          </div>
        );
      }
      case !!items: {
        return (
          <Select styles={CUSTOM_STYLES_SELECTOR} ref={ref} value={value} onChange={(e) => onChange(e.target.value)}>
            {items.map(({ name, hidden }, index) => (
              <option key={index} hidden={hidden} value={name}>
                {name}
              </option>
            ))}
          </Select>
        );
      }
      default: {
        return (
          <Input
            autoComplete="off"
            ref={ref}
            placeholder="Значение характеристики"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={onEnter}
          />
        );
      }
    }
  };

  return (
    <Grid templateColumns="repeat(2,1fr)" gap="15px">
      <Flex direction="column">
        <Input isReadOnly isDisabled placeholder="Название характеристики" value={charKey} />
      </Flex>
      <FormControlWithError hasError={error} errorText={error}>
        <Flex direction="column">{input()}</Flex>
      </FormControlWithError>
    </Grid>
  );
};

export default RequireCharacteristic;
