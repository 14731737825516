import styled from "@emotion/styled";
import Autocomplete from "../Autocomplete";
import { PRODUCT_INPUT_STYLE } from "../../pages/Products/style";
import { AVAILABLE_FOR } from "../../pages/Banners/constants";

const StyledFullWidthContainer = styled.div`
  width: 100%;
`;

export const RoleSelect = ({ isDisabled, value, onChange, errors, ...props }) => {
  return (
    <StyledFullWidthContainer>
      <Autocomplete
        isDisabled={isDisabled}
        placeholder="Выберите доступность"
        value={value}
        onChange={onChange}
        items={AVAILABLE_FOR}
        error={errors}
        style={PRODUCT_INPUT_STYLE}
        {...props}
      />
    </StyledFullWidthContainer>
  );
};
