import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";

export const getPromotion = (state) => state[SLICE_NAME].promotions;
export const promotionIsGetted = (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const promotionIsLoading = (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const promotionIsUpdated = (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const promotionToast = (state) => state[SLICE_NAME].toast;
export const promotionToastIsView = (state) => state[SLICE_NAME].toast.view;
