export const InputSearchNotMobile = {
  justifyContent: "end",
  marginBottom: "35px",
  width: "100%",
};

export const InputSearchMobile = {
  justifyContent: "center",
  marginBottom: "15px",
};

export const TabListNotMobile = {
  width: "100%",
  justifyContent: "space-between",
};

export const TabListMobile = {
  flexDirection: "column",
  width: "100%",
};

export const BODY_CELL_STYLES_NOT_USERS = {
  color: "#000",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "400",
};

export const BUTTON_REJECT = {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#37393D",
};

export const BUTTON_VERIFY = {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  backgroundColor: "#C06ECC",
  color: "#fff",
};

export const CONTAINER_STYLE = {
  maxHeight: "calc(100% - 10px)",
  overflowY: "auto",
  scrollbarGutter: "stable",
};

export const NAME_WIDTH = { maxWidth: "100px" };
export const EMAIL_WIDTH = { maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" };
export const PHONE_WIDTH = { maxWidth: "100px" };
export const MANAGER_WIDTH = { maxWidth: "100px" };
