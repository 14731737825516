import * as yup from "yup";

const TEXT_ADDRESS_ERROR = "Офис с таким адресом уже существует";

const TIME_CHECK = yup
  .string()
  .test("timetable", "Введите корректное значение", (value) => !value || value.length === 13);

export const VALIDATION_WAREHOUSE_SCHEMA = (warehouseList) => {
  const checkAddress = (_, { parent: { city, address } }) =>
    !warehouseList.some((item) => item.city === city && item.address === address);

  return yup.object({
    city: yup.string().required('Поле "Город" обязательное').test("name", TEXT_ADDRESS_ERROR, checkAddress),
    address: yup.string().required('Поле "Адрес" обязательное').test("address", TEXT_ADDRESS_ERROR, checkAddress),
    phone: yup.string().required('Поле "Номер телефона" обязательное').min(12, "Не меньше 11 символов"),
    timetable: yup.object().shape({
      mon: TIME_CHECK,
      tue: TIME_CHECK,
      wed: TIME_CHECK,
      thu: TIME_CHECK,
      fri: TIME_CHECK,
      sat: TIME_CHECK,
      sun: TIME_CHECK,
    }),
  });
};

export const TheadColumns = [
  { name: "Город", width: 40 },
  { name: "Адрес", width: 40 },
  { name: "", width: 20 },
];
