import { Td, Tr, Text } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useRef, Fragment } from "react";
import { format } from "date-fns";
import { commasLogic } from "../../../utils/commasLogic";
import { TABLE_DATE_FORMAT } from "../constants";
import { userRolesForNotifications } from "../../NotificationsModal/constants";

export const TableRow = ({ notification }) => {
  const nameRef = useRef(null);
  const navigate = useNavigate();

  const handleOpenDetail = () => navigate(notification.id);

  return (
    <Tr>
      <Td ref={nameRef}>
        <Link to={notification.id}>
          <Text fontWeight="600">{notification.title}</Text>
          <p>{notification.description}</p>
        </Link>
      </Td>

      <Td onClick={handleOpenDetail} cursor="pointer">
        {format(Date.parse(notification.datetime), TABLE_DATE_FORMAT)}
      </Td>

      <Td onClick={handleOpenDetail} cursor="pointer">
        {notification.roles.map((role, index) => (
          <Fragment key={`notification_${notification.id}_${role}`}>
            {commasLogic(userRolesForNotifications[role].label, index, notification.roles.length - 1)}
          </Fragment>
        ))}
        <Text fontWeight="400">{!!notification.usersCount && `Пользователи (${notification.usersCount})`}</Text>
        <Text fontWeight="400">{!!notification.warehousesCount && `Склады (${notification.warehousesCount})`}</Text>
      </Td>
    </Tr>
  );
};
