import { useCallback, useState } from "react";

export const useApiRequest = (apiRequest, { initialData, onRequestSuccess } = {}) => {
  const [data, setData] = useState(initialData);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(
    async (id) => {
      setLoading(true);
      setError(null);
      try {
        const requestData = (await apiRequest(id)).data;
        if (onRequestSuccess) {
          onRequestSuccess(requestData);
        }
        setData(requestData);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    },
    [apiRequest]
  );

  const clear = useCallback((clearData = null) => {
    setData(clearData);
  }, []);

  return { data, error, isLoading, request, clear };
};
