import { useMemo, useRef } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import { useIsOverflow } from "../../hooks/hooks";
import { generateName } from "./utils/generateName";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCurrentUserState } from "../Login/store/Login.selectors";
import { parseISO, format } from "date-fns";
import { checkIsRoleAdmin } from "../../utils/roles";
import { TABLE_DATE_FORMAT } from "../Notifications/constants";

const EMAIL_WIDTH = 300;
const PHONE_WIDTH = 180;

const RowClientsTable = ({ user, currentTab }) => {
  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);
  const navigate = useNavigate();
  const { role } = useSelector(getCurrentUserState);

  const { email, phone, id, lastVisitDate } = user;
  const finalName = generateName(user);

  const rowPropsByUserRole = useMemo(
    () => (checkIsRoleAdmin(role) ? { cursor: "pointer", onClick: () => navigate(id, { state: { currentTab } }) } : {}),
    [role, currentTab, id, navigate]
  );

  return (
    <Tr {...rowPropsByUserRole}>
      <Td ref={ref}>
        {isOverflow ? (
          <Tooltip label={finalName} color="black" bg="white" placement="bottom-start">
            <span>{finalName}</span>
          </Tooltip>
        ) : (
          finalName
        )}
      </Td>
      <Td w={EMAIL_WIDTH}>{email}</Td>
      <Td w={PHONE_WIDTH}>{phone}</Td>
      <Td>{lastVisitDate ? format(parseISO(lastVisitDate), TABLE_DATE_FORMAT) : "Неизвестна"}</Td>
    </Tr>
  );
};

export default RowClientsTable;
