import styled from "@emotion/styled";
import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isCategoriesUpdated, getCategories } from "../../pages/Categories/store/Categories/Categories.selectors";
import { gettingCategories } from "../../pages/Categories/store/Categories/Categories.thunk";
import Autocomplete from "../Autocomplete";
import { PRODUCT_INPUT_STYLE } from "../../pages/Products/style";

const StyledFullWidthContainer = styled.div`
  width: 100%;
`;
export const CategoriesSelect = ({ isDisabled, value, onChange, errors, ...props }) => {
  const dispatch = useDispatch();
  const isCategoryNeedUpdate = useSelector(isCategoriesUpdated());
  const categories = useSelector(getCategories());

  const itemsForCategories = useMemo(() => (categories ? [...categories] : []), [categories]);

  useEffect(() => {
    if (isCategoryNeedUpdate || !categories) {
      dispatch(gettingCategories());
    }
  }, [isCategoryNeedUpdate, dispatch]);

  return (
    <StyledFullWidthContainer>
      <Autocomplete
        isDisabled={isDisabled}
        placeholder="Выберите категорию"
        value={value}
        onChange={onChange}
        items={itemsForCategories}
        error={errors}
        style={PRODUCT_INPUT_STYLE}
        {...props}
      />
    </StyledFullWidthContainer>
  );
};
