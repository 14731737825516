import { InputGroup, Input, InputRightElement, IconButton, Image } from "@chakra-ui/react";
import { UploadInput } from "../UploadInput/UploadInput";
import { ReactComponent as LoadFileIcon } from "../../assets/svg/LoadFileIcon.svg";
import { forwardRef } from "react";
import { defaultAcceptableImageTypes } from "./constants";

export const UploadInputGroup = forwardRef(({ placeholder, errorSetter, handleSelectFile, image }, fileSelector) => {
  return (
    <InputGroup>
      {image ? (
        <Image src={image} onClick={() => fileSelector.current.click()} />
      ) : (
        <Input
          placeholder={placeholder}
          isReadOnly
          value=""
          style={{ position: "relative", paddingRight: "50px" }}
          onClick={() => fileSelector.current.click()}
        />
      )}
      <InputRightElement>
        <UploadInput
          ref={fileSelector}
          setError={errorSetter}
          formats={defaultAcceptableImageTypes}
          maxFileSize={500}
          onChangeFile={handleSelectFile}
          reset
        />
        {!image && (
          <IconButton
            height="14px"
            w="14px"
            icon={<LoadFileIcon />}
            variant="unstyled"
            aria-label="Load icon"
            onClick={() => fileSelector.current.click()}
          />
        )}
      </InputRightElement>
    </InputGroup>
  );
});
