import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  InputGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import PageLayout from "../../components/PageLayout/PageLayout";
import OrdersTable from "./OrdersTable";
import { Pagination } from "../../components/Pagination";
import InputSearch from "../../components/InputSearch";
import { usePagination } from "../../hooks/usePagination";
import { getOneOrderSelector, getOrders, getTotalOrders, orderToast, toastIsView } from "./store/Orders.selectors";
import {
  createReportOrdersThunk,
  getOrdersThunk,
  getUnreviewedOrdersThunk,
  updateOrderThunk,
} from "./store/Orders.thunk";
import { deleteToast } from "./store/Orders.slice";
import { useCustomToast } from "../../hooks/useCustomToast";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { EXPORT_FILE_BTN } from "../Products/style";
import { useWebsocet } from "../../hooks/useWebsocet";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
import { getCurrentUserState } from "../Login/store/Login.selectors";
import { checkIsRoleAnalytic } from "../../utils/roles";

export const TABS = {
  0: "",
  1: "outdated",
  2: "canceled",
};

const ORDER_TYPES = { 0: "ACTIVE", 1: TABS[1].toUpperCase(), 2: TABS[2].toUpperCase() };

const Orders = () => {
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();
  const newOrder = useWebsocet("new-user-order");

  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchError, setSearchError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = +searchParams.get("currentTab") ?? 0;
  const [isLoading, setIsLoading] = useState(false);

  const ordersList = useSelector(getOrders());
  const total = useSelector(getTotalOrders());
  const toastInfo = useSelector(orderToast() || "");
  const toastInViewed = useSelector(toastIsView() || false);
  const orderDetails = useSelector(getOneOrderSelector()) ?? {};
  const { role } = useSelector(getCurrentUserState);

  const userCanSeeCounter = !checkIsRoleAnalytic(role);

  const {
    isPageValid,
    isPrevDisabled,
    isNextDisabled,
    page,
    limit,
    totalPages,
    nextPage,
    prevPage,
    resetPage,
    setPage,
    setLimit,
  } = usePagination({
    total,
  });

  useEffect(() => {
    return () => {
      dispatch(deleteToast());
    };
  }, []);

  const handleChangeSearch = ({ target: { value } }) => {
    setSearchError(null);
    setSearchInput(value.trimStart());
  };

  const handleKeyDownSearchQuery = (event) => {
    if (event.key === "Enter") {
      setSearchQuery(searchInput === "" ? null : searchInput.trimEnd());
      event.target.blur();
    }
  };

  const handleClickSearchQuery = () => {
    setSearchQuery(searchInput === "" ? null : searchInput);
  };

  const request = useCallback(
    async (limitPage = 10, newPage = 1, orderStatus = TABS[0]) => {
      await dispatch(getOrdersThunk({ limit: limitPage, page: newPage, query: searchQuery, orderStatus })).unwrap();
    },
    [searchQuery, dispatch]
  );

  const updateInfoNewOrders = useCallback(
    async (id) => {
      try {
        if (userCanSeeCounter) {
          await dispatch(updateOrderThunk(id)).unwrap();
          await dispatch(getUnreviewedOrdersThunk());
          await dispatch(getUnreviewedOrdersThunk()).unwrap();
        }
        await request(limit, page, TABS[currentTab]);
      } catch (e) {
        console.error(e);
      }
    },
    [limit, page, currentTab]
  );

  const handleDelete = () => {
    if (searchInput || searchQuery) {
      setSearchInput("");
      setSearchQuery(null);
    }
  };

  const handleExportToExcel = async () => {
    try {
      setIsLoading(true);
      await dispatch(createReportOrdersThunk({ status: ORDER_TYPES[currentTab] })).unwrap();
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  };

  useUpdateEffect(() => {
    if (total === 0 || isPageValid) {
      dispatch(
        getOrdersThunk({ limit, page: total === 0 ? 1 : page, query: searchQuery, orderStatus: TABS[currentTab] })
      );
    }
  }, [limit, total, page, currentTab, searchQuery, isPageValid, dispatch]);

  useEffect(() => {
    resetPage();
    request();
  }, []);

  const cbProductsToast = useCallback(() => {
    if (toastInfo.view) {
      pushToast(toastInfo);
    }
    dispatch(deleteToast());
  }, [toastInfo, dispatch]);

  useUpdateEffect(() => {
    if (page === 1 && currentTab === 0) {
      request();
    }
  }, [newOrder]);

  useEffect(() => {
    cbProductsToast();
  }, [toastInViewed, cbProductsToast]);

  const handleChangeCurrentTab = (tab) => setSearchParams(qs.parse({ currentTab: tab }));

  return (
    <PageLayout>
      <Flex direction="column" sx={{ justifyContent: "space-between", marginBottom: "28px", width: "100%" }} gap="39px">
        <Flex justifyContent="space-between">
          <Button
            onClick={handleExportToExcel}
            style={EXPORT_FILE_BTN}
            colorScheme="purple"
            isLoading={isLoading}
            aria-label="Export"
          >
            Сформировать отчет
          </Button>

          <InputGroup style={{ maxWidth: "320px", position: "relative" }}>
            <FormControl isInvalid={searchError}>
              <InputSearch
                style={{ border: "1px solid #D0D3DA" }}
                placeholder="Поиск"
                value={searchInput}
                onChange={handleChangeSearch}
                onKeyDown={handleKeyDownSearchQuery}
                onClick={handleClickSearchQuery}
                onDelete={handleDelete}
              />
              <FormErrorMessage style={{ whiteSpace: "nowrap", position: "absolute" }}>{searchError}</FormErrorMessage>
            </FormControl>
          </InputGroup>
        </Flex>
      </Flex>

      <Tabs defaultIndex={currentTab} index={currentTab} w="100%" onChange={handleChangeCurrentTab}>
        <TabList borderBottom="none" marginBottom="8px">
          <Flex sx={{ width: "100%", justifyContent: "space-between" }}>
            <Flex color="#737680">
              <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Актуальные</Tab>
              <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Просроченные</Tab>
              <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Отмененные</Tab>
            </Flex>

            <Pagination
              totalPages={totalPages}
              page={page}
              limit={limit}
              isPrevDisabled={isPrevDisabled}
              isNextDisabled={isNextDisabled}
              onNextPage={nextPage}
              onPrevPage={prevPage}
              onChangePage={setPage}
              onLimitChange={setLimit}
            />
          </Flex>
        </TabList>

        <TabPanels>
          <TabPanel height="75vh" p="16px 0 0 0">
            {currentTab === 0 && <OrdersTable isActual orders={ordersList} request={updateInfoNewOrders} />}
          </TabPanel>

          <TabPanel height="75vh" p="16px 0 0 0">
            {currentTab === 1 && <OrdersTable isExpired orders={ordersList} request={updateInfoNewOrders} />}
          </TabPanel>

          <TabPanel height="75vh" p="16px 0 0 0">
            {currentTab === 2 && <OrdersTable isCancelled orders={ordersList} request={updateInfoNewOrders} />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageLayout>
  );
};

export default Orders;
