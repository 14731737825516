import React from "react";

const ArrowLeft = ({ width = "9", height = "15" }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5843 1.21383L1.2943 7.21383C1.19118 7.30755 1.10879 7.4218 1.05241 7.54923C0.996034 7.67667 0.966909 7.81448 0.966909 7.95383C0.966909 8.09318 0.996034 8.23099 1.05241 8.35842C1.10879 8.48586 1.19118 8.6001 1.2943 8.69383L7.5843 14.6938C7.74056 14.843 7.94828 14.9262 8.1643 14.9262C8.38032 14.9262 8.58804 14.843 8.7443 14.6938C8.81718 14.623 8.87511 14.5383 8.91467 14.4447C8.95423 14.351 8.97461 14.2505 8.97461 14.1488C8.97461 14.0472 8.95423 13.9466 8.91467 13.853C8.87511 13.7594 8.81718 13.6747 8.7443 13.6038L2.8143 7.98383L8.7443 2.30383C8.81718 2.233 8.87511 2.14827 8.91467 2.05466C8.95423 1.96105 8.97461 1.86045 8.97461 1.75883C8.97461 1.6572 8.95423 1.55661 8.91467 1.46299C8.87511 1.36938 8.81718 1.28466 8.7443 1.21383C8.58804 1.06467 8.38032 0.981445 8.1643 0.981445C7.94828 0.981445 7.74056 1.06467 7.5843 1.21383Z"
      fill="#C06ECC"
    />
  </svg>
);

export default ArrowLeft;
