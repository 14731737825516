import React from "react";
import { components } from "react-select";
import ArrowDown from "../../assets/icon/ArrowDown";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDown />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
