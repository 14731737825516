import { useRef } from "react";
import { Flex, IconButton, Td, Tr } from "@chakra-ui/react";
import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import CellCity from "./CellCity";
import { keyHandler } from "../../utils/keyHandler";

const RowWarehouse = ({ id, name, address, onSelect, onDelete }) => {
  const ref = useRef(null);

  const handleClick = () => {
    onSelect(id);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete();
  };

  const handleSelectWarehouse = (event) => keyHandler(event.key, () => onSelect(id));

  return (
    <Tr ref={ref} onClick={handleClick} tabIndex={0} onKeyDown={handleSelectWarehouse} style={{ cursor: "pointer" }}>
      <CellCity value={name} />
      <CellCity value={address} />
      <Td w="10%">
        <Flex justifyContent="end" gap="16px">
          <IconButton
            w="16px"
            height="16px"
            variant="unstyled"
            onClick={handleDelete}
            icon={<DeleteIcon />}
            aria-label="Delete"
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default RowWarehouse;
