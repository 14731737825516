import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  editClient,
  getClients,
  getOneClient,
  getReportClients,
  resetPassword,
  getClientOrders,
  changeUserBonusBallance,
  getClientBonusInfo,
} from "../../../services/clientService";
import { SLICE_NAME } from "./types";
import { generateFile } from "../../../utils/generateFile";

export const getClientsThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetClients`,
  async (params, { rejectWithValue }) => {
    try {
      const clients = await getClients(params);

      return clients.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const createReportClientsThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchCreateClientsReport`,
  async (params, { rejectWithValue }) => {
    try {
      const result = await getReportClients(params);

      generateFile(result.data.data);

      return result.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getClientOrdersThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchOrders`,
  async (params, { rejectWithValue }) => {
    try {
      const orderResponse = await getClientOrders(params, params.id);

      return { orders: orderResponse.data.data, orderCount: orderResponse.data.count };
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getClientBonusesThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchBonuses`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await getClientBonusInfo(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const changeClientBonusesThunk = createAsyncThunk(
  `${SLICE_NAME}/changeBonuses`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await changeUserBonusBallance(params);

      const newBonusData = await getClientBonusInfo(params.id);

      return newBonusData.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getClientThunk = createAsyncThunk(`${SLICE_NAME}/fetchClient`, async (id, { rejectWithValue }) => {
  try {
    const requests = [getOneClient(id), getClientOrders({}, id), getClientBonusInfo(id)];

    const [clientResponse, orderResponse, bonusResponse] = await Promise.allSettled(requests);

    return {
      ...clientResponse.value.data,
      orders: orderResponse.status === "fulfilled" ? orderResponse.value.data.data : {},
      orderCount: orderResponse.status === "fulfilled" ? orderResponse.value.data.count : 0,
      bonuses: bonusResponse.status === "fulfilled" ? bonusResponse.value.data : {},
    };
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const editingClientThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchEditClient`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await editClient(params);

      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  `${SLICE_NAME}/resetPasswordClient`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await resetPassword(id);

      return response.data.password;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
