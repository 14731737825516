import { createAsyncThunk } from "@reduxjs/toolkit";

import { addCity, deleteCity, editCity, getCities } from "../../../../services/cityService";

import { SLICE_NAME } from "./types";

export const getAllCities = createAsyncThunk(`${SLICE_NAME}/fetchGetCities`, async (values, { rejectWithValue }) => {
  try {
    const cities = await getCities();
    return cities.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const addingCity = createAsyncThunk(`${SLICE_NAME}/fetchAddCity`, async (params, { rejectWithValue }) => {
  try {
    return await addCity(params);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const deletingCity = createAsyncThunk(`${SLICE_NAME}/fetchDeleteCity`, async (id, { rejectWithValue }) => {
  try {
    return await deleteCity(id);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const editingCity = createAsyncThunk(`${SLICE_NAME}/fetchEditCity`, async (params, { rejectWithValue }) => {
  try {
    return await editCity(params);
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});
