import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";

export const getPromocodes = () => (state) => state[SLICE_NAME].promocodes;
export const promocodesIsGetted = () => (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const promocodesIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const promocodesIsLoading = (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const promocodesToast = () => (state) => state[SLICE_NAME].toast;
export const promocodesToastIsView = () => (state) => state[SLICE_NAME].toast.view;
export const getPromocodeState = (state) => state[SLICE_NAME].promocode;
