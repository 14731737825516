import { Icon, Image, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import Preloader from "../Preloader";

import { AiOutlineWarning } from "react-icons/ai";

export const ImageWithLoader = ({ src, height, width, maxHeight, objectFit, maxWidth, ...props }) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [hasImageLoadError, setIsImageError] = useState(false);

  return (
    <>
      <Image
        display={isImageLoading && "none"}
        maxWidth={maxWidth}
        width={width}
        maxHeight={maxHeight}
        height={height}
        onLoad={() => setIsImageLoading(false)}
        onError={() => setIsImageError(true)}
        src={src}
        objectFit={objectFit}
        {...props}
      />

      {hasImageLoadError && (
        <Tooltip color="black" bg="white" label="Избражение не загрузилось, пожалуйста замените его">
          <span>
            <Icon
              as={AiOutlineWarning}
              height={height ?? maxHeight}
              maxHeight={maxHeight}
              maxWidth={maxWidth}
              width={width ?? maxWidth}
            />
          </span>
        </Tooltip>
      )}

      {isImageLoading && !hasImageLoadError && (
        <Preloader width={width} maxWidth={maxWidth} height={height} maxHeight={maxHeight} {...props} />
      )}
    </>
  );
};
