import TableRow from "./TableRow";
import { CONTAINER_STYLE } from "./style";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";
import { useSelector } from "react-redux";
import { isStoriesLoading } from "./store/Stories.selectors";
import logoClicksSvg from "../../assets/svg/Clicks.svg";
import logoViewsSvg from "../../assets/svg/Views.svg";

const TheadColumns = [
  { name: "Дата", width: 10 },
  { name: "Обложка", width: 10 },
  { name: "Название", width: 30 },
  { name: "Просмотры", img: logoViewsSvg, width: 5 },
  { name: "Клики", img: logoClicksSvg, width: 5 },
  { name: "Время жизни", width: 15 },
  { name: "Статус", width: 15 },
  { name: "", width: 10 },
];

const StoriesTable = ({ stories = [], onEdit = () => {}, onDelete }) => {
  const isLoading = useSelector(isStoriesLoading());

  const storyRow = (story) => (
    <TableRow
      key={`stories-${story.id}`}
      onEdit={() => onEdit(story)}
      onDelete={() => onDelete({ ...story, name: story.title })}
      {...story}
    />
  );

  return (
    <TableConstructor
      isLoading={isLoading}
      notFoundText="Здесь пока нет историй"
      Row={storyRow}
      theadItems={TheadColumns}
      items={stories}
      containerStyles={CONTAINER_STYLE}
    />
  );
};

export default StoriesTable;
