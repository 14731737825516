import { useCallback, useState } from "react";

export const useSearch = (getData, { transform = null, defaultData = null } = {}) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(defaultData);

  const search = useCallback(
    async (...searchParams) => {
      setLoading(true);
      let fetchedData = (await getData(...searchParams)).data;
      if (transform) {
        fetchedData = transform(fetchedData);
      }
      setLoading(false);
      setData(fetchedData);
    },
    [getData, transform]
  );

  const clear = () => {
    setData(null);
  };

  return {
    isLoading,
    data,
    search,
    clear,
    setLoading,
  };
};
