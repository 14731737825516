import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  editUser,
  getUnreviewedUsers,
  getUsers,
  patchApproveUser,
  patchRejectUser,
  getUserRequest,
} from "../../../services/userService";

import { SLICE_NAME } from "./types";

export const getUsersThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetUsers`,
  async (params, { rejectWithValue, fulfillWithValue }) => {
    try {
      const users = await getUsers(params);

      return users.data;
    } catch (err) {
      if (!err?.status) {
        return fulfillWithValue([]);
      }
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const approveUserThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchPutchApproveUser`,
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const user = await patchApproveUser(id, body);

      return user.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getUserThunk = createAsyncThunk(`${SLICE_NAME}/fetchUsersRequest`, async (id, { rejectWithValue }) => {
  try {
    const user = await getUserRequest(id);

    return user.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const rejectUserThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchPutchRejectUser`,
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const user = await patchRejectUser(id, body);

      return user.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const updateUserThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchUpdateUser`,
  async (params, { rejectWithValue }) => {
    try {
      const order = await editUser(params);
      return order.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getUnreviewedUsersThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetUnreviewedUsers`,
  async (params, { rejectWithValue }) => {
    try {
      const users = await getUnreviewedUsers();
      return users.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getMoreUnreviewedUsersThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetMoreUnreviewedUsers`,
  async (params, { rejectWithValue }) => {
    try {
      const users = await getUnreviewedUsers();
      return users.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
