import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { CONTAINER_STYLE } from "../../pages/Users/style";
import Preloader from "../Preloader";

export const TableConstructor = ({
  isLoading,
  Row,
  theadExtraComponent,
  extraComponent,
  footerItem,
  theadItems = [],
  headersWithoutSpecialStyle = [],
  containerStyles = CONTAINER_STYLE,
  items = [],
  preloadHeight = "",
  notFoundText = "Здесь пока ничего нет",
  isHeaderNeed = true,
}) => {
  const cellsCount = theadExtraComponent ? theadItems.length + 1 : theadItems.length;

  return (
    <TableContainer sx={containerStyles}>
      <Table>
        {headersWithoutSpecialStyle.length !== 0 ? (
          <Tr>
            {headersWithoutSpecialStyle.map((header) => (
              <Td key={header}>{header}</Td>
            ))}
          </Tr>
        ) : (
          <Thead>
            <Tr>
              {theadExtraComponent}
              {theadItems.map((theadColl, index) => (
                <Th key={index} w={`${theadColl.width}%`} padding={isHeaderNeed ? "10px" : "0px"}>
                  {theadColl.img ? <img src={theadColl.img} alt={theadColl.name} /> : theadColl.name}
                </Th>
              ))}
            </Tr>
          </Thead>
        )}

        <Tbody>
          {isLoading && (
            <Tr>
              <Td colSpan={cellsCount} textAlign="center" borderBottom="none">
                <Preloader height={preloadHeight ?? "500"} />
              </Td>
            </Tr>
          )}

          {!isLoading && items?.length === 0 && (
            <Tr>
              <Td colSpan={cellsCount} textAlign="center" borderBottom="none">
                {notFoundText}
              </Td>
            </Tr>
          )}

          {!isLoading && !!items?.length && items.map(Row)}
        </Tbody>
        {extraComponent}

        {footerItem}
      </Table>
    </TableContainer>
  );
};
