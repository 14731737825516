import { useCallback, useRef, useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Image,
  Input,
  Td,
  Tooltip,
  Tr,
  Img,
} from "@chakra-ui/react";

import { UploadInput } from "../../components/UploadInput/UploadInput";

import { siteUrl } from "../../utils/siteUrl";

import { ReactComponent as DownloadIcon } from "../../assets/svg/DownloadIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import { ReactComponent as SubmitIcon } from "../../assets/svg/SubmitIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/svg/CancelIcon.svg";
import { removeSpaces } from "../../utils/removeSpaces";
import { handleEnterKey } from "../../utils/blurOnEnter";
import { useIsOverflow } from "../../hooks/hooks";
import { MAX_MARGIN, TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { checkForFractionalOrIntValue } from "../../utils/checkForFractionalOrIntValue";
import { ImageWithLoader } from "../../components/ImageWithLoader/ImageWithLoader";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";

export const BrandComponent = (props) => {
  const { brand, editState, isEditMode, onDelete, onSubmit, onEdit } = props;

  const fileSelector = useRef();
  const ref = useRef(null);

  const [isOverflow] = useIsOverflow(ref);

  const getInitialBrandChange = () => {
    const value = brand?.extraCharge.toString();
    if (value === "undefined") {
      return "";
    }
    return value;
  };

  const [brandName, setBrandName] = useState(brand?.name || "");
  const [selectedImage, setImage] = useState("");
  const [fileImage, setFileImage] = useState(null);
  const [brandExtraCharge, setBrandExtraCharge] = useState(getInitialBrandChange);

  const srcImage = `${siteUrl}/brand/${brand.icon}`;

  const handleCancelEditMode = () => {
    setBrandName(brand?.name);
    setBrandExtraCharge(brand?.extraCharge);
    setImage(null);
    editState.reset();
  };

  const handleChangeFile = useCallback(
    (file) => {
      const getFilePath = () => {
        const reader = new FileReader();

        reader.onload = () => {
          const url = reader.result;
          setImage(url);
        };

        reader.readAsDataURL(file);
      };

      setFileImage(file);
      getFilePath(file);
    },
    [setFileImage]
  );

  const handleChangeBrandName = (e) => {
    setBrandName(e.target.value);
    editState.resetError("name");
  };

  const handleEdit = () => {
    editState.setId(brand.id);
  };

  const handleDelete = () => {
    onDelete(brand);
  };

  const handleCancel = () => {
    handleCancelEditMode();
  };

  const handleChangeBrandExtraCharge = ({ target: { value } }) => {
    editState.resetError("extraCharge");
    if (value.replace(",", ".") > MAX_MARGIN) {
      return;
    }

    checkForFractionalOrIntValue(value, setBrandExtraCharge);
  };

  const handleSubmit = () => {
    try {
      const name = removeSpaces(brandName);
      const extraChargeInt = Number(brandExtraCharge);

      if (!fileImage && brand.name === name && extraChargeInt === brand?.extraCharge) {
        editState.setId(null);
        return;
      }

      setBrandName(name);
      setBrandExtraCharge(brandExtraCharge);
      onSubmit(fileImage, name, brandExtraCharge, brand.id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Tr borderBottom="1px solid #EBECEF" height="131px">
      <Td w="10%">
        {isEditMode ? (
          <Box maxW="111px">
            <UploadInput
              ref={fileSelector}
              onChangeFile={handleChangeFile}
              formats={[".jpg", ".jpeg", ".png"]}
              maxFileSize={400}
            />
            {selectedImage ? (
              <Img src={selectedImage} />
            ) : (
              <IconButton
                backgroundSize="contain"
                backgroundImage={selectedImage || srcImage}
                backgroundRepeat="no-repeat"
                backgroundPosition="center center"
                height="111px"
                w="111px"
                icon={<DownloadIcon />}
                onClick={() => fileSelector.current.click()}
                aria-label="Load icon"
                _hover={{ opacity: "0.7" }}
              />
            )}
          </Box>
        ) : (
          <ImageWithLoader maxHeight="111px" maxWidth="111px" objectFit="cover" alt={brand.name} src={srcImage} />
        )}
      </Td>
      <Td w="30%" ref={ref} style={TEXT_OVERFLOW_STYLE} alignItems="center" justifyContent="space-between">
        {/* eslint-disable-next-line no-nested-ternary */}
        {isEditMode ? (
          <FormControlWithError hasError={editState?.error?.name} errorText={editState?.error?.name}>
            <Input
              maxW="314px"
              value={brandName}
              onChange={handleChangeBrandName}
              onKeyDown={handleEnterKey}
              autoComplete="off"
            />
          </FormControlWithError>
        ) : isOverflow ? (
          <Tooltip label={brand.name} color="black" bg="white" placement="bottom-start">
            <span>{brand.name}</span>
          </Tooltip>
        ) : (
          brand.name
        )}
      </Td>
      <Td w="30%">
        {isEditMode ? (
          <FormControlWithError hasError={editState?.error?.extraCharge} errorText={editState?.error?.extraCharge}>
            <Input
              maxW="314px"
              value={brandExtraCharge}
              onChange={handleChangeBrandExtraCharge}
              onKeyDown={handleEnterKey}
              autoComplete="off"
            />
          </FormControlWithError>
        ) : (
          brand.extraCharge
        )}
      </Td>
      <Td size="sm" w="10%" onClick={(e) => e.stopPropagation()}>
        <Flex onClick={(e) => e.stopPropagation()} gap="16px">
          <IconButton
            style={{ display: !isEditMode ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<SubmitIcon />}
            onClick={handleSubmit}
            aria-label="submit"
          />
          <IconButton
            style={{ display: !isEditMode ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<CancelIcon />}
            onClick={handleCancel}
            aria-label="cancel"
          />

          <IconButton
            style={{ display: isEditMode ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<EditIcon />}
            onClick={handleEdit}
            aria-label="edit"
          />
          <IconButton
            style={{ display: isEditMode ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<DeleteIcon />}
            onClick={handleDelete}
            aria-label="delete"
          />
        </Flex>
      </Td>
    </Tr>
  );
};
