import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { employeesIsLoading, getEmployees } from "../store/Employees.selectors";
import { getEmployeesThunk } from "../store/Employees.thunk";
import { Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { CONTAINER_STYLE } from "../../CashbackSettings/style";
import Preloader from "../../../components/Preloader";
import { TableRow } from "./TableRow";

const TheadColumns = ["ФИ", "Контактные данные", "Должность", ""];

export const EmployeesTable = () => {
  const dispatch = useDispatch();

  const employees = useSelector(getEmployees);
  const isLoading = useSelector(employeesIsLoading);

  useEffect(() => {
    dispatch(getEmployeesThunk());
  }, []);

  return (
    <>
      {isLoading && (
        <Flex w="100%" maxH="500px" alignItems="center" justifyContent="center">
          <Preloader />
        </Flex>
      )}

      {!isLoading && (
        <TableContainer sx={CONTAINER_STYLE}>
          <Table>
            <Thead>
              <Tr>
                {TheadColumns.map((name, index) => (
                  <Th key={index}>{name}</Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {employees.length &&
                employees.map((employee) => <TableRow key={`employee-${employee.id}`} employee={employee} />)}

              {employees.length === 0 && !isLoading && (
                <Tr>
                  <Td colSpan="3" textAlign="center" borderBottom="none">
                    Здесь пока нет сотрудников
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};
