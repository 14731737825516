import { Box, Input } from "@chakra-ui/react";
import { FormControlWithError } from "../../../components/FormControlWithError/FormControlWithError";
import { PhonePushPreview } from "../Components/PhonePushPreview";

import { useFormikContext } from "formik";

export const NameAndTitleFormPart = ({ isFormBlockedForEditing }) => {
  const { values, errors, handleChange } = useFormikContext();

  return (
    <Box width="410px">
      <FormControlWithError title="Заголовок уведомления*" hasError={errors.title} errorText={errors.title}>
        <Input name="title" value={values.title} onChange={handleChange} disabled={isFormBlockedForEditing} />
      </FormControlWithError>

      <FormControlWithError title="Текст уведомления*" hasError={errors.description} errorText={errors.description}>
        <Input
          name="description"
          value={values.description}
          onChange={handleChange}
          disabled={isFormBlockedForEditing}
        />
      </FormControlWithError>

      <PhonePushPreview title={values.title} description={values.description} />
    </Box>
  );
};
