export const CDEK = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="12.5" fill="#008536" />
      <path
        d="M3.96208 20.3723C3.95733 20.4438 4.0503 20.4723 4.09225 20.4142C9.60448 12.7778 13.9874 10.1346 22.7872 7.49036C22.8683 7.46601 22.8467 7.34388 22.7621 7.34708C12.3662 7.74092 7.8161 7.50049 3.97862 5.75809C3.95919 5.74927 3.93561 5.7494 3.91794 5.76137C3.13367 6.2925 6.76412 11.0541 6.39052 10.9884C6.02043 10.9233 4.21401 16.5794 3.96208 20.3723Z"
        fill="white"
      />
      <path
        d="M5.59667 19.8379C5.64609 19.5002 5.78779 19.1808 6.00671 18.9191C10.471 13.5808 13.5404 11.3889 19.8861 9.48285C19.8928 9.48084 19.8999 9.47977 19.9069 9.47977H24.0239C24.1062 9.47977 24.1199 9.59752 24.0415 9.62241C13.9496 12.8262 9.74496 15.7442 5.4188 22.0082C5.37548 22.0709 5.2753 22.034 5.28634 21.9585L5.59667 19.8379Z"
        fill="white"
      />
      <path
        d="M3.96208 20.3723C3.95733 20.4438 4.0503 20.4723 4.09225 20.4142C9.60448 12.7778 13.9874 10.1346 22.7872 7.49036C22.8683 7.46601 22.8467 7.34388 22.7621 7.34708C12.3662 7.74092 7.8161 7.50049 3.97862 5.75809C3.95919 5.74927 3.93561 5.7494 3.91794 5.76137C3.13367 6.2925 6.76412 11.0541 6.39052 10.9884C6.02043 10.9233 4.21401 16.5794 3.96208 20.3723Z"
        stroke="white"
        strokeWidth="0.143678"
      />
      <path
        d="M5.59667 19.8379C5.64609 19.5002 5.78779 19.1808 6.00671 18.9191C10.471 13.5808 13.5404 11.3889 19.8861 9.48285C19.8928 9.48084 19.8999 9.47977 19.9069 9.47977H24.0239C24.1062 9.47977 24.1199 9.59752 24.0415 9.62241C13.9496 12.8262 9.74496 15.7442 5.4188 22.0082C5.37548 22.0709 5.2753 22.034 5.28634 21.9585L5.59667 19.8379Z"
        stroke="white"
        strokeWidth="0.143678"
      />
    </svg>
  );
};
