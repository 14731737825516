export const CurrencyIcon = ({ color = "#100F11" }) => {
  return (
    <div style={{ marginLeft: "2px" }}>
      <svg width="11" height="17" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.848 10V0.199999H5.698C6.958 0.199999 7.952 0.479999 8.68 1.04C9.408 1.59067 9.772 2.398 9.772 3.462C9.772 4.526 9.408 5.338 8.68 5.898C7.952 6.458 6.958 6.738 5.698 6.738H2.632L3.262 6.08V10H1.848ZM3.262 6.22L2.632 5.576H5.698C6.55667 5.576 7.21467 5.394 7.672 5.03C8.12933 4.666 8.358 4.148 8.358 3.476C8.358 2.81333 8.12933 2.30467 7.672 1.95C7.21467 1.59533 6.55667 1.418 5.698 1.418H2.632L3.262 0.717999V6.22ZM0.42 8.362V7.564H6.37V8.362H0.42Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
