import { css } from "@emotion/react";

export const GlobalStyles = css`
  .chakra-input[aria-invalid="true"][disabled] {
    border-color: #e53e3e;
    box-shadow: 0 0 0 1px #e53e3e;
    opacity: 1;
    color: #37393d;

    &::placeholder {
      color: rgb(226, 232, 240);
    }
  }

  input:-webkit-autofill {
    box-shadow: inset 0 0 0 1000px #fff;
  }

  input:-webkit-autofill:focus {
    box-shadow: inset 0 0 0 1000px #fff;
  }

  .react-datepicker {
    font-family: "Inter", sans-serif;
    border: 1px solid #f7ecf8;
    box-shadow: 0px 4px 8px rgba(76, 93, 112, 0.3), 0px 0px 1px rgba(76, 93, 112, 0.3);
    border-radius: 8px;

    &__moth-container {
      border: none;
    }

    &__triangle::after {
      border-bottom-color: #f8f8fb;
      border-top-color: transparent;
    }

    &__triangle::before {
      border-top-color: #f8f8fb;
      border-bottom-color: transparent;
    }

    &__header {
      border-radius: 8px;
      border-bottom: none;
      background: #f7ecf8;
    }

    &__close-icon {
      padding-right: 18px;
    }

    &__day {
      &:hover {
        background-color: #eed9f2;
        border-radius: 50%;
      }

      &--keyboard-selected {
        background-color: #f7ecf8;
        border-radius: 50%;
      }

      &--selected,
      &--in-range {
        border-radius: 50%;
        background-color: #c06ecc;
      }

      &-name {
        font-weight: 600;
      }
    }
  }
`;
