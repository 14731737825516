import * as yup from "yup";
import { SPECIALIST, RETAIL } from "../Banners/constants";

export const COURSE_VALIDATION_SCHEMA = () =>
  yup.object({
    name: yup.string().required('Поле "Название" обязательное'),
    description: yup.string().required('Поле "Описание" обязательное'),
    dateRange: yup
      .array()
      .required('Поле "Даты проведения" обязательное')
      .min(2, 'Поле "Даты проведения" должна обладать началом и концом')
      .max(2, 'Поле "Даты проведения" должна обладать началом и концом')
      .test("isCorrectDateArray", "Необходимо выбрать начало и конец курса ", (value) => {
        return value.every((element) => element !== null);
      }),
    imageUrl: yup.string().required('Поле "Фото" обязательное'),
    courseFile: yup.string().required('Поле "Материалы" обязательное'),
    city: yup.object().required('Поле "Город" обязательное'),
  });

export const COURSES_STATUSES_NUMBERS = {
  FUTURE_COURSES: 0,
  COURSES_HISTORY: 1,
  COURSES_REQUEST: 2,
};

export const COURSES_TABLE_HEADERS = [
  { name: "Название курса", width: 30 },
  { name: "Описание курса", width: 30 },
  { name: "Даты проведения", width: 20 },
  { name: "", width: 10 },
];

export const COURSE_STATUS_TYPES = {
  ACTIVE: "ACTIVE",
  SCHEDULED: "SCHEDULED",
  PAST: "PAST",
};

export const COURSES_REQUESTS_TABLE_HEADERS = [
  { name: "Название курса", width: 30 },
  { name: "ФИО пользователя", width: 30 },
  { name: "Телефон пользователя", width: 20 },
];

export const BASE_DEBOUNCE_DELAY = 1000; // one second delay for debounce

export const COURSES_AVAILABLE_ROLES = [
  { name: "Все", value: [SPECIALIST, RETAIL] },
  { name: "Розничный пользователь", value: [RETAIL] },
  { name: "Специалист", value: [SPECIALIST] },
];
