import * as yup from "yup";

export const SPECIALIST = "SPECIALIST";
export const RETAIL = "RETAIL";
export const PERCENT = "PERCENT";
export const PRICE = "PRICE";
export const AVAILABLE_FOR = [
  { id: "0", name: "Розничный пользователь", value: RETAIL, position: 0 },
  { id: "1", name: "Специалист", value: SPECIALIST, position: 1 },
];

export const VALIDATION_BANNER_SCHEMA = ({ names, descriptions, images }) => {
  return yup.object({
    name: yup
      .string()
      .trim()
      .required("Поле “Заголовок” обязательное")
      .test("name", "Баннер с таким названием уже существует", (name) => {
        if (names.includes(name)) {
          return false;
        }
        return true;
      }),
    description: yup
      .string()
      .trim()
      .required("Поле “Краткое описание” обязательное")
      .test("name", "Баннер с таким описанием уже существует", (description) => {
        if (descriptions.includes(description)) {
          return false;
        }
        return true;
      }),
    image: yup
      .string()
      .required('Поле "Баннер" обязательное')
      .nullable()
      .test("image", "Баннер с таким изображением уже существует", (image) => {
        if (images.includes(image)) {
          return false;
        }
        return true;
      }),
    products: yup.array().min(1, "Баннер должен содержать не менее одного товара"),
    discount: yup.number().required(`Поле "Скидка" обязательное`).nullable(true),
  });
};

export const STYLES_TEXT_SELECTOR = {
  fontSize: "14px",
  color: "#37393D",
};

export const PURPOSES = [
  { value: SPECIALIST, label: "Специалистов" },
  { value: RETAIL, label: "Розничных пользователей" },
];

export const TYPES_DISCOUNT = [
  { value: PERCENT, label: "Процент" },
  { value: PRICE, label: "Цена" },
];

export const DEFAULT_PURPOSES_SPECIALIST = { value: SPECIALIST, label: "Специалистов" };
export const DEFAULT_PURPOSES_RETAIL = { value: RETAIL, label: "Розничных пользователей" };

export const MAX_LIMIT_SUM = 1000000;
