/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../store/common";

import {
  addingPromocode,
  deletingPromocode,
  editingPromocode,
  fetchPromocode,
  getAllPromocodes,
} from "./Promocodes.thunk";

import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  promocodes: [],
  promocode: { loadingState: LoadingState.IDLE, promocodeDetails: null },
};

export const {
  reducer,
  actions: { deletePromocodesToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deletePromocodesToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromocode.pending, (state) => {
        state.promocode.loadingState = LoadingState.PENDING;
      })
      .addCase(fetchPromocode.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.promocode.loadingState = LoadingState.REJECTED;
        state.promocode.promocodeDetails = null;
      })
      .addCase(fetchPromocode.fulfilled, (state, action) => {
        state.promocode.loadingState = LoadingState.FULFILLED;
        state.promocode.promocodeDetails = action.payload;
      })
      .addCase(getAllPromocodes.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getAllPromocodes.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllPromocodes.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.promocodes = action.payload;
      })
      .addCase(addingPromocode.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(addingPromocode.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Промокод добавлен",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingPromocode.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(deletingPromocode.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Промокод удален",
          description: "",
          status: "success",
        };
      })
      .addCase(editingPromocode.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(editingPromocode.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Промокод изменен",
          description: "",
          status: "success",
        };
      });
  },
});
