import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";

import TableRow from "./TableRow";
import { HINT_STYLE } from "../../common/constants";
import { CONTAINER_STYLE } from "./style";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";
import { useSelector } from "react-redux";
import { bannersIsLoading } from "./store/Banners.selectors";

const TheadColumns = [
  { name: "Заголовок", width: 80 },
  { name: "", width: 20 },
];

const BannersTable = ({ banners = [], onEdit = () => {}, onDelete }) => {
  const isLoading = useSelector(bannersIsLoading());

  const bannerRow = (banner) => (
    <TableRow {...banner} key={`banner-${banner.id}`} onEdit={() => onEdit(banner)} onDelete={() => onDelete(banner)} />
  );

  return (
    <TableConstructor
      Row={bannerRow}
      containerStyles={CONTAINER_STYLE}
      items={banners}
      footerItem={
        banners.length > 0 && (
          <Text style={HINT_STYLE} marginTop="16px" textAlign="start">
            Максимум 10 баннеров
          </Text>
        )
      }
      notFoundText="Здесь пока нет баннеров"
      theadItems={TheadColumns}
      isLoading={isLoading}
    />
  );
};

export default BannersTable;
