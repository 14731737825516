import { createPortal } from "react-dom";
import {
  Box,
  Button,
  css,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

import { ReactComponent as CloseIcon } from "../../assets/svg/CloseIcon.svg";
import { POPUP_Z_INDEX } from "../../common/constants";

const fromTextStyles = css`
  height: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #bcbfcc;
`;

const SelectedCoursesPopUp = ({ checkedItems = [], onDelete, onClose, isOpen }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered useInert={false} trapFocus={false}>
      <Flex
        backgroundColor="white"
        direction="column"
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "10%",
          zIndex: POPUP_Z_INDEX,
          width: "400px",
          alignItems: "center",
          boxShadow: "0px 4px 8px rgba(76, 93, 112, 0.3), 0px 0px 1px rgba(76, 93, 112, 0.3)",
          borderRadius: "8px",
        }}
      >
        <Button position="absolute" right={0} top={0} bgColor="white" onClick={onClose}>
          <CloseIcon />
        </Button>
        <Box padding="16px">
          <Flex align="center" justifyContent="center" width="100%">
            <Text css={fromTextStyles}>Количество выбранных семинаров: {checkedItems.size}</Text>
          </Flex>

          <Flex align="center" justifyContent="center" width="100%" style={{ paddingTop: "16px", alignItems: "end" }}>
            <Button bgColor="white" color="#C06ECC" onClick={onDelete}>
              Удалить
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Modal>
  );
};

export default SelectedCoursesPopUp;
