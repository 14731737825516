/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { deletingProtocolCategory } from "./store/ProtocolCategories.thunk";
import { NAME_STYLES } from "../../common/constants";

const DeleteCategory = ({ setDeleteCategory, deleteCategory }) => {
  const dispatch = useDispatch();
  return (
    <Modal isCentered isOpen={deleteCategory} onClose={() => setDeleteCategory(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalBody style={{ ...NAME_STYLES, fontSize: "20px" }}>
          Вы действительно хотите удалить категорию {`"${deleteCategory.name}"?`}
        </ModalBody>

        <ModalCloseButton />
        <form>
          <ModalFooter style={{ display: "flex", gap: "16px" }}>
            <Button
              colorScheme="red"
              onClick={() => {
                dispatch(deletingProtocolCategory(deleteCategory.id));
                setDeleteCategory(false);
              }}
            >
              Удалить
            </Button>
            <Button colorScheme="blue" mr={3} onClick={() => setDeleteCategory(false)}>
              Отмена
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default DeleteCategory;
