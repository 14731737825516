import { createAsyncThunk } from "@reduxjs/toolkit";

import { SLICE_NAME } from "./types";
import {
  applyOrder,
  cancelOrder,
  deleteOrder,
  editOrder,
  getOneOrder,
  getOrders,
  getPreviewOrder,
  getReportOrders,
  getUnreviewedOrders,
  updateOrder,
} from "../../../services/orderService";
import { generateFile } from "../../../utils/generateFile";

export const getOrdersThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetOrders`,
  async ({ orderStatus, ...params }, { rejectWithValue }) => {
    try {
      const orders = await getOrders(params, orderStatus);
      return orders.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getOneOrderThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetOneOrder`,
  async ({ id }, { rejectWithValue }) => {
    try {
      const order = await getOneOrder(id);
      return order.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const updateOrderThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchUpdateOneOrder`,
  async (params, { rejectWithValue }) => {
    try {
      const order = await editOrder(params);
      return order.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getUnreviewedOrdersThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetUnreviewedOrders`,
  async (params, { rejectWithValue }) => {
    try {
      const orders = await getUnreviewedOrders();
      return orders.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const createReportOrdersThunk = createAsyncThunk(
  `${SLICE_NAME}/createReportOrders`,
  async (params, { rejectWithValue }) => {
    try {
      const result = await getReportOrders(params);

      generateFile(result.data.data);

      return result.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const applyOrderThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchApplyOrder`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await applyOrder(params);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const updateDetailsOfOrderThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchUpdateOrder`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await updateOrder(params);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getPreviewOrderThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchPreviewOrder`,
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await getPreviewOrder(params);
      return data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const deleteOrderThunk = createAsyncThunk(`${SLICE_NAME}/deleteOrderThunk`, async (id, { rejectWithValue }) => {
  try {
    await deleteOrder(id);
    return id;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const cancelOrderThunk = createAsyncThunk(`${SLICE_NAME}/cancelOrderThunk`, async (id, { rejectWithValue }) => {
  try {
    await cancelOrder(id);
    return id;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});
