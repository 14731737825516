/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../../store/common";

import {
  addingCourse,
  deletingCourse,
  editingCourse,
  getAllCourses,
  getCourse as requestCourse,
  addCourseMediaThunk,
} from "./courses.thunk";
import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  course: { loadingState: LoadingState.IDLE, courseDetail: null },
  courses: [],
};

export const {
  reducer,
  actions: { getAgainCourse, deleteToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    getAgainCourse: (state) => {
      state.loadingState = LoadingState.IDLE;
    },
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestCourse.pending, (state) => {
        state.course.loadingState = LoadingState.PENDING;
      })
      .addCase(requestCourse.rejected, (state, { payload }) => {
        state.toast = {
          view: true,
          title: payload,
          description: payload,
          status: "error",
        };
        state.course.loadingState = LoadingState.REJECTED;
      })
      .addCase(requestCourse.fulfilled, (state, { payload }) => {
        state.course.loadingState = LoadingState.FULFILLED;
        state.course.courseDetail = payload;
      })
      .addCase(getAllCourses.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getAllCourses.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllCourses.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.courses = action.payload;
      })
      .addCase(addingCourse.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(addingCourse.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Курс добавлен",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingCourse.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(deletingCourse.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Курс удален",
          description: "",
          status: "success",
        };
      })
      .addCase(editingCourse.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(editingCourse.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Курс изменен",
          description: "",
          status: "success",
        };
      })
      .addCase(addCourseMediaThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка загрузки медиа",
          description: action.payload,
          status: "error",
        };
      });
  },
});
