import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";

import { DeleteItemModal } from "../../components/DeleteModals";
import PageLayout from "../../components/PageLayout/PageLayout";

import { deletingBanner, getAllBanners } from "./store/Banners.thunk";
import { bannersIsUpdated, bannersToast, bannersToastIsView, getBanners } from "./store/Banners.selectors";
import { deleteBannersToast } from "./store/Banners.slice";
import AddRow from "./AddRow";
import BannersTable from "./Table";
import { useCustomToast } from "../../hooks/useCustomToast";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import { RETAIL, SPECIALIST } from "./constants";
import { MAX_HEIGHT_BANNER_BODY } from "./style";
import { useNavigate, useSearchParams } from "react-router-dom";
import qs from "qs";

const Banners = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pushToast } = useCustomToast();

  const banners = useSelector(getBanners());
  const isNeedBannersUpdated = useSelector(bannersIsUpdated());
  const bannersToastInfo = useSelector(bannersToast());
  const bannersToastIsViewed = useSelector(bannersToastIsView());

  const [searchParams, setSearchParams] = useSearchParams();

  const [deleteBanner, setDelete] = useState(null);

  const currentTab = searchParams.get("currentTab") ?? SPECIALIST;

  const handleDelete = (id) => {
    dispatch(deletingBanner({ id }));
  };

  const requestBanners = useCallback(() => {
    dispatch(getAllBanners(currentTab));
  }, [currentTab, dispatch]);

  const cbToast = useCallback(() => {
    if (bannersToastInfo.view) {
      pushToast(bannersToastInfo);
      dispatch(deleteBannersToast());
    }
  }, [bannersToastInfo, pushToast, dispatch]);

  useUpdateEffect(() => {
    return () => {
      dispatch(deleteBannersToast());
    };
  }, []);

  useUpdateEffect(() => {
    cbToast();
  }, [bannersToastIsViewed, cbToast]);

  useUpdateEffect(() => {
    if (isNeedBannersUpdated) {
      requestBanners();
    }
  }, [isNeedBannersUpdated, requestBanners]);

  useEffect(() => {
    requestBanners();
  }, [requestBanners]);

  const handleEditBanner = (banner) => navigate({ pathname: banner.id, search: searchParams.toString() });

  return (
    <PageLayout>
      <AddRow onAdd={() => navigate({ pathname: "create", search: searchParams.toString() })} banners={banners} />

      <Tabs
        defaultIndex={currentTab === SPECIALIST ? 0 : 1}
        tabIndex={currentTab === SPECIALIST ? 0 : 1}
        height="calc(100% - 75px)"
        w="100%"
        onChange={(index) => setSearchParams(qs.stringify({ currentTab: index === 0 ? SPECIALIST : RETAIL }))}
      >
        <TabList borderBottom="none" marginBottom="8px">
          <Flex color="#737680">
            <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Специалисты</Tab>
            <Tab _selected={{ color: "dark.100", borderColor: "purple.500" }}>Розничные пользователи</Tab>
          </Flex>
        </TabList>

        <TabPanels height={MAX_HEIGHT_BANNER_BODY}>
          <TabPanel height="100%" p="16px 0">
            <BannersTable
              banners={banners}
              onEdit={handleEditBanner}
              onDelete={setDelete}
              count={banners.length}
              filterType={SPECIALIST}
            />
          </TabPanel>

          <TabPanel height="100%" p="16px 0">
            <BannersTable
              banners={banners}
              onEdit={handleEditBanner}
              onDelete={setDelete}
              count={banners.length}
              filterType={RETAIL}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      {deleteBanner && (
        <DeleteItemModal label="баннер" item={deleteBanner} onClose={() => setDelete(null)} onDelete={handleDelete} />
      )}
    </PageLayout>
  );
};
export default Banners;
