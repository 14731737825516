import { format } from "date-fns";
import { ru } from "date-fns/locale";

export const formatDateForInput = (date) => {
  if (!date) {
    return date;
  }

  const formattedDate = format(new Date(date), "MMMM dd", {
    locale: ru,
  });

  return formattedDate.at(0).toUpperCase() + formattedDate.slice(1);
};
