/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../store/common";
import { addingBrand, deletingBrand, editingBrand, getAllBrands } from "./Brands.thunk";
import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  brands: [],
};

export const {
  reducer,
  actions: { getAgainBrands, deleteToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    getAgainBrands: (state) => {
      state.loadingState = LoadingState.IDLE;
    },
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBrands.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getAllBrands.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload.message,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllBrands.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.brands = action.payload; // TODO: передавать нормально пайлод чтобы brands оставался массивом и поправить обращения по приложению
      })
      .addCase(addingBrand.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Бренд добавлен",
          description: "",
          status: "success",
        };
      })
      .addCase(addingBrand.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(deletingBrand.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Бренд удален",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingBrand.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      })
      .addCase(editingBrand.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Бренд изменен",
          description: "",
          status: "success",
        };
      })
      .addCase(editingBrand.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
      });
  },
});
