import { requestAxios } from "../api/axiosRequest";

export const getCashbacks = () => {
  return requestAxios.get(`/cashback`);
};

export const getCashbackItem = (id) => {
  return requestAxios.get(`/cashback/${id}`);
};

export const addCashback = (payload) => {
  return requestAxios.post(`/cashback`, payload);
};

export const deleteCashback = ({ id }) => {
  return requestAxios.delete(`/cashback/${id}`);
};

export const editCashback = async ({ id, objToSend }) => {
  return requestAxios.patch(`/cashback/${id}`, objToSend);
};

export const fetchCashback = (id) => requestAxios.get(`/cashback/${id}`);
