import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addCategory,
  deleteCategory,
  editCategory,
  getCategories,
  updatePositionCategory,
} from "../../../../services/categoryService";

import { gettingSubcategories } from "../Subcategories/Subcategories.thunk";
import { SLICE_NAME } from "./types";

export const gettingCategories = createAsyncThunk(
  `${SLICE_NAME}/fetchgGetCategories`,
  async (values, { rejectWithValue }) => {
    try {
      const res = await getCategories();

      return res.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const addingCategory = createAsyncThunk(
  `${SLICE_NAME}/fetchgAddCategory`,
  async (values, { rejectWithValue }) => {
    try {
      return await addCategory(values);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const editingCategory = createAsyncThunk(
  `${SLICE_NAME}/fetchgEditCategory`,
  async ({ id, name }, { rejectWithValue }) => {
    try {
      return await editCategory(id, name);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const updatingPositionCategory = createAsyncThunk(
  `${SLICE_NAME}/fetchgUpdateCategory`,
  async (values, { rejectWithValue }) => {
    try {
      return await updatePositionCategory(values);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const deletingCategory = createAsyncThunk(
  `${SLICE_NAME}/fetchgDeleteCategory`,
  async (values, { rejectWithValue }) => {
    try {
      return await deleteCategory(values);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const gettingCategoriesWithSubCategories = createAsyncThunk(
  `${SLICE_NAME}/fetchgGettingAllShit`,
  // eslint-disable-next-line consistent-return
  async (values, { rejectWithValue, dispatch }) => {
    try {
      await dispatch(gettingCategories());
      await dispatch(gettingSubcategories());
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
