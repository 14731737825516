import React, { useMemo, useEffect } from "react";
import { getBrands } from "../Brands/store/Brands.selectors";
import { useSelector, useDispatch } from "react-redux";
import { getAllBrands } from "../Brands/store/Brands.thunk";
import Autocomplete from "../../components/Autocomplete";

export const BrandsSelector = ({ value, onChange, ...props }) => {
  const dispatch = useDispatch();
  const brands = useSelector(getBrands()).data;

  useEffect(() => {
    dispatch(getAllBrands());
  }, []);

  const itemsForBrands = useMemo(() => brands ?? [], [brands]);

  return (
    <Autocomplete placeholder="Выберите бренд..." value={value} items={itemsForBrands} onChange={onChange} {...props} />
  );
};
