import { requestAxios } from "../api/axiosRequest";

export const getCities = () => {
  return requestAxios.get(`/cities`);
};

export const addCity = ({ name, address }) => {
  return requestAxios.post(`/cities`, { name, address });
};

export const deleteCity = ({ id }) => {
  return requestAxios.delete(`/cities/${id}`);
};

export const editCity = async ({ id, name, address }) => {
  return requestAxios.patch(`/cities/${id}`, { name, address });
};
