import * as yup from "yup";
import { RETAIL, SPECIALIST } from "../Banners/constants";

export const ACHIEVEMENTS_OPTIONS = [
  { value: SPECIALIST, label: "Специалисты" },
  { value: RETAIL, label: "Розничные пользователи" },
];

export const PROMOTION_VALIDATION_SCHEMA = yup.object({
  name: yup.string().trim().required('Поле "Название" обязательное'),
  endDate: yup.date().required('Поле "Дата окончания" обязательное').nullable(),
  image: yup
    .string()
    .trim()
    .required('Поле "Изображение" обязательное')
    .nullable()
    .url('Поле "Изображение" должно быть ссылкой'),
  availableFor: yup.mixed().required('Поле "Доступность" обязательное').nullable(),
});
