import { Flex } from "@chakra-ui/react";
import { PAYMENT_TYPES } from "../constants";
import { ReactComponent as CrossIcon } from "../../../assets/svg/CrossIcon.svg";
import { ReactComponent as OnlinePayIcon } from "../../../assets/svg/Payed.svg";
import { ReactComponent as CashPayIcon } from "../../../assets/svg/CashPayIcon.svg";

const PayStatusIcon = ({ isPayed, paymentType }) => {
  if (!isPayed) {
    return (
      <Flex bg="red.100" borderRadius="5px" width="40px" height="40px" align="center" justify="center">
        <CrossIcon />
      </Flex>
    );
  }

  if (paymentType === PAYMENT_TYPES.OFFICE_CASH || paymentType === PAYMENT_TYPES.COURIER_CASH) {
    return <CashPayIcon />;
  }

  return <OnlinePayIcon />;
};

export default PayStatusIcon;
