const getFilteredArrayByValue = (key, oldValue, array) => {
  return array.reduce((acc, el) => {
    if (el[key] !== oldValue) {
      acc.push(el[key]);
    }
    return acc;
  }, []);
};

export const generateObjForValidate = ({ oldName = "", oldDescription = "", oldImage = "", arr }) => {
  const names = getFilteredArrayByValue("name", oldName, arr);
  const descriptions = getFilteredArrayByValue("description", oldDescription, arr);
  const images = getFilteredArrayByValue("image", oldImage, arr);

  return { names, descriptions, images };
};
