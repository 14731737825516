import { IconButton, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useRef } from "react";
import ReactDatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import ru from "date-fns/locale/ru";

import { CalendarIcon } from "../../assets/icon/CalendarIcon";
import { DEFAULT_DATE_FORMAT } from "../../common/constants";
import { CrossIcon } from "../../assets/icon/CrossIcon";
import { InputOverflowText } from "../Autocomplete";
import customRuLocale from "../../utils/customRuLocale";

export const DatePicker = ({
  placeholder = "Введите дату",
  dateFormat = DEFAULT_DATE_FORMAT,
  selected = null,
  isClearable = true,
  ...props
}) => {
  const dateRef = useRef(null);

  return (
    <ReactDatePicker
      disabled={props.isDisabled}
      selected={selected}
      ref={dateRef}
      popperProps={{
        positionFixed: true,
        strategy: "fixed",
      }}
      locale={customRuLocale}
      autoComplete="off"
      placeholderText={placeholder}
      dateFormat={dateFormat}
      customInput={
        <MaskedInput
          mask={dateFormat === DEFAULT_DATE_FORMAT && [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
          keepCharPositions
          guide={false}
          readOnly={props.isReadOnly}
          showMask={false}
          render={(ref, customProps) => (
            <InputGroup>
              <InputOverflowText ref={ref} value={customProps.defaultValue} {...customProps} isReadOnly readOnly />{" "}
              <InputRightElement width={20} justifyContent="end">
                {selected && isClearable && (
                  <IconButton
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    variant="unstyled"
                    onClick={() => props.onChange(null)}
                    icon={<CrossIcon width="24" />}
                  />
                )}

                <IconButton
                  justifyContent="center"
                  alignItems="center"
                  variant="unstyled"
                  onClick={() => dateRef.current?.handleFocus()}
                  icon={<CalendarIcon width="36" />}
                />
              </InputRightElement>
            </InputGroup>
          )}
        />
      }
      {...props}
    />
  );
};
