export const convertCashbackToApi = (cashback) => {
  const defaultConvert = {
    percent: Number(cashback.percent),
    name: cashback.name,
  };

  if (cashback.products.length > 0) {
    return { ...defaultConvert, products: cashback.products.map((product) => product.id) };
  }

  if (cashback.brand) {
    return { ...defaultConvert, brandId: cashback.brand.id };
  }

  if (cashback.subcategory) {
    return { ...defaultConvert, subcategoryId: cashback.subcategory.id };
  }

  if (cashback.category) {
    return { ...defaultConvert, categoryId: cashback.category.id };
  }

  return defaultConvert;
};
