import { Flex } from "@chakra-ui/react";
import React from "react";

export const Option = ({ isActive, handleSelect, text }) => (
  <Flex
    _hover={{ bgColor: "gray.100" }}
    padding={1}
    justifyItems="center"
    cursor="pointer"
    flexDirection="column"
    backgroundColor={isActive ? "purple.100" : "transparent"}
    alignItems="center"
    onClick={handleSelect}
  >
    {text}
  </Flex>
);
