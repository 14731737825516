import React, { useEffect, useMemo } from "react";
import qs from "qs";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import { isEqual } from "lodash";
import { useFormik } from "formik";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import BrandProductsSelect from "../../components/BrandProductsSelect";

import { getProtocolState, getProtocols } from "./store/Protocol.selectors";
import { addingProtocol, editingProtocol, getProtocolById } from "./store/Protocol.thunk";
import { BrandSelect } from "../../components/BrandSelect/BrandSelect";

import { handleEnterKey } from "../../utils/blurOnEnter";
import { removeSpaces } from "../../utils/removeSpaces";
import { VALIDATION_SCHEMA } from "./constants";
import { PRODUCT_HINT } from "../Products/style";
import { Drawer } from "../../components/Drawer/Drawer";
import { FormControlWithError } from "../../components/FormControlWithError/FormControlWithError";

import { TEXT_STYLE } from "../../common/constants";
import { usePrompt } from "../../hooks/usePrompt";

const initialValues = {
  name: "",
  description: "",
  brand: null,
  products: [],
  isRetailAllowed: false,
};

const DrawerCreateProtocol = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedProtocolCategory = qs.parse(searchParams.toString());

  const { loadingState, protocolDetail: protocol } = useSelector(getProtocolState);
  const protocols = useSelector(getProtocols());
  const protocolsNamesWithoutCurrent = useMemo(
    () =>
      protocols
        .filter(
          (protocolInState) =>
            protocolInState?.protocol_category?.id === selectedProtocolCategory.id && protocolInState.id !== id
        )
        .map((el) => el.name),
    [id, protocols]
  );

  const handleSaveProtocol = (values) => {
    if (!isEqual(values, protocol)) {
      const protocolForSend = {
        ...values,
        name: removeSpaces(values.name),
        brand: values.brand.id,
        products: values.products.map((product) => product.id),
        protocol_category: selectedProtocolCategory.id,
      };

      if (id) {
        dispatch(editingProtocol({ id: protocol.id, values: protocolForSend }));
      } else {
        dispatch(addingProtocol(protocolForSend));
      }
    }

    navigate(-1);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: handleSaveProtocol,
    validationSchema: VALIDATION_SCHEMA(protocolsNamesWithoutCurrent),
  });

  useEffect(() => {
    if (id) {
      dispatch(getProtocolById(id));
    }
  }, [id]);

  useEffect(() => {
    if (protocol && id === protocol.id) {
      formik.resetForm({ values: protocol });
    }
  }, [id, protocol]);

  const handleDeleteProducts = (i) => {
    formik.setFieldValue(
      "products",
      formik.values.products.filter(({ id: stId }) => stId !== i)
    );
  };

  const onBrandChange = (value) => {
    if (value?.id !== formik.values.brand?.id) {
      formik.setFieldValue("brand", value);
      formik.setFieldValue("products", []);
    }
  };

  const { errors, values, handleChange, handleSubmit, dirty } = formik;

  usePrompt(!formik.isSubmitting && dirty);

  return (
    <Drawer
      isOpen
      isLoading={loadingState}
      handleClose={() => navigate(-1)}
      extraButtonHeader={
        <Button onClick={handleSubmit} colorScheme="purple" w="100%">
          Сохранить
        </Button>
      }
    >
      <FormControlWithError hasError={!!errors?.name} errorText={errors.name}>
        <Text>Название*</Text>

        <Input autoComplete="off" name="name" value={values.name} onChange={handleChange} onKeyDown={handleEnterKey} />
      </FormControlWithError>

      <FormControlWithError hasError={errors?.brand} errorText={errors.brand}>
        <Text>Бренд*</Text>
        <BrandSelect value={values.brand} error={errors?.brand} onChange={onBrandChange} />
      </FormControlWithError>

      <FormControlWithError errorText={errors.description} hasError={!!errors?.description}>
        <Text>Описание*</Text>
        <Textarea sx={TEXT_STYLE} name="description" value={values.description} onChange={handleChange} />
      </FormControlWithError>

      <Flex direction="column">
        <Text>Категория</Text>
        <Input isReadOnly isDisabled marginBottom="10px" value={selectedProtocolCategory.name} />
      </Flex>

      <Flex direction="column" mb="12px">
        <Checkbox isChecked={values.isRetailAllowed} name="isRetailAllowed" onChange={handleChange}>
          <Text>Доступен для розницы</Text>
        </Checkbox>
      </Flex>

      <Flex direction="column" mb="12px">
        <Text>Товары протокола</Text>
        <BrandProductsSelect
          brand={values.brand}
          products={values.products}
          onAdd={(product) => formik.setFieldValue("products", [...values.products, product])}
          onDelete={handleDeleteProducts}
          isRetail={values.isRetailAllowed}
          error={errors?.products && [errors?.products]}
          extraInputContent={<Text sx={PRODUCT_HINT}>Поиск осуществляется только по товарам выбранного бренда</Text>}
        />
      </Flex>
    </Drawer>
  );
};

export default DrawerCreateProtocol;
