import { commasLogicForSingleItem } from "./commasLogicForSingleItem";

export const getWarehousesValues = (warehouses, warehousesValues, warehouseIsLoading) => {
  if (warehouses.length === 0 || warehouseIsLoading) {
    return [];
  }

  if (warehousesValues.length === 0) {
    return [];
  }

  if (warehousesValues.length < 3) {
    return warehousesValues.map((valueWarehouseId, index) => {
      return {
        value: { payload: valueWarehouseId, isChecked: warehousesValues.includes(valueWarehouseId) },
        label: commasLogicForSingleItem(
          warehouses.find((serverWarehouse) => serverWarehouse.id === valueWarehouseId).city,
          index === warehousesValues.length - 1
        ),
      };
    });
  }

  return [
    {
      value: { payload: "all", isChecked: true },
      label: warehouses.length === warehousesValues.length ? "Все склады" : `Склады ${warehousesValues.length} `,
    },
    ...warehousesValues.map((valueWarehouseId) => {
      return {
        value: { payload: valueWarehouseId, isChecked: warehousesValues.includes(valueWarehouseId) },
        label: "",
      };
    }),
  ];
};
