import { Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const LinkRow = ({ text, path }) => (
  <Flex p="24px 8px" w="100%" borderBottom="1px solid #EBECEF">
    <Link to={path}>{text}</Link>
  </Flex>
);

export default LinkRow;
