import React from "react";

export const ProtocolIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V9C1.5 9.39782 1.65804 9.77936 1.93934 10.0607C2.22064 10.342 2.60218 10.5 3 10.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V10.5C21.3978 10.5 21.7794 10.342 22.0607 10.0607C22.342 9.77936 22.5 9.39782 22.5 9V4.5C22.5 4.10218 22.342 3.72064 22.0607 3.43934C21.7794 3.15804 21.3978 3 21 3ZM20.25 5.25V7.875H3.75V5.25H20.25ZM18.75 18.75H5.25V10.5H18.75V18.75ZM15 15H9C8.70163 15 8.41548 14.8815 8.2045 14.6705C7.99353 14.4595 7.875 14.1734 7.875 13.875C7.875 13.5766 7.99353 13.2905 8.2045 13.0795C8.41548 12.8685 8.70163 12.75 9 12.75H15C15.2984 12.75 15.5845 12.8685 15.7955 13.0795C16.0065 13.2905 16.125 13.5766 16.125 13.875C16.125 14.1734 16.0065 14.4595 15.7955 14.6705C15.5845 14.8815 15.2984 15 15 15Z"
        fill={isActive ? "white" : "black"}
      />
    </svg>
  );
};
