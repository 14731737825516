import {
  Button,
  Drawer as DrawerChakra,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  IconButton,
} from "@chakra-ui/react";
import { DRAWER_HEADER_STYLE, DRAWER_Z_INDEX } from "../../common/constants";
import { LoadingState } from "../../store/common";
import Preloader from "../Preloader";
import { checkMobileDevice } from "../../utils/checkMobileDevice";
import { ReactComponent as CloseIcon } from "../../assets/svg/CloseIcon.svg";

const isMobile = checkMobileDevice();

const EXTRA_HEADER_TEXT_STYLE = {
  color: "#737680",
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
  fontWeight: "400",
};

export const Drawer = ({
  children,
  isOpen,
  handleClose,
  extraButtonHeader,
  extraText,
  handleSave,
  handleCancelClick = null,
  isLoading = LoadingState.FULFILLED,
  isError = false,
  buttonCancelText = "Отменить",
  buttonSaveText = "Сохранить и закрыть",
  isSaveOptionDisabled = false,
}) => (
  <DrawerChakra
    sx={{ position: "absolute", zIndex: DRAWER_Z_INDEX }}
    size="lg"
    placement="left"
    isOpen={isOpen}
    onClose={handleClose}
  >
    {!isError && (
      <>
        <DrawerOverlay />
        <DrawerContent>
          {isLoading === LoadingState.PENDING ? (
            <DrawerBody sx={{ display: "flex", flexDirection: "column" }}>
              <Preloader />
            </DrawerBody>
          ) : (
            <>
              {isMobile && (
                <IconButton
                  display="flex"
                  alignItems="end"
                  justifyContent="end"
                  paddingRight={6}
                  variant="unstyled"
                  icon={<CloseIcon style={{ width: "15px", height: "15px" }} />}
                  onClick={() => {
                    handleCancelClick?.();
                    handleClose?.();
                  }}
                />
              )}

              <DrawerHeader sx={DRAWER_HEADER_STYLE} borderBottom="1px solid" borderColor="#D0D3DA">
                {extraButtonHeader || (
                  <Flex gap={3}>
                    <Button
                      w="50%"
                      variant="outline"
                      onClick={() => {
                        handleCancelClick?.();
                        handleClose?.();
                      }}
                    >
                      {buttonCancelText}
                    </Button>

                    <Button w="50%" onClick={handleSave} colorScheme="purple" isDisabled={isSaveOptionDisabled}>
                      {buttonSaveText}
                    </Button>
                  </Flex>
                )}

                {extraText && (
                  <Text fontSize="sm" sx={EXTRA_HEADER_TEXT_STYLE}>
                    {extraText}
                  </Text>
                )}
              </DrawerHeader>

              <DrawerBody sx={{ display: "flex", flexDirection: "column" }}>{children}</DrawerBody>
            </>
          )}
        </DrawerContent>
      </>
    )}
  </DrawerChakra>
);
