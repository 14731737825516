import { useCallback, useEffect, useRef, useState } from "react";
import { Checkbox, Td, Tooltip, Tr } from "@chakra-ui/react";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";

import { useIsOverflow } from "../../hooks/hooks";
import { keyHandler } from "../../utils/keyHandler";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";
import { StarIcon } from "../../assets/icon/StarIcon";
import { useDispatch } from "react-redux";
import { editingProductThunk } from "./store/Products.thunk";
import useDebounce from "../../hooks/useDebounce";

const CHECKBOX_COLUMN_STYLE = { maxWidth: 48, width: 48, padding: "14px 16px" };
const ARTICUL_COLUMN_STYLE = { maxWidth: 180, width: 180 };

const RowReadyProduct = ({
  id,
  name,
  nameFrom1C,
  codeFrom1C,
  checkedItems,
  onCheckItemClicked,
  openRequestModal,
  product,
  isBestseller,
}) => {
  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(isBestseller);
  const handleSelectProduct = (event) => keyHandler(event.key, () => openRequestModal(product));
  const currentBestseller = useDebounce(isActive, 500);

  const handlePushBestsellers = () => {
    const productForSend = { isBestseller: !isActive };
    setIsActive((prev) => !prev);
    const onClose = () => {};

    dispatch(editingProductThunk({ productForSend, id, onClose }));
  };

  return (
    <Tr key={`products-${id}`} maxH="60px">
      <Td textAlign="center" lineHeight={0}>
        <Checkbox isChecked={checkedItems.has(id)} onChange={onCheckItemClicked(id)} />
      </Td>

      <Td
        cursor="pointer"
        tabIndex={0}
        style={TEXT_OVERFLOW_STYLE}
        onKeyDown={handleSelectProduct}
        onClick={() => openRequestModal(product)}
        ref={ref}
      >
        <TooltipWithContent text={name || nameFrom1C || "-"} isOverflow={isOverflow} />
      </Td>
      <Td style={ARTICUL_COLUMN_STYLE}>{codeFrom1C || "-"}</Td>
      <Td style={ARTICUL_COLUMN_STYLE}>
        <button disabled={currentBestseller !== isActive} type="button" onClick={handlePushBestsellers}>
          <StarIcon isActive={isActive} />
        </button>
      </Td>
    </Tr>
  );
};

export default RowReadyProduct;
