import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestCoursesRequests } from "../../../../services/courseService";

import { SLICE_NAME } from "./types";

export const getAllRequestedCourses = createAsyncThunk(
  `${SLICE_NAME}/fetchGetSeminarRequests`,
  async (params, { rejectWithValue }) => {
    try {
      const course = await requestCoursesRequests(params);

      return course?.data.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
