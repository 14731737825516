import * as yup from "yup";
import { PASSWORD_VALIDATOR } from "../../pages/EmployeeModal/constants";

export const CHANGE_PASSWORD_VALIDATION_SCHEMA = yup.object().shape({
  oldPassword: yup
    .string()
    .trim()
    .required('Поле "Старый пароль" обязательное')
    .notOneOf([yup.ref("newPassword"), null], "Старый и новый пароли не должны совпадать"),
  newPassword: PASSWORD_VALIDATOR.required('Поле "Новый пароль" обязательное').notOneOf(
    [yup.ref("oldPassword"), null],
    "Старый и новый пароли не должны совпадать"
  ),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Пароли должны совпадать")
    .required('Поле "Повторите новый пароль" обязательное'),
});
