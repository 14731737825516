/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";
import {
  getUsersThunk,
  approveUserThunk,
  rejectUserThunk,
  updateUserThunk,
  getUnreviewedUsersThunk,
  getMoreUnreviewedUsersThunk,
  getUserThunk,
} from "./Users.thunk";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  users: [],
  user: { userDetail: null, loadingState: LoadingState.IDLE },
  unreviewedUsers: null,
};

export const {
  reducer,
  actions: { getAgainUsers, deleteToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    getAgainUsers: (state) => {
      state.loadingState = LoadingState.IDLE;
    },
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserThunk.pending, (state) => {
        state.user.loadingState = LoadingState.PENDING;
      })
      .addCase(getUserThunk.rejected, (state, { payload }) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: payload.message,
          status: "error",
        };

        state.user.loadingState = LoadingState.REJECTED;
      })
      .addCase(getUserThunk.fulfilled, (state, { payload }) => {
        state.user.loadingState = LoadingState.FULFILLED;

        state.user.userDetail = payload;
      })

      .addCase(getUsersThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getUsersThunk.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload.message,
          status: "error",
        };

        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getUsersThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;

        state.users = action.payload;
      })
      .addCase(approveUserThunk.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.unreviewedUsers -= 1;

        state.toast = {
          view: true,
          title: "Заявка одобрена",
          description: "",
          status: "success",
        };
      })
      .addCase(rejectUserThunk.fulfilled, (state) => {
        state.loadingState = LoadingState.IDLE;
        state.unreviewedUsers -= 1;

        state.toast = {
          view: true,
          title: "Заявка отклонена",
          description: "",
          status: "success",
        };
      })
      .addCase(updateUserThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
      })
      .addCase(updateUserThunk.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;

        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(updateUserThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getUnreviewedUsersThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;

        state.unreviewedUsers = action.payload;
      })
      .addCase(getMoreUnreviewedUsersThunk.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;

        state.unreviewedUsers = action.payload;
      })
      .addCase(getMoreUnreviewedUsersThunk.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;

        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(getUnreviewedUsersThunk.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;

        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(getUnreviewedUsersThunk.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      });
  },
});
