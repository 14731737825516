import { requestAxios } from "../api/axiosRequest";

export const getProtocolCategories = () => {
  return requestAxios.get(`/protocol-categories`);
};

export const addProtocolCategory = (name) => {
  return requestAxios.post(`/protocol-categories`, {
    name,
  });
};

export const updateProtocolCategory = (name, id) => {
  return requestAxios.patch(`/protocol-categories/${id}`, {
    name,
  });
};

export const deleteProtocolCategory = (id) => {
  return requestAxios.delete(`/protocol-categories/${id}`);
};
