/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../../store/common";

import {
  addingWarehouse,
  deletingWarehouse,
  editingWarehouse,
  getAllWarehouses,
  getWarehouseThunk,
} from "./Warehouses.thunk";

import { SLICE_NAME } from "./types";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  warehouses: [],
  warehouse: { warehouseDetail: null, loadingState: LoadingState.IDLE },
};

export const {
  reducer,
  actions: { getAgainWarehouses, deleteToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    getAgainWarehouses: (state) => {
      state.loadingState = LoadingState.IDLE;
    },
    deleteToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWarehouseThunk.pending, (state) => {
        state.warehouse.loadingState = LoadingState.PENDING;
      })
      .addCase(getWarehouseThunk.rejected, (state, { payload }) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: payload.message,
          status: "error",
        };
        state.warehouse.loadingState = LoadingState.REJECTED;
      })
      .addCase(getWarehouseThunk.fulfilled, (state, { payload }) => {
        state.warehouse.loadingState = LoadingState.FULFILLED;
        state.warehouse.warehouseDetail = payload;
      })

      .addCase(getAllWarehouses.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getAllWarehouses.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload.message,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllWarehouses.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.warehouses = action.payload;
      })
      .addCase(addingWarehouse.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Офис добавлен",
          description: "",
          status: "success",
        };
      })
      .addCase(addingWarehouse.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      })
      .addCase(deletingWarehouse.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "Офис удален",
          description: "",
          status: "success",
        };
      })
      .addCase(editingWarehouse.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.toast = {
          view: true,
          title: "",
          description: "Офис изменен",
          status: "success",
        };
      })
      .addCase(editingWarehouse.rejected, (state, action) => {
        state.loadingState = LoadingState.REJECTED;
        state.toast = {
          view: true,
          title: "Ошибка",
          description: action.payload,
          status: "error",
        };
      });
  },
});
