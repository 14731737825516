import { useRef } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";
import { useIsOverflow } from "../../hooks/hooks";

import { generateName } from "./helpers";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";
import { keyHandler } from "../../utils/keyHandler";

const FULL_WIDTH = "100";

const RowUserRequestTable = ({ user, onSelect }) => {
  const {
    isViewedByAdmin,
    user: { firmName, name, lastName, secondName, email, phone },
  } = user;

  const ref = useRef(null);
  const [isOverflow] = useIsOverflow(ref);

  const finalName = generateName(firmName, name, lastName, secondName);

  const handleSelectUser = (event) => keyHandler(event.key, () => onSelect(user));

  return (
    <Tr
      sx={{ backgroundColor: !isViewedByAdmin && "purple.50" }}
      cursor="pointer"
      tabIndex={0}
      onKeyDown={handleSelectUser}
      onClick={() => onSelect(user)}
    >
      <Td w={`${FULL_WIDTH / 3}%`} ref={ref}>
        <TooltipWithContent text={finalName} isOverflow={isOverflow} />
      </Td>
      <Td w={`${FULL_WIDTH / 3}%`}>{email}</Td>
      <Td w={`${FULL_WIDTH / 3}%`}>{phone}</Td>
    </Tr>
  );
};

export default RowUserRequestTable;
