import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

import PageLayout from "../../components/PageLayout/PageLayout";
import ProtocolWorkArea from "./ProtocolWorkArea";

import {
  protocolCategoriesIsUpdated,
  protocolCategoryToast,
  toastIsView as toastIsViewCategoryProtocol,
} from "../ProtocolCategories/store/ProtocolCategories.selectors";
import { gettingProtocolCategories } from "../ProtocolCategories/store/ProtocolCategories.thunk";
import { deleteToast as deleteCategoryProtocolToast } from "../ProtocolCategories/store/ProtocolCategories.slice";
import { getAllBrands } from "../Brands/store/Brands.thunk";

import { protocolIsUpdated, protocolToast, toastIsView as toastIsViewProtocol } from "./store/Protocol.selectors";
import { gettingProtocol } from "./store/Protocol.thunk";
import { deleteToast as deleteProtocolToast } from "./store/Protocol.slice";
import { useCustomToast } from "../../hooks/useCustomToast";
import useUpdateEffect from "../../hooks/useUpdateEffect";

const ProtocolsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  minHeight: "100%",
  height: "100%",
  gap: "24px",

  ".protocol-table-edit-icon": {
    display: "none",
  },

  tr: {
    "&:hover .protocol-table-edit-icon": {
      display: "block",
    },
  },

  td: {
    border: "none",
  },
});

const Protocols = () => {
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();

  const isProtocolCategoryNeedUpdate = useSelector(protocolCategoriesIsUpdated());
  const isProtocolNeedUpdated = useSelector(protocolIsUpdated());
  const toastIsViewedCategoryProtocol = useSelector(toastIsViewCategoryProtocol());
  const toastInfoCategoryProtocol = useSelector(protocolCategoryToast());
  const toastIsViewedProtocol = useSelector(toastIsViewProtocol());
  const toastInfoProtocol = useSelector(protocolToast());

  const request = useCallback(async () => {
    await dispatch(gettingProtocol()).unwrap();
    await dispatch(gettingProtocolCategories()).unwrap();
    await dispatch(getAllBrands()).unwrap();
  }, []);

  useUpdateEffect(() => {
    if (isProtocolCategoryNeedUpdate) {
      dispatch(gettingProtocolCategories());
    }
  }, [isProtocolCategoryNeedUpdate, dispatch]);

  useUpdateEffect(() => {
    if (isProtocolNeedUpdated) {
      dispatch(gettingProtocol());
    }
  }, [isProtocolNeedUpdated, dispatch]);

  useEffect(() => {
    request();
  }, []);

  useEffect(() => {
    if (toastInfoCategoryProtocol.view) {
      pushToast(toastInfoCategoryProtocol);
    }
    dispatch(deleteCategoryProtocolToast());
  }, [toastIsViewedCategoryProtocol, dispatch]);

  useEffect(() => {
    if (toastInfoProtocol.view) {
      pushToast(toastInfoProtocol);
      dispatch(deleteProtocolToast());
    }
  }, [toastIsViewedProtocol, dispatch, toastInfoProtocol]);

  return (
    <PageLayout>
      <ProtocolsContainer>
        <ProtocolWorkArea />
      </ProtocolsContainer>
    </PageLayout>
  );
};

export default Protocols;
