import { LoadingState } from "../../../../store/common";

import { SLICE_NAME } from "./types";

export const getCourse = () => (state) => state[SLICE_NAME].courses;
export const courseIsGetted = () => (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const courseIsLoading = (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const courseIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const getCourseDetail = (state) => state[SLICE_NAME].course;

export const courseToast = () => (state) => state[SLICE_NAME].toast;
export const toastIsView = () => (state) => state[SLICE_NAME].toast.view;
