import { useRef } from "react";
import { IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";
import { PlusIcon } from "../../assets/icon/PlusIcon";
import { useIsOverflow } from "../../hooks/hooks";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { TooltipText } from "../../components/Autocomplete";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";

const RowSuggestProducts = ({ id, name, productBrand, onAdd }) => {
  const refName = useRef(null);
  const refBrand = useRef(null);

  const [isOverflowName] = useIsOverflow(refName);
  const [isOverflowBrand] = useIsOverflow(refBrand);

  return (
    <Tr key={id} height="52px">
      <Td ref={refName} width="10px" style={{ ...TEXT_OVERFLOW_STYLE, maxWidth: "346px" }}>
        <TooltipWithContent
          label={name || " - "}
          color="black"
          bg="white"
          placement="bottom-start"
          isDisabled={!isOverflowName}
          content={<TooltipText cursor="default">{name || " - "}</TooltipText>}
        />
      </Td>
      <Td ref={refBrand} style={TEXT_OVERFLOW_STYLE}>
        <TooltipWithContent
          label={productBrand?.name || " - "}
          color="black"
          bg="white"
          placement="bottom-start"
          isDisabled={!isOverflowBrand}
          content={<TooltipText cursor="default">{productBrand?.name || " - "} </TooltipText>}
        />
      </Td>
      <Td style={{ width: "40px" }} p="0">
        <IconButton
          w="40px"
          height="40px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          variant="unstyled"
          onClick={() => onAdd(id)}
          icon={<PlusIcon />}
          aria-label="add"
        />
      </Td>
    </Tr>
  );
};

export default RowSuggestProducts;
