import React, { useEffect, useRef } from "react";
import { Input } from "@chakra-ui/react";
import { useScrollIntoViewError } from "../../hooks/useScrollIntoViewError";
import { useCombinedRefs } from "../../hooks/useCombineRefs";
import { SymbolContainer } from "./style";

const InputWithSymbol = React.forwardRef((props, ref) => {
  const { value, error, symbol, onChange, ...restProps } = props;

  const innerRef = useRef(null);

  const mergedRefs = useCombinedRefs([innerRef, ref]);

  useScrollIntoViewError(mergedRefs, error);

  const onChangeHandle = (e) => {
    onChange(e.target.value);
  };

  const handleFocus = (event) => event.target.select();

  useEffect(() => {
    if (mergedRefs.current) {
      mergedRefs.current.value = value;
    }
  }, [value, mergedRefs]);

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <Input
        ref={mergedRefs}
        placeholder="0"
        value={value}
        onChange={onChangeHandle}
        onFocus={handleFocus}
        autoComplete="off"
        style={{ position: "relative" }}
        {...restProps}
      />
      {!!value && <SymbolContainer>{symbol}</SymbolContainer>}
    </div>
  );
});

export default InputWithSymbol;
