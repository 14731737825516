import { createAsyncThunk } from "@reduxjs/toolkit";

import { SLICE_NAME } from "./types";

import { getCheckToken } from "../../../services/authService";

export const checkAdminToken = createAsyncThunk(
  `${SLICE_NAME}/fetchTokenStatus`,
  async (values, { rejectWithValue }) => {
    try {
      const response = await getCheckToken();
      return response.data;
    } catch (err) {
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      return rejectWithValue("error");
    }
  }
);
