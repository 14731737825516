import React from "react";

export const SearchIcon = () => {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7852 14.7436L10.7229 10.672C11.5501 9.57954 11.9317 8.21388 11.7908 6.85093C11.6498 5.48798 10.9966 4.22933 9.96334 3.32925C8.93003 2.42916 7.59357 1.95473 6.22392 2.00179C4.85427 2.04885 3.55353 2.61391 2.58448 3.5828C1.61542 4.55169 1.05027 5.8522 1.00319 7.22161C0.956123 8.59102 1.43064 9.92726 2.33088 10.9604C3.23112 11.9935 4.48999 12.6465 5.85317 12.7875C7.21636 12.9285 8.58225 12.5469 9.67492 11.7198L13.7472 15.7814C13.8144 15.8511 13.8951 15.9065 13.9843 15.9442C14.0735 15.9819 14.1694 16.0012 14.2662 16.001C14.3632 16.0024 14.4595 15.9836 14.5488 15.9458C14.6382 15.908 14.7187 15.8521 14.7852 15.7814C14.854 15.7136 14.9087 15.6329 14.946 15.5438C14.9833 15.4547 15.0025 15.3591 15.0025 15.2625C15.0025 15.1659 14.9833 15.0703 14.946 14.9812C14.9087 14.8921 14.854 14.8114 14.7852 14.7436ZM2.49855 7.41876C2.49855 6.64309 2.7286 5.88483 3.15962 5.23988C3.59063 4.59493 4.20325 4.09226 4.92 3.79542C5.63676 3.49858 6.42545 3.42092 7.18635 3.57224C7.94725 3.72357 8.64618 4.09709 9.19476 4.64557C9.74334 5.19406 10.1169 5.89287 10.2683 6.65364C10.4196 7.41441 10.342 8.20297 10.0451 8.91959C9.74818 9.63622 9.24541 10.2487 8.60035 10.6797C7.95529 11.1106 7.19691 11.3406 6.4211 11.3406C5.38158 11.338 4.3854 10.924 3.65035 10.189C2.9153 9.45411 2.50118 8.45809 2.49855 7.41876Z"
        fill="#818590"
      />
    </svg>
  );
};
