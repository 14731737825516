import { useRef } from "react";
import { Td, Tooltip, Tr } from "@chakra-ui/react";

import { generateName } from "./helpers";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { useIsOverflow } from "../../hooks/hooks";
import { EMAIL_WIDTH, MANAGER_WIDTH, PHONE_WIDTH } from "./style";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";

const RowUsersTable = ({ user }) => {
  const {
    manager,
    user: { firmName, name, lastName, secondName, email, phone },
  } = user;

  const refName = useRef(null);
  const refEmail = useRef(null);

  const [isOverflowEmail] = useIsOverflow(refEmail);
  const [isOverflowName] = useIsOverflow(refName);

  const finalName = generateName(firmName, name, lastName, secondName);

  return (
    <Tr>
      <Td style={TEXT_OVERFLOW_STYLE} ref={refName}>
        <TooltipWithContent isOverflow={isOverflowName} text={finalName} />
      </Td>

      <Td style={EMAIL_WIDTH} ref={refEmail}>
        <TooltipWithContent isOverflow={isOverflowEmail} text={email} />
      </Td>
      <Td style={PHONE_WIDTH}>{phone}</Td>
      <Td style={MANAGER_WIDTH}>{manager || "-"}</Td>
    </Tr>
  );
};

export default RowUsersTable;
