import PageLayout from "../../components/PageLayout/PageLayout";
import styled from "@emotion/styled";

import LinkRow from "./LinkRow";
import { settingSubLinkAdmin, settingSubLinkManager, settingSubLink } from "./constants";
import { useSelector } from "react-redux";
import { getCurrentUserState } from "../Login/store/Login.selectors";
import { checkIsRoleAdmin } from "../../utils/roles";
import { EmployeeToastView } from "../Employees/EmployeeToastView";

const SettingsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
});

const Settings = () => {
  const { role, id } = useSelector(getCurrentUserState);

  return (
    <PageLayout>
      <SettingsContainer>
        <EmployeeToastView>
          {checkIsRoleAdmin(role)
            ? settingSubLinkAdmin.map((link, index) => <LinkRow key={index} text={link.text} path={link.path} />)
            : settingSubLinkManager.map((link, index) => <LinkRow key={index} text={link.text} path={link.path(id)} />)}
        </EmployeeToastView>
      </SettingsContainer>
    </PageLayout>
  );
};

export default Settings;
