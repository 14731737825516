import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Table, TableContainer, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";

import { DeleteItemModal } from "../../components/DeleteModals";
import TableRow from "./TableRow";
import { sortByField } from "./utils/sortByField";
import { isEqual } from "lodash";

const SubcategoriesTable = ({ subcategories = [], selectedId, onDelete, onChangePosition, ...props }) => {
  const [subcategoriesList, setSubcategoriesList] = useState(subcategories.sort(sortByField("position")));
  const [editSubcategory, setEditSubcategory] = useState(null);
  const [deleteSubcategory, setDeleteSubcategory] = useState(null);

  useEffect(() => {
    setSubcategoriesList(subcategories.sort(sortByField("position")));
  }, [subcategories]);

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const tempListSubCategory = Array.from(subcategoriesList);
    const [selectedSubCategory] = tempListSubCategory.splice(source.index, 1);
    tempListSubCategory.splice(destination.index, 0, selectedSubCategory);

    if (!isEqual(subcategoriesList, tempListSubCategory)) {
      setSubcategoriesList(tempListSubCategory);
      onChangePosition(tempListSubCategory.map((item) => item.id));
    }
  };

  return (
    <>
      <TableContainer w="100%" height="calc(100% - 115px)" paddingBottom="16px" overflowY="auto">
        {subcategories?.length > 0 ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Table variant="simple" colorScheme="blackAlpha" size="sm">
              <Thead>
                <Tr
                  height="44px"
                  padding="10px 8px"
                  backgroundColor="#F8F8FA"
                  color="#737680"
                  borderTop="1px solid #EBECEF"
                  borderBottom="1px solid #EBECEF"
                >
                  <Th>Название подкатегории</Th>
                  <Th />
                </Tr>
              </Thead>
              <Droppable droppableId="subCategories">
                {(provider) => (
                  <Tbody ref={provider.innerRef} {...provider.droppableProps}>
                    {subcategoriesList.map((row, index) => (
                      <Draggable key={`subcategory-${row.id}`} draggableId={row.id} index={index}>
                        {/* eslint-disable-next-line no-shadow */}
                        {(provider) => (
                          <TableRow
                            itemsList={subcategoriesList}
                            provider={provider}
                            {...props}
                            row={row}
                            editedId={editSubcategory?.id}
                            onEdit={setEditSubcategory}
                            onDelete={setDeleteSubcategory}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provider.placeholder}
                  </Tbody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        ) : (
          <Text>Здесь пока нет подкатегорий</Text>
        )}
      </TableContainer>

      {deleteSubcategory && (
        <DeleteItemModal
          label="подкатегорию"
          item={deleteSubcategory}
          descripion='При удалении подкатегории, товары которые принадлежат ТОЛЬКО этой подкатегории будут перемещены в "Неготовые"'
          onClose={setDeleteSubcategory}
          onDelete={onDelete}
        />
      )}
    </>
  );
};

export default SubcategoriesTable;
