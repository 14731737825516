import { PERCENT } from "../constants";

export const transformBannerForSend = ({ products, discount, brand, ...sendBanner }) => ({
  ...sendBanner,
  brandId: brand?.id ?? null,
  [`${sendBanner.type === PERCENT ? "percent" : "price"}`]: discount ?? sendBanner.percent ?? sendBanner.price,
  productIds: products?.map(({ id }) => id),
});

export const transformOdlBanner = ({ name, description, promocode, image, products, availableFor }) => {
  return {
    name,
    description,
    discount: promocode?.percent || 0,
    image,
    products,
    availableFor,
  };
};
