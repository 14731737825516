import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import TableRow from "./TableRow";
import { CONTAINER_STYLE } from "./style";
import { useSelector } from "react-redux";
import { promocodesIsLoading } from "./store/Promocodes.selectors";
import { useCallback } from "react";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";

const TheadColumns = [
  { name: "Заголовок", width: 30 },
  { name: "Доступен для", width: 26 },
  { name: "Действует с:", width: 17 },
  { name: "Действует до:", width: 17 },
  { name: "", width: 10 },
];

const PromocodeTable = ({ promocodes = [], onEdit = () => {}, onDelete }) => {
  const isLoading = useSelector(promocodesIsLoading);

  const promocodeRow = (promocode) => (
    <TableRow
      key={`promocode-${promocode.id}`}
      onEdit={() => onEdit(promocode)}
      onDelete={() => onDelete(promocode)}
      {...promocode}
    />
  );

  return (
    <TableConstructor
      containerStyles={CONTAINER_STYLE}
      notFoundText="Здесь пока нет промокодов"
      Row={promocodeRow}
      items={promocodes}
      isLoading={isLoading}
      theadItems={TheadColumns}
    />
  );
};

export default PromocodeTable;
