export const convertStoryToApi = (story) => {
  const startTimeArray = story.startTime.split(":");
  const endTimeArray = story.endTime.split(":");

  const startDate = new Date(story.dateRange[0].setHours(startTimeArray[0], startTimeArray[1])).toISOString();
  const endDate = new Date(story.dateRange[1].setHours(endTimeArray[0], endTimeArray[1])).toISOString();

  const { dateRange, startTime, endTime, status, allowedRole, allowedWarehouse, ...props } = story;

  const result = {
    ...props,
    startDate,
    endDate,
    allowedRole: story.allowedRole.value === "ALL" ? "" : story.allowedRole.value,
    allowedWarehouse:
      story.allowedWarehouse && story.allowedWarehouse?.name ? { id: story.allowedWarehouse?.id } : null,
  };

  return result;
};
