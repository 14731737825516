import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";

export const getBanners = () => (state) => state[SLICE_NAME].banners;
export const getAnotherBannersSelector = () => (state) => state[SLICE_NAME].anotherBanners;
export const bannersIsGetted = () => (state) => state[SLICE_NAME].loadingState === LoadingState.FULFILLED;
export const bannersIsUpdated = () => (state) => state[SLICE_NAME].loadingState === LoadingState.IDLE;
export const bannersIsLoading = () => (state) => state[SLICE_NAME].loadingState === LoadingState.PENDING;
export const bannersToast = () => (state) => state[SLICE_NAME].toast;
export const bannersToastIsView = () => (state) => state[SLICE_NAME].toast.view;
export const getBannerState = (state) => state[SLICE_NAME].banner;
