import { each, groupBy, keys } from "lodash";

export const buildError = async (error) => {
  const errors = {};

  const fieldErrors = await groupBy(error.inner, "path");
  const fields = keys(fieldErrors);

  each(fields, (field) => {
    const errorsHolder = [];

    each(fieldErrors[field], (fieldError) => {
      errorsHolder.push(fieldError.errors[0]);
    });

    errors[field] = errorsHolder;
  });
  return errors;
};
