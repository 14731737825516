import { useRef, useState } from "react";
import { Flex, FormControl, FormErrorMessage, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";

import { ReactComponent as EditIcon } from "../../assets/svg/EditIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/DeleteIcon.svg";
import { ReactComponent as SubmitIcon } from "../../assets/svg/SubmitIcon.svg";
import { ReactComponent as CancelIcon } from "../../assets/svg/CancelIcon.svg";

import InputNewName from "../../components/InputNewName";
import { useIsOverflow } from "../../hooks/hooks";
import { TEXT_OVERFLOW_STYLE } from "../../common/constants";
import { removeSpaces } from "../../utils/removeSpaces";
import { checkIsEnterKey } from "../../utils/checkIsEnterKey";
import { TooltipWithContent } from "../../components/TooltipWithContent/TooltipWithContent";

const CategoryRow = (props) => {
  const {
    row,
    selectedId,
    editedId,
    isSelecteble,
    onUpdate,
    onDelete,
    onEdit,
    onSelect,
    validationName,
    onClose,
    resetError,
  } = props;

  const [newCategoryName, setNewCategoryName] = useState(row.name);

  const rowRef = useRef(null);
  const ref = useRef(null);
  const inputRef = useRef(null);

  const isSelected = row.id === selectedId;
  const isEditing = row.id === editedId;

  const [isOverflow] = useIsOverflow(ref);

  const rowActions = isSelecteble
    ? {
        color: isSelected ? "#BD68CA" : "#434652",
        cursor: "pointer",
        onClick: () => onSelect(isSelected ? {} : row),
      }
    : {};

  const handleChangeName = (e) => {
    resetError();
    setNewCategoryName(e.target.value);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(row);
  };
  const handleEdit = () => {
    onEdit(row.id);
  };
  const handleClose = () => {
    onClose();
  };
  const handleKeyDown = (e) => {
    if (checkIsEnterKey(e.key)) {
      const name = removeSpaces(newCategoryName);

      if (name && name !== row.name) {
        onUpdate(row.id, newCategoryName);
        return;
      }
      onClose();
    } else if (e.key === "Escape") {
      handleClose();
    }
  };
  const handleUpdate = () => {
    const name = removeSpaces(newCategoryName);

    if (name && name !== row.name) {
      onUpdate(row.id, newCategoryName);
      return;
    }
    onClose();
  };

  return (
    <Tr
      ref={rowRef}
      tabIndex={0}
      borderBottom="solid 1px #EBECEF"
      backgroundColor={isSelected ? "#F8F8FA" : "transparent"}
    >
      <Td {...rowActions} style={TEXT_OVERFLOW_STYLE} ref={ref}>
        {isEditing ? (
          <FormControl isInvalid={validationName?.name}>
            <InputNewName
              rowRef={inputRef}
              value={newCategoryName || ""}
              onKeyDown={handleKeyDown}
              onChange={handleChangeName}
              onClick={(e) => e.stopPropagation()}
              onClose={handleClose}
            />
            <FormErrorMessage>{validationName?.name}</FormErrorMessage>
          </FormControl>
        ) : (
          <TooltipWithContent isOverflow={isOverflow} text={row.name} />
        )}
      </Td>
      <Td size="sm" w="100px">
        <Flex gap="16px">
          <IconButton
            style={{ display: !isEditing ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<SubmitIcon />}
            onClick={handleUpdate}
            aria-label="Save"
          />
          <IconButton
            style={{ display: !isEditing ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<CancelIcon />}
            onClick={handleClose}
            aria-label="Close"
          />
          <IconButton
            style={{ display: isEditing ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<EditIcon />}
            onClick={handleEdit}
            aria-label="Edit"
          />
          <IconButton
            style={{ display: isEditing ? "none" : "" }}
            variant="unstyled"
            w="24px"
            height="24px"
            icon={<DeleteIcon />}
            onClick={handleDelete}
            aria-label="Delete"
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default CategoryRow;
