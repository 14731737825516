import { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { usePrompt } from "../../../hooks/usePrompt";

import { Input, InputGroup, InputRightElement, Text, Image } from "@chakra-ui/react";
import { LinkIcon } from "../../../assets/icon/LinkIcon";
import { Drawer } from "../../../components/Drawer/Drawer";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { FormControlWithError } from "../../../components/FormControlWithError/FormControlWithError";
import DescriptionTextarea from "../../../components/DescriptionTextarea/DescriptionTextarea";
import { UploadInputGroup } from "../../../components/UploadInputGroup/UploadInputGroup";
import Autocomplete from "../../../components/Autocomplete";
import { PRODUCT_INPUT_STYLE } from "../../Products/style";

import { HINT_STYLE } from "../../../common/constants";
import { COURSE_VALIDATION_SCHEMA, COURSES_AVAILABLE_ROLES } from "../constants";
import {
  addingCourse,
  editingCourse,
  getCourse as requestCourse,
  addCourseMediaThunk,
} from "../store/courses/courses.thunk";
import { handleEnterKey } from "../../../utils/blurOnEnter";
import { getCourseDetail } from "../store/courses/courses.selectors";
import { convertCourseFromApi, convertCourseToApi } from "../utils/transformData";
import { CitiesAutocomplete } from "../../../components/CitiesAutocomplete/CitiesAutocomplete";
import { RETAIL, SPECIALIST } from "../../Banners/constants";

const initialValues = {
  name: "",
  description: "",
  city: "",
  dateRange: [null, null],
  imageUrl: "",
  courseFile: "",
  allowedRoles: { name: "Все", value: [SPECIALIST, RETAIL] },
};

const CourseModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const { courseDetail: course, loadingState } = useSelector(getCourseDetail);

  const handleCloseModal = () => navigate(-1);

  const courseImageFileSelector = useRef();

  const handleSave = (courseValues) => {
    const courseForSend = convertCourseToApi(courseValues);

    if (id) {
      dispatch(editingCourse({ id, values: courseForSend }));
    } else {
      dispatch(addingCourse(courseForSend));
    }

    handleCloseModal();
  };

  const { values, errors, handleChange, resetForm, handleSubmit, setFieldValue, dirty, isSubmitting, setFieldError } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validateOnChange: false,
      onSubmit: handleSave,
      validationSchema: COURSE_VALIDATION_SCHEMA(),
    });

  const handleChangeCity = (value) => {
    setFieldValue("city", value);
  };

  const handleChangeRangeDate = (update) => {
    if (!update) {
      setFieldValue("dateRange", [null, null]);
      return;
    }
    setFieldValue("dateRange", update);
  };

  const handleCourseSelectFile = useCallback(
    (file) => {
      dispatch(addCourseMediaThunk({ file })).then(({ payload }) => {
        setFieldValue("imageId", payload.id);
        setFieldValue("imageUrl", payload.url);
      });
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (id) {
      dispatch(requestCourse(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && course && course.id === id) {
      resetForm({ values: convertCourseFromApi(course) });
    }
  }, [id, course]);

  usePrompt(dirty && !isSubmitting);

  return (
    <Drawer isOpen handleClose={handleCloseModal} isLoading={loadingState} handleSave={handleSubmit}>
      <FormControlWithError hasError={errors?.name} errorText={errors.name} title="Название курса*">
        <Input autoComplete="off" name="name" value={values.name} onChange={handleChange} onKeyDown={handleEnterKey} />
      </FormControlWithError>

      <FormControlWithError hasError={errors?.description} errorText={errors.description} title="Описание курса*">
        <DescriptionTextarea
          placeholder="Опишите курс"
          value={values.description}
          onChange={(text) => setFieldValue("description", text)}
        />
      </FormControlWithError>

      <FormControlWithError hasError={!!errors?.dateRange} errorText={errors.dateRange} title="Даты проведения курса*">
        <DatePicker
          placeholder="C дд.мм.гг - По дд.мм.гг"
          selected={values.dateRange[0]}
          startDate={values.dateRange[0]}
          endDate={values.dateRange[1]}
          onChange={handleChangeRangeDate}
          selectsRange
        />
      </FormControlWithError>
      <FormControlWithError title="Кому доступен курс*">
        <Autocomplete
          placeholder="Выберите доступность"
          value={values.allowedRoles}
          onChange={(value) => setFieldValue("allowedRoles", value)}
          items={COURSES_AVAILABLE_ROLES}
          style={PRODUCT_INPUT_STYLE}
        />
      </FormControlWithError>

      <FormControlWithError hasError={errors?.city} errorText={errors.city} title="Город*">
        <CitiesAutocomplete handleChange={handleChangeCity} value={values.city} />
      </FormControlWithError>

      <FormControlWithError
        extraContent={<Text sx={HINT_STYLE}>Материалы курса, формат pdf</Text>}
        hasError={errors?.courseFile}
        errorText={errors.courseFile}
        title="Полные материалы курса*"
      >
        <InputGroup>
          <InputRightElement>
            <LinkIcon />
          </InputRightElement>
          <Input
            value={values.courseFile}
            name="courseFile"
            placeholder="Вставьте ссылку на файл"
            onChange={handleChange}
          />
        </InputGroup>
      </FormControlWithError>

      <FormControlWithError
        extraContent={<Text sx={HINT_STYLE}>Фото превью курса (В сооотношении 1:1)</Text>}
        hasError={errors?.imageId}
        errorText={errors.imageId}
        title="Фото превью курса*"
      >
        <UploadInputGroup
          ref={courseImageFileSelector}
          placeholder="Загрузите изображение"
          errorSetter={(errorText) => setFieldError("imageId", errorText)}
          image={values.imageUrl}
          handleSelectFile={handleCourseSelectFile}
        />
      </FormControlWithError>
    </Drawer>
  );
};

export default CourseModal;
