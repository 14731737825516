import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Text, Checkbox, Button, Flex, Img, Link } from "@chakra-ui/react";

import { USER_VALIDATION_SCHEMA } from "./constants";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { RoleSelect } from "../../components/RoleSelect/RoleSelect";
import { usePrompt } from "../../hooks/usePrompt";
import { getRoleObj } from "../Promocodes/utils/getRoleObj";
import { getClient, isLoadingClientInfo, isLoadingEditingClientInfo } from "../Clients/store/Clients.selectors";
import vkLogo from "../../assets/png/vk-logo.png";

import { WarehousesAutocomplete } from "../../components/WarehousesAutocomplete/WarehousesAutocomplete";
import { editingClientThunk } from "../Clients/store/Clients.thunk";
import { transformClientForSend } from "./utils/transformClientForSend";
import { SPECIALIST, RETAIL } from "../Banners/constants";
import { ContentContainer } from "./ContentContainer";
import Preloader from "../../components/Preloader";
import { ClientInfoInput } from "./ClientInfoInput";
import { ClientInfoInputContainer } from "./ClientInfoInputContainer";
import { getUserStatusNameByStatusKey } from "./utils/getUserStatusNameByStatusKey";

const initialValues = {
  name: "",
  lastName: "",
  secondName: "",
  firmName: "",
  email: "",
  role: null,
  phone: "",
  warehouse: null,
  isVerifiedPerson: null,
};

export const ClientEditForm = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const client = useSelector(getClient());
  const isLoadingInfo = useSelector(isLoadingClientInfo());
  const isLoadingEditingInfo = useSelector(isLoadingEditingClientInfo());

  const handleSaveUser = (values) => {
    dispatch(editingClientThunk({ id, objToSend: transformClientForSend(values) }));
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: handleSaveUser,
    validationSchema: USER_VALIDATION_SCHEMA,
  });

  const { values, errors, setFieldValue, handleSubmit, dirty, resetForm } = formik;

  const handleChangeRole = (value) => {
    if (value.value === RETAIL) {
      setFieldValue("isVerifiedPerson", false);
    }

    if (value.value === SPECIALIST && client.role === SPECIALIST && client.verificationStatus === "accepted") {
      setFieldValue("isVerifiedPerson", true);
    }

    setFieldValue("role", value);
  };

  const handleChangeWarehouse = (value) => {
    setFieldValue("warehouse", value);
  };

  const handleChangeField = (value, key) => {
    setFieldValue(key, value);
  };

  useEffect(() => {
    if (client && id === client.id) {
      resetForm({
        values: {
          name: client.name,
          lastName: client.lastName,
          secondName: client.secondName,
          firmName: client.firmName,
          email: client.email,
          role: getRoleObj(client.role),
          phone: client.phone,
          warehouse: client.warehouse ? { id: client.warehouse.id, name: client.warehouse.city } : null,
          isVerifiedPerson: client.verificationStatus === "accepted",
        },
      });
    }
  }, [id, client]);

  usePrompt(!formik.isSubmitting && dirty);

  return (
    <ContentContainer title="Информация о пользователе">
      {isLoadingInfo ? (
        <Preloader height="auto" />
      ) : (
        <>
          <ClientInfoInput
            label="Имя*"
            value={values.name}
            placeholder="Имя не указано"
            onChange={(e) => handleChangeField(e.target.value, "name")}
            hasError={!!errors?.name}
            errorText={errors?.name}
          />

          <ClientInfoInput
            label="Фамилия*"
            value={values.lastName}
            placeholder="Фамилия не указана"
            onChange={(e) => handleChangeField(e.target.value, "lastName")}
            hasError={!!errors?.lastName}
            errorText={errors?.lastName}
          />

          <ClientInfoInput
            label="Отчество"
            value={values.secondName}
            placeholder="Отчество не указано"
            onChange={(e) => handleChangeField(e.target.value, "secondName")}
            hasError={!!errors?.secondName}
            errorText={errors?.secondName}
          />

          <ClientInfoInput
            label="Организация"
            value={values.firmName}
            placeholder="Организация не указана"
            onChange={(e) => handleChangeField(e.target.value, "firmName")}
            hasError={!!errors?.firmName}
            errorText={errors?.firmName}
          />

          <ClientInfoInput
            label="Почта*"
            value={values.email}
            placeholder="Организация не указана"
            onChange={(e) => handleChangeField(e.target.value, "email")}
            hasError={!!errors?.email}
            errorText={errors?.email}
            type="email"
          />

          <ClientInfoInput
            label="Номер телефона*"
            value={values.phone}
            placeholder="Номер телефона не указан"
            onChange={(e) => handleChangeField(e.target.value, "phone")}
            hasError={!!errors?.phone}
            errorText={errors?.phone}
          />

          <ClientInfoInputContainer label="Роль*" hasError={!!errors?.role} errorText={errors?.role}>
            <RoleSelect value={values.role} placeholder="Роль не указана" onChange={handleChangeRole} />
          </ClientInfoInputContainer>

          <ClientInfoInputContainer label="Город*" hasError={!!errors?.warehouse} errorText={errors?.warehouse}>
            <WarehousesAutocomplete
              value={values.warehouse}
              placeholder="Склад не указан"
              onChange={handleChangeWarehouse}
            />
          </ClientInfoInputContainer>

          {client?.role === SPECIALIST && (
            <ClientInfoInput
              label="Статус"
              value={getUserStatusNameByStatusKey(client.verificationStatus)}
              placeholder="Неизвестно"
              isReadOnly
            />
          )}

          {client?.vkUrl && (
            <ClientInfoInputContainer label="Ссылки на соцсети">
              <Link href={client?.vkUrl} width="30px" display="block" target="_blank" rel="noopener noreferrer">
                <Img src={vkLogo} height="30px" />
              </Link>
            </ClientInfoInputContainer>
          )}

          {values.role?.value === SPECIALIST && (
            <Checkbox
              width="fit-content"
              isChecked={values.isVerifiedPerson}
              onChange={() => setFieldValue("isVerifiedPerson", !values.isVerifiedPerson)}
              marginLeft="calc(19.5% + 12px)"
            >
              <Text>Заявка подтверждена</Text>
            </Checkbox>
          )}
          <Flex mb="16px" w="100%">
            <Button
              w="135px"
              bgColor="#C06ECC"
              color="#ffffff"
              disabled={!dirty}
              isLoading={isLoadingEditingInfo}
              onClick={handleSubmit}
            >
              Сохранить
            </Button>
          </Flex>
        </>
      )}
    </ContentContainer>
  );
};
