import { createAsyncThunk } from "@reduxjs/toolkit";

import { getSpecialistPromocodes, getReportSpecialistPromocodes } from "../../../services/specialistPromocodesService";
import { SLICE_NAME } from "./types";
import { generateFile } from "../../../utils/generateFile";

export const getSpecialistPromocodesThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchGetSpecialistPromocodes`,
  async (params, { rejectWithValue }) => {
    try {
      const result = await getSpecialistPromocodes(params);

      return result;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const createReportSpecialistPromocodesThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchCreateSpecialistPromocodesReport`,
  async (params, { rejectWithValue }) => {
    try {
      const result = await getReportSpecialistPromocodes(params);

      generateFile(result.data.data);

      return result.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
