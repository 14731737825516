import { useRef } from "react";
import { Input } from "@chakra-ui/react";
import { useScrollIntoViewError } from "../../hooks/useScrollIntoViewError";

const InputName = ({ value, error, onChange, onEnter, style = null, ...props }) => {
  const ref = useRef(null);
  useScrollIntoViewError(ref, error);
  return (
    <Input
      ref={ref}
      onChange={(event) => onChange(event.target.value)}
      value={value}
      onKeyDown={onEnter}
      style={style}
      autoComplete="off"
      {...props}
    />
  );
};

export default InputName;
