import { createAsyncThunk } from "@reduxjs/toolkit";

// eslint-disable-next-line import/no-cycle
import { SLICE_NAME } from "./types";
import {
  addProtocolCategory,
  deleteProtocolCategory,
  getProtocolCategories,
  updateProtocolCategory,
} from "../../../services/categoryProtocolService";

export const addingProtocolCategory = createAsyncThunk(
  `${SLICE_NAME}/fetchAddProtocolCategory`,
  async (values, { rejectWithValue }) => {
    try {
      return await addProtocolCategory(values);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const gettingProtocolCategories = createAsyncThunk(
  `${SLICE_NAME}/fetchGetProtocolCategory`,
  async (values, { rejectWithValue }) => {
    try {
      const res = await getProtocolCategories();
      return res.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const editingProtocolCategory = createAsyncThunk(
  `${SLICE_NAME}/fetchEditProtocolCategory`,
  async ({ name, id }, { rejectWithValue }) => {
    try {
      return await updateProtocolCategory(name, id);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const deletingProtocolCategory = createAsyncThunk(
  `${SLICE_NAME}/fetchDeleteProtocolCategory`,
  async (id, { rejectWithValue }) => {
    try {
      return await deleteProtocolCategory(id);
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
