import React from "react";

export const CitiesIcon = ({ isActive = false }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.035 3.20996C21.8942 3.09053 21.7255 3.00866 21.5445 2.97194C21.3636 2.93522 21.1763 2.94486 21 2.99996L16.185 4.82996L9.13503 2.99996C9.09022 2.99398 9.04483 2.99398 9.00003 2.99996C8.89621 2.97766 8.78884 2.97766 8.68503 2.99996L2.29503 4.91996C2.06414 4.9908 1.86218 5.13406 1.71901 5.32856C1.57584 5.52305 1.49906 5.75846 1.50003 5.99996V19.875C1.49884 20.0498 1.53899 20.2225 1.61719 20.3789C1.69539 20.5353 1.80943 20.671 1.95003 20.775C2.13738 20.924 2.37062 21.0036 2.61003 21C2.70941 21.0157 2.81064 21.0157 2.91003 21L8.91003 19.215L15.975 21H16.23C16.3496 21.0144 16.4705 21.0144 16.59 21L21.75 19.125C21.9787 19.042 22.1749 18.8881 22.3098 18.6857C22.4448 18.4832 22.5114 18.243 22.5 18V4.12496C22.4983 3.94692 22.4554 3.7717 22.3747 3.61297C22.2941 3.45425 22.1778 3.31632 22.035 3.20996ZM10.035 5.56496L15.195 6.88496V18.435L10.035 17.115V5.56496ZM7.77003 17.16L3.70503 18.375V6.83996L7.77003 5.62496V17.16ZM20.295 17.16L17.295 18.24V6.79496L20.295 5.71496V17.16Z"
        fill={isActive ? "white" : "black"}
      />
    </svg>
  );
};
