import React from "react";
import styled from "@emotion/styled";
import { checkMobileDevice } from "../../utils/checkMobileDevice";
import Header from "../Header/Header";

const isMobile = checkMobileDevice();

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginLeft: "25px",
});

const mobileStyle = {
  margin: "0 15px",
  minWidth: "270px",
};

const PageContainer = ({ children }) => (
  <Container style={!isMobile ? { paddingRight: "96px", gap: 24 } : mobileStyle}>
    <Header />
    {children}
  </Container>
);

export default PageContainer;
