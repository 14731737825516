import React from "react";
import { CONTAINER_DESKTOP, CONTAINER_MOBILE } from "./style";
import { checkMobileDevice } from "../../utils/checkMobileDevice";

const isMobile = checkMobileDevice();

export const ContentContainer = ({ title, children }) => {
  return (
    <div style={!isMobile ? CONTAINER_DESKTOP : CONTAINER_MOBILE}>
      <div
        style={{
          alignSelf: "flex-start",
          fontSize: "20px",
          lineHeight: "24px",
          marginBottom: "24px",
        }}
      >
        {title}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </div>
    </div>
  );
};
