import { DateTime } from "luxon";

// TODO: Temporary measure before backend changes
export const formatedDate = (date) => {
  if (!date) {
    return date;
  }

  const formattedDate = new Date(date).toISOString().slice(0, 16);

  return formattedDate.at(0).toUpperCase() + formattedDate.slice(1);
};

export const transformDate = (date) => {
  if (!date) return "";
  return new Date(date);
};

export const convertTimeToCurrentTimeZone = (time) => {
  if (!time) {
    return time;
  }

  const dateTime = DateTime.fromISO(time, { zone: "utc" }).setZone("local");
  const formattedTime = dateTime.toFormat("dd.LL.yyyy HH:mm");

  return formattedTime;
};
