import React from "react";
import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { checkMobileDevice } from "../../utils/checkMobileDevice";
import SidebarMenu from "../Sidebar";
import Header from "../Header/Header";

const isMobile = checkMobileDevice();

const PageContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100vh",
  marginLeft: "25px",
});

const ContentContainer = styled("div")({
  padding: !isMobile ? "24px 24px 0 24px" : "24px 16px 0",
  overflowY: "auto",
  backgroundColor: "#fff",
  boxShadow: "-5px 1px 15px rgba(0, 0, 0, 0.05)",
  borderRadius: "24px 24px 0px 0px",
  height: "calc(100% - 88px)",
});

const mobileStyle = {
  margin: "0 15px",
  minWidth: "270px",
};

const PageLayout = ({ children }) => (
  <PageContainer style={!isMobile ? { paddingRight: "96px" } : mobileStyle}>
    <Header />
    <ContentContainer>{children}</ContentContainer>
  </PageContainer>
);

export default PageLayout;
