export const PRODUCT_HEAD_TEXT_STYLE = {
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "24px",
  color: "#37393D",
};

export const PRODUCT_BTN_REJECT = {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#37393D",
};

export const PRODUCT_BTN_SAVE = {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#FFF",
  background: "#C06ECC",
};

export const PRODUCT_READ_ONLY = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#AAAFBD",
  background: "#F8F8FA",
};

export const PRODUCT_INPUT_STYLE = {
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#37393D",
};

export const PRODUCT_HINT = {
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "20px",
  color: "#737680",
};

export const EXPORT_FILE_BTN = {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "24px",
};
