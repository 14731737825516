import qs from "qs";
import { useCallback } from "react";
import { useSearchParams as useSearchParamsRouter } from "react-router-dom";

export const useSearchParams = (initialState) => {
  const initialSearchParams = typeof initialState === "string" ? initialState : qs.stringify(initialState);

  const [searchParams, setSearchParams] = useSearchParamsRouter(initialSearchParams);

  const handleAddSearchParams = useCallback(
    (value) => setSearchParams(`${searchParams.toString()}&${qs.stringify(value)}`),
    [setSearchParams]
  );

  const handleChangeSearchParams = useCallback(
    (params) => {
      setSearchParams(qs.stringify({ ...qs.parse(searchParams.toString()), ...params }));
    },
    [setSearchParams]
  );

  const handleDeleteSearchParams = useCallback(() => setSearchParams(""), [setSearchParams]);

  const handleGetSearchFieldValue = useCallback((field) => searchParams.get(field), [searchParams]);

  return {
    searchParams,
    searchParamsString: searchParams.toString(),
    searchParamsObj: qs.parse(searchParams.toString()),
    handleAddSearchParams,
    handleChangeSearchParams,
    handleDeleteSearchParams,
    handleGetSearchFieldValue,
  };
};
