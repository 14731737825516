import { formatISO, add } from "date-fns";
import { COURSES_AVAILABLE_ROLES } from "../constants";

export const getCurrentRolesAutocompleteValue = (inputValue) => {
  const currentAutocompleteRole = COURSES_AVAILABLE_ROLES.find(({ value }) =>
    value.every((role) => inputValue.includes(role))

  );

  return currentAutocompleteRole;
};

export const convertCourseFromApi = (course) => {
  return {
    city: course.city,
    dateRange: [new Date(Date.parse(course.startDate)), new Date(Date.parse(course.endDate))],
    name: course.name,
    description: course.description,
    image: course.imageUrl,
    courseFile: course.courseFile,
    imageUrl: course.imageUrl,
    allowedRoles: course.allowedRoles
      ? getCurrentRolesAutocompleteValue(course.allowedRoles)
      : COURSES_AVAILABLE_ROLES[0],
  };
};

export const convertCourseToApi = ({
  name,
  description,
  imageUrl,
  imageId,
  dateRange,
  courseFile,
  city,
  allowedRoles,
}) => {
  return {
    name: name.trim(),
    description: description.trim(),
    cityId: city.id,
    startDate: formatISO(dateRange[0]),
    endDate: formatISO(add(dateRange[1], { hours: 23, minutes: 59 })),
    imageUrl: imageUrl.trim(),
    courseFile: courseFile.trim(),
    imageId,
    allowedRoles: allowedRoles.value,
  };
};

export const getSubscribedUsersToCourse = (coursesArray) => {
  const usersSubscriptions = [];

  coursesArray.forEach((course) => {
    course.subscribedUsers.forEach((user) => {
      usersSubscriptions.push({ courseName: course.name, courseId: course.id, user });
    });
  });

  return usersSubscriptions;
};
