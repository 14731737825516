import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  addWarehouse,
  deleteWarehouse,
  editWarehouse,
  getWarehouses,
  getWarehouse,
} from "../../../../services/warehouseService";

import { SLICE_NAME } from "./types";

export const getAllWarehouses = createAsyncThunk(
  `${SLICE_NAME}/fetchGetWarehouses`,
  async (values, { rejectWithValue }) => {
    try {
      const warehouses = await getWarehouses();
      return warehouses.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const getWarehouseThunk = createAsyncThunk(`${SLICE_NAME}/fetchWarehouse`, async (id, { rejectWithValue }) => {
  try {
    const response = await getWarehouse(id);

    return response.data;
  } catch (err) {
    return rejectWithValue(err?.response?.data?.message || "Ошибка");
  }
});

export const addingWarehouse = createAsyncThunk(
  `${SLICE_NAME}/fetchAddWarehouse`,
  async (params, { rejectWithValue }) => {
    try {
      return await addWarehouse(params);
    } catch (err) {
      // eslint-disable-next-line consistent-return
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const deletingWarehouse = createAsyncThunk(
  `${SLICE_NAME}/fetchDeleteWarehouse`,
  async (id, { rejectWithValue }) => {
    try {
      return await deleteWarehouse(id);
    } catch (err) {
      // eslint-disable-next-line consistent-return
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);

export const editingWarehouse = createAsyncThunk(
  `${SLICE_NAME}/fetchEditWarehouse`,
  async (params, { rejectWithValue }) => {
    try {
      return await editWarehouse(params);
    } catch (err) {
      // eslint-disable-next-line consistent-return
      return rejectWithValue(err?.response?.data?.message || "Ошибка");
    }
  }
);
