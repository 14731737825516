/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { LoadingState } from "../../../store/common";

import { SLICE_NAME } from "./types";
import { getAllPromotion, addingPromotion, editingPromotion, deletingPromotion } from "./Promotion.thunk";

const initialState = {
  toast: { view: false, title: "", description: "", status: "" },
  loadingState: LoadingState.IDLE,
  promotions: [],
};

export const {
  reducer,
  actions: { deletePromotionToast },
} = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    deletePromotionToast: (state) => {
      state.toast = {
        view: false,
        title: "",
        description: "",
        status: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPromotion.pending, (state) => {
        state.loadingState = LoadingState.PENDING;
      })
      .addCase(getAllPromotion.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(getAllPromotion.fulfilled, (state, action) => {
        state.loadingState = LoadingState.FULFILLED;
        state.promotions = action.payload;
      })
      .addCase(addingPromotion.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(addingPromotion.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.promotions.push(action.payload);
        state.toast = {
          view: true,
          title: "Промоакция добавлена",
          description: "",
          status: "success",
        };
      })
      .addCase(deletingPromotion.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(deletingPromotion.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.promotions = state.promotions.filter(({ id }) => id !== action.payload);
        state.toast = {
          view: true,
          title: "Промоакция удалена",
          description: "",
          status: "success",
        };
      })
      .addCase(editingPromotion.rejected, (state, action) => {
        state.toast = {
          view: true,
          title: action.payload,
          description: action.payload,
          status: "error",
        };
        state.loadingState = LoadingState.REJECTED;
      })
      .addCase(editingPromotion.fulfilled, (state, action) => {
        state.loadingState = LoadingState.IDLE;
        state.promotions = state.promotions.map((promotion) => {
          if (promotion.id === action.payload.id) {
            return action.payload;
          }

          return promotion;
        });
        state.toast = {
          view: true,
          title: "Промоакция изменена",
          description: "",
          status: "success",
        };
      });
  },
});
