export const getUsersOptions = (users, valuesUsers) => {
  if (users.length) {
    const usersIds = valuesUsers.map((user) => user.id);

    const optionsArray = users.reduce((prev, currentValue) => {
      if (!usersIds.includes(currentValue.id)) {
        return [
          ...prev,
          {
            label: `${currentValue.name} ${currentValue.lastName} ${currentValue.secondName ?? ""} ${
              currentValue.phone
            }`,
            value: currentValue,
          },
        ];
      }
      return prev;
    }, []);

    return optionsArray;
  }

  return [];
};
