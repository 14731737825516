import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Text } from "@chakra-ui/react";
import { ValidationError } from "yup";

import { ReactComponent as DoubleRightIcon } from "../../assets/svg/DoubleRightIcon.svg";
import PageLayout from "../../components/PageLayout/PageLayout";
import { ProgressWrapper } from "../../components/Preloader";

import {
  categoriesToast,
  categoriesToastIsView,
  getCategories,
  isCategoriesLoading,
  isCategoriesUpdated,
} from "./store/Categories/Categories.selectors";
import { deleteCategoriesToast } from "./store/Categories/Categories.slice";
import {
  addingCategory,
  deletingCategory,
  editingCategory,
  gettingCategoriesWithSubCategories,
  gettingCategories,
  updatingPositionCategory,
} from "./store/Categories/Categories.thunk";

import {
  getSubcategories,
  isSubcategoriesLoading,
  isSubcategoriesUpdated,
  subcategoriesToast,
  subcategoriesToastIsView,
} from "./store/Subcategories/Subcategories.selectors";
import { deleteSubcategoriesToast } from "./store/Subcategories/Subcategories.slice";
import {
  addingSubcategory,
  deletingSubcategory,
  editingSubcategory,
  gettingSubcategories,
  updatingPositionSubCategory,
} from "./store/Subcategories/Subcategories.thunk";

import AddCategoryAndSubcategory from "./AddCategoryAndSubsategory";
import CategoriesTable from "./CategoriesTable";
import SubcategoriesTable from "./SubcategoriesTable";
import { buildError } from "../Products/utils/buildError";
import { CATEGORIES_VALIDATION_SHCEMA } from "./constants";
import { useCustomToast } from "../../hooks/useCustomToast";
import useUpdateEffect from "../../hooks/useUpdateEffect";

const Categories = () => {
  const dispatch = useDispatch();
  const { pushToast } = useCustomToast();

  // =========     SELECTORS         ==========
  // ============= CATEGORIES =================
  const categories = useSelector(getCategories());
  const isNeedCategoriesUpdate = useSelector(isCategoriesUpdated());
  const isLoadingCategories = useSelector(isCategoriesLoading());
  const categoriesToastInfo = useSelector(categoriesToast());
  const isCategoriesToastVies = useSelector(categoriesToastIsView());
  // ============= SUBCATEGORIES ===============
  const subcategories = useSelector(getSubcategories());
  const isNeedSubcategoriesUpdate = useSelector(isSubcategoriesUpdated());
  const isLoadingSubategories = useSelector(isSubcategoriesLoading());
  const subcategoriesToastInfo = useSelector(subcategoriesToast());
  const isSubcategoriesToastVies = useSelector(subcategoriesToastIsView());
  const [validationSubcategoryError, setValidationSubcategoryError] = useState({});

  const [selectCategory, setSelectCategory] = useState(null);

  const [validationError, setValidationError] = useState({});

  const subcategoriesByCategory = useMemo(() => {
    return subcategories?.filter(({ catalog_product: { id } }) => selectCategory === id);
  }, [selectCategory, subcategories]);

  // ========= CATEGORIES HANDLERS ==========
  const handleCreateCategory = async (name) => {
    try {
      await CATEGORIES_VALIDATION_SHCEMA.validate({ name }, { abortEarly: false });
      dispatch(addingCategory(name));
    } catch (e) {
      if (e instanceof ValidationError) {
        const error = await buildError(e);
        setValidationError(error);
      }
    }
  };
  const handleUpdateCategory = (name, id) => {
    dispatch(editingCategory({ name, id }));
  };
  const handleSelectCategory = (id) => {
    setSelectCategory(id);
  };
  const handleDeleteCategory = (id) => {
    dispatch(deletingCategory(id)).then((res) => {
      if (res.error) {
        return;
      }
      setSelectCategory(null);
    });
  };
  const handleChangePositionCategory = (idCategories) => {
    dispatch(updatingPositionCategory(idCategories));
  };
  const handleChangePositionSubCategory = (idSubCategories) => {
    dispatch(updatingPositionSubCategory({ catalog_id: selectCategory, sub_catalogs: idSubCategories }));
  };
  // ========= SUBCATEGORIES HANDLERS ==========
  const handleCreateSubcategory = async (name) => {
    try {
      await CATEGORIES_VALIDATION_SHCEMA.validate({ name }, { abortEarly: false });
      dispatch(addingSubcategory({ name, categoryId: selectCategory }));
    } catch (e) {
      if (e instanceof ValidationError) {
        const error = await buildError(e);
        setValidationSubcategoryError(error);
      }
    }
  };
  const handleUpdateSubcategory = (name, id) => {
    dispatch(editingSubcategory({ name, id, categoryId: selectCategory }));
  };
  const handleDeleteSubcategory = (id) => {
    dispatch(deletingSubcategory(id));
  };

  // ===== TOAST CALLBACKS =========
  const cbCategoriesToast = useCallback(() => {
    if (categoriesToastInfo.view) {
      pushToast(categoriesToastInfo);
      dispatch(deleteCategoriesToast());
    }
  }, [categoriesToastInfo, dispatch]);

  const cbSubategoriesToast = useCallback(() => {
    if (subcategoriesToastInfo.view) {
      const { title, description, status } = subcategoriesToastInfo;
      pushToast({ title, status, description });
      dispatch(deleteSubcategoriesToast());
    }
  }, [subcategoriesToastInfo, dispatch]);

  useEffect(() => {
    dispatch(gettingCategoriesWithSubCategories());
  }, []);

  useUpdateEffect(() => {
    if (isNeedCategoriesUpdate) {
      dispatch(gettingCategories());
    }
  }, [isNeedCategoriesUpdate, dispatch]);

  useUpdateEffect(() => {
    if (isNeedSubcategoriesUpdate) {
      dispatch(gettingSubcategories());
    }
  }, [isNeedSubcategoriesUpdate, dispatch]);

  useEffect(() => {
    cbCategoriesToast();
  }, [isCategoriesToastVies, cbCategoriesToast]);

  useEffect(() => {
    cbSubategoriesToast();
  }, [isSubcategoriesToastVies, cbSubategoriesToast]);

  return (
    <>
      <PageLayout>
        <Flex minH="100%" height="100%">
          <Box w="100%" height="100%">
            <AddCategoryAndSubcategory
              inputPlaceholder="Введите название категории"
              buttonLabel="Добавить категорию"
              itemsList={categories}
              errors={validationError}
              resetError={setValidationError}
              onSave={handleCreateCategory}
              onClearSelected={handleSelectCategory}
            />
            <CategoriesTable
              isLoading={isLoadingCategories}
              categories={categories}
              selectedId={selectCategory}
              onSelect={handleSelectCategory}
              onUpdate={handleUpdateCategory}
              onDelete={handleDeleteCategory}
              onChangePosition={handleChangePositionCategory}
            />
          </Box>
          <Flex w="208px" height="100%" justifyContent="center" alignItems="center">
            <DoubleRightIcon />
          </Flex>
          <Box w="100%" height="100%">
            {selectCategory ? (
              <>
                <AddCategoryAndSubcategory
                  inputPlaceholder="Введите название подкатегории"
                  buttonLabel="Добавить подкатегорию"
                  itemsList={subcategories}
                  errors={validationSubcategoryError}
                  resetError={setValidationSubcategoryError}
                  onSave={handleCreateSubcategory}
                />
                <SubcategoriesTable
                  subcategories={subcategoriesByCategory}
                  onUpdate={handleUpdateSubcategory}
                  onDelete={handleDeleteSubcategory}
                  onChangePosition={handleChangePositionSubCategory}
                />
              </>
            ) : (
              <Flex height="100%" justifyContent="center" alignItems="center">
                <Text fontSize="lg">Выберите категорию</Text>
              </Flex>
            )}
          </Box>
        </Flex>
      </PageLayout>
      {(isLoadingCategories || isLoadingSubategories) && <ProgressWrapper />}
    </>
  );
};

export default Categories;
