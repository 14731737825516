import { Outlet } from "react-router-dom";
import Users from "../pages/Users/Users";
import { routeGuard } from "./guards/routesGuard";
import DrawerUser from "../pages/Users/DrawerUser";

export const mobileRoutes = () => [
  {
    path: "*",
    element: routeGuard(
      <>
        <Users />
        <Outlet />
      </>
    ),
    children: [{ path: ":id", element: <DrawerUser /> }],
  },
];
