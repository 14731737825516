import { Td, Tr } from "@chakra-ui/react";
import { generateName } from "../../Clients/utils/generateName";
import { TooltipWithContent } from "../../../components/TooltipWithContent/TooltipWithContent";

const TableRow = ({ courseName, user }) => {
  const userFullName = generateName(user);
  return (
    <Tr>
      <Td color="#434652">
        <TooltipWithContent text={courseName} />
      </Td>
      <Td w="250px">
        <TooltipWithContent text={userFullName} />
      </Td>
      <Td w="150px">
        <TooltipWithContent text={user.phone} />
      </Td>
    </Tr>
  );
};

export default TableRow;
