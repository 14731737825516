import React from "react";

export const PlusIcon = () => {
  return (
    <svg
      style={{ color: `#C06ECC` }}
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74992 7.24969H12.0799V8.74969H8.74992V12.0697H7.24992V8.74969H3.91992V7.24969H7.24992V3.92969H8.74992V7.24969Z"
        fill="#C06ECC"
      />
    </svg>
  );
};
