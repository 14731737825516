import { Tooltip } from "@chakra-ui/react";

export const TooltipWithContent = ({ isOverflow, color = "black", bg = "white", text, content }) =>
  isOverflow ? (
    <Tooltip label={text} color={color} bg={bg} placement="bottom-start">
      {content ?? <span>{text}</span>}
    </Tooltip>
  ) : (
    content ?? text
  );
