import { AUTOCOMPLETE_Z_INDEX, TEXT_STYLE } from "../../common/constants";

export const HOURS = [
  "00",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

const createTime = () => {
  const MAX_MINUTE = 60;
  const minutes = [];

  for (let index = 0; index < MAX_MINUTE; index += 1) {
    const minute = index < 10 ? `0${index}` : index.toString();

    minutes.push(minute);
  }

  return minutes;
};

export const MINUTES = createTime();

export const OPTION_CONTAINER_STYLE = {
  maxHeight: "240px",
  background: "#FFFFFF",
  boxShadow: "0px 4px 8px rgba(76, 93, 112, 0.3), 0px 0px 1px rgba(76, 93, 112, 0.3)",
  position: "absolute",
  zIndex: AUTOCOMPLETE_Z_INDEX,
  width: "100%",
  maxWidth: 200,
  overflowY: "hide",
  display: "flex",
  flexWrap: "nowrap",
  ...TEXT_STYLE,
};

export const TIME_MASK = "99:99";

export const DEFAULT_CONTAINER = { width: "50%", overflowY: "auto", display: "flex", flexDirection: "column" };
