import { useCallback } from "react";
import RowWarehouse from "./RowWarehouse";
import { CONTAINER_STYLE } from "./style";
import { TheadColumns } from "./constants";
import { useNavigate } from "react-router-dom";
import { TableConstructor } from "../../components/TableConstructor/TableConstructor";
import { useSelector } from "react-redux";
import { warehousesIsLoading } from "./store/Warehouses/Warehouse.selectors";

const WarehouseTable = ({ warehouses = [], onDelete }) => {
  const isLoading = useSelector(warehousesIsLoading());
  const navigate = useNavigate();

  const handleSelect = (id) => navigate(id);

  const warehouseRow = (warehouseItem) => {
    const { id, name, city, address } = warehouseItem;

    return (
      <RowWarehouse
        key={`city-${id}`}
        id={id}
        address={address}
        name={name || city}
        onSelect={handleSelect}
        onDelete={() => onDelete(warehouseItem)}
      />
    );
  };

  return (
    <TableConstructor
      isLoading={isLoading}
      containerStyles={CONTAINER_STYLE}
      theadItems={TheadColumns}
      Row={warehouseRow}
      notFoundText="Здесь пока нет городов"
      items={warehouses}
    />
  );
};

export default WarehouseTable;
