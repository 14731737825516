import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Grid, Image, Input, Stack, Text } from "@chakra-ui/react";

import Selector from "../../components/Selector";
import { ReactComponent as MagnifierIcon } from "../../assets/svg/MagnifierIcon.svg";
import { DRAWER_HEADER_STYLE, DRAWER_Z_INDEX } from "../../common/constants";
import { siteUrl } from "../../utils/siteUrl";

import { approveUserThunk, getUserThunk, rejectUserThunk } from "./store/Users.thunk";
import { generateName } from "./helpers";
import { handleEnterKey } from "../../utils/blurOnEnter";
import { DEFAULT_PURPOSES_REQUEST, PURPOSES_REQUEST } from "../Products/constants";
import { BUTTON_REJECT } from "./style";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer } from "../../components/Drawer/Drawer";
import { getUser } from "./store/Users.selectors";
import { checkMobileDevice } from "../../utils/checkMobileDevice";

const isMobile = checkMobileDevice();

const ImageWrapper = ({ src }) => {
  const [isOpen, setOpen] = useState(false);

  const showImage = (url) => {
    if (isMobile) {
      return window.open(url, "_blank");
    }

    return setOpen(true);
  };

  return (
    <>
      <Flex position="relative" w="fit-content">
        {src && <Image p="0" w="300px" src={src} />}
        <Flex
          w="100%"
          height="100%"
          position="absolute"
          cursor="pointer"
          justifyContent="center"
          alignItems="center"
          opacity={0}
          _hover={{ backgroundColor: "rgba(0, 0, 0, 0.4)", opacity: 1 }}
          onClick={() => showImage(src)}
        >
          <MagnifierIcon />
        </Flex>
      </Flex>
      {isOpen && (
        <Flex
          position="fixed"
          bgColor="rgba(0, 0, 0, 0.4)"
          onClick={() => setOpen(false)}
          justifyContent="center"
          w="100vw"
          height="100vh"
          top={0}
          left={0}
          zIndex={DRAWER_Z_INDEX}
        >
          {src && <Image src={src} />}
        </Flex>
      )}
    </>
  );
};

const DrawerUser = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userDetail: user, loadingState } = useSelector(getUser);

  const [manager, setManager] = useState("");
  const [type, setType] = useState(DEFAULT_PURPOSES_REQUEST);

  const { firmName, name, lastName, secondName, email, phone } = user?.user ?? {};
  const finalName = generateName(firmName, name, lastName, secondName);

  const handleClose = () => navigate(-1);

  const handleChangeManager = (e) => {
    setManager(e.target.value);
  };

  const handleApprove = () => {
    dispatch(approveUserThunk({ id, body: { manager, type: type.value } }));

    handleClose();
  };

  const handleDiscard = () => {
    dispatch(rejectUserThunk({ id, body: { manager, type: type.value } }));
    handleClose();
  };

  const onPurposeChange = (value) => {
    setType(value);
  };

  useEffect(() => {
    if (id) {
      dispatch(getUserThunk(id));
    }
  }, [id]);

  useEffect(() => {
    if (user && id && user.id === id) {
      setManager(user.manager);
      setType(
        user.user?.type
          ? { value: user.user.type, label: `${user.user.type === "cosmetolog" ? "Косметолог" : "Медик"}` }
          : DEFAULT_PURPOSES_REQUEST
      );
    }
  }, [id, user]);

  const diploma = `${siteUrl}/diploma/${user?.diploma}`;
  const passport = `${siteUrl}/passport/${user?.passport}`;

  return (
    <Drawer
      isOpen
      handleClose={handleClose}
      isLoading={loadingState}
      extraButtonHeader={
        <Grid templateColumns="repeat(2,1fr)">
          <Button sx={BUTTON_REJECT} variant="outline" mr={3} onClick={handleDiscard}>
            Отклонить
          </Button>
          <Button colorScheme="purple" onClick={handleApprove}>
            Подтвердить
          </Button>
        </Grid>
      }
    >
      <Stack spacing={5} mb="12px">
        <Grid templateColumns="repeat(2,1fr)" style={{ gap: "15px" }}>
          <Flex direction="column">
            <Text>ФИО</Text>
            <Input isReadOnly isDisabled autoComplete="off" value={finalName || ""} />
          </Flex>
          <Flex direction="column">
            <Text>Почта</Text>
            <Input isReadOnly isDisabled autoComplete="off" value={email || ""} />
          </Flex>
        </Grid>
      </Stack>

      <Flex direction="column" mb="12px">
        <Text>Менеджер</Text>
        <Input
          autoComplete="off"
          placeholder="Укажите менеджера"
          value={manager || ""}
          onChange={handleChangeManager}
          onKeyDown={handleEnterKey}
        />
      </Flex>

      <Flex direction="column" mb="12px">
        <Text>Телефон</Text>
        <Input isReadOnly isDisabled autoComplete="off" value={phone || ""} />
      </Flex>

      <Flex direction="column" mb="30px">
        <Selector
          value={type}
          height={40}
          placeholder="Выберите тип пользователя"
          onPurposeChange={onPurposeChange}
          options={PURPOSES_REQUEST}
          title="Выберите тип:"
        />
      </Flex>

      <Flex direction="column" mb="12">
        <Text>Фото диплома</Text>
        <ImageWrapper src={diploma} />
      </Flex>

      <Flex direction="column" mb="12">
        <Text>Фото паспорта</Text>
        <ImageWrapper src={passport} />
      </Flex>
    </Drawer>
  );
};

export default DrawerUser;
